import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import $ from "jquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ImageCarousel1 = () => {
  const [progress, setProgress] = useState(0);
  const [activeTextIndex, setActiveTextIndex] = useState(0);

  const texts = [
    {
      header: "Lead MANAGMENT",
      Desc: "Easily manage contacts and tasks and follow up to predict lead scores based on their responses. Addy simplifies contact checking, while a search bar and action center offer quick access to details and upcoming tasks.",
    },
    {
      header: "EMAIL MARKETING",
      Desc: "Customize templates easily, preview, use trigger links, A/B testing, craft email campaigns, analyze performance and collaborate by sharing templates.",
    },
    {
      header: "Third Party Integration",
      Desc: "CRM third-party integration enhances efficiency by connecting the software with external tools, streamlining processes through automation.",
    },
    {
      header: "White Label",
      Desc: "Custom CRM to fit your business, adjusting features and appearance according to customer preferences, with added customization for specific industries.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((progress) => (progress + 0.7) % 95);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setActiveTextIndex(Math.floor(progress / 25));
  }, [progress]);

  return (
    <div>
      <div className="row bgF7F7F7 px-lg-6 d-flex align-items-center">
        <div className="col-lg-5 col-md-6 px-5 a2Hero ">
          <div className="row  position-relative">
            <div className="col-1">
              <div className="dot" style={{ top: `${progress}%` }}></div>
              <div
                className="progress-bar"
                style={{
                  height: $("#a2progress")?.height() - 35,
                }}
              >
                <div
                  className="progress"
                  style={{ height: `${progress}%` }}
                ></div>
              </div>
            </div>
            <div id="a2progress" className="col-11 ">
              {texts.map((text, index) => (
                <div
                  key={index}
                  className={`text col-12 pb-4  ${
                    activeTextIndex === index ? "" : "op25"
                  }`}
                >
                  <label>{text.header}</label>
                  <br />
                  <span>{text.Desc}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-6 mb-4">
          <Carousel
            // onSlide={handleSlide}
            activeIndex={activeTextIndex}
            indicators={false}
            interval={10000}
            controls={false}
          >
            <Carousel.Item interval={10000}>
              <div className="A2Carousel">
                <LazyLoadImage
                  className="img-fluid ms-md-5"
                  src="/images/A2image1.png"
                  alt=""
                  // height={"546px"}
                  // width={"786px"}
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={10000}>
              <div className="A2Carousel">
                <LazyLoadImage
                  className="img-fluid ms-md-5"
                  src="/images/A2image2.png"
                  alt=""
                  // height={"546px"}
                  // width={"786px"}
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={10000}>
              <div className="A2Carousel">
                <LazyLoadImage
                  className="img-fluid ms-md-5"
                  src="/images/A2image3.png"
                  alt=""
                  // height={"546px"}
                  // width={"786px"}
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={10000}>
              <div className="A2Carousel">
                <LazyLoadImage
                  className="img-fluid ms-md-5"
                  src="/images/A2image4.png"
                  alt=""
                  // height={"546px"}
                  // width={"786px"}
                />
              </div>
            </Carousel.Item>
            {/* <Carousel.Item interval={10000}>
              <div className="">
                <LazyLoadImage
                  className="img-fluid ms-md-5"
                  src="/images/A2image5.png"
                  alt=""
                  height={"546px"}
                  width={"786px"}
                />
              </div>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel1;
