import { useEffect, useState } from "react";
import "../../Css/Common.css";
import $ from "jquery";
import Popups from "../Modals/Popups";
import PersonalizedActionModal from "../Modals/PersonalizedActionModal";
import { Link, useLocation } from "react-router-dom";

export function NewNavbar() {
  const [state, setState] = useState({});
  const location = useLocation();
  const [show, setShow] = useState(false);
  var width = $(window).width();
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    $("#navbarNavDropdown").removeClass("show");
    activeNavigation();
    return () => {
      setState({});
    };
  }, []);
  const isSticky = (e) => {
    const header = document.querySelector(".navbarfixedPos");
    const scrollTop = window.scrollY;
    if (scrollTop > 0) {
      header.classList.add("is-sticky");
    } else {
      header.classList.remove("is-sticky");
    }
  };
  const activeNavigation = () => {
    var PathName = window.location.pathname;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get("page");
    $(".nav-link").removeClass("activeNav");
    $(".dropdown-item").removeClass("activeNav");
    if (PathName == "/portfolio") {
      $("#PortfolioId").addClass("activeNav");
    } else if (foo == "whatwedo") {
      $("#whatwedoId").addClass("activeNav");
      window.scroll(0, size1);
    } else if (foo == "whoweare") {
      $("#whoweareId").addClass("activeNav");
      window.scroll(0, size3);
    }
  };
  var width = $(window).width();
  var size1 = 650;
  var size2 = 3500;
  var size3 = 6700;
  if (width <= 991) {
    size1 = 500;
    size2 = 5250;
    size3 = 8850;
  }
  const CheckDropdownOpen = () => {
    if (width <= 991) {
      if ($("#DropdownMenuId").hasClass("d-none")) {
        $("#DropdownMenuId").removeClass("d-none");
        $("#DropdownMenuId").css("display", "block");
      } else {
        $("#DropdownMenuId").addClass("d-none");
        $("#DropdownMenuId").css("display", "none");
      }
    }
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark NavBgColor navbarfixedPos newpx-5">
        <div className="container-fluid mt-2 px-4">
          <Link className="navbar-brand me-0" to="/">
            <img
              src="/images/NavLogo.svg"
              alt="SprigStack footer Logo"
              title="SprigStack Software development Company"
            />
          </Link>
          <button
            className="navbar-toggler mt-4 me-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse mt-1 justify-content-end"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav d-flex alignItemsCenter">
              <li
                className="nav-item dropdown px-3"
                onClick={CheckDropdownOpen}
              >
                <span className="nav-link dropdown-toggle active align-items-center d-flex adminlink">
                  Services
                  <img
                    className="ps-1 rotate h-8"
                    src="/images/down_arrow.png"
                    alt=""
                  />
                </span>
                <ul
                  className="dropdown-menu d-none text-m-center mobile"
                  aria-labelledby="navbarDropdownMenuLink"
                  id="DropdownMenuId"
                >
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65 adminlink"
                      id="WebDevelopment"
                      to="/web-development"
                    >
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="WebApp"
                      to="/web-application-development"
                    >
                    Web App development
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="Ecommerce"
                      to="/ecommerce-services"
                    >
                    Ecommerce Services
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="AppDevelopment"
                      to="/mobile-app-development"
                    >
                      Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="ApiIntegration"
                      to="/api-integration-services"
                    >
                      API Integration
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="CrmDevelopment"
                      to="/crm-development-services"
                    >
                      CRM Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="ErpDevelopment"
                      to="/erp-development-services"
                    >
                      ERP Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-50 adminlink"
                      id="UIUXDesigns"
                      to="/uiux-design"
                    >
                      UI/UX Design
                    </Link>
                  </li>
                  <li className="desktop">
                    <Link
                      className="nav-link dropdown-item w-100 adminlink"
                      id="SeoId"
                      to="/seo"
                    >
                      Search Engine Optimization
                    </Link>
                  </li>
                  <li className="mobile">
                    <Link
                      className="nav-link dropdown-item adminlink"
                      id="SeoId"
                      to="/seo"
                    >
                      Search Engine <br /> Optimization
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-25 adminlink"
                      id="SeoId"
                      to="/devops"
                    >
                      DevOps
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-77 adminlink"
                      id="SeoId"
                      to="/project-management"
                    >
                      Project Management
                    </Link>
                  </li>
                </ul>
                <ul
                  className="dropdown-menu text-m-center desktop"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65 adminlink"
                      id="WebDevelopment"
                      to="/web-development"
                    >
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="WebApp"
                      to="/web-application-development"
                    >
                    Web App development
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="Ecommerce"
                      to="/ecommerce-services"
                    >
                    Ecommerce Services
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="AppDevelopment"
                      to="/mobile-app-development"
                    >
                      Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="ApiIntegration"
                      to="/api-integration-services"
                    >
                      API Integration
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="CrmDevelopment"
                      to="/crm-development-services"
                    >
                      CRM Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-65  adminlink"
                      id="ErpDevelopment"
                      to="/erp-development-services"
                    >
                      ERP Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-50 adminlink"
                      id="UIUXDesigns"
                      to="/uiux-design"
                    >
                      UI/UX Design
                    </Link>
                  </li>
                  <li className="desktop">
                    <Link
                      className="nav-link dropdown-item w-100 adminlink"
                      id="SeoId"
                      to="/seo"
                    >
                      Search Engine Optimization
                    </Link>
                  </li>
                  <li className="mobile">
                    <Link
                      className="nav-link dropdown-item adminlink"
                      id="SeoId"
                      to="/seo"
                    >
                      Search Engine <br /> Optimization
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-25 adminlink"
                      id="SeoId"
                      to="/devops"
                    >
                      DevOps
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-link dropdown-item w-77 adminlink"
                      id="SeoId"
                      to="/project-management"
                    >
                      Project Management
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item px-3 adminlink">
                <Link className="nav-link" id="PortfolioId" to="/portfolio">
                  Portfolio
                </Link>
              </li>
              <li className="nav-item px-3 adminlink">
                <Link className="nav-link" id="WebDevelopment" to="/about-us">
                  About Us
                </Link>
              </li>
              <li className="nav-item px-3 adminlink">
                <Link className="nav-link" id="WebDevelopment" to="/career">
                  Career
                </Link>
              </li>
              <li className="nav-item px-3 adminlink">
                <Link className="nav-link" id="Blog" to="/blog">
                  Blog
                </Link>
              </li>

              <li className="nav-item px-3 adminlink">
                <Link
                  className="nav-link "
                  id="ContactUsId"
                  to="contact-us"
                  state={{ pathname: location?.pathname }}
                >
                  Contact Us
                </Link>
              </li>
              {width <= 576 ? (
                <li className="nav-item px-3 adminlink">
                  <Link
                    className="nav-link"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    FREE QUOTE
                  </Link>
                </li>
              ) : (
                <li className="ps-3">
                  <button
                    className="freeQuoteBtn"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    <b>FREE QUOTE</b>
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">

        <PersonalizedActionModal modalSource="(Free Quote)" />


      </Popups>
    </>
  );
}
