import React, { useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Getcall from "./ApiRequest";
import ReactPaginate from "react-paginate";
import HelmetMetaTag from "../../Components/HelmetMetaTag";
import { Spinner } from "react-bootstrap";

export default function BlogCategoriesPage() {
  const CUSTOM_URL = process.env.REACT_APP_CUSTOM_URL;
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [categoryData, setcategoryData] = useState([]);
  const [categoryTitle, setcategoryTitle] = useState("Category");
  const [postData, setpostData] = useState([]);
  const [WindowWidth, setWindowWidth] = useState();
  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth);
    getData();
  }, [param]);
  async function getData() {
    setLoading(true);
    await Getcall(
      `/get_posts_by_category?name=${param.title.replace(/-/g, " ")}`,
      CUSTOM_URL
    ).then((result) => {
      var catresult = JSON.parse(result);
      if (catresult) {
        Getcall("/categories/" + catresult)
          .then((result) => {
            var results = JSON.parse(result);
            setcategoryData(results);
            setcategoryTitle(results.name);
          })
          .catch((error) => console.log("error", error));
      }
    });

    await Getcall(
      `/get_posts_by_category?name=${param.title.replace(/-/g, " ")}`,
      CUSTOM_URL
    ).then((result) => {
      var catresult = JSON.parse(result);
      if (catresult) {
        Getcall("/posts?categories=" + catresult)
          .then((result) => {
            var results = JSON.parse(result);
            setpostData(results);
          })
          .catch((error) => console.log("error", error));
      }
    });
    setLoading(false);
  }
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(postData.length / itemsPerPage);
  const displayedItems = postData?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    <React.Fragment>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      ) : (
        <>
          <HelmetMetaTag
            titleName={`${categoryTitle} | SprigStack Blog`}
            desc={`Explore the latest insights and updates in the field of ${categoryData?.name} on the SprigStack Blog's dedicated category page.`}
            canonical={window.location.href}
            ogtitle={`${categoryData?.name} | SprigStack Blog`}
            ogdesc={`Explore the latest insights and updates in the field of ${categoryData?.name} on the SprigStack Blog's dedicated category page.`}
            ogtype="website"
            ogurl={window.location.href}
          />
          <div className="px-6 row">
            <div className="col-12 text-center mt-md-5 mt-4 category-heading">
              <h1 className="m-0">{categoryData?.name}</h1>
              <img src="/images/hrImg.png" alt="" className="img-fluid " />
            </div>
          </div>
          <div className="row px-6 mt-md-1 mb-3">
            {displayedItems.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              displayedItems?.map((item) => (
                <>
                  <div className="col-md-4 col-12 my-3 px-4">
                    <Link
                      className="anchorOrange"
                      to={`/blog/${item?.title?.rendered
                        ?.split(" ")
                        .join("-")}`}
                    >
                      <div className="row postBox h-100">
                        <div className="col-12">
                          <img
                            className="img-fluid mt-3"
                            src={item?.acf?.hero_image?.url}
                            alt={item?.acf?.hero_image?.alt}
                          />
                        </div>
                        <div className="col-12">
                          <p className="postHeader my-3">
                            {item?.acf?.page_title}
                          </p>
                          <p className="postPara">{item?.acf?.hero_para}</p>
                        </div>
                        <div className="col-12">
                          {item?.yoast_head_json?.description
                            ?.split(", ")
                            ?.map((article, index) => (
                              <button className="postBtn">
                                <Link
                                  to={`/blog/category/${article
                                    ?.split(" ")
                                    ?.join("-")
                                    .toLowerCase()}`}
                                >
                                  {article}
                                </Link>
                              </button>
                            ))}
                        </div>
                        <div className="col-12 my-3">
                          <div className="row ">
                            <div className="col-7 HeroDate">
                              {/* {item?.date?.split("T")[0]} */}
                              {new Date(item?.date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </div>
                            <div
                              className="col-5 "
                              style={{ textAlign: "right" }}
                            >
                              <div className="clr-orange">Read More »</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ))
            )}
            {pageCount > 1 && (
              <div className="pagination-container">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
}
