import { useEffect, useState, useRef } from "react";
import $ from "jquery";
export function ClientComp(props) {
  var [TimerInterval, setTimerInterval] = useState(5000);
  const [show, setshow] = useState(false);
  var [ClientArray, setClientArray] = useState([
    {
      id: 1,
      Name: "Ryan Skidmore",
      Role: "Director at Berger's TablePads - USA",
      Profile_Img: "/images/DummyAvtar.png",
      video: "./videos/luffy.mp4",
      Info: " We needed fast and efficient desktop and mobile websites for our products to sell online. Ronak and team did a great job in achieving that for us.We have a picture perfect website now which has eased lots of our manual work automated since we use a different system in backend.Me and my team gets a lot of productive time on other productive tasks than manual data entry.",
      type: "text",
    },
    {
      id: 2,
      Name: "Bill Burke",
      Role: "Founder at BoxScore - USA",
      Profile_Img: "/images/Bill.webp",
      video: "https://www.youtube.com/embed/2tdaEvZ9znw?autoplay=1",
      // Info: "We were in search for a perfect web development team to convert our idea to MVP. We wanted our feedback collecting system, BoxScore, to be kept simple yet powerful.SprigStack team, specially Swati, did exactly what we wanted. Our system does not have any distracting fuss and straight to the point functionality which makes it very impactful.We have been connected with SprigStack for more than 5 years now, they still manage and maintain our IT work.",
      type: "video",
      alt: "Bill",
      title: "Our Client Bill",
    },
    {
      id: 3,
      IsActive: "true",
      Name: "Ryan Thomas",
      Role: "Operations Manager at MarketWagon - USA",
      Profile_Img: "/images/RyanThomas.webp",
      video: "https://www.youtube.com/embed/dPdWTzngcwQ?autoplay=1",
      // Info: "Market Wagon was in its initial stage of development when we first met Ronak. He and his team worked with us from concept to reality. Initially, we began with Shopify, and eventually, Ronak and his team flawlessly migrated the entire application to the Laravel tech stack.Our journey wouldn't have been the same without the expertise of the SprigStack team.",
      type: "video",
      alt: "Ryan Thomas",
      title: "Our Client Ryan Thomas",
    },
    {
      id: 4,
      Name: "Yehoshua Kotlar",
      Role: "Resource Manager at TalkNSave - Israel",
      Profile_Img: "/images/Yehoshua.webp",
      video: "https://www.youtube.com/embed/2Xx1Tnh-AxI",
      Info: "Ronak and his team handles multiple projects for our telecom channel partner. They develop and maintain our frontend part where we sell products online on desktop and mobile.They also take care of backend and database interactions. Some of our existing softwares shares the usage of same database so SprigStack team makes sure everything is in order.They have been part of our IT team for more than 2 years now and we are very happy with their services. ",
      type: "text",
      alt: "Yehoshua",
      title: "Our Client Yehoshua",
    },
    {
      id: 5,
      Name: "Ali Akram",
      Role: "IT Manager at SMBAL - Dubai",
      Profile_Img: "/images/Aliakram.webp",
      video: "https://www.youtube.com/embed/tECKL99LbKM",
      Info: "We wanted to create web-cum-mobile application for our privileged client base. Our app was supposed to have limited but powerful features. SprigStack team came up with a perfect hybrid application idea which served all the purposes. Our app looks beautiful in desktop as well as modern in mobile. Moreover, users can also directly download our app from their mobile browsers which is amazing. Thanks team for a great work, our users love using our app. ",
      type: "text",
      alt: "AliaKram",
      title: "Our Client AliaKram",
    },
    {
      id: 6,
      IsActive: "true",
      Name: "Josh",
      Role: "General Manager at Tellos",
      Profile_Img: "/images/DummyAvtar.png",
      video: "https://www.youtube.com/embed/suR2KwSPuxc?si=hywGRt1TpTUE5GZi",
      // Info: "Market Wagon was in its initial stage of development when we first met Ronak. He and his team worked with us from concept to reality. Initially, we began with Shopify, and eventually, Ronak and his team flawlessly migrated the entire application to the Laravel tech stack.Our journey wouldn't have been the same without the expertise of the SprigStack team.",
      type: "video",
      alt: "Josh",
      title: "Our Client Josh",
    },
  ]);
  const sliderMinus = () => {
    $(".slide-0.w-20").removeClass("active");
    $(".custom-blockquote").removeClass("active");
    $(".slide-0.w-20.true").addClass("active");
    $(".custom-blockquote").addClass("active");
    var newClientArray = [];
    for (var i = 0; i < ClientArray.length; i++) {
      var clientArr = ClientArray[i];
      clientArr.id = ClientArray.length == clientArr.id ? 1 : clientArr.id + 1;
      clientArr.IsActive = clientArr.id == 3 ? "true" : "false";
      newClientArray.push(clientArr);
      setClientArray(newClientArray);
    }
    setTimeout(() => {
      $(".slide-0.w-20").removeClass("active");
      $(".custom-blockquote").removeClass("active");
    }, 2000);
  };
  var container = useRef(null);
  const videoPlay = (url, id) => {
    var inhouseVideo =
      '<iframe id="videoContainerRemove" width="100%" height="100%" src="' +
      url +
      '" allow="autoplay" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

    $(".videoContainer").empty().append(inhouseVideo);
    $(".videoContainer").empty().append(inhouseVideo);
    $(".video-parent").removeClass("d-none").focus();
  };
  const videoClose = () => {
    setshow(false);
    $("#videoContainerRemove").remove();
    $(".video-parent").addClass("d-none");
    $("body").removeClass("stopScroll");
  };
  const sliderPlus = () => {
    $(".slide-0.w-20").removeClass("active");
    $(".custom-blockquote").removeClass("active");
    $(".slide-0.w-20.true").addClass("active");
    $(".custom-blockquote").addClass("active");
    var newClientArray = [];
    for (var i = 0; i < ClientArray.length; i++) {
      var clientArr = ClientArray[i];
      clientArr.id = 1 == clientArr.id ? ClientArray.length : clientArr.id - 1;
      clientArr.IsActive = clientArr.id == 3 ? "true" : "false";
      newClientArray.push(clientArr);
    }
    setClientArray(newClientArray);
    setTimeout(() => {
      $(".slide-0.w-20").removeClass("active");
      $(".custom-blockquote").removeClass("active");
    }, 2000);
  };
  const infoExists = ClientArray.some(client => client.Info);
  return (
    <>
      <div className="col-12 text-center pt-lg-5 pt-4">
        <div className={props.isBackgroundColor ? "" : "bgF7F7F7"}>
          <div
            className="video-parent d-none"
            ref={(el) => (container = el)}
            id="videoContainerParentDiv"
          >
            <div className="videoCloseIcon" onClick={videoClose}>
              X
            </div>
            <div className="videoContainer"></div>
          </div>
          <div className="col-12 text-center py-4 pt-5 pt-lg-5">
            <h2 className="clr0E2B3D  headerFontSizeH6 setlineheight mx-lg-0 mx-2">
              What Our Clients Think About us!
            </h2>
            <h3 className="clr0E2B3D letterSpacing headerFontSizeH3 h1Tag mx-lg-0 mx-3">
              Check out our clients' feedback below
            </h3>
            <img src="images/hrImg.png" alt="" className="img-fluid" />
          </div>
          <div className="slider position-relative">
            <div className="custom-minus  seticonleft" style={{ top: infoExists ? '15%' : '75%' }} onClick={sliderMinus}>
              <img
                src="/images/slick-prevImg.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="custom-plus seticonright" style={{ top: infoExists ? '15%' : '75%' }} onClick={sliderPlus}>
              <img
                src="/images/slick-nextImg.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="slide-description d-flex">
              {ClientArray.sort((a, b) => a.id - b.id).map(function (
                client,
                i
              ) {
                return (
                  <div
                    className={`slide-0 text-center w-20 ${client.IsActive}`}
                    key={i}
                  >
                    <span className="text-slide">
                      <div>
                        <img
                          style={{ borderRadius: "50%" }}
                          src={`${client.Profile_Img}`}
                          alt={client.alt}
                          title={client.title}
                          className={`img-fluid h-13  mySlides ${client.IsActive == "true" ? `` : `opacity-25`
                            }`}
                        />
                        <div
                          className={`${client.IsActive == "true" ? `active` : `d-none`
                            }`}
                        >
                          <h4 className="fontPoppins mt-3">{client.Name}</h4>
                          <h6 className="fontPoppins">{client.Role}</h6>
                          <img
                            src="/images/video_icon.png"
                            alt=""
                            className={`clickable ${client.type == "text" && "d-none"
                              }`}
                            onClick={() => {
                              setshow(true);
                              videoPlay(client.video, client.Name);
                            }}
                          ></img>
                          <div
                            className={`clickable videoFeedbackLink ${client.type == "text" && "d-none"
                              }`}
                            onClick={() => {
                              setshow(true);
                              videoPlay(client.video, client.Name);
                            }}
                          >
                            Watch Video Feedback
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-12 col-lg-8 m-auto text-center py-4">
            {ClientArray.sort((a, b) => a.id - b.id).map(function (client, i) {
              return (
                <div
                  key={i}
                  className={`${client.IsActive == "true" ? `custom-blockquote` : `d-none`
                    } ${client.type == "video" && "d-none"}`}
                >
                  {client.Info ? (
                    <h6 className="fontPoppins">{client.Info}</h6>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
