import React from "react";
import { useNavigate } from "react-router-dom";
import HelmetMetaTag from "./Components/HelmetMetaTag";

export default function PageNotFound() {
  const navigation = useNavigate();
  return (
    <>
      <HelmetMetaTag
        titleName="Page Not Found | SprigStack"
        desc=""
        canonical=""
        ogtitle=""
        ogdesc=""
        ogtype="website"
        ogurl=""
      />
      <div className="py-5 PageNotFound">
        <div className="row">
          <div className="col-md-6 mobile">
            <img className="img-fluid" src="/images/404Img.svg" alt="" />
          </div>
          <div className="col-md-6 text-center">
            <img className="img-fluid mt-5" src="/images/404.svg" alt="" />
            <div className="row my-3">
              <div className="col-12 text-center pageNotFoundFont1">
                Oops, this page doesn't exist
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 text-center pageNotFoundFont2">
                Try going back or click the button below and we'll take you home
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 text-center pageNotFoundFont2">
                <button
                  className="btn btn-primary phpModalBtn"
                  type="submit"
                  onClick={() => navigation("/")}
                >
                  Go to home
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 desktop">
            <img className="img-fluid" src="/images/404Img.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
