import React, { useEffect, useLayoutEffect, useState } from "react";
import Getcall from "./BlogPage/ApiRequest";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useLocation } from "react-router-dom";
import HelmetMetaTag from "../Components/HelmetMetaTag";
import ContactUsComp from "../Components/ContactUsComp";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default function BlogPage() {
  const [HeroData, setHeroData] = useState({
    img: "",
    alt: "",
    text: "",
    date: "",
    id: 0,
    link: "",
  });
  const [categoryData, setcategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postData, setpostData] = useState([]);
  const [filterData, setfilterData] = useState([]);
  const [searchData, setsearchData] = useState([]);
  const [windowWidth, setWindowWidth] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    await Getcall("/posts?per_page=100")
      .then((result) => {
        var results = JSON.parse(result);
        setpostData(results);
        setfilterData(results);
        setsearchData(results);
        setHeroData({
          ...HeroData,
          img: results[0]?.acf.hero_image?.url,
          alt: results[0]?.acf.hero_image?.alt,
          text: results[0]?.acf?.page_title,
          date: results[0]?.date?.split("T")[0],
          id: results[0]?.id,
          link: results[0]?.title?.rendered,
        });
      })
      .catch((error) => console.log("error", error));

    await Getcall("/categories?per_page=100")
      .then((result) => {
        var results = JSON.parse(result);
        setcategoryData(results);
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  }

  const itemsPerPage = 9;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const displayedItems = filterData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const pageCount = Math.ceil(filterData.length / itemsPerPage);
  const handleCategoryChange = (event) => {
    var selectedCategory = event.target.value;
    if (selectedCategory === "2") {
      setfilterData(postData);
      setsearchData(postData);
    } else {
      let data = postData.filter((item) =>
        item?.categories.includes(parseInt(selectedCategory))
      );
      setfilterData(data);
      setsearchData(data);
    }
  };

  const handleSearch = (event) => {
    const searchText = event.target.value;
    if (searchText === "") {
      setfilterData(searchData);
    } else {
      const filteredData = searchData.filter((item) => {
        const itemAsString = JSON.stringify(item).toLowerCase();
        return itemAsString.includes(searchText.toLowerCase());
      });
      setfilterData(filteredData);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      ) : (
        <>
          <Helmet>
            <script type="application/ld+json">
              {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Sprigstack",
            "alternateName": "Sprigstack",
            "url": "https://sprigstack.com/blog",
            "logo": "https://sprigstack.com/Sprigstacklogo.png",
            "contactPoint": [{
              "@type": "ContactPoint",
              "telephone": "+91 8780913538",
              "contactType": "customer service",
              "areaServed": ["US", "CA", "GB", "AE", "IL", "IN"],
              "availableLanguage": "en"
            }, {
              "@type": "ContactPoint",
              "telephone": "+1 3174601173",
              "contactType": "customer service",
              "areaServed": ["US", "GB", "CA", "IN", "IL", "AE"],
              "availableLanguage": "en"
            }],
            "sameAs": [
              "https://www.instagram.com/sprigstack",
              "https://www.linkedin.com/company/sprig-stack"
            ]
          }
          `}
            </script>
          </Helmet>
          <HelmetMetaTag
            titleName="Web & Web App | Stay Ahead with Tech Insights | SprigStack"
            desc="Stay informed with the latest tech trends and insights on SprigStack's blog. Explore software, web development, web apps, mobile apps, ecommerce & more."
            canonical={window.location.href}
            ogtitle="Web & Web App | Stay Ahead with Tech Insights | SprigStack"
            ogdesc="Stay informed with the latest tech trends and insights on SprigStack's blog. Explore software, web development, web apps, mobile apps, ecommerce & more."
            ogtype="website"
            ogurl={window.location.href}
          />
          <div className={windowWidth > 786 ? "px-6" : ""}>
            <div className="row   position-relative">
              <div className="col-lg-9 col-12 pe-0">
                <img
                  className="my-md-5 wid-fill"
                  src={HeroData?.img}
                  alt={HeroData?.alt}
                />
              </div>
              {windowWidth > 786 ? (
                <div className="col-lg-3 ps-0">
                  <img
                    className="my-md-5 wid-fill"
                    src="../images/DottedRectangle.png"
                    alt=""
                  />
                </div>
              ) : (
                <></>
              )}

              <div className="row position-absolute topright-7020">
                <Link
                  className="anchorOrange"
                  to={`/blog/${HeroData?.link?.split(" ")?.join("-")}`}
                >
                  <div className="col-12">
                    <div className="Blog-header ">
                      <div className="row mx-1 py-2">
                        <div className="col-12">
                          <p className="">{HeroData?.text}</p>
                        </div>
                        <div className="col-6 HeroDate">
                          {/* {HeroData?.date} */}
                          {new Date(HeroData?.date).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </div>
                        <div className="col-6" style={{ textAlign: "right" }}>
                          <div
                            className="clr-orange"
                            //to={`/blog/${HeroData?.text
                            //  ?.split(" ")
                            // ?.join("-")}`}
                          >
                            Read More
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="row mt-md-0 pt-md-0 mt-5 pt-5">
              <div className="text-center">
                <h1
                  style={{ fontSize: "44px", color: "#203F59" }}
                  className="clr0E2B3D headerFontSizeH6 setlineheight recenthdpd mb-md-0 mt-md-0 mt-4"
                >
                  SprigStack Blog
                </h1>
                <img src="/images/hrImg.png" alt="" className="img-fluid " />
              </div>
            </div>
          </div>
          <hr className="blogLine"></hr>
          <div className="row px-6 mt-4">
            <div className="col-12">
              <div className="row">
                <div className="col-md-3 col-12">
                  <select
                    className="blogSelect"
                    onChange={handleCategoryChange}
                  >
                    {categoryData?.map((items, i) => (
                      <>
                        <option key={items?.id} value={items?.id}>
                          {items?.name}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-md-3 col-0"></div>
                <div className="col-md-3 col-0"></div>
                <div className="col-md-3 col-11 mt-3 mt-md-0 text-lg-end ">
                  <div className="position-relative">
                    <img
                      className="position-absolute searchBLog"
                      src="./images/searchlogo.png"
                      alt=""
                    />
                    <input
                      onChange={handleSearch}
                      className="blogSearch"
                      type="search"
                      placeholder="Search Blog"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 my-4">
              <div className="row">
                {displayedItems?.length === 0 ? (
                  <p style={{ fontSize: "20px" }} className="text-center">
                    No record found
                  </p>
                ) : (
                  displayedItems?.map((item) => (
                    <div
                      className="col-md-4 col-12 my-3 px-4 BlogContent"
                      key={item?.id}
                    >
                      <Link
                        to={`/blog/${item?.title?.rendered
                          ?.split(" ")
                          ?.join("-")}`}
                      >
                        <div className="row postBox h-100">
                          <div className="col-12">
                            <img
                              className="img-fluid mt-3"
                              src={item?.acf?.hero_image?.url}
                              alt={item?.acf?.hero_image?.alt}
                            />
                          </div>
                          <div className="col-12">
                            <p className="postHeader my-3">
                              {item?.acf?.page_title}
                            </p>
                            <p className="postPara">{item?.acf?.hero_para}</p>
                          </div>
                          <div className="col-12">
                            {item?.yoast_head_json?.description
                              ?.split(", ")
                              ?.map((article, index) => (
                                <button className="postBtn" key={index}>
                                  <Link
                                    to={`/blog/category/${article
                                      ?.split(" ")
                                      ?.join("-")
                                      .toLowerCase()}`}
                                  >
                                    {article}
                                  </Link>
                                </button>
                              ))}
                          </div>
                          <div className="col-12 my-3">
                            <div className="row">
                              <div className="col-7 HeroDate">
                                {/* {item?.date?.split("T")[0]} */}
                                {new Date(item?.date).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </div>
                              <div
                                className="col-5"
                                style={{ textAlign: "right" }}
                              >
                                <div className="clr-orange">Read More »</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                )}
              </div>
              {pageCount > 1 && (
                <div className="pagination-container">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
          <ContactUsComp
            pageName="Blog Page"
            header="GET FREE CONSULTATION"
            headerClass="blogFormHeader mb-3"
            prevPage=""
          />
        </>
      )}

      <React.Fragment />
    </React.Fragment>
  );
}
