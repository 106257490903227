import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { TextandHoverComponenet } from "../Components/TextandHoverComponenet";
import DivHoverComp from "./DivHoverComp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HelmetMetaTag from "./HelmetMetaTag";

export function K12Portpoliopage() {
  const [windowWidth, setWindowWidth] = useState("");
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });
  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="Streamlined K-12 Device Management - SprigStack"
        desc="Seamlessly allocate, track, manage devices with SprigStack's intuitive web solution. Simplify inventory challenges and resolve technical issues efficiently."
        canonical="https://sprigstack.com/portfolio/website-development-for-K12"
        ogtitle="Streamlined K-12 Device Management - SprigStack"
        ogdesc="Seamlessly allocate, track, manage devices with SprigStack's intuitive web solution. Simplify inventory challenges and resolve technical issues efficiently."
        ogtype="website"
        ogurl="https://sprigstack.com/portfolio/website-development-for-K12"
        ogimg="https://sprigstack.com/images/K12-mockup.webp"
      />
      <div>
        {windowWidth >= 768 ? (
          <>
            <div className="row bgF7F7F7 min-width644 px-lg-6">
              <div className="col-12 col-md-6 mt-md-4 ">
                <h1 className="k12Header my-md-4">
                  Website Development for&nbsp;
                  <label className="k12Label">K-12 One to</label>
                  <br />
                  <label className="k12Label"> One Made Easy</label>
                </h1>
                <Carousel
                  fade={true}
                  indicators={false}
                  interval={5000}
                  controls={false}
                >
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body word w1">Device Repairs</h2>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body word w1">Reports</h2>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body word w1">Device Allocation</h2>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body word w1">Tickets</h2>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body word w1">
                      Purchase and Inventory Management
                    </h2>
                  </Carousel.Item>
                </Carousel>
                <div className="k12text mt-md-4  row">
                  <div className="col-md-4">
                    Business : <label className="k12textColor">K-12</label>
                  </div>
                  <div className="col-md-4">
                    Industry : <label className="k12textColor"> Ed Tech</label>
                  </div>
                  <div className="col-md-4">
                    Region : <label className="k12textColor">USA</label>
                  </div>
                </div>

                <p className="k12para mb-md-5 mt-md-4 pb-md-5 pe-md-5">
                  K-12 Tech Repairs is an emerging education technology company
                  specializing in repairing, allocating and managing school
                  technology. Providing full life cycle management for
                  one-to-one devices for students is their speciality. They
                  provide comprehensive services like device repair,device
                  buyback, devices supply, inventory management, asset tracking,
                  and other miscellaneous support. With experienced technicians
                  and convenient options like local repair service and quick
                  turnaround times, K-12 Tech Repairs offers unparalleled repair
                  quality and outstanding service using our website solution.
                </p>
              </div>
              <div className="col-12 col-md-6  mb-md-5">
                <Carousel indicators={false} interval={5000} controls={false}>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image1.jpg"
                        alt="K12 tech repairs"
                        title="K12 tech repairs"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image2.svg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image3.svg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image4.svg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image5.svg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
            <div className="row d-flex justify-content-center position-relative">
              <div className="rowType techLogo row-new-logo px-md-3 text-center1 pt-md-3 px-md-5 pb-md-3 position-absolute top-80 bottom-0">
                <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="Laravel"
                    title="Laravel"
                    imgSrc="laravel-logo"
                    hoverSrc="laravel-logo-hover"
                  />
                </div>
                <div className="wid-15">
                  <TextandHoverComponenet
                    alt="React Js"
                    title="React Js logo"
                    imgSrc="react-logo-simple"
                    hoverSrc="react-hover"
                  />
                </div>
                <div className="wid-15">
                  <TextandHoverComponenet
                    alt="Bootstrap"
                    title="Bootstrap logo"
                    imgSrc="Bootstrap-simple"
                    hoverSrc="Bootstrap-hover"
                  />
                </div>
                <div className="wid-15">
                  <TextandHoverComponenet
                    alt="MySQl"
                    title="MySQl"
                    imgSrc="mysql"
                    hoverSrc="mysql-hover"
                  />
                </div>
                <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="Aws"
                    title="Aws logo"
                    imgSrc="Aws-simple"
                    hoverSrc="Aws-hover"
                  />
                </div>
                <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="Fedex"
                    title="Fedex logo"
                    imgSrc="Fedex-simple"
                    hoverSrc="Fedex-hover"
                  />
                </div>
              </div>
            </div>
            <div className="row mx-md-5 mt-md-5 width100">
              <div className="col-12 col-md-5 me-md-4 mt-md-5 pe-md-5 ps-md-4 position-relative">
                <h2 className="mt-md-5 sideSection text-center">
                  <label className="sideSectionlabel">Client&nbsp;</label>
                  Requirements
                </h2>
                <p className="blurFont">Client</p>
                <p className="sideSectionPara pt-md-5">
                  Our client wanted a comprehensive web-based system to
                  efficiently manage school-owned devices. The system should
                  encompass key functionalities like device allocation, support
                  ticket management, report generation, and inventory
                  management. It was designed with four distinct panels: K-12
                  Admin Panel, Technician Panel, School Admin Panel, and School
                  Teacher Panel. Customized features and functionalities will be
                  available for each panel, catering to the specific needs and
                  roles of its users.
                </p>
              </div>
              <div className="col-md-1 d-flex justify-content-center align-content-center">
                <img className="img-fluid" src="/images/Rectangle.svg" alt="" />
              </div>
              <div className="col-12 col-md-5 mx-md-4 mt-md-5 pe-md-5 ps-md-4 position-relative">
                <h2 className="mt-md-5 sideSection text-center">
                  <label className="sideSectionlabel">Client</label> Challenges
                </h2>
                <p className="blurFont">Problem</p>
                <p className="sideSectionPara pt-md-5 mb-0 ps-md-5">
                  Our client was facing multiple challenges in managing and
                  tracking school devices. They lack an efficient system for
                  information, status, inventory, and repair management. Major
                  Problems and challenges:
                </p>
                <ul className="sideSectionPara ms-md-4 ps-md-5">
                  <li>No ticketing system to raise device-related issues.</li>
                  <li>
                    Inability to track technician work and monitor progress.
                  </li>
                  <li>Lack of inventory management for devices.</li>
                  <li>
                    Difficulty in identifying and replacing outdated equipment.
                  </li>
                  <li>Insufficient management of security risks.</li>
                  <li>
                    Challenges in resolving technical issues and optimizing
                    resource allocation.
                  </li>
                </ul>
              </div>
            </div>
            <div className=" px-md-5 width100 px-0 px-md-2  py-md-5 phoneMargin0">
              <div className="row borderk1210 hover-hero-card px-0 mx-4 bgF7F7F7">
                <div className="col-12 col-md-8 px-md-5">
                  <h2 className="k12HeaderSide mt-md-5 py-md-4">
                    <label className="k12HeaderSideLabel">SprigStack</label>{" "}
                    Solution
                  </h2>
                  <p className="sprigK12Para">
                    After thoroughly understanding the client's requirements, we
                    created a strategic plan and allocated suitable resources
                    and developers. Our custom development process involved
                    creating a web-based solution from scratch,{" "}
                    <span className="sprigK12ParaLabel">
                      utilizing technologies such as Laravel (backend), React
                      (frontend), Bootstrap (UI/UX), MySQL (database), HTML, and
                      CSS.
                    </span>{" "}
                    The solution encompasses comprehensive features for device
                    management, allocation, and tracking. With our system, the
                    client gains the ability to efficiently manage diverse
                    school-owned devices through a single platform, ensuring
                    effective utilization and streamlined operations.
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <img
                    className="img-fluid"
                    src="/images/k12-ss-sideimg.svg"
                    alt="K12 Side Image"
                    title="K12 Side Image "
                  />
                </div>
              </div>
            </div>
            <div className="row mb-5 pb-5">
              <div className="col-12 col-md-12 py-md-4 text-center pt-md-0">
                <h2 className="clr0E2B3D lineHeader setlineheight recenthdpd mb-md-0">
                  Features
                </h2>
                <img src="/images/hrImg.png" alt="" className="img-fluid " />
              </div>
              <div className="  mx-md-2 px-md-5 pb-md-5">
                <div className="row pb-5 pe-md-3 mx-2">
                  {/* <DivHoverComp
                    imgSrc="/images/import-export-device.svg"
                    desc="The admin can import and export devices, import and export user data, and import and export tickets. They can utilize the upload option, click and download template CSV files, and effectively manage device and user data."
                    BtnText="Import / Export Device"
                    alt="Import Export Device Icon"
                    title="Import Export Device "
                  /> */}
                  <DivHoverComp
                    imgSrc="/images/device-management.svg"
                    desc="The admin can import and export devices, import and export user data, and import and export tickets. They can utilize the upload option, click and download template CSV files, and effectively manage device and user data."
                    BtnText="Device Management"
                    alt="Device Management Icon"
                    title="Device Management"
                  />
                  {/* <DivHoverComp
                    imgSrc="/images/Manage-Devices.svg"
                    desc="Admin can easily toggle between different ticket views such as open, closed, submitted, or view all tickets. They can search for specific tickets, take appropriate actions, view ticket status, export data in CSV format, and create new tickets"
                    BtnText="Manage Tickets"
                    alt="Manage Devices Icon"
                    title="Manage Devices"
                  /> */}
                  <DivHoverComp
                    imgSrc="/images/Manage-Devices.svg"
                    desc="Admin can easily toggle between different ticket views such as open, closed, submitted, or view all tickets. They can search for specific tickets, take appropriate actions, view ticket status, export data in CSV format, and create new tickets"
                    BtnText="Tickets Management"
                    alt="Tickets Management Icon"
                    title="Tickets Management"
                  />
                  {/* <DivHoverComp
                    imgSrc="/images/shipping.svg"
                    desc="Admin can toggle between awaiting shipment and all open ticket options, view shipment details, check status and amount, and access created batches for efficient device management."
                    BtnText="Shipping"
                    alt="Shipping Icon"
                    title="Shipping"
                  /> */}
                  <DivHoverComp
                    imgSrc="/images/repaier-tech.svg"
                    desc="The K-12 team specializes in repairing iPads, Chromebooks, MacBooks and other tech devices. A dedicated team of technicians ensures school devices are fully functional and reliable, restoring them to optimal performance."
                    BtnText="Repair Tech"
                    alt="Repair Tech Icon"
                    title="Repair Tech"
                  />
                </div>
                <div className="row py-5 pe-md-3 mx-2">
                  {/* <DivHoverComp
                    imgSrc="/images/manage-users.svg"
                    desc="Admin can export user history, import and export user data, add new users, view user information, and perform necessary actions."
                    BtnText="Manage Users"
                    alt="Manage Users Icon"
                    title="Manage Users"
                  /> */}
                  {/* <DivHoverComp
                    imgSrc="/images/manage-parts.svg"
                    desc="Admin can add inventory, toggle between school and master inventory, search for specific schools using the search box, view information, and take necessary actions."
                    BtnText="Manage Parts"
                    alt="Manage Parts Icon"
                    title="Manage Parts"
                  /> */}
                   <DivHoverComp
                    imgSrc="/images/shipping-k12.svg"
                    desc="Admin can toggle between awaiting shipment and all open ticket options, view shipment details, check status and amount, and access created batches for efficient device management."
                    BtnText="Repair Shipping"
                    alt="Shipping Icon"
                    title="Shipping"
                  />
                  {/* <DivHoverComp
                    imgSrc="/images/staff-members.svg"
                    desc="Admin can add details of staff members, check current members' information, verify their status, and take necessary actions."
                    BtnText="Staff Members"
                    alt="Staff Members Icon"
                    title="Staff Members"
                  /> */}
                   <DivHoverComp
                    imgSrc="/images/parental-coverage.svg"
                    desc="Admin can add details of staff members, check current members' information, verify their status, and take necessary actions."
                    BtnText="Parental Coverage"
                    alt="Parental Coverage Icon"
                    title="Parental Coverage"
                  />
                   <DivHoverComp
                    imgSrc="/images/reports-manage.svg"
                    desc="Admin can access various reports such as Breakage Rate by Devices, Tickets by Status, Breakage Rate by Users, Tickets by Issue Type, and Ticket Expenses by Month. These reports provide valuable insights into different aspects of the system."
                    BtnText="Reports & Insights"
                    alt="Reports Manage Icon"
                    title="Reports Manage "
                  />
                </div>
                <div className="row py-5 pe-md-3 mx-2">
                  {/* <DivHoverComp
                    imgSrc="/images/device-allocation.svg"
                    desc="Teachers have the ability to allocate devices to students. They can manage the process of assigning devices to individual students, ensuring proper distribution and tracking."
                    BtnText="Device Allocation"
                    alt="Device Allocation Icon"
                    title="Device Allocation"
                  /> */}
                   <DivHoverComp
                    imgSrc="/images/school-helpdesk.svg"
                    desc="Teachers have the ability to allocate devices to students. They can manage the process of assigning devices to individual students, ensuring proper distribution and tracking."
                    BtnText="School Helpdesk"
                    alt="School Helpdesk Icon"
                    title="School Helpdesk"
                  />
                   <DivHoverComp
                    imgSrc="/images/invoice-payment.svg"
                    desc="Teachers have the ability to allocate devices to students. They can manage the process of assigning devices to individual students, ensuring proper distribution and tracking."
                    BtnText="Invoicing & Payment"
                    alt="Invoicing & Payment Icon"
                    title="Invoicing & Payment"
                  />                
                </div>
              </div>
            </div>

            <div className="bgF7F7F7 mt-md-5 pt-md-5 pb150">
              <div className="row  mx-md-5  ">
                <div className="col-md-6  px-md-4 mt-md-3">
                  <div className="position-relative">
                    <h2 className="sideText ps-5">Admin Panel</h2>
                    <span className="backPanel">Admin Panel</span>
                  </div>
                  <div className=" py-md-5 mt-md-0">
                    <div className="sideSectionPara row">
                      <div className="col-1 col1 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-11 ps-0">
                        <label className="sideSectionParaLabel">
                          Admin Panel Overview :&nbsp;
                        </label>
                        The Admin Panel allows the admin user to manage and
                        control all the features of the system. They can add
                        technicians and school admins and grant access to
                        various functionalities.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 row">
                      <div className="col-1 col1 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-11 ps-0">
                        <label className="sideSectionParaLabel">
                          Dashboard :&nbsp;
                        </label>
                        The Dashboard provides an overview of various statistics
                        and insights for the admin. It displays information such
                        as technician performance, school invoices, and incoming
                        and outgoing devices. The admin can select a specific
                        date range, apply filters based on location or school,
                        and reset all filters
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 row">
                      <div className="col-1 col1 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-11 ps-0">
                        <label className="sideSectionParaLabel">
                          Schools :&nbsp;
                        </label>
                        It allows the admin to view information about all the
                        schools in the system. They can see details like open
                        and total tickets for each school and take necessary
                        actions. The admin can also use the search box to
                        quickly find specific schools.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 row">
                      <div className="col-1 col1 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-11 ps-0">
                        <label className="sideSectionParaLabel">
                          Technicians :&nbsp;
                        </label>
                        The Technicians feature enables the admin to add and
                        create new technicians. They can view details such as
                        the technician's name, location, and status. The admin
                        can also take necessary actions related to technicians
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 row">
                      <div className="col-1 col1 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-11 ps-0">
                        <label className="sideSectionParaLabel">
                          Billing :&nbsp;
                        </label>
                        The Billing feature allows the admin to check billing
                        details. They can view billing status, take necessary
                        actions, preview invoices, and send them to schools.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-md-5 px-md-4 position-relative ">
                  <div className="sideimage text-center ps-5">
                    <img
                      className=" img-fluid"
                      src="/images/SideScreen1.webp"
                      alt=""
                    />
                    <div className=" mb-md-5 mt-md-3 pe-md-2 me-md-3">
                      <div className="sideSectionPara row">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Domains :&nbsp;
                          </label>
                          In the Domains feature, the admin can manage access by
                          adding or removing domains. They can also check the
                          active status of domains and take appropriate actions.
                        </div>
                      </div>
                      <div className="sideSectionPara mt-md-3 row">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Reports :&nbsp;
                          </label>
                          The Reports feature provides insights and visual
                          representations of data. Admin can check graphs
                          related to tickets by issue, revenue, and ticket
                          distribution by the school
                        </div>
                      </div>
                      <div className="sideSectionPara mt-md-3 row">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Incoming Batches :&nbsp;
                          </label>
                          The Incoming Batches feature enables tracking and
                          viewing of data related to incoming batches.
                        </div>
                      </div>
                      <div className="sideSectionPara mt-md-3 row">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Locations :&nbsp;
                          </label>
                          In the Locations feature, the admin can add or remove
                          locations. They can also check the status of
                          locations, whether they are active or inactive, and
                          take necessary actions.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bgF7F7F7 mt-md-5 pt-md-5 pb150">
              <div className="row  mx-md-5 ">
                <div className="col-md-6 my-md-5 px-md-4 position-relative ">
                  <div className="leftSideImage">
                    <img
                      className="img-fluid"
                      src="/images/SideScreen2.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6  mt-md-3 px-md-4">
                  <div className="position-relative">
                    <h2 className="sideText1 ps-5">Technician Panel</h2>
                    <span className="backPanel">Technician Panel</span>
                  </div>
                  <div className="sideSectionPara  my-md-5">
                    The Technician Panel provides technicians with the necessary
                    features to check and manage ongoing tasks efficiently.
                  </div>
                </div>
                <div className="col-12 pt-md-5 mt-md-5">
                  <div className="my-md-3 mx-3">
                    <div className="sideSectionPara row my-2">
                      <div className="col-1 col2 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div style={{ width: "96%" }} className="col-11 ps-0">
                        <label className="sideSectionParaLabel">
                          Domains :&nbsp;
                        </label>
                        Technicians can add domains and easily search for
                        specific domains using the search box. They can also
                        check the status of domains, whether they are active or
                        inactive, and take appropriate actions.
                      </div>
                    </div>
                    <div className="sideSectionPara row my-2">
                      <div className="col-1 col2 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div style={{ width: "96%" }} className="col-11 ps-0">
                        <label className="sideSectionParaLabel">
                          Schools :&nbsp;
                        </label>
                        In the Schools feature, technicians can access all
                        available school information. They can check the number
                        of open and total tickets for each school, take
                        necessary actions, and conveniently search for specific
                        schools using the search box.
                      </div>
                    </div>
                    <div className="sideSectionPara row my-2">
                      <div className="col-1 col2 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div style={{ width: "96%" }} className="col-11 ps-0">
                        <label className="sideSectionParaLabel">
                          School Tickets :&nbsp;
                        </label>
                        Technicians can find schools using the search box, view
                        school information, and switch between open tickets and
                        submitted ticket options. They can also check the data
                        related to created batches for efficient ticket
                        management. box.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bgF7F7F7 mt-md-5 pt-md-5 pb150">
              <div className="row  mx-md-5 ">
                <div className="col-md-6  px-md-4 mt-md-3">
                  <div className="position-relative mb-5">
                    <h2 className="sideText ps-5">School Panel</h2>
                    <span className="backPanel">School Panel</span>
                  </div>
                  <div className="sideSectionPara row mt-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        School Admin Panel Overview :&nbsp;
                      </label>
                      The School Admin Panel gives admin users complete control
                      over all the features. They can manage and add teachers
                      and staff members and grant access to various
                      functionalities.
                    </div>
                  </div>
                  <div className="sideSectionPara row mt-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        Dashboard :&nbsp;
                      </label>
                      The Dashboard allows the school admin to view statistics
                      and insights. They can access information such as Breakage
                      Rate by Devices, Tickets by Status (open, repaired,
                      support), check due invoices, and select a specific date
                      range for analysis.
                    </div>
                  </div>
                  <div className="sideSectionPara row mt-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        Staff Members :&nbsp;
                      </label>
                      Admin can add details of staff members, check current
                      members' information, verify their status, and take
                      necessary actions.
                    </div>
                  </div>
                  <div className="sideSectionPara row mt-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        Manage Tickets :&nbsp;
                      </label>
                      Admin can easily toggle between different ticket views
                      such as open, closed, submitted, or view all tickets. They
                      can search for specific tickets, take appropriate actions,
                      view ticket status, export data in CSV format, and create
                      new tickets.
                    </div>
                  </div>
                  <div className="sideSectionPara row mt-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        Manage Devices :&nbsp;
                      </label>
                      The admin can conveniently switch between features related
                      to active devices, loaner devices, and decommissioned
                      devices. They can perform actions accordingly.
                    </div>
                  </div>
                  <div className="sideSectionPara row mt-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        Import and Add Devices :&nbsp;
                      </label>
                      The Admin can import and export user data, add new users,
                      export user history, and search for specific users using
                      the search box. They can also manage the allocation of
                      devices to users.
                    </div>
                  </div>
                  <div className="sideSectionPara row mt-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        Manage Users :&nbsp;
                      </label>
                      Admin can export user history, import and export user
                      data, add new users, view user information, and perform
                      necessary actions.
                    </div>
                  </div>
                  <div className="sideSectionPara row my-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        Manage Parts :&nbsp;
                      </label>
                      Admin can add inventory, toggle between school and master
                      inventory, search for specific schools using the search
                      box, view information, and take necessary actions.
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-md-5 px-md-4 position-relative ">
                  <div className="sideimage1 text-center ps-5">
                    <img
                      className="img-fluid"
                      src="/images/SideScreen3.png"
                      alt=""
                    />
                    <div className="  mb-md-5 mt-md-3 pe-md-0 me-md-4">
                      <div className="sideSectionPara row my-3">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Device Allocation :&nbsp;
                          </label>
                          The Admin can allocate and deallocate devices, and
                          easily search for users and devices.
                        </div>
                      </div>
                      <div className="sideSectionPara row my-3">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Import/Export :&nbsp;
                          </label>
                          The admin can import and export devices, import and
                          export user data, and import and export tickets. They
                          can utilize the upload option, click and download
                          template CSV files, and effectively manage device and
                          user data.
                        </div>
                      </div>
                      <div className="sideSectionPara row my-3">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Billing :&nbsp;
                          </label>
                          Admin can view billing information, check amount and
                          status, and take necessary actions. They can also
                          search for specific details using the search box.
                        </div>
                      </div>
                      <div className="sideSectionPara row my-3">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Reports :&nbsp;
                          </label>
                          Admin can access various reports such as Breakage Rate
                          by Devices, Tickets by Status, Breakage Rate by Users,
                          Tickets by Issue Type, and Ticket Expenses by Month.
                          These reports provide valuable insights into different
                          aspects of the system
                        </div>
                      </div>
                      <div className="sideSectionPara row my-3">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Support Tickets :&nbsp;
                          </label>
                          Admin can check ticket information, status and take
                          necessary actions to address support-related issues
                        </div>
                      </div>
                      <div className="sideSectionPara row my-3">
                        <div className="col-1 col1 pe-0">
                          <img
                            className="img-fluid"
                            src="/images/key-vector.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-11 ps-0">
                          <label className="sideSectionParaLabel">
                            Ship Devices :&nbsp;
                          </label>
                          Admin can toggle between awaiting shipment and all
                          open ticket options, view shipment details, check
                          status and amount, and access created batches for
                          efficient device management.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bgF7F7F7 my-md-5 pt-md-5 ">
              <div className="row  mx-md-5 ">
                <div className="col-md-6 my-md-5 px-md-4 position-relative ">
                  <div className="leftSideImage1">
                    <img
                      className="img-fluid"
                      src="/images/SideScreen4.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6  mt-md-3 px-md-4">
                  <div className="position-relative">
                    <h2 className="sideText1 ps-5">Teacher Panel</h2>
                    <span className="backPanel">Teacher Panel</span>
                  </div>
                  <div className="sideSectionPara  mt-md-5">
                    The Teacher Panel provides teachers with a user-friendly
                    interface to efficiently monitor and manage devices used by
                    students. They can easily allocate devices, check relevant
                    information, and perform various actions.
                  </div>
                  <div className="sideSectionPara row my-3 pb-4">
                    <div className="col-1 col1 pe-0">
                      <img
                        className="img-fluid"
                        src="/images/key-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-11 ps-0">
                      <label className="sideSectionParaLabel">
                        Device Allocation :&nbsp;
                      </label>
                      Teachers have the ability to allocate devices to students.
                      They can manage the process of assigning devices to
                      individual students, ensuring proper distribution and
                      tracking.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="bgBlack p-md-5 mb-5">
              <div className="bgBlackHeader">
                We are popular because of our way
              </div>
              <div className="bgBlackHeader">of working</div>
              <div className="bgBlackPara mt-md-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut
              </div>
              <div className="bgBlackPara">
                labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                gravida.
              </div>
              <div className="row mt-md-5 position-relative">
                <div className="lines"></div>
                <div className="col-md-3 text-center">
                  <img
                    className="imgClass"
                    src="/images/ArrowImage1.svg"
                    alt=""
                  />
                </div>
                <div className="col-md-3 text-center">
                  <img
                    className="imgClass"
                    src="/images/ArrowImage2.svg"
                    alt=""
                  />
                </div>
                <div className="col-md-3 text-center">
                  <img
                    className="imgClass"
                    src="/images/ArrowImage3.svg"
                    alt=""
                  />
                </div>
                <div className="col-md-3 text-center">
                  <img
                    className="imgClass"
                    src="/images/ArrowImage4.svg"
                    alt=""
                  />
                </div>
                <img
                  src="/images/ArrowImage.svg"
                  alt="Arrow"
                  className="arrow1"
                ></img>
              </div>
              <div className="row mt-md-2  position-relative">
                <div className="col-md-3 px-md-5 text-center">
                  <div className="imageHeader ">Research Product</div>
                  <div className="imagePara mt-md-2">
                    Lorem ipsum dolor sit amet, co nsectetur adipiscing elit,
                    sed do eiusmod tempor incididunt.
                  </div>
                </div>
                <div className="col-md-3 px-md-5 text-center">
                  <div className="imageHeader">UIX Design</div>
                  <div className="imagePara mt-md-2">
                    Lorem ipsum dolor sit amet, co nsectetur adipiscing elit,
                    sed do eiusmod tempor incididunt.
                  </div>
                </div>
                <div className="col-md-3 px-md-5 text-center">
                  <div className="imageHeader">Development</div>
                  <div className="imagePara mt-md-2">
                    Lorem ipsum dolor sit amet, co nsectetur adipiscing elit,
                    sed do eiusmod tempor incididunt.
                  </div>
                </div>
                <div className="col-md-3 px-md-5 text-center">
                  <div className="imageHeader">Product Handover</div>
                  <div className="imagePara mt-md-2">
                    Lorem ipsum dolor sit amet, co nsectetur adipiscing elit,
                    sed do eiusmod tempor incididunt.
                  </div>
                </div>
              </div>
            </div> */}
          </>
        ) : (
          <>
            <div className="row bgF7F7F7 px-3">
              <div className="col-12 col-md-6 mb-3 mb-md-5">
                <h1 className="k12Header text-center my-3 px-0">
                  Website Development for
                  <label className="k12Label mt-2">&nbsp;K-12</label>
                  <label className="k12Label mt-2">One to One Made Easy</label>
                </h1>
                <Carousel
                  data-pause="false"
                  indicators={false}
                  interval={5000}
                  controls={false}
                >
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image1.jpg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image2.svg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image3.svg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image4.svg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <div className="">
                      <LazyLoadImage
                        className="img-fluid ms-md-5"
                        src="/images/k12_image5.svg"
                        alt=""
                      />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-12 mt-3">
                <Carousel
                  fade
                  className="mt-2   px-0"
                  indicators={false}
                  interval={5000}
                  controls={false}
                >
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body zoom-in-out-box text-center width100 pb-4">
                      Device Repairs
                    </h2>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body zoom-in-out-box text-center width100 pb-4">
                      Reports
                    </h2>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body zoom-in-out-box text-center width100 pb-4">
                      Device Allocation
                    </h2>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body zoom-in-out-box text-center width100 pb-4">
                      Tickets
                    </h2>
                  </Carousel.Item>
                  <Carousel.Item interval={5000}>
                    <h2 className="k12Body zoom-in-out-box text-center width100 pb-1">
                      Purchase and Inventory Management
                    </h2>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-12">
                <div className="k12text mt-md-4 mx-0 px-0 row">
                  <div className="col-4 px-0">
                    Business : <label className="k12textColor">K-12</label>
                  </div>
                  <div className="col-5 px-0">
                    Industry : <label className="k12textColor"> Ed Tech</label>
                  </div>
                  <div className="col-3 px-0">
                    Region : <label className="k12textColor">USA</label>
                  </div>
                </div>

                <p className="k12para mb-2 mt-2 pb-2 mx-0 px-2">
                  K-12 Tech Repairs is an emerging education technology company
                  specializing in repairing, allocating and managing school
                  technology. Providing full life cycle management for
                  one-to-one devices for students is their speciality. They
                  provide comprehensive services like device repair,device
                  buyback, devices supply, inventory management, asset tracking,
                  and other miscellaneous support. With experienced technicians
                  and convenient options like local repair service and quick
                  turnaround times, K-12 Tech Repairs offers unparalleled repair
                  quality and outstanding service using our website solution.
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center mx-3 ">
              <div className="rowType techLogo row-new-logo px-md-3 text-center1 pt-md-3 px-md-5 mb-5  top-80 bottom-0">
                <div className="mt-2 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="Laravel"
                    title="Laravel"
                    imgSrc="laravel-logo"
                    hoverSrc="laravel-logo-hover"
                    height="logo-height"
                  />
                </div>
                <div className="mt-2 wid-15">
                  <TextandHoverComponenet
                    alt="React Js"
                    title="React Js logo"
                    imgSrc="react-logo-simple"
                    hoverSrc="react-hover"
                    height="logo-height"
                  />
                </div>
                <div className="mt-1 wid-15">
                  <TextandHoverComponenet
                    alt="Bootstrap"
                    title="Bootstrap logo"
                    imgSrc="Bootstrap-simple"
                    hoverSrc="Bootstrap-hover"
                    height="logo-height"
                  />
                </div>
                <div className="mt-0 wid-15">
                  <TextandHoverComponenet
                    alt="MySQl"
                    title="MySQl"
                    imgSrc="mysql"
                    hoverSrc="mysql-hover"
                    height="logo-height"
                  />
                </div>
                <div className="mt-0 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="Aws"
                    title="Aws logo"
                    imgSrc="Aws-simple"
                    hoverSrc="Aws-hover"
                    height="logo-height"
                  />
                </div>
                <div className="mt-0 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="Fedex"
                    title="Fedex logo"
                    imgSrc="Fedex-simple"
                    hoverSrc="Fedex-hover"
                    height="logo-height1"
                  />
                </div>
              </div>
            </div>
            <div className="row mx-0 mt-md-5 width100 px-2">
              <div className="col-12 px-0 col-md-5 me-md-4 mt-md-5 pe-md-5 ps-md-4 position-relative">
                <h2 className="mt-md-5 sideSection text-center">
                  <label className="sideSectionlabel">Client &nbsp;</label>
                  Requirements
                </h2>
                <p className="blurFont">Client</p>
                <p className="sideSectionPara pt-3 mx-2 px-2">
                  Our client wanted a comprehensive web-based system to
                  efficiently manage school-owned devices. The system should
                  encompass key functionalities like device allocation, support
                  ticket management, report generation, and inventory
                  management. It was designed with four distinct panels: K-12
                  Admin Panel, Technician Panel, School Admin Panel, and School
                  Teacher Panel. Customized features and functionalities will be
                  available for each panel, catering to the specific needs and
                  roles of its users.
                </p>
              </div>
              <div className="col-12 col-md-5 mx-md-4 mt-4 pe-md-5 ps-md-4 position-relative px-2">
                <h2 className="mt-md-5 sideSection text-center">
                  <label className="sideSectionlabel">Client</label> Challenges
                </h2>
                <p className="blurFont">Problem</p>
                <p className="sideSectionPara pt-3 mb-0 ps-md-5 mx-2 px-0 ">
                  Our client was facing multiple challenges in managing and
                  tracking school devices. They lack an efficient system for
                  information, status, inventory, and repair management. Major
                  Problems and challenges:
                </p>
                <ul className="sideSectionPara pe-2 ms-md-4 ps-md-5">
                  <li>No ticketing system to raise device-related issues.</li>
                  <li>
                    Inability to track technician work and monitor progress.
                  </li>
                  <li>Lack of inventory management for devices.</li>
                  <li>
                    Difficulty in identifying and replacing outdated equipment.
                  </li>
                  <li>Insufficient management of security risks.</li>
                  <li>
                    Challenges in resolving technical issues and optimizing
                    resource allocation.
                  </li>
                </ul>
              </div>
            </div>
            <div className="  width100 px-0 px-md-2  py-3 phoneMargin0">
              <div className="row borderk1210 hover-hero-card px-0 mx-0 bgF7F7F7">
                <div className="col-12 col-md-4">
                  <h2 className="k12HeaderSide mt-md-5 py-4 pb-2">
                    <label className="k12HeaderSideLabel">SprigStack</label>{" "}
                    Solution
                  </h2>
                  <img
                    className="img-fluid px-4"
                    src="/images/k12-ss-sideimg.svg"
                    alt=""
                  />
                </div>
                <div className="col-12 mt-4 px-4">
                  <p className="sprigK12Para">
                    After thoroughly understanding the client's requirements, we
                    created a strategic plan and allocated suitable resources
                    and developers. Our custom development process involved
                    creating a web-based solution from scratch,{" "}
                    <span className="sprigK12ParaLabel">
                      utilizing technologies such as Laravel (backend), React
                      (frontend), Bootstrap (UI/UX), MySQL (database), HTML and
                      CSS.&nbsp;
                    </span>
                    The solution encompasses comprehensive features for device
                    management, allocation, and tracking. With our system, the
                    client gains the ability to efficiently manage diverse
                    school-owned devices through a single platform, ensuring
                    effective utilization and streamlined operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-md-5 pb-md-5">
              <div className="col-12 col-md-12 py-md-4 text-center pt-md-0 pt-1 pb-3">
                <h2 className="clr0E2B3D lineHeader setlineheight recenthdpd mb-0">
                  Features
                </h2>
                <img src="/images/hrImg.png" alt="" className="img-fluid " />
              </div>
              <div className="  mx-md-2 px-md-5 pb-md-5">
                <div className="row pb-md-5 pe-md-3 mx-2">
                  <DivHoverComp
                    imgSrc="/images/device-management.svg"
                    desc="The admin can import and export devices, import and export user data, and import and export tickets. They can utilize the upload option, click and download template CSV files, and effectively manage device and user data."
                    BtnText="Device Management"
                    alt="Device Management Icon"
                    title="Device Management"
                  />
                   <DivHoverComp
                    imgSrc="/images/Manage-Devices.svg"
                    desc="Admin can easily toggle between different ticket views such as open, closed, submitted, or view all tickets. They can search for specific tickets, take appropriate actions, view ticket status, export data in CSV format, and create new tickets"
                    BtnText="Tickets Management"
                    alt="Tickets Management Icon"
                    title="Tickets Management"
                  />
                   <DivHoverComp
                    imgSrc="/images/repaier-tech.svg"
                    desc="The K-12 team specializes in repairing iPads, Chromebooks, MacBooks and other tech devices. A dedicated team of technicians ensures school devices are fully functional and reliable, restoring them to optimal performance."
                    BtnText="Repair Tech"
                    alt="Repair Tech Icon"
                    title="Repair Tech"
                  />
                </div>
                <div className="row py-md-5 pe-md-3 mx-2">
                <DivHoverComp
                    imgSrc="/images/shipping-k12.svg"
                    desc="Admin can toggle between awaiting shipment and all open ticket options, view shipment details, check status and amount, and access created batches for efficient device management."
                    BtnText="Repair Shipping"
                    alt="Shipping Icon"
                    title="Shipping"
                  />
                  <DivHoverComp
                    imgSrc="/images/parental-coverage.svg"
                    desc="Admin can add details of staff members, check current members' information, verify their status, and take necessary actions."
                    BtnText="Parental Coverage"
                    alt="Parental Coverage Icon"
                    title="Parental Coverage"
                  />
                   <DivHoverComp
                    imgSrc="/images/reports-manage.svg"
                    desc="Admin can access various reports such as Breakage Rate by Devices, Tickets by Status, Breakage Rate by Users, Tickets by Issue Type, and Ticket Expenses by Month. These reports provide valuable insights into different aspects of the system."
                    BtnText="Reports & Insights"
                    alt="Reports Manage Icon"
                    title="Reports Manage "
                  />                  
                </div>
                <div className="row py-md-5 pe-md-3 mx-2">
                <DivHoverComp
                    imgSrc="/images/school-helpdesk.svg"
                    desc="Teachers have the ability to allocate devices to students. They can manage the process of assigning devices to individual students, ensuring proper distribution and tracking."
                    BtnText="School Helpdesk"
                    alt="School Helpdesk Icon"
                    title="School Helpdesk"
                  />
                   <DivHoverComp
                    imgSrc="/images/invoice-payment.svg"
                    desc="Teachers have the ability to allocate devices to students. They can manage the process of assigning devices to individual students, ensuring proper distribution and tracking."
                    BtnText="Invoicing & Payment"
                    alt="Invoicing & Payment Icon"
                    title="Invoicing & Payment"
                  />                      
                </div>
              </div>
            </div>

            <div className="bgF7F7F7 mt-md-5 pt-md-5 mx-2 borderk1210 mt-4 mb-5">
              <div className="row  mx-md-5  ">
                <div className="col-12 text-center my-md-5 px-md-4 ">
                  <div className="p-3">
                    <div className="position-relative">
                      <h2 className="sideText py-4 pt-3">Admin Panel</h2>
                      <span className="backPanel">Admin Panel</span>
                    </div>
                    <img
                      className=" img-fluid"
                      src="/images/SideScreen1.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12  px-4 mt-2">
                  <div className=" py-md-5 px-2 mt-md-0">
                    <div className="sideSectionPara py-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Admin Panel Overview :&nbsp;
                        </label>
                        The Admin Panel allows the admin user to manage and
                        control all the features of the system. They can add
                        technicians and school admins and grant access to
                        various functionalities.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3  row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Dashboard :&nbsp;
                        </label>
                        The Dashboard provides an overview of various statistics
                        and insights for the admin. It displays information such
                        as technician performance, school invoices, and incoming
                        and outgoing devices. The admin can select a specific
                        date range, apply filters based on location or school,
                        and reset all filters
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Schools :&nbsp;
                        </label>
                        It allows the admin to view information about all the
                        schools in the system. They can see details like open
                        and total tickets for each school and take necessary
                        actions. The admin can also use the search box to
                        quickly find specific schools.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Technicians :&nbsp;
                        </label>
                        The Technicians feature enables the admin to add and
                        create new technicians. They can view details such as
                        the technician's name, location, and status. The admin
                        can also take necessary actions related to technicians
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Billing :&nbsp;
                        </label>
                        The Billing feature allows the admin to check billing
                        details. They can view billing status, take necessary
                        actions, preview invoices, and send them to schools.
                      </div>
                    </div>
                  </div>
                  <div className=" mb-md-5 mt-md-3 pe-md-2 px-2 me-md-3">
                    <div className="sideSectionPara row pb-3">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Domains :&nbsp;
                        </label>
                        In the Domains feature, the admin can manage access by
                        adding or removing domains. They can also check the
                        active status of domains and take appropriate actions.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-3 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Reports :&nbsp;
                        </label>
                        The Reports feature provides insights and visual
                        representations of data. Admin can check graphs related
                        to tickets by issue, revenue, and ticket distribution by
                        the school
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-3 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Incoming Batches :&nbsp;
                        </label>
                        The Incoming Batches feature enables tracking and
                        viewing of data related to incoming batches.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-3 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Locations :&nbsp;
                        </label>
                        In the Locations feature, the admin can add or remove
                        locations. They can also check the status of locations,
                        whether they are active or inactive, and take necessary
                        actions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bgF7F7F7 mt-md-5 pt-md-5 mx-2 borderk1210 mb-5">
              <div className="row  mx-md-5  ">
                <div className="col-12 text-center my-md-5 px-md-4 ">
                  <div className="p-3 px-0">
                    <div className="position-relative">
                      <h2 className="sideText py-4 pt-3">Technician Panel</h2>
                      <span className="backPanel1">Technician Panel</span>
                    </div>
                    <img
                      className="px-3 img-fluid"
                      src="/images/SideScreen2.webp"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12  px-4 mt-2">
                  <div className=" py-md-5 mt-md-0 px-2">
                    <div className="row sideSectionPara px-3">
                      The Technician Panel provides technicians with the
                      necessary features to check and manage ongoing tasks
                      efficiently.
                    </div>
                    <div className="sideSectionPara py-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Domains :&nbsp;
                        </label>
                        Technicians can add domains and easily search for
                        specific domains using the search box. They can also
                        check the status of domains, whether they are active or
                        inactive, and take appropriate actions.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3  row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Schools :&nbsp;
                        </label>
                        In the Schools feature, technicians can access all
                        available school information. They can check the number
                        of open and total tickets for each school, take
                        necessary actions, and conveniently search for specific
                        schools using the search box.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          School Tickets :&nbsp;
                        </label>
                        Technicians can find schools using the search box, view
                        school information, and switch between open tickets and
                        submitted ticket options. They can also check the data
                        related to created batches for efficient ticket
                        management.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bgF7F7F7 mt-md-5 pt-md-5 mx-2 borderk1210 mb-5">
              <div className="row  mx-md-5  ">
                <div className="col-12 text-center my-md-5 px-md-4 ">
                  <div className="p-3">
                    <div className="position-relative">
                      <h2 className="sideText py-4 pt-3">School Panel</h2>
                      <span className="backPanel">School Panel</span>
                    </div>
                    <img
                      className=" img-fluid"
                      src="/images/SideScreen3.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12  px-4 mt-0">
                  <div className=" py-md-5 mt-md-0 px-2">
                    <div className="sideSectionPara py-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          School Admin Panel Overview :&nbsp;
                        </label>
                        The School Admin Panel gives admin users complete
                        control over all the features. They can manage and add
                        teachers and staff members and grant access to various
                        functionalities.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3  row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Dashboard :&nbsp;
                        </label>
                        The Dashboard allows the school admin to view statistics
                        and insights. They can access information such as
                        Breakage Rate by Devices, Tickets by Status (open,
                        repaired, support), check due invoices, and select a
                        specific date range for analysis.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Staff Members :&nbsp;
                        </label>
                        Admin can add details of staff members, check current
                        members' information, verify their status, and take
                        necessary actions.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Manage Tickets :&nbsp;
                        </label>
                        Admin can easily toggle between different ticket views
                        such as open, closed, submitted, or view all tickets.
                        They can search for specific tickets, take appropriate
                        actions, view ticket status, export data in CSV format,
                        and create new tickets.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Manage Devices :&nbsp;
                        </label>
                        The admin can conveniently switch between features
                        related to active devices, loaner devices, and
                        decommissioned devices. They can perform actions
                        accordingly.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Import and Add Devices :&nbsp;
                        </label>
                        The Admin can import and export user data, add new
                        users, export user history, and search for specific
                        users using the search box. They can also manage the
                        allocation of devices to users.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Manage Users :&nbsp;
                        </label>
                        Admin can export user history, import and export user
                        data, add new users, view user information, and perform
                        necessary actions.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Manage Parts :&nbsp;
                        </label>
                        Admin can add inventory, toggle between school and
                        master inventory, search for specific schools using the
                        search box, view information, and take necessary
                        actions.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Device Allocation :&nbsp;
                        </label>
                        The Admin can allocate and deallocate devices, and
                        easily search for users and devices.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Import/Export :&nbsp;
                        </label>
                        The admin can import and export devices, import and
                        export user data, and import and export tickets. They
                        can utilize the upload option, click and download
                        template CSV files, and effectively manage device and
                        user data.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Billing :&nbsp;
                        </label>
                        Admin can view billing information, check amount and
                        status, and take necessary actions. They can also search
                        for specific details using the search box.
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Reports :&nbsp;
                        </label>
                        Admin can access various reports such as Breakage Rate
                        by Devices, Tickets by Status, Breakage Rate by Users,
                        Tickets by Issue Type, and Ticket Expenses by Month.
                        These reports provide valuable insights into different
                        aspects of the system
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Support Tickets :&nbsp;
                        </label>
                        Admin can check ticket information, status and take
                        necessary actions to address support-related issues
                      </div>
                    </div>
                    <div className="sideSectionPara mt-md-4 pb-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Ship Devices :&nbsp;
                        </label>
                        Admin can toggle between awaiting shipment and all open
                        ticket options, view shipment details, check status and
                        amount, and access created batches for efficient device
                        management.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bgF7F7F7 mt-md-5 pt-md-5 mx-2 borderk1210 mb-5">
              <div className="row  mx-md-5  ">
                <div className="col-12 text-center my-md-5 px-md-4 ">
                  <div className="p-3">
                    <div className="position-relative">
                      <h2 className="sideText py-4 pt-3">Teacher Panel</h2>
                      <span className="backPanel1">Teacher Panel</span>
                    </div>
                    <img
                      className=" img-fluid"
                      src="/images/SideScreen4.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12  px-4 mt-0">
                  <div className=" py-md-5 mt-md-0 px-2">
                    <div className="row sideSectionPara  px-3">
                      The Teacher Panel provides teachers with a user-friendly
                      interface to efficiently monitor and manage devices used
                      by students. They can easily allocate devices, check
                      relevant information, and perform various actions.
                    </div>
                    <div className="sideSectionPara py-3 row">
                      <div className="col-2 col21 pe-0">
                        <img
                          className="img-fluid"
                          src="/images/key-vector.svg"
                          alt=""
                        />
                      </div>
                      <div className="col-10 ps-0">
                        <label className="sideSectionParaLabel">
                          Device Allocation :&nbsp;
                        </label>
                        Teachers have the ability to allocate devices to
                        students. They can manage the process of assigning
                        devices to individual students, ensuring proper
                        distribution and tracking.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="bgBlack p-md-5 mb-0 mb-5 py-5">
              <div className="bgBlackHeader px-2 px-md-0">
                We are popular because of our way&nbsp;
                {windowWidth >= 768 ? <br /> : <></>}
                of working
              </div>
              <div className="bgBlackHeader"></div>
              <div className="bgBlackPara mt-md-2 px-2 px-md-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut&nbsp;
                {windowWidth >= 768 ? <br /> : <></>}
                labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                gravida.
              </div>
              <div className="row mt-5 mb-5 mb-md-0 position-relative">
                <div className="lines"></div>
                <div className="col-3 col-md-3 text-center">
                  <img
                    className="imgClass"
                    src="/images/ArrowImage1.svg"
                    alt=""
                  />
                </div>
                <div className="col-3 col-md-3 text-center">
                  <img
                    className="imgClass"
                    src="/images/ArrowImage2.svg"
                    alt=""
                  />
                </div>
                <div className="col-3 col-md-3 text-center">
                  <img
                    className="imgClass"
                    src="/images/ArrowImage3.svg"
                    alt=""
                  />
                </div>
                <div className="col-3 col-md-3 text-center">
                  <img
                    className="imgClass"
                    src="/images/ArrowImage4.svg"
                    alt=""
                  />
                </div>
                <img
                  src="/images/ArrowImage.svg"
                  alt="Arrow"
                  className="arrow1"
                ></img>
              </div>
              <div className="row mt-md-2  position-relative">
                <div className=" col-md-3 px-md-5 text-center">
                  <div className="imageHeader ">Research Product</div>
                  <div className="imagePara mt-md-2">
                    Lorem ipsum dolor sit amet, co nsectetur adipiscing elit,
                    sed do eiusmod tempor incididunt.
                  </div>
                </div>
                <div className=" col-md-3 px-md-5 text-center">
                  <div className="imageHeader">UIX Design</div>
                  <div className="imagePara mt-md-2">
                    Lorem ipsum dolor sit amet, co nsectetur adipiscing elit,
                    sed do eiusmod tempor incididunt.
                  </div>
                </div>
                <div className=" col-md-3 px-md-5 text-center">
                  <div className="imageHeader">Development</div>
                  <div className="imagePara mt-md-2">
                    Lorem ipsum dolor sit amet, co nsectetur adipiscing elit,
                    sed do eiusmod tempor incididunt.
                  </div>
                </div>
                <div className=" col-md-3 px-md-5 text-center">
                  <div className="imageHeader">Product Handover</div>
                  <div className="imagePara mt-md-2">
                    Lorem ipsum dolor sit amet, co nsectetur adipiscing elit,
                    sed do eiusmod tempor incididunt.
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}

        {/*
        <div className="row">
          <div className="row pb50 pe-0">
            <div className="col-12 col-md-12 mt-md-5 text-center pt-4 pt-md-0 pe-0">
              <h2 className="clr0E2B3D default-Paraheader-card Card-ul-header setlineheight recenthdpd">
                What our client think about us!
              </h2>
              <img
                src="images/hrImg.png"
                alt="test"
                className="img-fluid "
              ></img>
            </div>
             <div className="row pt-md-3 px10">
              <div className="col-12 text-center col-md-3">
                <div className="row">
                  <div className="col-12 col-md-12 mb-3">
                    <img
                      className="ps-5 ps-md-0 pt-4"
                      src="./images/RyanSkidMore.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-12 col-md-12 mb-1 ps-5 pe-0  px-md-2">
                    <span className="paraName">Ryan SkidMore</span>
                  </div>
                  <div className="col-12 col-md-12 ps-5 ps-5 pe-0  px-md-2">
                    <span className="paraPost">Director at Berger’s</span>
                  </div>
                </div>
              </div>
              <div className="col-12 custom-blockquote3 spanPara col-md-9 ms-4 ms-md-0">
                <span className="QuotePara ">
                  The service that is served is so very charming with friendly
                  waiters and also a comfortable place so that it makes
                  customers feel at home for a long time in this shop and also
                  the tea shop owner provides free warm and pastries making the
                  atmosphere so beautiful, and this is a very pleasant
                  experience for me. and I will visit here again every weekend
                  with my family.
                </span>
              </div>
            </div> 
          </div>
        </div>*/}
      </div>
    </React.Fragment>
  );
}
