import React, { useEffect, useLayoutEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function HelmetMetaTag(props) {
  useLayoutEffect(() => {
    // Remove elements by ID after component has been mounted
    document.getElementById("ogTitle")?.remove();
    document.getElementById("Title")?.remove();
    document.getElementById("ogDesc")?.remove();
    document.getElementById("Desc")?.remove();
    // document.getElementById("Canonical")?.remove();
    document.getElementById("Ogtype")?.remove();
    document.getElementById("Ogurl")?.remove();
    document.getElementById("OgImage")?.remove();
    document.getElementById("TwtImage")?.remove();
    document.getElementById("TwtSite")?.remove();
    document.getElementById("TwtTitle")?.remove();
    document.getElementById("TwtDesc")?.remove();
    var linkElement = document.getElementById("Canonical");
    if (linkElement) {
      linkElement.href = props.canonical;
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {props.titleName ? <title>{props.titleName}</title> : null}
          {props.ogtitle ? (
            <meta property="og:title" content={props.ogtitle} />
          ) : null}
          {props.ogdesc ? (
            <meta property="og:description" content={props.ogdesc} />
          ) : null}
          {props.desc ? <meta name="description" content={props.desc} /> : null}
          {/* {props.canonical ? (
            <link rel="canonical" href={props.canonical} />
          ) : null} */}
          {props.ogtype ? (
            <meta property="og:type" content={props.ogtype} />
          ) : null}
          {props.ogurl ? (
            <meta property="og:url" content={props.ogurl} />
          ) : null}
          {props.ogimg ? (
            <meta property="og:image" content={props.ogimg} />
          ) : null}
          {props.twittercard ? (
            <meta name="twitter:card" content={props.twittercard} />
          ) : null}
          {props.twitterimg ? (
            <meta name="twitter:image" content={props.twitterimg} />
          ) : null}
          {props.twittersite ? (
            <meta name="twitter:site" content={props.twittersite} />
          ) : null}
          {props.twittertitle ? (
            <meta name="twitter:title" content={props.twittertitle} />
          ) : null}
          {props.twitterdesc ? (
            <meta name="twitter:description" content={props.twitterdesc} />
          ) : null}
        </Helmet>
      </HelmetProvider>
    </>
  );
}
