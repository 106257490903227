import axios from "axios";
import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";
export default function ProcessDynamicComp(props) {
  const [RequirementData, setRequirementData] = useState([]);
  const location = useLocation();
  var width = $(window).width();
  useLayoutEffect(() => {
    var validUrl = true;
    axios
      .get("/jsonFiles/TechProcessData.json")
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (location.pathname == res.data[i].PageName) {
            setRequirementData(res.data[i].Data);
            validUrl = false;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <React.Fragment>
       <>
          {RequirementData?.map((data, i) => {
            return (
              <>
                <div className="col-12 col-md-3 py-3" key={data.id}>
                  <div className="p-4 processDynamicDiv">
                    <div className="row position-relative">
                      <div className="col-12 processCenter">
                        <div className="processCircle">
                          <img className="img-fluid" src={data.Img} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <h3
                        className={` pt-lg-3 mt-3 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                      >
                        {data.id}. {data.Header}
                      </h3>
                    </div>
                    <div className="col-12 ">
                      <div
                        className={` mt-2 px-lg-4 pb-4 processWeFollowFonts `}
                      >
                        {data.Desc}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      {/* {width >= 991 ? (
        <>
          {RequirementData?.map((data, i) => {
            return (
              <>
              <div className="col-md-3 col-12 mt-4 mt-md-0">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src={data.Img}
                    alt="Web Process Icon 3"
                    title="Web Process 3 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  {data.Header}
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                {data.Desc}
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
                {data.id % 2 != 0 && (
                  <>
                    <div className="col-3 " key={data.id}>
                      <div className="row m-2 processDynamicDiv">
                        <div className="col-12">
                          <h3
                            className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                          >
                            {data.Header}
                          </h3>
                        </div>
                        <div className="col-12 pb-5 mb-5">
                          <div
                            className={` mt-2 px-lg-4  processWeFollowFonts `}
                          >
                            {data.Desc}
                          </div>
                        </div>
                      </div>
                      <div className="row m-2 position-relative">
                        <div className="col-12 processCenter">
                          <div
                            className="processCircle position-absolute"
                            style={{ top: "-95px" }}
                          >
                            <img className="img-fluid" src={data.Img} alt="" />
                          </div>
                          <div className="verticalLineTop"></div>
                          <div className="verticalCircle">{data.id}</div>
                        </div>
                      </div>
                    </div>
                    
                  </>
                )}
              </>
            );
          })}
          <div className="py-4 my-4">
            <hr />
          </div>
          {RequirementData?.map((data, i) => {
            return (
              <>
                {data.id % 2 == 0 && (
                  <>
                    <div className="col-3" key={data.id}>
                      <div className="row m-2 processDynamicDiv">
                        <div className="row m-2 position-relative">
                          <div className="col-12 processCenter">
                            <div className="verticalBottom"></div>
                            <div className="verticalCircleBottom">
                              {data.id}
                            </div>
                            <div
                              className="processCircle position-absolute"
                              style={{ top: "-40px" }}
                            >
                              <img
                                className="img-fluid"
                                src={data.Img}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <h3
                            className={` pt-lg-5 mt-3 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                          >
                            {data.Header}
                          </h3>
                        </div>
                        <div className="col-12 ">
                          <div
                            className={` mt-2 px-lg-4 pb-4 processWeFollowFonts `}
                          >
                            {data.Desc}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </>
      ) : (
        <>
          {RequirementData?.map((data, i) => {
            return (
              <>
                <div className="col-12 py-3" key={data.id}>
                  <div className="p-4 processDynamicDiv">
                    <div className="row position-relative">
                      <div className="col-12 processCenter">
                        <div className="processCircle">
                          <img className="img-fluid" src={data.Img} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <h3
                        className={` pt-lg-5 mt-3 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                      >
                        {data.id}. {data.Header}
                      </h3>
                    </div>
                    <div className="col-12 ">
                      <div
                        className={` mt-2 px-lg-4 pb-4 processWeFollowFonts `}
                      >
                        {data.Desc}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      )} */}
    </React.Fragment>
  );
}
