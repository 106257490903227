import ButtonHoverEffect from "./ButtonHoverEffect";
import LazyLoadingImg from "./LazyLoadingImg";
export function DevelopmentRecentWork(props) {
  var imagePositionRight = "";
  if (props.ImagePosition == "right") {
    imagePositionRight = "image-pos-right";
  }
  var headerClass = "ServiceHeader";
  if (
    props.CustomHeaderClass != undefined &&
    props.CustomHeaderClass != null &&
    props.CustomHeaderClass != ""
  ) {
    headerClass = props.CustomHeaderClass;
  }
  var bodyClass = "ServicePara";
  if (
    props.CustomBodyClass != undefined &&
    props.CustomBodyClass != null &&
    props.CustomBodyClass != ""
  ) {
    bodyClass = props.CustomBodyClass;
  }
  return (
    <>
      <div className={` ${props.CardMargin} `}>
        <div className={` row ${props.cardcolor} ${imagePositionRight}`}>
          <div
            className={` ${props.customDescriptionItemFirst} px-0 text-center`}
          >
            <LazyLoadingImg
              src={props.Image}
              className={`${props.CustomImageClass} px-0`}
            />
          </div>
          <div
            className={`${props.customDescriptionItemSecond}  ${props.CustomDescriptionBoxClass} `}
          >
            <h3 className={`fontColorBlue ${headerClass} `}>
              {props.DescriptionHeader}
            </h3>
            <div
              className={`mt-2 ${bodyClass} `}
              style={{ textAlign: "justify" }}
            >
              {props.Description}
            </div>
            {props.DevelopmentRecentWorkBtn && <div className={`mt-2`}>
            <ButtonHoverEffect RedirectUrl={props.redirectNumber} />
              </div>}
          </div>
        </div>
      </div>
    </>
  );
}
