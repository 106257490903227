import React from "react";
import IndustryServingsHoverComp from "./IndustryServingsHoverComp";

export default function LifeAtSprigStack() {
  const diu = [
    {
      original: "/images/Life@SprigStack/Diu/Diu1.JPEG",
      thumbnail: "/images/Life@SprigStack/Diu/Diu1.JPEG",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diu/Diu2.JPEG",
      thumbnail: "/images/Life@SprigStack/Diu/Diu2.JPEG",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diu/Diu3.jpg",
      thumbnail: "/images/Life@SprigStack/Diu/Diu3.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diu/Diu4.JPEG",
      thumbnail: "/images/Life@SprigStack/Diu/Diu4.JPEG",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diu/Diu5.jpg",
      thumbnail: "/images/Life@SprigStack/Diu/Diu5.jpg",
      originalHeight: "400px",
    },
  ];
  const Udaipur = [
    {
      original: "/images/Life@SprigStack/UdaipurTrip/udaipur3.jpg",
      thumbnail: "/images/Life@SprigStack/UdaipurTrip/udaipur3.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/UdaipurTrip/udaipur1.jpg",
      thumbnail: "/images/Life@SprigStack/UdaipurTrip/udaipur1.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/UdaipurTrip/udaipur2.jpg",
      thumbnail: "/images/Life@SprigStack/UdaipurTrip/udaipur2.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/UdaipurTrip/udaipur4.jpg",
      thumbnail: "/images/Life@SprigStack/UdaipurTrip/udaipur4.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/UdaipurTrip/udaipur5.jpg",
      thumbnail: "/images/Life@SprigStack/UdaipurTrip/udaipur5.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/UdaipurTrip/udaipur6.jpg",
      thumbnail: "/images/Life@SprigStack/UdaipurTrip/udaipur6.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/UdaipurTrip/udaipur7.jpg",
      thumbnail: "/images/Life@SprigStack/UdaipurTrip/udaipur7.jpg",
      originalHeight: "400px",
    },
  ];
  const RadheUpavan = [
    {
      original: "/images/Life@SprigStack/RadheUpavan/radheUpavan1.jpg",
      thumbnail: "/images/Life@SprigStack/RadheUpavan/radheUpavan1.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/RadheUpavan/radheUpavan2.jpg",
      thumbnail: "/images/Life@SprigStack/RadheUpavan/radheUpavan2.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/RadheUpavan/radheUpavan3.jpg",
      thumbnail: "/images/Life@SprigStack/RadheUpavan/radheUpavan3.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/RadheUpavan/radheUpavan4.jpg",
      thumbnail: "/images/Life@SprigStack/RadheUpavan/radheUpavan4.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/RadheUpavan/radheUpavan5.jpg",
      thumbnail: "/images/Life@SprigStack/RadheUpavan/radheUpavan5.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/RadheUpavan/radheUpavan6.jpg",
      thumbnail: "/images/Life@SprigStack/RadheUpavan/radheUpavan6.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/RadheUpavan/radheUpavan7.jpg",
      thumbnail: "/images/Life@SprigStack/RadheUpavan/radheUpavan7.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/RadheUpavan/radheUpavan8.jpg",
      thumbnail: "/images/Life@SprigStack/RadheUpavan/radheUpavan8.jpg",
      originalHeight: "400px",
    },
  ];
  const Navratri = [
    {
      original: "/images/Life@SprigStack/Navratri/navratri1.jpg",
      thumbnail: "/images/Life@SprigStack/Navratri/navratri1.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Navratri/navratri2.jpg",
      thumbnail: "/images/Life@SprigStack/Navratri/navratri2.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Navratri/navratri3.jpg",
      thumbnail: "/images/Life@SprigStack/Navratri/navratri3.jpg",
      originalHeight: "400px",
    },
  ];
  const Diwali = [
    {
      original: "/images/Life@SprigStack/Diwali/diwali 1.jpg",
      thumbnail: "/images/Life@SprigStack/Diwali/diwali 1.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diwali/diwali 2.jpg",
      thumbnail: "/images/Life@SprigStack/Diwali/diwali 2.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diwali/diwali 3.jpg",
      thumbnail: "/images/Life@SprigStack/Diwali/diwali 3.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diwali/diwali 4.jpg",
      thumbnail: "/images/Life@SprigStack/Diwali/diwali 4.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diwali/diwali 5.jpg",
      thumbnail: "/images/Life@SprigStack/Diwali/diwali 5.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diwali/diwali 6.jpg",
      thumbnail: "/images/Life@SprigStack/Diwali/diwali 6.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diwali/diwali 7.jpg",
      thumbnail: "/images/Life@SprigStack/Diwali/diwali 7.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Diwali/diwali 8.jpg",
      thumbnail: "/images/Life@SprigStack/Diwali/diwali 8.jpg",
      originalHeight: "400px",
    },
  ];
  const outing = [
    {
      original: "/images/Life@SprigStack/Outing_Day_2023/outing 1.jpg",
      thumbnail: "/images/Life@SprigStack/Outing_Day_2023/outing 1.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Outing_Day_2023/outing 2.jpg",
      thumbnail: "/images/Life@SprigStack/Outing_Day_2023/outing 2.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Outing_Day_2023/outing 3.jpg",
      thumbnail: "/images/Life@SprigStack/Outing_Day_2023/outing 3.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Outing_Day_2023/outing 4.jpg",
      thumbnail: "/images/Life@SprigStack/Outing_Day_2023/outing 4.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Outing_Day_2023/outing 5.jpg",
      thumbnail: "/images/Life@SprigStack/Outing_Day_2023/outing 5.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Outing_Day_2023/outing 6.jpg",
      thumbnail: "/images/Life@SprigStack/Outing_Day_2023/outing 6.jpg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Outing_Day_2023/outing 7.jpg",
      thumbnail: "/images/Life@SprigStack/Outing_Day_2023/outing 7.jpg",
      originalHeight: "400px",
    },
  ];
  const Navratri_2023 = [
    {
      original: "/images/Life@SprigStack/Navratri_2023/Navratri_2023 1.jpeg",
      thumbnail: "/images/Life@SprigStack/Navratri_2023/Navratri_2023 1.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Navratri_2023/Navratri_2023 2.jpeg",
      thumbnail: "/images/Life@SprigStack/Navratri_2023/Navratri_2023 2.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Navratri_2023/Navratri_2023 3.jpeg",
      thumbnail: "/images/Life@SprigStack/Navratri_2023/Navratri_2023 3.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Navratri_2023/Navratri_2023 4.jpeg",
      thumbnail: "/images/Life@SprigStack/Navratri_2023/Navratri_2023 4.jpeg",
      originalHeight: "400px",
    },
  ];
  const Goa_Trip_2023 = [
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/1.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/1.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/2.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/2.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/3.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/3.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/4.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/4.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/5.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/5.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/6.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/6.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/7.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/7.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/8.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/8.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/9.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/9.jpeg",
      originalHeight: "400px",
    },
    {
      original: "/images/Life@SprigStack/Goa_Trip_2023/10.jpeg",
      thumbnail: "/images/Life@SprigStack/Goa_Trip_2023/10.jpeg",
      originalHeight: "400px",
    },
  ];
  const Newyear_Christmas = [
    {
      original:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-1.jpg",
      thumbnail:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-1.jpg",
      originalHeight: "400px",
    },
    {
      original:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-2.jpg",
      thumbnail:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-2.jpg",
      originalHeight: "400px",
    },
    {
      original:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-3.jpg",
      thumbnail:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-3.jpg",
      originalHeight: "400px",
    },
    {
      original:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-4.jpg",
      thumbnail:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-4.jpg",
      originalHeight: "400px",
    },
    {
      original:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-5.jpg",
      thumbnail:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-5.jpg",
      originalHeight: "400px",
    },
    {
      original:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-6.jpg",
      thumbnail:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-6.jpg",
      originalHeight: "400px",
    },
    {
      original:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-7.jpg",
      thumbnail:
        "/images/Life@SprigStack/Christmas_NewYear/christmas-newyear-7.jpg",
      originalHeight: "400px",
    },
  ];
  const OfficeDinner = [
    {
      original:
        "/images/Life@SprigStack/Office_Dinner/office-dinner-1.jpg",
      thumbnail:
        "/images/Life@SprigStack/Office_Dinner/office-dinner-1.jpg",
      originalHeight: "400px",
    },
    {
      original:
        "/images/Life@SprigStack/Office_Dinner/office-dinner-2.jpg",
      thumbnail:
        "/images/Life@SprigStack/Office_Dinner/office-dinner-2.jpg",
      originalHeight: "400px",
    },
    {
      original:
        "/images/Life@SprigStack/Office_Dinner/office-dinner-3.jpg",
      thumbnail:
        "/images/Life@SprigStack/Office_Dinner/office-dinner-3.jpg",
      originalHeight: "400px",
    }
  ];
  return (
    <div className="container-fluid px-0 pb-4">
      <div className="col-12 text-center py-4">
        <h3 className="headerFontSizeH6 clr0E2B3D"> #LifeatSprigStack</h3>
        <img src="/images/hrImg.png" alt="" className="img-fluid"></img>
      </div>
      <div className="row px-md-5 LS_Containers">
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic10.jpg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Office Dinner"
            galleryImages={OfficeDinner}
          />
        </div>
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic9.jpg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="New year & Christmas"
            galleryImages={Newyear_Christmas}
          />
        </div>
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic8.svg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Goa Trip 2023"
            galleryImages={Goa_Trip_2023}
          />
        </div>
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic6.svg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Outing Day 2023"
            galleryImages={outing}
          />
        </div>
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic7.svg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Navratri 2023"
            galleryImages={Navratri_2023}
          />
        </div>
        <div className="col-3 ">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic5.jpg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Annual Trip at Diu 2023"
            galleryImages={diu}
          />
        </div>
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic4.png"
            ContainerSizeClass=" mx-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Diwali Celebration 2022"
            galleryImages={Diwali}
          />
        </div>
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic3.svg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Navratri 2022"
            galleryImages={Navratri}
          />
        </div>
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic2.jpg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Day Outing at Resort"
            galleryImages={RadheUpavan}
          />
        </div>
        <div className="col-3">
          <IndustryServingsHoverComp
            imgSrc="/images/LS_Pic1.jpg"
            ContainerSizeClass=" mx-md-3 p-0 col-3 "
            imgBorder="LS_imgBor LifeSS_Container"
            hoverDisplay={true}
            hoverText="Annual Trip at Udaipur 2022"
            galleryImages={Udaipur}
          />
        </div>
      </div>
    </div>
  );
}
