import React from "react";
import ButtonHoverEffect from "./ButtonHoverEffect";
import LazyLoadingImg from "./LazyLoadingImg";

export default function HomePortfolioComponent(props) {
  return (
    <>
      <div className={`${props.mainClass}`}>
        <div
          className={`row ${
            props.ImagePosition == "right" && "image-pos-right"
          }`}
        >
          <div
            className="col-md-6 px-0 text-center pfbrdrtop"
            style={{ backgroundColor: `${props.PortfolioCardBGC}` }}
          >
            <LazyLoadingImg
              src={`/images/${props.imgSrc}`}
              className={` ${props.customMarginImg} ${
                props.imgHeight ? "h-100" : ""
              } `}
            />
          </div>
          <div
            className="col-md-6 px-0 content-container pfbrdrbtm"
            style={{ backgroundColor: `${props.PortfolioCardBGC}` }}
          >
            <div
              className={props.cardClass}
              style={{ backgroundColor: `${props.PortfolioCardBGC}` }}
            >
              <h3 className="fontColorBlue" style={{ fontWeight: "600" }}>
                {props.title}
              </h3>
              <div className="row pt-4 pb-3">
                <div className={props.customSectionCol}>
                  <div className="row">
                    <div
                      className={props.customSection1Col}
                      style={{
                        borderRight: `1px solid ${props.borderColor} `,
                        lineHeight: props.customLineHeight,
                      }}
                    >
                      <div className={`row ${props.sectionTextColor} pfFont-1`}>
                        {props.sectionOneTextOne}
                      </div>
                      <div className={`row h6 ${props.sectionTextColor}`}>
                        {props.sectionOneTextTwo}
                      </div>
                    </div>
                    <div
                      className={props.customSection2Col}
                      style={{
                        paddingLeft: `${props.customSection2ColPadding}`,
                        lineHeight: props.customLineHeight,
                      }}
                    >
                      <div className={`row ${props.sectionTextColor} pfFont-1`}>
                        {props.sectionTwoTextOne}
                      </div>
                      <div className={`row h6 ${props.sectionTextColor}`}>
                        {props.sectionTwoTextTwo}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b
                className={`text-decoration-none clr0E2B3D crsr-pointer ${props.TitleCustomClasses}`}
                onClick={() => {
                  props.redirecturl &&
                    window.open(`${props.redirecturl}`, "_blank");
                }}
              >
                {props.linkTitle}
              </b>
              {props.portfolioBtn && (
                <div className="d-flex  pt-3">
                  <ButtonHoverEffect RedirectUrl={props.redirectNumber} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
