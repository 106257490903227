import React, { useLayoutEffect, useState } from "react";
import CountSetInterval from "./CounterComp";
import $ from "jquery";
export default function HeroSectionProjects() {
  var width = $(window).width();
  const [countNumber, setCountNumber] = useState({
    Count1: 0,
    Count2: 0,
    Count3: 0,
    Count4: 0,
  });
  useLayoutEffect(() => {
    window.addEventListener("scroll", CounterFun);
    return () => {
      window.removeEventListener("scroll", CounterFun);
    };
  }, [countNumber]);
  const CounterFun = () => {
    const scrollTop = window.scrollY;
    if (width <= 991) {
      if (scrollTop >= 310) {
        setCountNumber({
          ...countNumber,
          Count1: 100,
          Count2: 50,
          Count3: 20,
          Count4: 7,
        });
      }
    }
  };
  return (
    <React.Fragment>
      <div className="container-fluid" id="CountSetIntervalDiv" tabIndex="100">
        <div className="row pb-4 px-lg-6 px-sm-6 ">
          <div className="col-lg-3 col-md-3 col-6 px-md-5 px-1 py-3 text-center RecentContainer ">
            <div className=" HeroSectionHover py-2">
              <div className="row">
                <div className="HS_Font1 ">
                  <CountSetInterval
                    label={"count"}
                    number={width <= 991 ? countNumber.Count1 : 100}
                    duration={parseInt(0)}
                  />
                  +
                </div>
              </div>
              <div className="row">
                <div className="HS_Font2 ">Projects Completed</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6 px-md-5 px-1 py-3 text-center RecentContainer  ">
            <div className=" HeroSectionHover py-2">
              <div className="row">
                <div className="HS_Font1 ">
                  <CountSetInterval
                    label={"count"}
                    number={width <= 991 ? countNumber.Count2 : 50}
                    duration={parseInt(0)}
                  />
                  +
                </div>
              </div>
              <div className="row">
                <div className="HS_Font2 ">Happy Clients</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6 px-md-5 px-1 py-3 text-center RecentContainer ">
            <div className=" HeroSectionHover py-2">
              <div className="row">
                <div className="HS_Font1 ">
                  <CountSetInterval
                    label={"count"}
                    number={width <= 991 ? countNumber.Count3 : 20}
                    duration={parseInt(1)}
                  />
                  +
                </div>
              </div>
              <div className="row">
                <div className="HS_Font2 ">Experts in Team</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6 px-md-5 px-1 py-3 text-center RecentContainer ">
            <div className=" HeroSectionHover py-2">
              <div className="row">
                <div className="HS_Font1 ">
                  <CountSetInterval
                    label={"count"}
                    number={width <= 991 ? countNumber.Count4 : 7}
                    duration={parseInt(1)}
                  />
                  +
                </div>
              </div>
              <div className="row">
                <div className="HS_Font2 ">Successful Years</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
