import React from 'react'

export default function CrmErpLatestProjects(props) {
  return (
    <>
       <div className="mt-md-3 mt-3 zoom-container">
        <div className="row crmaddress2 d-flex justify-content-center align-items-center p-md-3 pb-5" style={{ backgroundImage: `url(${props.ProjectBackground})` }} >
          <div className={`col-md-6 text-center ${props.contentOrder === 'imageFirst' ? 'order-md-1' : 'order-md-2'}`}>
            <img
              src={`/images/${props.ProjectImage}`}
              alt={`${props.Project}`}
              className={`img-fluid mt-4 ${props.imgwidth}`}
            />
          </div>
          <div className={`col-md-6 text-center text-md-start ${props.contentOrder === 'imageFirst' ? 'order-md-2' : 'order-md-1'} ${props.contentOrder === 'textFirst' ? 'p-md-5 p-3' : ''}`}>
            <h3 className={`crmheading mt-3 mt-md-0 ${props.contentOrder === 'textFirst' ? 'ps-md-5' : ''}`} style={{color:`${props.TextColor}`}}>{props.ProjectName}</h3>
            <br />
            <label className={`crmTechnology ${props.contentOrder === 'textFirst' ? 'ps-md-5' : ''}`} style={{color:`${props.TextColor}`}}>
              Technologies Used:{" "}
              <span style={{ fontWeight: "300" }}>
              {props.ProjectTech}
              </span>
            </label>
            <br />

            <div
              className={`px-0 desktop mt-5 ${props.contentOrder === 'textFirst' ? 'ps-md-5' : ''}`}
              id="container"
              style={{ textAlign: "-webkit-left" }}
            >
              <button
                className={`${props.Btneffect} learn-more`}
                onClick={() => {
                    window.open(
                      props.ProjectUrl,
                      "_blank"
                    );
                  }}                  
              >
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                </span>
                <span className="button-text">Know More</span>
              </button>
            </div>
            <div className="mobile" style={{ textAlign: "-webkit-center" }}>
              <div className="col-lg-8 col-md-8 m-auto mb-lg-4 col-8">
                <button
                  className={`${props.BtnMobileEffect} mt-5`}
                  onClick={() => {
                    window.open(
                      props.ProjectUrl,
                      "_blank"
                    );
                  }} 
                >
                  <img
                    className="img-fluid me-2"
                    src={props.flag === '1' ? './images/rightArrow.png' : '/images/personalizedWhiteArrow.svg'}
                    alt=""
                  />{" "}
                  Know More{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
