import React from "react";
import { useState, useLayoutEffect, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ContentAndImage } from "../Components/ContentAndImage";
import { HeaderWithDynamicContent } from "../Components/HeaderWithDynamicContent";
import { BreadCrumbCard } from "../Components/BreadCrumbCard";
import PersonalizedComp from "../Components/PersonalizedComp";
import ButtonHoverEffect from "../Components/ButtonHoverEffect";
import { TempTestPage } from "../TempTestPage";
import { ClientComp } from "../Components/ClientComp";

export function TechnologyDynamicComp() {
  const [RequirementData, setRequirementData] = useState([]);

  const Params = useParams();
  useLayoutEffect(() => {
    var validUrl = true;
    axios
      .get("/jsonFiles/TechnologyDetails.json")
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (Params.details == res.data[i].url) {
            setRequirementData(res.data[i]);
            validUrl = false;
          }
        }

        if (validUrl) window.location.href = "/react-technology";
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/Vector.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="The standard Lorem Ipsum  passage used since the 1500s"
      CustomBodyClass="serviceBodyFonts"
      Body=""
      IsCard="false"
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/Vector.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="The standard Lorem Ipsum passage, used since the 1500s"
      CustomBodyClass="serviceBodyFonts"
      Body=" "
      IsCard="false"
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/Vector.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="The standard Lorem Ipsum passage, used since the 1500s"
      CustomBodyClass="serviceBodyFonts"
      Body=""
      IsCard="false"
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/Vector.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="The standard Lorem Ipsum passage, used since the 1500s"
      CustomBodyClass="serviceBodyFonts"
      Body=""
      IsCard="false"
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/Vector.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="The standard Lorem Ipsum passage, used since the 1500s"
      CustomBodyClass="serviceBodyFonts"
      Body=""
      IsCard="false"
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/Vector.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="The standard Lorem Ipsum passage, used since the 1500s"
      CustomBodyClass="serviceBodyFonts"
      Body=""
      IsCard="false"
    />
  );

  var serviceConent2 = [];
  serviceConent2.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      CustomHeaderClass="pdd-font1"
      Header="The standard Lorem Ipsum passage"
      CustomBodyClass=""
      Body=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      IsCard="false"
    />
  );
  serviceConent2.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      CustomHeaderClass="pdd-font1"
      Header="The standard Lorem Ipsum passage"
      CustomBodyClass=""
      Body=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      IsCard="false"
    />
  );
  serviceConent2.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      CustomHeaderClass="pdd-font1"
      Header="The standard Lorem Ipsum passage"
      CustomBodyClass=""
      Body=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      IsCard="false"
    />
  );
  serviceConent2.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      CustomHeaderClass="pdd-font1"
      Header="The standard Lorem Ipsum passage"
      CustomBodyClass=""
      Body=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      IsCard="false"
    />
  );
  serviceConent2.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      CustomHeaderClass="pdd-font1"
      Header="The standard Lorem Ipsum passage"
      CustomBodyClass=""
      Body=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      IsCard="false"
    />
  );
  serviceConent2.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      CustomHeaderClass="pdd-font1"
      Header="The standard Lorem Ipsum passage"
      CustomBodyClass=""
      Body=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      IsCard="false"
    />
  );

  var techonologiesName = [
    {
      Technology: "Frontend",
    },
    {
      Technology: "Backend",
    },
    {
      Technology: "CMS",
    },
    {
      Technology: "Databases",
    },
    {
      Technology: "Cloud Services",
    },
  ];
  var techonologiesContent = [
    {
      Frontend: [
        {
          Technology: "React.js",
          Img: "/images/Technology/frontendlogo1.png",
        },
        {
          Technology: "Vue.js",
          Img: "/images/Technology/frontendlogo2.png",
        },
        {
          Technology: "Next.js",
          Img: "/images/Technology/frontendlogo3.png",
        },
        {
          Technology: "Angular",
          Img: "/images/Technology/frontendlogo4.png",
        },
        {
          Technology: "Html",
          Img: "/images/Technology/frontendlogo5.png",
        },
        {
          Technology: "Css",
          Img: "/images/Technology/frontendlogo6.png",
        },
        {
          Technology: "Bootstrap",
          Img: "/images/Technology/frontendlogo7.png",
        },
      ],
      Backend: [
        {
          Technology: "Asp.net core",
          Img: "/images/Technology/backendlogo1.png",
        },
        {
          Technology: "Asp.net MVC",
          Img: "/images/Technology/backendlogo2.png",
        },
        {
          Technology: "Node.js",
          Img: "/images/Technology/backendlogo3.png",
        },
        {
          Technology: "Php",
          Img: "/images/Technology/backendlogo4.png",
        },
        {
          Technology: "Laravel",
          Img: "/images/Technology/backendlogo5.png",
        },
        {
          Technology: "Code Igniter",
          Img: "/images/Technology/backendlogo6.png",
        },
      ],
      CMS: [
        {
          Technology: "WooCommerce",
          Img: "/images/Technology/cms1.png",
        },
        {
          Technology: "Shopify",
          Img: "/images/Technology/cms2.png",
        },
        {
          Technology: "Joomla",
          Img: "/images/Technology/cms3.png",
        },
        {
          Technology: "Wix",
          Img: "/images/Technology/cms4.png",
        },
        {
          Technology: "Webflow",
          Img: "/images/Technology/cms5.png",
        },
      ],
      DataBases: [
        {
          Technology: "Sql Server",
          Img: "/images/Technology/database1.png",
        },
        {
          Technology: "MySql Server",
          Img: "/images/Technology/database2.png",
        },
        {
          Technology: "MongoDB",
          Img: "/images/Technology/database3.png",
        },
        {
          Technology: "PostgreSql",
          Img: "/images/Technology/database4.png",
        },
        {
          Technology: "AWS RDS",
          Img: "/images/Technology/database5.png",
        },
      ],
      CloudServices: [
        {
          Technology: "Amazon AWS",
          Img: "/images/Technology/CloudService1.png",
        },
        {
          Technology: "Microsoft Azure",
          Img: "/images/Technology/CloudService2.png",
        },
        {
          Technology: "Google Cloud",
          Img: "/images/Technology/CloudService3.png",
        },
      ],
    },
  ];

  var HeroSection = {
    ImagePosition: "left",
    DescriptionHeader: "Where does it come from?",
    CustomHeaderClass: "C_font1",
    CustomBodyClass: "C_font2",
    Image: "./images/Group.svg",
    CustomImageClass: "",
    CardMargin: "mt-5 px-lg-6 px-sm-6",
    CustomDescriptionBoxClass: "p-lg-4",
    Description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
    customDescriptionItemFirst: "col-lg-5 col-md-5 col-12",
    customDescriptionItemSecond: "col-lg-7 col-md-7 col-12 homePageGYB",
    bodyClassCareerRequirement: "d-none",
  };
  var Overview = {
    Header: "Why do we use it?",
    Content:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    ContentCustomClass: "pt-4",
  };
  var OurKey = {
    BgColor: "#F7F7F7",
    Header: "React js Development Services We Offer",
    ContainerBottomPadding: "",
    Content: [serviceConent],
  };
  var PersonalizedCompOne = {
    cardTitle:
      "Looking to migrate your <br/> online identity to a newer <br/> tech or platform?",
    ContainerColor: "FFFPersonalize",
    flag: "1",
  };

  var UiUxProcess = {
    Header: "React.js development services we provide",
    ContainerBottomPadding: "pb-2",
    ContentCustomClass: "justifyCenter",
    Content: [serviceConent2],
    BgColor: "#F7F7F7",
  };
  var PersonalizedCompTwo = {
    cardTitle:
      "Starting from the Logo to complete <br/> Application design, get in touch!  ",
    ContainerColor: "cA49F9D",
    flag: "2",
  };

  return (
    <>
      <ContentAndImage
        h1Tag={true}
        ImagePosition="left"
        DescriptionHeader={RequirementData.heroSection?.DescriptionHeader}
        CustomHeaderClass="C_font1"
        CustomBodyClass="C_font2"
        Image={RequirementData.heroSection?.Image}
        CustomImageClass="webDpManImage"
        CardMargin="mt-5 px-lg-6 px-sm-6"
        CustomDescriptionBoxClass="p-lg-4"
        Description={RequirementData.heroSection?.Description}
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12"
        customDescriptionItemSecond="col-lg-7 col-md-7 col-12 homePageGYB"
        bodyClassCareerRequirement="d-none"
      />

      <HeaderWithDynamicContent
        Header={RequirementData.whydoweuseitSection?.Header}
        Content={RequirementData.whydoweuseitSection?.Content}
        ContentCustomClass="pt-4 px-lg-3"
      />

      <HeaderWithDynamicContent
        BgColor="#F7F7F7"
        Header={RequirementData.developmentserviceofferSection?.Header}
        ContainerBottomPadding=""
        Content={[
          RequirementData?.developmentserviceofferSection?.serviceContent.map(
            (data, i) => {
              return (
                <BreadCrumbCard
                  ColSize="col-md-4 py-4"
                  Image="/images/Vector.svg"
                  CustomHeaderClass="TpPlaceFont clr0E2B3D"
                  Header={data?.Header}
                  CustomBodyClass="serviceBodyFonts"
                  Body=""
                  IsCard="false"
                  ImageContainerClass="text-m-center"
                />
              );
            }
          ),
        ]}
      />

      <PersonalizedComp
        cardTitle="Looking to migrate your <br/> online identity to a newer <br/> tech or platform?"
        ContainerColor="FFFPersonalize"
        flag="1"
      />

      <HeaderWithDynamicContent
        BgColor={UiUxProcess?.BgColor}
        Header={RequirementData.developmentserviceprovideSection?.Header}
        ContainerBottomPadding={UiUxProcess?.ContainerBottomPadding}
        ContentCustomClass={UiUxProcess?.ContentCustomClass}
        Content={
          RequirementData.developmentserviceprovideSection?.serviceConent2
        }
      />

      <div className="row pt-3">
        <div className="col-12 heading">
          {RequirementData?.developmentCycleSection}
          <div>
            <img src="/images/hrImg.png" className="image-fluid" />
          </div>
        </div>
        <div className="method-block-row redesign--technologie-container mx-lg-3 px-lg-6 px-sm-6 pt-3">
          <div className="method-block-col pe-lg-5">
            <div className="method-content">
              <div className="item-header justify-content-m-center text-m-center">
                <h3>
                  <a
                    href={RequirementData.devCyclecontentSection1?.link}
                    className="textcolor"
                  >
                    {RequirementData.devCyclecontentSection1?.heading}
                  </a>
                </h3>
                <div className="icon-wrapper">
                  <img
                    className="our-methods--content-icon-image-1"
                    src="/images/circle-icon-1.png"
                    alt="Business analysis icon"
                  />
                </div>
              </div>
              <p>
                {RequirementData.devCyclecontentSection1?.bodytext}
                <br />
              </p>
              <div className="">
                <a href="https://keenethics.com/services-business-analysis">
                  <ButtonHoverEffect />
                </a>
              </div>
            </div>
            <div className="method-content mb-2">
              <div className="item-header justify-content-m-center text-m-center">
                <h3>
                  <a
                    href={RequirementData.devCyclecontentSection2?.link}
                    className="textcolor"
                  >
                    {" "}
                    {RequirementData.devCyclecontentSection2?.heading}
                  </a>
                </h3>
                <div className="icon-wrapper">
                  <img
                    className="our-methods--content-icon-image-1"
                    src="/images/circle-icon-1.png"
                    alt=" Development and testing icon"
                  />
                </div>
              </div>
              <p>{RequirementData.devCyclecontentSection2?.bodytext} </p>
              <div className="margin-for-mobile">
                <a href={RequirementData.devCyclecontentSection2?.link}>
                  <ButtonHoverEffect />
                </a>
              </div>
            </div>
          </div>
          <div className="method-block-col d-none-for-mobile">
            <div className="circle-block">
              <div className="horizontal-line"></div>
              <div className="vertical-line"></div>
              <a
                href={RequirementData.devCyclecircleSection1?.link}
                className="our-methods--content-icon-image-0 our-methods--content-icon-image"
              >
                <img src={RequirementData.devCyclecircleSection1?.image} />
              </a>
              <a
                href={RequirementData.devCyclecircleSection2?.link}
                className="our-methods--content-icon-image-1 our-methods--content-icon-image"
              >
                <img src={RequirementData.devCyclecircleSection2?.image} />
              </a>
              <a
                href={RequirementData.devCyclecircleSection3?.link}
                className="our-methods--content-icon-image-2 our-methods--content-icon-image"
              >
                <img src={RequirementData.devCyclecircleSection3?.image} />
              </a>
              <a
                href={RequirementData.devCyclecircleSection4?.link}
                className="our-methods--content-icon-image-3 our-methods--content-icon-image"
              >
                <img src={RequirementData.devCyclecircleSection4?.link} />
              </a>
            </div>
          </div>

          <div className="method-block-col pe-lg-5">
            <div className="method-content">
              <div className="item-header justify-content-m-center text-m-center">
                <h3>
                  <a
                    href={RequirementData.devCyclecontentSection3?.link}
                    className="textcolor"
                  >
                    {RequirementData.devCyclecontentSection3?.heading}
                  </a>
                </h3>
                <div className="icon-wrapper">
                  <img
                    className="our-methods--content-icon-image-1"
                    src="/images/circle-icon-1.png"
                    alt="UI/UX design icon"
                  />
                </div>
              </div>
              <p>{RequirementData.devCyclecontentSection3?.bodytext} </p>
              <div className="margin-for-mobile">
                <a href={RequirementData.devCyclecontentSection3?.link}>
                  <ButtonHoverEffect />
                </a>
              </div>
            </div>
            <div className="method-content mb-2">
              <div className="item-header justify-content-m-center text-m-center">
                <h3>
                  <a
                    href={RequirementData.devCyclecontentSection4?.link}
                    className="textcolor"
                  >
                    {RequirementData.devCyclecontentSection4?.heading}
                  </a>
                </h3>
                <div className="icon-wrapper">
                  <img
                    className="our-methods--content-icon-image-1"
                    src="/images/circle-icon-1.png"
                    alt="Maintenance icon"
                  />
                </div>
              </div>

              <p>{RequirementData.devCyclecontentSection4?.bodytext} </p>
              <div className="margin-for-mobile">
                <a href={RequirementData.devCyclecontentSection4?.link}>
                  <ButtonHoverEffect />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-4">
        <PersonalizedComp
          cardTitle="Starting from the Logo to complete <br/> Application design, get in touch!  "
          ContainerColor="cA49F9D"
          flag="2"
        />
        <div className="pt-5" />
        <div className="" style={{ backgroundColor: "#F7F7F7" }}>
          <TempTestPage
            TechName={[
              {
                Technology: "Frontend",
              },
              {
                Technology: "Backend",
              },
              {
                Technology: "CMS",
              },
              {
                Technology: "Databases",
              },
              {
                Technology: "Cloud Services",
              },
            ]}
            TechContent={[
              {
                Frontend: [
                  {
                    Technology: "React.js",
                    Img: "/images/Technology/frontendlogo1.png",
                  },
                  {
                    Technology: "Vue.js",
                    Img: "/images/Technology/frontendlogo2.png",
                  },
                  {
                    Technology: "Next.js",
                    Img: "/images/Technology/frontendlogo3.png",
                  },
                  {
                    Technology: "Angular",
                    Img: "/images/Technology/frontendlogo4.png",
                  },
                  {
                    Technology: "Html",
                    Img: "/images/Technology/frontendlogo5.png",
                  },
                  {
                    Technology: "Css",
                    Img: "/images/Technology/frontendlogo6.png",
                  },
                  {
                    Technology: "Bootstrap",
                    Img: "/images/Technology/frontendlogo7.png",
                  },
                ],
                Backend: [
                  {
                    Technology: "Asp.net core",
                    Img: "/images/Technology/backendlogo1.png",
                  },
                  {
                    Technology: "Asp.net MVC",
                    Img: "/images/Technology/backendlogo2.png",
                  },
                  {
                    Technology: "Node.js",
                    Img: "/images/Technology/backendlogo3.png",
                  },
                  {
                    Technology: "Php",
                    Img: "/images/Technology/backendlogo4.png",
                  },
                  {
                    Technology: "Laravel",
                    Img: "/images/Technology/backendlogo5.png",
                  },
                  {
                    Technology: "Code Igniter",
                    Img: "/images/Technology/backendlogo6.png",
                  },
                ],
                CMS: [
                  {
                    Technology: "WooCommerce",
                    Img: "/images/Technology/cms1.png",
                  },
                  {
                    Technology: "Shopify",
                    Img: "/images/Technology/cms2.png",
                  },
                  {
                    Technology: "Joomla",
                    Img: "/images/Technology/cms3.png",
                  },
                  {
                    Technology: "Wix",
                    Img: "/images/Technology/cms4.png",
                  },
                  {
                    Technology: "Webflow",
                    Img: "/images/Technology/cms5.png",
                  },
                ],
                DataBases: [
                  {
                    Technology: "Sql Server",
                    Img: "/images/Technology/database1.png",
                  },
                  {
                    Technology: "MySql Server",
                    Img: "/images/Technology/database2.png",
                  },
                  {
                    Technology: "MongoDB",
                    Img: "/images/Technology/database3.png",
                  },
                  {
                    Technology: "PostgreSql",
                    Img: "/images/Technology/database4.png",
                  },
                  {
                    Technology: "AWS RDS",
                    Img: "/images/Technology/database5.png",
                  },
                ],
                CloudServices: [
                  {
                    Technology: "Amazon AWS",
                    Img: "/images/Technology/CloudService1.png",
                  },
                  {
                    Technology: "Microsoft Azure",
                    Img: "/images/Technology/CloudService2.png",
                  },
                  {
                    Technology: "Google Cloud",
                    Img: "/images/Technology/CloudService3.png",
                  },
                ],
              },
            ]}
            mainheader="Technologies that we expertise!"
            subHeader="We are expert in various technologies for different needs, take a look below for different categories."
            BgColor="#F7F7F7"
            pt={true}
          />
        </div>
        <ClientComp isBackgroundColor={true} />
      </div>
    </>
  );
}
