import React, { useEffect, useState } from "react";
import Getcall from "./ApiRequest";
import { Link, useParams } from "react-router-dom";
import HelmetMetaTag from "../../Components/HelmetMetaTag";
import ReactPaginate from "react-paginate";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

export default function BlogAuthorPage() {
  const CUSTOM_URL = process.env.REACT_APP_CUSTOM_URL;
  const param = useParams();
  const [authorData, setAuthorData] = useState([]);
  const [AuthorTitle, setAuthorTitle] = useState("Author");
  const [loading, setLoading] = useState(false);
  const [postData, setpostData] = useState([]);
  const [socialUrls, setSocialUrls] = useState([]);
  const socialMediaUrls = [
    { Name: "linkedin", Url: "", img: "../../images/linkedinBlog.svg" },
    { Name: "facebook", Url: "", img: "../../images/facebookblog.svg" },
    { Name: "pinterest", Url: "", img: ".././images/PinterestBlog.svg" },
    { Name: "twitter", Url: "", img: "../../images/xblog.svg" },
  ];
  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    await Getcall(
      `/get_posts_by_author?name=${param.title.replace(/-/g, " ")}`,
      CUSTOM_URL
    )
      .then((res) => {
        var authres = JSON.parse(res);
        Getcall("/users/" + authres)
          .then((result) => {
            var results = JSON.parse(result);
            setAuthorData(results);
            setAuthorTitle(results?.yoast_head_json?.title);
            console.log(AuthorTitle);
            const socialMediaLinks = results?.yoast_head_json?.schema[
              "@graph"
            ].find((item) => item["@type"] === "Person")?.sameAs;

            socialMediaUrls.forEach((items, i) => {
              socialMediaLinks.forEach((data, j) => {
                if (data.includes(items?.Name)) {
                  socialMediaUrls[i].Url = data;
                  return;
                }
              });
            });
            setSocialUrls(socialMediaUrls);
          })
          .catch((error) => console.log("error", error));
      })
      .catch((error) => console.log("error", error));
    await Getcall(
      `/get_posts_by_author?name=${param.title.replace(/-/g, " ")}`,
      CUSTOM_URL
    ).then((res) => {
      var authres = JSON.parse(res);
      Getcall(`/posts?author=${authres}`)
        .then((result) => {
          var results = JSON.parse(result);
          setpostData(results);
        })
        .catch((error) => console.log("error", error));
    });
    setLoading(false);
  }
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;
  const pageCount = Math.ceil(postData.length / itemsPerPage);
  const displayedItems = postData?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const blogSchema = {
    "@context": "https://schema.org/",
    "@type": "Person",
    name: "Ronak shah",
    url: `https://sprigstack.com/blog/author/${authorData?.name
      ?.split(" ")
      ?.join("-")
      ?.toLowerCase()}`,
    image: `${authorData?.yoast_head_json?.og_image[0]?.url}`,
    sameAs: `${socialUrls
      .filter((item) => item.Url && item.Url.trim() !== "") // Filter out null or empty URLs
      .map((item) => item.Url)
      .join(",")}`,
    jobTitle: `${authorData?.yoast_head_json?.title}`,
    worksFor: {
      "@type": "Organization",
      name: "SprigStack",
    },
  };
  return (
    <React.Fragment>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      ) : (
        <>
          <HelmetProvider>
            <Helmet>
              <script type="application/ld+json">
                {JSON.stringify(blogSchema)}
              </script>
            </Helmet>
          </HelmetProvider>
          <HelmetMetaTag
            titleName={`${AuthorTitle} - ${authorData?.name} | SprigStack`}
            canonical={window.location.href}
            ogtitle={`${authorData?.yoast_head_json?.title} - ${authorData?.name} | SprigStack`}
            ogtype="website"
            ogurl={window.location.href}
          />
          <div className="row px-6 py-md-4 py-2 ">
            <div className="col-md-3 ">
              <img
                className="img-fluid authorImg"
                src={authorData?.yoast_head_json?.og_image[0]?.url}
                alt=""
              />
            </div>
            <div className="col-md-9 mt-4 mt-md-0 position-relative">
              <h1 className="authorName">{authorData?.name}</h1>
              <p className="authorDesignation">
                {authorData?.yoast_head_json?.title}
              </p>
              <p className="authorDescription">{authorData?.description}</p>
              {socialUrls.map((item, index) =>
                item?.Url ? (
                  <a href={item.Url} rel="nofollow">
                    <img className="img-fluid me-2" src={item.img} alt="" />
                  </a>
                ) : (
                  ""
                )
              )}
            </div>
          </div>
          <div className="row px-6 py-md-4 py-2">
            <div className="col-12 text-center">
              <h2 className="clr0E2B3D  headerFontSizeH6">Author Blogs</h2>
              <img
                src="../../images/hrImg.png"
                alt=""
                className="img-fluid "
              ></img>
            </div>
            <div className="col-12">
              <div className="row">
                {displayedItems?.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  displayedItems?.map((item) => (
                    <>
                      <div className="col-md-4 col-12 my-3 px-4">
                        <Link
                          className="anchorOrange"
                          to={`/blog/${item?.title?.rendered
                            ?.split(" ")
                            ?.join("-")}`}
                        >
                          <div className="row postBox h-100">
                            <div className="col-12">
                              <img
                                className="img-fluid mt-3"
                                src={item?.acf?.hero_image?.url}
                                alt={item?.acf?.hero_image?.alt}
                              />
                            </div>
                            <div className="col-12">
                              <p className="postHeader my-3">
                                {item?.acf?.page_title}
                              </p>
                              <p className="postPara">{item?.acf?.hero_para}</p>
                            </div>
                            <div className="col-12">
                              {item?.yoast_head_json?.description
                                ?.split(", ")
                                ?.map((article, index) => (
                                  <button className="postBtn">
                                    <Link
                                      to={`/blog/category/${article
                                        ?.split(" ")
                                        ?.join("-")
                                        .toLowerCase()}`}
                                    >
                                      {article}
                                    </Link>
                                  </button>
                                ))}
                            </div>
                            <div className="col-12 my-3">
                              <div className="row ">
                                <div className="col-7 HeroDate">
                                  {/* {item?.date?.split("T")[0]} */}
                                  {new Date(item?.date).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )}
                                </div>
                                <div
                                  className="col-5 "
                                  style={{ textAlign: "right" }}
                                >
                                  <div className="clr-orange">Read More »</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </>
                  ))
                )}
                {pageCount > 1 && (
                  <div className="pagination-container">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
