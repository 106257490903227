export const dynamicData = (id) => {
  var dynamicValue;
  switch (id) {
    case 1:
      dynamicValue = {
        title: "Your Online Farmers Market that Delivers",
        titleDes:
          "Locally grown, no minimum orders. Buy what, and when you want.",
        challengesArray: [
          " when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          " It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
          " Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy",
          "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        ],
        img: "portfoliodetailsImg1",
        card1: {
          text1: "Technology",
          text2: "react",
        },
        card2: {
          text1: "Category",
          text2: "Network, Marketing",
        },
      };
      break;
    case 2:
      dynamicValue = {
        title: "Find the best CoWorking space near you",
        titleDes: "Easy steps to book best CoWorking space online",
        challengesArray: [
          "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
          " Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy",
          " It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
          " when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        ],
        img: "portfolio2",
        card1: {
          text1: "Technology",
          text2: "php",
        },
        card2: {
          text1: "Category",
          text2: " Marketing",
        },
      };
      break;
    case 3:
      dynamicValue = {
        title: "TalknSave - Your Cell Phone Company in Israel",
        titleDes:
          "TalknSave has been offering quality service to individuals and groups traveling to Israel since 1999.",
        challengesArray: [
          "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
          " Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy",
          " It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
          " when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        ],
        img: "portfolio3",
        card1: {
          text1: "Technology",
          text2: "React",
        },
        card2: {
          text1: "Category",
          text2: "Marketing",
        },
      };
      break;
    default:
      break;
  }
  localStorage.setItem("dynamicData", JSON.stringify(dynamicValue));
  window.location.href = "/portfolio/details";
};
