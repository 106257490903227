import $ from "jquery";
export function DescriptiveCard(props) {
  var width = $(window).width();
  var imagePositionRight = "";
  if (props.ImagePosition == "right") {
    imagePositionRight = "image-pos-right";
  }
  var headerClass = "C_font1";
  if (
    props.CustomHeaderClass != undefined &&
    props.CustomHeaderClass != null &&
    props.CustomHeaderClass != ""
  ) {
    headerClass = props.CustomHeaderClass;
  }
  var bodyClass = "C_font2";
  if (
    props.CustomBodyClass != undefined &&
    props.CustomBodyClass != null &&
    props.CustomBodyClass != ""
  ) {
    bodyClass = props.CustomBodyClass;
  }
  return (
    <>
      {width >= 991 ? (
        <div className={`row ${props.CardMargin} ${imagePositionRight} `}>
          <div className="col-lg-2 col-md-2 col-12 text-center">
            <img
              src={props.Image}
              alt=""
              className={`img-fluid mt-lg-3 ${props.imgClass}`}
            />
          </div>
          <div
            className={`col-lg-10 col-md-10 col-12  ${props.CustomDescriptionBoxClass} `}
          >
            <h3 className={`row mt-3 fontColorBlue ${headerClass} `}>
              {props.DescriptionHeader}
            </h3>
            <div className={`row ${bodyClass} `}>{props.Description}</div>
          </div>
        </div>
      ) : (
        // ---------------mobile view-------------------
        <>
          <div className={`row ${props.CardMargin} ${imagePositionRight} `}>
            <div
              className={`col-lg-2 col-md-2 col-3 text-center ${props.SubIconImgCss}`}
            >
              <img
                src={props.Image}
                alt=""
                className={`img-fluid mt-lg-3 ${props.imgClass}`}
              />
            </div>
            <div
              className={`col-lg-10 col-md-10 col-9  ${props.CustomDescriptionBoxClass} `}
            >
              <h3 className={`row mt-1 fontColorBlue ${headerClass} `}>
                {props.DescriptionHeader}
              </h3>
            </div>
          </div>
          <div className={`row ${bodyClass} `}>{props.Description}</div>
        </>
      )}
    </>
  );
}
