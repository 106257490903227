import React, { useReducer, useState } from "react";
import HoverComponent from "./Components/HoverComponent";
import { Link } from "react-router-dom";
import { formReducer, INITIAL_STATE } from "./Reducer/UserDetailsReduser";
import PhoneInput from "react-phone-input-2";
import $ from "jquery";

export default function Referral() {
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [country, setCountry] = useState(sessionStorage.getItem("Country"));
  const [successMsg, setSuccessMsg] = useState({
    errMsg: "",
    mailSentMsg: false,
  });

  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const ReferralSubmit = (e) => {
    e.preventDefault();
    setSuccessMsg({ ...successMsg, errMsg: "" });
    if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        errMsg: "Please Enter Phone Number",
      });
    } else {
      var formdata = new FormData();
      formdata.append("Name", state.Name);
      formdata.append("ContactNumber", $("#ContactNumber").val());
      formdata.append("Email", state.Email);
      formdata.append("ProjectDetail", state.ProjectDetail);
      formdata.append("ProjectBrief", state.ProjectBrief);
      formdata.append("Subject", "From Referral Page");
      formdata.append("PageName", "/referral-page");
      var requestOptions = {
        method: "POST",
        body: formdata,
      };

      fetch("https://app.addresstwo.com/SprigstackEmail/Index", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var results = JSON.parse(result);
          if (results.Status == "Success" && results.Message == "Success") {
            setSuccessMsg({ ...successMsg, mailSentMsg: true });
          } else {
            console.log("Error:", results.Message);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div className="refdiv text-center position-relative">
          <h1 className="refHeading">
            Claim up to <span className="discount">10%</span>
            <br className="d-block d-md-none" /> Discount Now!
          </h1>
          <br className="d-none d-md-block" />
          <h2 className="refText">
            Your friend's referral link gets you extra perks for your project 🤩
          </h2>
          <br className="d-none d-md-block" />
          <h2 className="refText px-md-5 px-2 py-1 highlight">
            <span style={{ border: "1px solid #6BB7FC" }}>
              Let's code your vision Together!
            </span>
          </h2>

          <Link to="/">
            <img
              src="/images/ss-referral.png"
              alt="ss-ref"
              className="position-absolute ss-ref clickable bottom-0 start-50 translate-middle-x"
            />
          </Link>
        </div>

        <div className="container-fluid p-0">
          <div className="colorWhite d-flex align-items-center">
            <h2 className="pr-3 clientdiv ">Our Clients</h2>
            <logo-slider style={{ marginTop: "0px" }}>
              <div>
                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="Berger's Table Pads & Table Cloth logo"
                    title="Our Project Berger's Table Pads & Table Cloth logo"
                    imgSrc="client1"
                    hoverSrc="client1Hover"
                  />
                </a>
                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="gocoworq logo"
                    title="Our Project gocoworq"
                    imgSrc="client2"
                    hoverSrc="client2Hover"
                  />
                </a>

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="talknsave logo"
                    title="Our Project  talknsave"
                    imgSrc="client3"
                    hoverSrc="client3Hover"
                  />
                </a>
                <HoverComponent
                  alt="secureroofing logo"
                  title="Our Project secureroofing"
                  imgSrc="client4"
                  hoverSrc="client4Hover"
                />

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="addresstwo logo"
                    title="Our Project addresstwo"
                    imgSrc="client5"
                    hoverSrc="client5Hover"
                  />
                </a>

                <HoverComponent
                  alt="alwajeeh logo"
                  title="Our Project alwajeeh"
                  imgSrc="client6"
                  hoverSrc="client6Hover"
                />

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="Marketwagon logo"
                    title="Our Project  Marketwagon"
                    imgSrc="client7"
                    hoverSrc="client7Hover"
                  />
                </a>
              </div>
              <div>
                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="Berger's Table Pads & Table Cloth logo"
                    title="Our Project Berger's Table Pads & Table Cloth logo"
                    imgSrc="client1"
                    hoverSrc="client1Hover"
                  />
                </a>

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="gocoworq logo"
                    title="Our Project gocoworq"
                    imgSrc="client2"
                    hoverSrc="client2Hover"
                  />
                </a>

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="talknsave logo"
                    title="Our Project  talknsave"
                    imgSrc="client3"
                    hoverSrc="client3Hover"
                  />
                </a>
                <HoverComponent
                  alt="secureroofing logo"
                  title="Our Project secureroofing"
                  imgSrc="client4"
                  hoverSrc="client4Hover"
                />

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="addresstwo logo"
                    title="Our Project addresstwo"
                    imgSrc="client5"
                    hoverSrc="client5Hover"
                  />
                </a>

                <HoverComponent
                  alt="alwajeeh logo"
                  title="Our Project alwajeeh"
                  imgSrc="client6"
                  hoverSrc="client6Hover"
                />

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="Marketwagon logo"
                    title="Our Project  Marketwagon"
                    imgSrc="client7"
                    hoverSrc="client7Hover"
                  />
                </a>
              </div>
            </logo-slider>
          </div>
        </div>

        <div className="row px-6 my-5">
          <div className="col-md-5 referralForm p-5">
            {!successMsg.mailSentMsg ? (
              <>
                <h3 className="refFormText text-center">
                  Drop your details below, and we will contact you within 24
                  hours.
                </h3>

                <form onSubmit={ReferralSubmit}>
                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 position-relative">
                    <label htmlFor="Name" className="customLabel">
                      Name
                    </label>
                    <input
                      type="text"
                      className="customInput"
                      name="Name"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-lg-12 col-md-12 col-12 mt-5 position-relative">
                    <label htmlFor="Email" className="customLabel">
                      Email
                    </label>
                    <input
                      type="email"
                      className="customInput"
                      name="Email"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 position-relative">
                    <div className="customPhoneInputWrapper">
                      <PhoneInput
                        country={country}
                        inputProps={{
                          name: "ContactNumber",
                          required: true,
                          id: "ContactNumber",
                          placeholder: "Phone Number*",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 position-relative">
                    <label htmlFor="ProjectDetail" className="customLabel">
                      Area of interest
                    </label>
                    <select
                      style={{ color: "inherit", height: "35px" }}
                      className="customInput"
                      name="ProjectDetail"
                      onChange={handleChange}
                      required
                    >
                      <option value="Area of interest">
                        Request a consultation
                      </option>
                      <option value="Hire Developers">Hire Developers</option>
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="App Development">App Development</option>
                      <option value="API Integration Services">
                        API Integration Services
                      </option>
                      <option value="UI/UX project">UI/UX project</option>
                      <option value="SEO services">SEO services</option>
                      <option value="DevOps">DevOps</option>
                      <option value="Project Management">
                        Project Management
                      </option>
                      <option value="Project Estimation">
                        Project Estimation
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 position-relative">
                    <label htmlFor="ProjectBrief" className="customLabel">
                      Message
                    </label>
                    <textarea
                      className="customInput"
                      name="ProjectBrief"
                      rows="4"
                      placeholder="Let us know your project idea and we will make it reality"
                      onChange={handleChange}
                      required
                    ></textarea>

                    <div className="row mt-5">
                      <div className="col-lg-8 col-md-8 col-12 text-center m-auto">
                        <input
                          className="referralBtn"
                          type="submit"
                          value="Send"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div className="text-center h3">
                  Thank you for reaching out to us. We will get back to you
                  shortly!
                </div>
              </>
            )}
          </div>
          <div className="col-md-1 OrText d-flex justify-content-center align-items-center my-3 my-md-0">
            OR
          </div>
          <div className="col-md-6 referralForm py-5 px-md-5 px-0">
            <h3 className="refFormText text-center">
              Get started right away by scheduling a free consultation call at
              your convenience.
            </h3>
            <div
              className="calendly-inline-widget calender p-3"
              data-url="https://calendly.com/sprigstack-consultation/30min"
            ></div>
          </div>
        </div>

        <div className="serviceDiv">
          <h2 className="serviceTxt text-center pt-md-4">Our Services</h2>
          <div className="row mt-4">
            <div className="col-md-6 col-6 custom-pe-0">
              <Link to="/mobile-app-development">
                <img
                  src="/images/app-dev-service.png"
                  className="img-fluid"
                  alt="app-development"
                />
              </Link>
            </div>
            <div className="col-md-3 col-3 custom-pe-0 d-flex">
              <Link to="/api-integration-services" className="d-flex">
                <img
                  src="/images/api-int-service.png"
                  className="img-fluid"
                  style={{ flexGrow: "1" }}
                  alt="api-integration"
                />
              </Link>
            </div>
            <div className="col-md-3 col-3 custom-pe-2 d-flex">
              <Link to="/uiux-design" className="d-flex">
                <img
                  src="/images/ui-ux-service.png"
                  className="img-fluid"
                  style={{ flexGrow: "1" }}
                  alt="ui/ux"
                />
              </Link>
            </div>

            <div className="col-md-3 col-3 custom-pe-0 mt-3 d-flex">
              <Link to="/seo" className="d-flex">
                <img
                  src="/images/seo-service.png"
                  style={{ flexGrow: "1" }}
                  className="img-fluid "
                  alt="seo"
                />
              </Link>
            </div>
            <div className="col-md-9 col-9 mt-3 d-flex">
              <Link to="/web-development" className="d-flex">
                <img
                  src="/images/web-dev-service.png"
                  className="img-fluid"
                  style={{ flexGrow: "1" }}
                  alt="web development"
                />
              </Link>
            </div>

            <div className="col-md-3 col-3 custom-pe-0 mt-3 d-flex">
              <Link to="/project-management" className="d-flex">
                <img
                  src="/images/project-service.png"
                  className="img-fluid "
                  style={{ flexGrow: "1" }}
                  alt="project management"
                />
              </Link>
            </div>
            <div className="col-md-3 col-3 custom-pe-0 mt-3 d-flex">
              <Link to="/devops" className="d-flex">
                <img
                  src="/images/devops-service.png"
                  style={{ flexGrow: "1" }}
                  className="img-fluid h-100"
                  alt="devops"
                />
              </Link>
            </div>
            <div className="col-md-6 col-6 mt-3">
              <Link to="/portfolio">
                <img
                  src="/images/portfolio-service.png"
                  className="img-fluid"
                  alt="portfolio"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
