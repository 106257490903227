export function TechnologiesFunction(props) {
  return (
    <>
      <div className={`container-fluid pt-lg-5 pt-3`}>
        <div className="clr0E2B3D text-center pb-4">
          <h2 className={`headerFontSizeH6 ${props.HeaderCss}`}>
            {props.MainHeader}
          </h2>
          <h3
            className={`pt-1 headerFontSizeH3 ${props.SubHeaderCss}`}
            dangerouslySetInnerHTML={{ __html: props.SubHeader }}
          ></h3>
          <img src="/images/hrImg.png" alt="" className="img-fluid mt-1" />
        </div>
        <div className="container-fluid commonClassHdn pb-4">
          <div
            className={`deskDispFlex px-5  justify-content-center gridClass ${props.ContainerMain}`}
          >
            {props.Tech?.map(function (tech, i) {
              return (
                <div
                  className={`px-4 text-center py-2  ${props.ContainerSubMain}`}
                >
                  <div className="techClass">
                    <img
                      src={tech.Img}
                      title={tech.title}
                      alt={tech.alt}
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="techNameClass">{tech.Technology}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
