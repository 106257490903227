import React, { useEffect, useState } from "react";

export default function DivHoverComp(props) {
  const [hoverDiv, setHoverDiv] = useState(false);
  const [windowWidth, setWindowWidth] = useState("");
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });
  return (
    <React.Fragment>
      <div
        className="col-md-4 mb-5 pb-4 pb-md-0 mb-md-0 col-12 col-4"
        onMouseEnter={() => {
          windowWidth >= 768 && setHoverDiv(true);
        }}
        onMouseLeave={() => {
          windowWidth >= 768 && setHoverDiv(false);
        }}
        onClick={() => {
          windowWidth <= 768 && hoverDiv
            ? setHoverDiv(false)
            : setHoverDiv(true);
        }}
      >
        <div className="row position-relative">
          <div className="col-12 px-3 px-md-4 ">
            {hoverDiv ? (
              <div
                className="col-12 px-md-3 px-md-5 px-2 divHoverContainer1"
                style={{
                  height: document.getElementById("img1").height,
                  textAlign: "justify",
                }}
              >
                <div
                  style={{
                    height: document.getElementById("img1").height,
                    textAlign: "justify",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {props.desc}
                </div>
              </div>
            ) : (
              <div className="col-12 px-2 px-md-5 text-center divHoverContainer">
                <img
                  style={{ height: "265px" }}
                  className="img-fluid"
                  id="img1"
                  src={props.imgSrc}
                  alt={props.alt}
                  title={props.title}
                />
              </div>
            )}
          </div>
          <div
            className="col-12 m-auto position-absolute"
            style={{
              bottom: "-15%",
            }}
          >
            <div
              className="text-center mx-5 px-3 py-4 px-md-4 divHoverImgContainer"
              style={{
                background: hoverDiv ? "#3CBBA5" : "#fff",
                color: !hoverDiv ? "#3CBBA5" : "#fff",
              }}
            >
              {props.BtnText}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
