import React from "react";
import Modal from "react-bootstrap/Modal";

export default function Popups(props) {
  return (
    <>
      <Modal
        size={props.size}
        show={props.show}
        onHide={() => {
          props.setShow(false);
        }}
        dialogClassName={props.customClassName}
      >
        <Modal.Header closeButton className={props.customHeader}>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
}
