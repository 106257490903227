import React from "react";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import { DescriptiveCard } from "./Components/DescriptiveCard";
import { DevelopmentRecentWork } from "./Components/DevelopmentWork";
import { DevelopmentCommonFunc } from "./Components/DevelopmentCommon";
import { TempTestPage } from "./TempTestPage";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
import ProcessDynamicComp from "./DynamicComponent/ProcessDynamicComp";
import WhyChooseUsComp from "./Components/WhyChooseUsComp";
import { ClientComp } from "./Components/ClientComp";

export function WebDevelopment() {
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/WdIcon1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Custom Web Development"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Our custom web development services help you achieve your goals with websites built using cutting-edge tech and best practices. We collaborate closely to create user-friendly, flexible solutions, from simple landing pages to complex projects."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/WdIcon2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="Ecommerce Web Development"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Our Ecommerce web development services leverage the power of platforms like WordPress and Shopify to build user-friendly online stores with intuitive and smooth shopping experiences. Responsive designs ensure a seamless experience on any device, making your business shine online."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/WdIcon3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="API Development and Integration"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={
        <span className="ps-0">
          It is very important to have effective web API development for businesses. Our <a href="api-integration-services" className="anchorOrange1"> API integration services </a> ensure a well-designed flow of information to extend the functionality of your web applications and servers. We design & integrate APIs for maximum impact.
        </span>
      }
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/WdIcon4.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="Web App Development"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={
        <span className="ps-0">
          Turn data into action with our <a href="web-application-development" className="anchorOrange1">web application development services</a>. We build custom CRMs, inventory systems, and dashboards to unlock valuable insights. Secure, scalable, and user-friendly, our apps empower informed decision-making.
        </span>
      }
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/WdIcon5.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Content Management Systems"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Our CMS development services, including custom-built solutions and enhancements to existing platforms like WordPress, Shopify, and Wix, simplify creation, storage, and publishing. No coding is required. We offer a perfect fit for any website, from small businesses to complex needs."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/WdIcon6.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="CRM and ERP Development"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={
        <span className="ps-0">
          No business is one-size-fits-all. Our <a className="anchorOrange1" href="erp-development-services">ERP development services</a> and <a href="crm-development-services" className="anchorOrange1">custom CRM development services</a> provide valuable insights to optimize operations and elevate customer interactions. We build user-friendly ERP & CRM systems that equip your business for success.
        </span>
      }
    />
  );
  //#endregion
  //#region Web App Benefit content
  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/webAppIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Quick Turn Around"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/crossPlatformIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Mobile Friendly"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/manageIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Easy to Use and <br/> Manage"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/deployIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Scalable & <br/> Quickly Deployed"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/secureIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Data Security"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/redcuedCostIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Budget Friendly"
      CustomClass="MajorBenefitsContainer"
    />
  );
  //#endregion
  //#region Recent work content
  var recentWorkContent = (
    <>
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader mt-3"
        DescriptionHeader="My Pet Carnivore"
        Image="./images/mpc-web-dev.png"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-4 m-0 p-4 py-5 mt-4 mb-1 recentWorkbox1 RecentContainer"
        Description="Technologies Used : Bootstrap, MySql, Laravel, Webhooks"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto"
        DevelopmentRecentWorkBtn={true}
        redirectNumber="/portfolio/ecommerce-website-development-for-my-pet-carnivore"
        CardMargin="col-12 recentBox"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="AddressTwo - Small Business CRM"
        Image="./images/AddressTwoRecentImg.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 align-items-center RecentContainer"
        Description="Technologies Used : Laravel, Vue.js, MySql, Bootstrap"
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12  px-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 "
        RedirectUrl="https://www.addresstwo.com/"
        DevelopmentRecentWorkBtn={true}
        redirectNumber="/portfolio/custom-crm-development-for-address-two"
        CardMargin="col-md-6"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="ERP System for the Travel Industry"
        Image="./images/erp-web-dev.png"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 align-items-center RecentContainer"
        Description="Technologies Used : React, .Net Core, PostgreSql, Bootstrap, Aws Cloud, Stripe, Twilio"
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12  px-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 "
        DevelopmentRecentWorkBtn={true}
        redirectNumber="/portfolio/development-of-an-erp-system-for-the-travel-industry"
        CardMargin="col-md-6"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="Berger's Table Pads - Custom Made Table Pades Online"
        Image="./images/TablePadsWork.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-5 align-items-center RecentContainer"
        Description="Technologies Used : React.js, Asp.net core, Sql server, Authorize.net"
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12  px-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 "
        RedirectUrl="https://tablepads.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-md-6"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="Gocoworq - Marketplace for
        Coworking"
        Image="./images/goCoworqWork.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 align-items-center RecentContainer"
        Description="Technologies Used : Laravel, Vue.js, MySql, Bootstrap"
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12  px-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 "
        RedirectUrl="https://www.gocoworq.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-md-6"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="MarketWagon - Local Food
        Delivered"
        Image="./images/MarketWagon.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 align-items-center RecentContainer"
        Description="Technologies Used : Laravel, Vue.js, Alpine.js, MySql"
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12  px-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 "
        RedirectUrl="https://marketwagon.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-md-6 ps-md-0"
      />

      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="PanelHive (Event Management System)"
        Image="./images/PanelHive.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 align-items-center RecentContainer"
        Description="Technologies Used : Laravel, Vue.js, Alpine.js, MySql"
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12  px-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 "
        RedirectUrl="http://panelhive.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-md-6 ps-md-0"
      />
    </>
  );
  //#endregion
  //#region Why choose us content
  var whChooseUseLeftBox = [];
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Widely Experienced Team"
      Image="./images/qaSearch.svg"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`Have your website built with our expert web development team and be assured for the great result. Experience is the key here.`}
      CardMargin="pt-1 my-lg-0  mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Top Notch Security"
      Image="./images/valueForMoneyIcon.svg"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`When you work with us, your idea and data are secured intact. We do NDA with all of our clients and share the same process with our employees. `}
      CardMargin="pt-1 my-lg-0 my-4 mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="100% Assured Quality Output"
      Image="./images/flexEngagementIcon.svg"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`We are known for our quality output and support. No client is big or small for us, all of them get 100% assurance in terms of quality and result.`}
      CardMargin="pt-1 my-lg-0 my-4  mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  var whyChooseUsContent = [];
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/web-devChooseUsIcon1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Widely Experienced Team"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={`Our team has a wide range of expertise, ensuring we can tackle any website development challenge you throw our way.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/web-devChooseUsIcon2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Top Notch Security"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`We understand that your website needs to be safe and secure. We take every precaution to protect your data and your visitors' information.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-5 pt-4"
      Image="./images/web-devChooseUsIcon3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Assured Quality Output"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`We are known for our quality output and support. No client is big or small for us, all of them get 100% assurance in terms of quality and result.`}
    />
  );
  //#endregion
  var HeroSection = {
    ImagePosition: "left",
    DescriptionHeader: "Custom Web Development Services",
    CustomHeaderClass: "C_font1",
    CustomBodyClass: "C_font2",
    Image: "./images/webDevelopmentManWithDesk.svg",
    alt: "Web development services ",
    title: "Web Application & Website Development Services",
    CustomImageClass: "",
    CardMargin: " px-lg-6 px-sm-6",
    CustomDescriptionBoxClass: "p-lg-4 px-4",
    Description:
      "Tired of websites that look good but don't deliver results? We offer comprehensive web development services to bring your vision to life, creating a website that is both aesthetically pleasing and functionally sound. Our skilled developers leverage the latest technologies and have advanced programming abilities to ensure we deliver professional web services suited to your specific needs. As an experienced custom web development company specializing in building scalable, secure, and high-performing web applications, we help companies achieve their goals.",
    customDescriptionItemFirst:
      "col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3 text-center",
    customDescriptionItemSecond: "col-lg-6 col-md-6 col-12 homePageGYB",
    bodyClassCareerRequirement: "d-none",
  };
  var Overview = {
    Header: "Overview",
    Content:
      "In our initial days, we started our offerings in website development services. With our expertise and experience, we are always on the path of expanding our business segment in the tech field. As a result of our success, we've helped over 20 startups establish their online identities and completed over 50 successful projects in a short timeframe. We are a website and app development company based in India. We have served web application development services in several technologies suitable for business segments and trends.",
    ContentCustomClass: "pt-3 m-0",
    HeaderPadding: "overviewHeading",
    flag: true,
  };
  var OurKey = {
    BgColor: "#F7F7F7",
    Header: "Our Key Web Development <br/> Services Include",
    ContainerBottomPadding: "pb-md-3 pt-5",
    HeaderPadding: "ourKeyHeading",
    flag: true,
    Content: [serviceConent],
  };
  var PersonalizedCompFirst = {
    cardTitle: "Looking to build your <br/> website at great price?",
    ContainerColor: "FFFPersonalize",
    flag: 3,
  };
  var MajorBenefits = {
    BgColor: "",
    Header: `Major Benefits of Web Development`,
    HeaderPadding: "majorBenefitHeading",
    ContentCustomClass: "",
    Content: [webAppBenefitContent],
    flag: true,
  };
  var UiUxProcess = {
    BgColor: "#F7F7F7",
    Header: `Web Development Process that We Follow`,
    HeaderPadding: "webProcessHeading",
    ContainerBottomPadding: "pb-2 ",
    ContentCustomClass: "justifyCenter weFollowContainer py-4",
    Content: <ProcessDynamicComp />,
  };
  var PersonalizedCompSecond = {
    cardTitle: "Start your mobile friendly website development straight away!",
    ContainerColor: "cA49F9D",
    flag: "2",
  };
  var RecentWork = {
    BgColor: "",
    Header: `Recent Web Development Work`,
    HeaderPadding: "RecentWorkHeading",
    ContentCustomClass: "",
    ContainerBottomPadding: "RecentworkPadding",
    flag: true,
    Content: [recentWorkContent],
  };
  var WhyChooseUs = {
    BgColor: "#F7F7F7",
    Header: ` Why Choose us for Website Development?`,
    HeaderPadding: "whyChooseUsHeading",
    ContentCustomClass: "whyChooseUsBox",
    Content: [whyChooseUsContent],
  };
  var techonologiesName = [
    {
      Technology: "Frontend",
    },
    {
      Technology: "Backend",
    },
    {
      Technology: "CMS",
    },
    {
      Technology: "Databases",
    },
    {
      Technology: "Cloud Services",
    },
  ];
  var techonologiesContent = [
    {
      Frontend: [
        {
          Technology: "React.js",
          Img: "/images/Technology/frontendlogo1.png",
          alt: "React logo",
          title: "Frontend Technology React ",
        },
        {
          Technology: "Vue.js",
          Img: "/images/Technology/frontendlogo2.png",
          alt: "Vue.js logo",
          title: "Frontend Technology Vue.js",
        },
        {
          Technology: "Next.js",
          Img: "/images/Technology/frontendlogo3.png",
          alt: "Next.js logo",
          title: "Frontend Technology Next.js",
        },
        {
          Technology: "Angular",
          Img: "/images/Technology/frontendlogo4.png",
          alt: "Angular logo",
          title: "Frontend Technology Angular",
        },
        {
          Technology: "Html",
          Img: "/images/Technology/frontendlogo5.png",
          alt: "Html  logo",
          title: "Frontend Technology Html",
        },
        {
          Technology: "Css",
          Img: "/images/Technology/frontendlogo6.png",
          alt: "CSS logo",
          title: "Frontend Technology CSS",
        },
        {
          Technology: "Bootstrap",
          Img: "/images/Technology/frontendlogo7.png",
          alt: "BootStrap logo",
          title: "Frontend Technology BootStrap",
        },
      ],
      Backend: [
        {
          Technology: "Asp.net core",
          Img: "/images/Technology/backendlogo1.png",
          alt: "Asp.net core logo",
          title: "Backend Technology Asp.net core ",
        },
        {
          Technology: "Asp.net MVC",
          Img: "/images/Technology/backendlogo2.png",
          alt: "Asp.net MVC logo",
          title: "Backend Technology Asp.net MVC",
        },
        {
          Technology: "Node.js",
          Img: "/images/Technology/backendlogo3.png",
          alt: "Node.js logo",
          title: "Backend Technology Node.js",
        },
        {
          Technology: "Php",
          Img: "/images/Technology/backendlogo4.png",
          alt: "Php logo",
          title: "Backend Technology Php",
        },
        {
          Technology: "Laravel",
          Img: "/images/Technology/backendlogo5.png",
          alt: "Laravel logo",
          title: "Backend Technology Laravel",
        },
        {
          Technology: "Code Igniter",
          Img: "/images/Technology/backendlogo6.png",
          alt: "Code Igniter logo",
          title: "Backend Technology Code Igniter",
        },
      ],
      CMS: [
        {
          Technology: "Wordpress",
          Img: "/images/wordpress.png",
          alt: "Wordpress logo",
          title: "CMS Wordpress",
        },
        {
          Technology: "Shopify",
          Img: "/images/Technology/cms2.png",
          alt: "Shopify logo",
          title: "CMS Shopify",
        },
        {
          Technology: "Joomla",
          Img: "/images/Technology/cms3.png",
          alt: "Joomla logo",
          title: "CMS Joomla",
        },
        {
          Technology: "Wix",
          Img: "/images/Technology/cms4.png",
          alt: "Wix logo",
          title: "CMS Wix",
        },
        {
          Technology: "Webflow",
          Img: "/images/Technology/cms5.png",
          alt: "Webflow logo",
          title: "CMS Webflow  ",
        },
      ],
      DataBases: [
        {
          Technology: "Sql Server",
          Img: "/images/Technology/database1.png",
          alt: "Sql Server logo",
          title: "Data Base Sql Server",
        },
        {
          Technology: "MySql Server",
          Img: "/images/Technology/database2.png",
          alt: "MySql Server logo",
          title: "Data Base MySql Server",
        },
        {
          Technology: "MongoDB",
          Img: "/images/Technology/database3.png",
          alt: "MongoDB logo",
          title: "Data Base MongoDB",
        },
        {
          Technology: "PostgreSql",
          Img: "/images/Technology/database4.png",
          alt: "PostgreSql logo",
          title: "Data Base PostgreSql",
        },
        {
          Technology: "AWS RDS",
          Img: "/images/Technology/database5.png",
          alt: "AWS RDS logo",
          title: "Data Base AWS RDS",
        },
      ],
      CloudServices: [
        {
          Technology: "Amazon AWS",
          Img: "/images/Technology/CloudService1.png",
          alt: "Amazon AWS logo",
          title: "Data Base Amazon AWS",
        },
        {
          Technology: "Microsoft Azure",
          Img: "/images/Technology/CloudService2.png",
          alt: "Microsoft Azure logo",
          title: "Data Base Microsoft Azure",
        },
        {
          Technology: "Google Cloud",
          Img: "/images/Technology/CloudService3.png",
          alt: "Google Cloud logo",
          title: "Data Base Google Cloud",
        },
      ],
    },
  ];
  return (
    <>
      <HelmetMetaTag
        titleName="Custom Web Development Services Company | SprigStack"
        desc="SprigStack's Custom Web Development Services build high-performing, secure & scalable websites for any platform. Get a website that works for you."
        canonical="https://sprigstack.com/web-development"
        ogtitle="Custom Web Development Services Company | SprigStack"
        ogdesc="SprigStack's Custom Web Development Services build high-performing, secure & scalable websites for any platform. Get a website that works for you."
        ogtype="website"
        ogurl="https://sprigstack.com/web-development"
      />
      <DevelopmentCommonFunc
        // Title="Web Development"
        HeroSection={HeroSection}
        Overviewsection={Overview}
        OurKeySection={OurKey}
        Space={true}
        PersonalizedCompOne={PersonalizedCompFirst}
        MajorBenefitsSection={MajorBenefits}
        UiUxProcessSection={UiUxProcess}
        PersonalizedCompTwo={PersonalizedCompSecond}
        RecentWorkSection={RecentWork}
        IsNewSection="no"
        TechnologySection={
          <TempTestPage
            TechName={techonologiesName}
            TechContent={techonologiesContent}
            mainheader="Technologies Used For Web Development"
            HeaderPadding="technologiesHeading"
            pt={true}
          />
        }
        IsClientComp={true}
        WhyChooseUsSection={WhyChooseUs}
        FAQSection={<FAQDynamicComp />}
      />
    </>
  );
}
