import React from "react";
import IndustryServingsHoverComp from "./IndustryServingsHoverComp";

export default function IndustryServingsComp(props) {
  return (
    <>
      <div className={`${props.bgColorClass}`}>
        <div className="container-fluid px-0 pb-4">
          <div className={`col-12 text-center ${props.HeaderPadding}`}>
            <h2 className="clr0E2B3D headerFontSizeH6">
              Industries that we serve
            </h2>
            <h3 className="clr0E2B3D headerFontSizeH3New h1Tag">
              We serve clients diversified in almost all the industries.
            </h3>
            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div className="row px-7 IS_Containers">
            <IndustryServingsHoverComp
              imgSrc="/images/Retail_Blue.svg"
              hoverImg="/images/Retail_Orange.svg"
              cardTitle="E-commerce"
              ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/ItSolution__Blue.svg"
              hoverImg="/images/ItSolution_Orange.svg"
              cardTitle="IT Solution"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/Sports_Blue.svg"
              hoverImg="/images/Sports_Orange.svg"
              cardTitle="Sports"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/Coworking_Blue.svg"
              hoverImg="/images/Coworking_Orange.svg"
              cardTitle="Coworking"
              ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
          </div>
          <div className="row px-7 IS_Containers">
            <IndustryServingsHoverComp
              imgSrc="/images/RealEstate_Blue.svg"
              hoverImg="/images/RealEstate_Orange.svg"
              cardTitle="Real Estate"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
              imgBorder=""
            />
            <IndustryServingsHoverComp
              imgSrc="/images/Travel_Blue.svg"
              hoverImg="/images/Travel_Orange.svg"
              cardTitle="Travel"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/Education_Blue.svg"
              hoverImg="/images/Education_Orange.svg"
              cardTitle="Education"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/FoodDeliveryBlue.svg"
              hoverImg="/images/FoodDeliveryOrange.svg"
              cardTitle="Food Delivery"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/Banking&FinanceBlue.svg"
              hoverImg="/images/Banking&FinanceOrange.svg"
              cardTitle="Banking & Finance"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/TelecommunicationBlue.svg"
              hoverImg="/images/TelecommunicationOrange.svg"
              cardTitle="Telecommunication"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/Lifestyle&FashionBlue.svg"
              hoverImg="/images/Lifestyle&FashionOrange.svg"
              cardTitle="Lifestyle & Fashion"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3"
            />
            <IndustryServingsHoverComp
              imgSrc="/images/ManyMore_Blue.svg"
              hoverImg="/images/ManyMore_Orange.svg"
              cardTitle="Many More"
              ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
              ContainerSizeName="IS_Container p-3 h-100"
              imgBorder="andImage"
            />
          </div>
        </div>
      </div>
    </>
  );
}
