import React, { useEffect, useState } from "react";
import { TextandHoverComponenet } from "../Components/TextandHoverComponenet";
import SMBLPHONEHOVEREFFECT from "../Components/SMBLPHONEHOVEREFFECT";
import HelmetMetaTag from "../Components/HelmetMetaTag";

export default function SMBL() {
  const [windowWidth, setWindowWidth] = useState("");

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });
  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="Al-Wajeeh: Hybrid App for Family Communication | SprigStack"
        desc="Al-Wajeeh, developed by SprigStack, connecting over 300 families seamlessly. Powered by React Native, Laravel, AWS, enhancing family communication."
        canonical="https://sprigstack.com/portfolio/hybrid-application-development-for-al-wajeeh"
        ogtitle="Al-Wajeeh: Hybrid App for Family Communication | SprigStack"
        ogdesc="Al-Wajeeh, developed by SprigStack, connecting over 300 families seamlessly. Powered by React Native, Laravel, AWS, enhancing family communication."
        ogtype="website"
        ogurl="https://sprigstack.com/portfolio/hybrid-application-development-for-al-wajeeh"
        ogimg="https://sprigstack.com/images/smbl_heroImage.png"
      />
      <div className="row">
        <div className="col-12 ">
          <div className="row smbl_heroHeader px-6 pb-5 pb-md-3">
            <div className="col-md-6">
              <h1 className="mt-5">
                Hybrid Application Development for Al-Wajeeh
              </h1>
              <p className="mt-4">
                Al Wajeeh is a hybrid app that enables family office members to
                both receive updates from the Al-Wajeeh group and share their
                own, enabling seamless communication among users from over 300
                individual families rooted three generations before. The App
                aims to bring relatives together on a platform where they can
                chat with Family, check media archives, browse contacts, group
                chat, upload images and videos, and use many more features. In
                short, if users want to see what’s happening in the family
                community, they can do so through the Al Wajeeh app.
              </p>
              <div>
                <label htmlFor="smblbussiness">Business Name :</label>
                <span> Al-Wajeeh</span>
              </div>
              <div>
                <label htmlFor="smblbussiness">Industry :</label>
                <span> Mobile Communication and Family Messaging </span>
              </div>
              <div>
                <label htmlFor="smblbussiness">Region :</label>
                <span> Dubai (UAE)</span>
              </div>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid mt-md-3 mt-5"
                src="/images/smbl_heroImage.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-12 px-6">
          <div className="row mt-4">
            <div className="col-12 col-md-12 py-md-4 text-center pt-md-0">
              <h2 className="clr203F59 lineHeader setlineheight recenthdpd mb-md-0">
                Technology <label>Stack</label>
              </h2>
              <img src="/images/hrImg.png" alt="" className="img-fluid " />
            </div>
            <div className="col-12">
              <div className="rowType   px-md-3 text-center1 pt-md-3 pt-4 px-md-5 pb-md-3 d-flex justify-content-center">
                <div className=" wid-15">
                  <TextandHoverComponenet
                    alt="Laravel"
                    title="Laravel"
                    imgSrc="laravel-logo"
                    hoverSrc="laravel-logo-hover"
                  />
                </div>
                <div className="wid-15">
                  <TextandHoverComponenet
                    alt="React Js"
                    title="React Js logo"
                    imgSrc="Pusher"
                    hoverSrc="pusherHover"
                  />
                </div>
                <div className="wid-15">
                  <TextandHoverComponenet
                    alt="Bootstrap"
                    title="Bootstrap logo"
                    imgSrc="react-logo-simple"
                    hoverSrc="react-hover"
                  />
                </div>
                <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="Aws"
                    title="Aws logo"
                    imgSrc="Aws-simple"
                    hoverSrc="Aws-hover"
                  />
                </div>
                <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="MySQl"
                    title="MySQl"
                    imgSrc="mysql"
                    hoverSrc="mysql-hover"
                  />
                </div>
                <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                  <TextandHoverComponenet
                    alt="Fedex"
                    title="Fedex logo"
                    imgSrc="sendgrid"
                    hoverSrc="sendgridHover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 px-6 mt-3">
          {windowWidth >= 768 ? (
            <>
              <div className="row smblRequirement">
                <div className="col-md-6">
                  <h2>Client Requirements</h2>
                  <p className="mt-4">
                    The main requirement was to provide an easy-to-use UI and
                    platform where users connect with family faster and more
                    efficiently. He got an idea with a simple goal about an app
                    to help connect with family members, stay in touch, and
                    share updates.
                  </p>
                  <p>
                    Breaking the communication barriers and giving the members a
                    medium to interact with each other swiftly. He contacted
                    SprigStack to build a solution for him, with this unique
                    concept to create a mobile application that lets relatives
                    associate without compromising security and privacy.
                  </p>
                </div>
                <div className="col-md-6 text-md-end text-center">
                  <img
                    className="img-fluid"
                    src="/images/smbl_image1.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="row smblRequirement">
                <div className="col-6 text-start">
                  <img
                    className="img-fluid"
                    src="/images/smbl_image2.png"
                    alt=""
                  />
                </div>
                <div className="col-6">
                  <h2 className="mt-4">Challenges</h2>
                  <ul className="mt-4">
                    <li>
                      <label>Group Chat : </label> Enabling multiple user chat
                      simultaneously.
                    </li>
                    <li>
                      <label>Large and Multiple File Upload:</label> No limit on
                      uploaded files without quality degradation issues.
                    </li>
                    <li>
                      <label>Unique Design Background:</label> Each page has a
                      different background image; stable static image
                      implementation has been completed.
                    </li>
                  </ul>
                  <h2 className="mt-5">Sprigstack Solution</h2>

                  <p className="mt-4">
                    After carefully analyzing the client's requirements, we
                    assigned appropriate resources and developers and developed
                    a plan of action.
                  </p>
                  <p>
                    We developed a hybrid app using technologies like React
                    native (frontend) and Backend (Laravel), MYSQL, SendGrid,
                    AWS, Twilio, and Pusher. In bringing the custom app to life,
                    SprigStack exceeded the client's expectations and made
                    communication easy between family members. The app is
                    available on Android and iOS platforms.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row smblRequirement mt-4">
                <div className="col-md-6 text-center">
                  <h2>Client Requirements</h2>
                  <img
                    className="img-fluid my-3 "
                    src="/images/smbl_image1.png"
                    alt=""
                  />
                  <p className="mt-4">
                    The main requirement was to provide an easy-to-use UI and
                    platform where users connect with family faster and more
                    efficiently. He got an idea with a simple goal about an app
                    to help connect with family members, stay in touch, and
                    share updates.
                  </p>
                  <p>
                    Breaking the communication barriers and giving the members a
                    medium to interact with each other swiftly. He contacted
                    SprigStack to build a solution for him, with this unique
                    concept to create a mobile application that lets relatives
                    associate without compromising security and privacy.
                  </p>
                </div>
              </div>
              <div className="row smblRequirement">
                <div className="col-md-6 text-center">
                  <h2 className="mt-4">Challenges</h2>
                  <img
                    className="img-fluid my-3"
                    src="/images/smbl_image2.png"
                    alt=""
                  />
                  <ul className="mt-4">
                    <li>
                      <label>Group Chat : </label> Enabling multiple user chat
                      simultaneously.
                    </li>
                    <li>
                      <label>Large and Multiple File Upload:</label> No limit on
                      uploaded files without quality degradation issues.
                    </li>
                    <li>
                      <label>Unique Design Background:</label> Each page has a
                      different background image; stable static image
                      implementation has been completed.
                    </li>
                  </ul>
                  <h2 className="mt-4">Sprigstack Solution</h2>
                  <p className="mt-4">
                    After carefully analyzing the client's requirements, we
                    assigned appropriate resources and developers and developed
                    a plan of action.
                  </p>
                  <p>
                    We developed a hybrid app using technologies like React
                    native (frontend) and Backend (Laravel), MYSQL, SendGrid,
                    AWS, Twilio, and Pusher. In bringing the custom app to life,
                    SprigStack exceeded the client's expectations and made
                    communication easy between family members. The app is
                    available on Android and iOS platforms.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="col-12  mt-5">
          <div className="row">
            <div className="col-12 col-md-12 py-md-4 text-center pt-md-0">
              <h2 className="clr203F59 lineHeader setlineheight recenthdpd mb-md-0">
                <label>Features</label>
              </h2>
              <img src="/images/hrImg.png" alt="" className="img-fluid " />
            </div>
            <div className="col-12">
              <SMBLPHONEHOVEREFFECT
                Title1="Home"
                Title2="CHAT"
                img1="smblphonescreen1"
                img2="smblphonescreen2"
                hoverImg1="smblphonescreenhover1"
                hoverImg2="smblphonescreenhover2"
                mbottom="40%"
                mTop="15%"
              />
              <SMBLPHONEHOVEREFFECT
                Title1="FAMILY CHAT"
                Title2="BUSINESS FEED"
                img1="smblphonescreen3"
                img2="smblphonescreen4"
                hoverImg1="smblphonescreenhover3"
                hoverImg2="smblphonescreenhover4"
                mbottom="40%"
                mTop="15%"
              />
              <SMBLPHONEHOVEREFFECT
                Title1="FAMILY SOUK"
                Title2="MENU"
                img1="smblphonescreen5"
                img2="smblphonescreen6"
                hoverImg1="smblphonescreenhover5"
                hoverImg2="smblphonescreenhover6"
                mbottom="15%"
                mTop="40%"
              />
            </div>
          </div>
        </div>
        <div className="col-12 ">
          {windowWidth >= 786 ? (
            <div className="row d-flex align-items-center">
              <div className="col-3 p-0">
                <h3 className="smblphonescreen1">IBADA</h3>
              </div>
              <div className="col-2 p-0">
                <img
                  className="img-fluid"
                  src="/images/smblphonescreen7.png"
                  alt=""
                />
              </div>
              <div className="col-7">
                <div className="row">
                  <div className="col-12 smblIbadaSection px-6 ">
                    <p>
                      Ibada is an all-in-one solution for prayer-related data
                      and resources for a better understanding of Islamic
                      teachings. Ibada offers unique features that have been
                      customized to meet the needs of our clients and enhance
                      the religious journey of family members.
                    </p>
                    <p>Key Features:</p>
                    <div className="py-2">
                      <label>Hadith of the Day :&nbsp;</label>
                      <span>
                        Each day brings a new insight to inspire and guide users
                        for prayers.
                      </span>
                    </div>
                    <div className="py-2">
                      <label>Prayer Time Table : &nbsp;</label>
                      <span>
                        Stay connected with your daily prayers by accessing the
                        complete prayer timetable.
                      </span>
                    </div>
                    <div className="py-2">
                      <label>Holy Quran Resources : &nbsp;</label>
                      <span>Access the Holy Quran directly through Ibada.</span>
                    </div>
                    <div className="py-2">
                      <label>Qibla Finder: &nbsp;</label>
                      <span>
                        No matter where you are, quickly locate the Qibla
                        direction using Qibla Finder.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row mt-5 smblIbadaSection">
                <div className="col-12 ">
                  <div className="row d-flex align-items-center px-6 justify-content-center">
                    <div className="col-5 p-0">
                      <h3 className="smblphonescreen1">IBADA</h3>
                    </div>
                    <div className="col-7 p-0 mb-5">
                      <img
                        className="img-fluid"
                        style={{ maxHeight: "300px" }}
                        src={`/images/smblphonescreen7.png`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12  px-6">
                  <p>
                    Ibada is an all-in-one solution for prayer-related data and
                    resources for a better understanding of Islamic teachings.
                    Ibada offers unique features that have been customized to
                    meet the needs of our clients and enhance the religious
                    journey of family members.
                  </p>
                  <p>Key Features:</p>
                  <div className="py-2">
                    <label>Hadith of the Day :&nbsp;</label>
                    <span>
                      Each day brings a new insight to inspire and guide users
                      for prayers.
                    </span>
                  </div>
                  <div className="py-2">
                    <label>Prayer Time Table : &nbsp;</label>
                    <span>
                      Stay connected with your daily prayers by accessing the
                      complete prayer timetable.
                    </span>
                  </div>
                  <div className="py-2">
                    <label>Holy Quran Resources : &nbsp;</label>
                    <span>Access the Holy Quran directly through Ibada.</span>
                  </div>
                  <div className="py-2">
                    <label>Qibla Finder: &nbsp;</label>
                    <span>
                      No matter where you are, quickly locate the Qibla
                      direction using Qibla Finder.
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="col-12 px-6">
          <div className="row">
            <div className="row pb50 pe-0">
              <div className="col-12 col-md-12 mt-md-5 text-center pt-4 pt-md-0 pe-0">
                <h2 className="clr203F59 default-Paraheader-card Card-ul-header setlineheight recenthdpd">
                  <label>What our client think about us!</label>
                </h2>
                <img
                  src="/images/hrImg.png"
                  alt="test"
                  className="img-fluid "
                ></img>
              </div>
              <div className="row pt-md-3 px10">
                <div className="col-12 text-center col-md-3">
                  <div className="row">
                    <div className="col-12 col-md-12 mb-3">
                      <img
                        className="ps-5 ps-md-0 pt-4"
                        src="/images/aliakramsmbl.png"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-1 ps-5 pe-0  px-md-2">
                      <span className="smblreview">Ali Akram</span>
                    </div>
                    <div className="col-12 col-md-12 ps-5 ps-5 pe-0  px-md-2">
                      <span className="smblreviewlabel">
                        IT Manager at SMBAL - Dubai
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 custom-blockquote3 spanPara col-md-9 ms-4 ms-md-0">
                  <span className="QuotePara ">
                    We wanted to create web-cum-mobile application for our
                    privileged client base. Our app was supposed to have limited
                    but powerful features. SprigStack team came up with a
                    perfect hybrid application idea which served all the
                    purposes. Our app looks beautiful in desktop as well as
                    modern in mobile. Moreover, users can also directly download
                    our app from their mobile browsers which is amazing. Thanks
                    team for a great work, our users love using our app.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 col-md-12 py-md-4 text-center pt-md-0">
              <h2 className="clr203F59 lineHeader setlineheight recenthdpd mb-md-0">
                <label>What our client think about us!</label>
              </h2>
              <img src="/images/hrImg.png" alt="" className="img-fluid " />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <div className="row text-center">
                    <div className="col-12">
                      <img src="./images/aliakramsmbl.png" alt="" />
                    </div>
                    <div className="col-12 smblreview ">
                      <p className="mt-2 mb-0">Ali Akram</p>
                      <label className="m-0 p-0">
                        IT Manager at SMBAL - Dubai
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-8"></div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}
