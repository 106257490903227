import { useState, useEffect } from "react";
import "../../Css/timerSlider.css";
import $ from "jquery";
export function TimeSlider(props) {
  var [technologyContent, setTechnologyContent] = useState(
    props.TechnologyContent
  );
  var [technologyImages, settechnologyImages] = useState(
    props.TechnologyImages
  );
  var CurrentTech = 0;
  useEffect(() => {
    if (!$("#SliderHeaderId_" + CurrentTech).hasClass("active")) {
      var NextTech = CurrentTech;
      $("#SliderHeaderId_" + CurrentTech).addClass("active");
      if (CurrentTech == 4) {
        NextTech = 0;
      } else {
        NextTech++;
      }
      $("#SliderHeaderId_" + NextTech).removeClass("active");
      $(".commonClassSliderHdn").addClass("d-none");
      $("#ImageDivId_" + CurrentTech).removeClass("d-none");
      var elem = document.getElementById("ProgressBarId_" + CurrentTech);
      elem.style.width = "0%";
      setTimeout(() => {
        elem.style.width = "100%";
      });
      $("#ProgressBarId_" + CurrentTech)
        .removeClass("bgNewGrey")
        .addClass("bg-warning");
      $("#ImageDivId_" + CurrentTech).addClass("show");
      $("#ProgressBarId_" + NextTech)
        .removeClass("bg-warning")
        .addClass("bgNewGrey");
    }
    setInterval(() => {
      move();
    }, 6000);
  });
  const ShowTechDiv = (index) => {
    $(".timeSliderHeader").removeClass("active");
    $("#SliderHeaderId_" + index).addClass("active");
    $(".commonClassSliderHdn").addClass("d-none");
    $("#ImageDivId_" + index).removeClass("d-none");
    $("#ImageDivId_" + index).addClass("show");
    $(".BarClass").removeClass("bg-warning").addClass("bgNewGrey");
    $("#ProgressBarId_" + index)
      .removeClass("bgNewGrey")
      .addClass("bg-warning");
    var elem = document.getElementById("ProgressBarId_" + index);
    setTimeout(() => {
      elem.style.width = "100%";
    });
    CurrentTech = index;
  };
  function move() {
    var NextTech = CurrentTech;
    if ($("#SliderHeaderId_" + CurrentTech).hasClass("active")) {
      $("#SliderHeaderId_" + CurrentTech).removeClass("active");
      if (CurrentTech == 4) {
        NextTech = 0;
      } else {
        NextTech++;
      }
      var elem = document.getElementById("ProgressBarId_" + NextTech);
      setTimeout(() => {
        elem.style.width = "100%";
      });
      $("#SliderHeaderId_" + NextTech).addClass("active");
      $(".commonClassSliderHdn").addClass("d-none");
      $("#ImageDivId_" + NextTech).removeClass("d-none");
      $("#ImageDivId_" + NextTech).addClass("show");
      var elemCurrent = document.getElementById("ProgressBarId_" + CurrentTech);
      elemCurrent.style.width = "0%";
      $("#ProgressBarId_" + CurrentTech)
        .removeClass("bg-warning")
        .addClass("bgNewGrey");
      $("#ProgressBarId_" + NextTech)
        .removeClass("bgNewGrey")
        .addClass("bg-warning");
      if (CurrentTech == 4) {
        CurrentTech = 0;
        $(".commonClassSliderHdn").removeClass("show");
        $("#ImageDivId_0").addClass("show");
        var Firstelem = document.getElementById("ProgressBarId_0");
        setTimeout(() => {
          Firstelem.style.width = "100%";
        });
      } else {
        CurrentTech++;
      }
    }
    return false;
  }
  return (
    <>
      <div className="row timeSliderHeaderContainer pt-4 ">
        {technologyContent.map(function (tech, i) {
          return (
            <div
              key={i}
              className={`customTS-Col mx-0 crsr-pointer  timeSliderHeader ${tech.active} `}
              id={`SliderHeaderId_${i}`}
            >
              <span className="techFont"> {tech.Technology}</span>
              <div
                id="myProgress"
                className="progress sliderProgressContainer bgNewGrey"
              >
                <div
                  className="bg-warning myBar BarClass"
                  id={`ProgressBarId_${i}`}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="container commonClassSliderHdn" id="ImageDivId_0">
        {technologyImages.map(function (tech, i) {
          return (
            <div
              key={i}
              className="deskDispFlex p-5 pb-0 justify-content-center roww"
            >
              {tech.Frontend.map(function (frontend, i) {
                return (
                  <div className="px-4 col6  text-center py-2" key={i}>
                    <div className="techClass">
                      <img
                        alt={frontend.alt}
                        title={frontend.title}
                        src={frontend.Img}
                        className="img-fluid"
                      />
                    </div>
                    <div className="techNameClass">{frontend.Technology}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="container commonClassSliderHdn" id="ImageDivId_1">
        {technologyImages.map(function (tech, i) {
          return (
            <div
              className="deskDispFlex p-5 pb-0 justify-content-center roww"
              key={i}
            >
              {tech.Backend.map(function (backend, i) {
                return (
                  <div className="px-4 col6 text-center py-2" key={i}>
                    <div className="techClass">
                      <img
                        alt={backend.alt}
                        title={backend.title}
                        src={backend.Img}
                        className="img-fluid"
                      />
                    </div>
                    <div className="techNameClass">{backend.Technology}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="container commonClassSliderHdn" id="ImageDivId_2">
        {technologyImages.map(function (tech, i) {
          return (
            <div
              className="deskDispFlex p-5 pb-0 justify-content-center roww"
              key={i}
            >
              {tech.CMS.map(function (cms, i) {
                return (
                  <div className="px-4 col6 text-center py-2" key={i}>
                    <div className="techClass">
                      <img
                        alt={cms.alt}
                        title={cms.title}
                        src={cms.Img}
                        className="img-fluid"
                      />
                    </div>
                    <div className="techNameClass">{cms.Technology}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="container commonClassSliderHdn" id="ImageDivId_3">
        {technologyImages.map(function (tech, i) {
          return (
            <div
              className="deskDispFlex p-5 pb-0 justify-content-center roww"
              key={i}
            >
              {tech.DataBases.map(function (db, i) {
                return (
                  <div className="px-4 col6 text-center py-2" key={i}>
                    <div className="techClass">
                      <img
                        alt={db.alt}
                        title={db.title}
                        src={db.Img}
                        className="img-fluid"
                      />
                    </div>
                    <div className="techNameClass">{db.Technology}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="container commonClassSliderHdn" id="ImageDivId_4">
        {technologyImages.map(function (tech, i) {
          return (
            <div
              className="deskDispFlex p-5 pb-0 justify-content-center roww"
              key={i}
            >
              {tech.CloudServices.map(function (cloud, i) {
                return (
                  <div className="px-4 col6 text-center py-2" key={i}>
                    <div className="techClass">
                      <img
                        alt={cloud.alt}
                        title={cloud.title}
                        src={cloud.Img}
                        className="img-fluid"
                      />
                    </div>
                    <div className="techNameClass">{cloud.Technology}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
}
