import React, { useState } from "react";

export default function CrmFeatures(props) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className={`${props.ContainerSizeClass}`}>
      <div
       className={isHovered ? `${props.crmDiv} hovered` : props.crmDiv}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={isHovered ? `${props.hoverImageUrl}` : props.imgUrl}
          className="img-fluid"
          alt="Image"
        />
        <h3 className={isHovered ? "text-hovered setfontsize fw-bold mt-1" : "clr0E2B3D setfontsize fw-bold mt-1"}>{props.text}</h3>
      </div>
    </div>
  );
}
