import React, { useState, createRef, useEffect } from "react";
import axios from "axios";
import LifeAtSprigStack from "../Components/LifeAtSprigStack";
import HelmetMetaTag from "../Components/HelmetMetaTag";
import LazyLoadingImg from "../Components/LazyLoadingImg";

export default function NewCareerPage() {
  const [careerData, setCareerData] = useState([]);
  const myRef1 = createRef();
  const [scrollDataId, setScrollDataId] = useState(100);

  useEffect(() => {
    axios
      .get("/jsonFiles/CareerJobsOpenings.json")
      .then((res) => {
        setCareerData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <HelmetMetaTag
        titleName="Build Your Career in Software Development - SprigStack"
        desc="Looking for a fulfilling career in tech? SprigStack has a wide range of opportunities available for passionate and talented individuals. Join our team today!"
        canonical="https://sprigstack.com/career"
        ogtitle="Build Your Career in Software Development - SprigStack"
        ogdesc="Looking for a fulfilling career in tech? SprigStack has a wide range of opportunities available for passionate and talented individuals. Join our team today!"
        ogtype="website"
        ogurl="https://sprigstack.com/career"
      />
      <div className="row py-lg-3  px-lg-6 px-sm-6 ">
        <div className="col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3 careerImg">
          <LazyLoadingImg
            src="./images/careerImg2.svg"
            className="careerImg2"
            alt="career infographic"
            title="career infographic"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-12 homePageGYB  p-lg-4  ">
          <span className=" row mt-lg-0 mt-4 devTags">
            <h1 className=" cfont1 px-o">
              A company that values innovation, creativity & the power of
              teamwork
            </h1>
          </span>
          <div className=" my-3 fontColorBlue careerVwBtn">
            <button
              className="btn cbtn1"
              onClick={() => {
                window.scroll(0, 1505);
              }}
            >
              View Current Opportunities
            </button>
          </div>
        </div>
      </div>
      <div className="px-0 container-fluid ">
        <div className="col-12 text-center grtPlcHeadingBox">
          <h2 className="clr0E2B3D  headerFontSizeH6 grtPlcHeading">
            Why SprigStack is a Great Place to Build Your Career?
          </h2>
          <img src="images/hrImg.png" alt="" className="img-fluid" />
        </div>
        <div className="px-lg-6 px-sm-6">
          <div className="">
            <div
              className="mt-3 C_font2 grtPlcTxt"
              style={{
                lineHeight: "28px",
                textAlign: "justify",
              }}
            >
              Join SprigStack, a leading Software development service provider
              based in India, and take your professional development to the next
              level! As a company, we strive to be one of the best workplaces to
              work, offering competitive pay and benefits to attract and retain
              talent. Our open, adaptable, and supportive environment is perfect
              for learning, growing, and achieving your career goals.
              <div className="">
                <div className="row">
                  <div className="col-md-4 col-12 py-4 padMob">
                    <div
                      className="BradCrumbContainer p-md-4 padMob"
                      style={{ boxShadow: "none" }}
                    >
                      <div className="row align-self-center">
                        <img
                          src="./images/ourcorevalue.svg"
                          alt="Core Values"
                          title="Our Core Values"
                          className="w-100"
                        />
                      </div>
                      <h2 className="row  my-2 pt-lg-4 pt-0 align-self-start careercardbox fontColorBlue align-self-start text-m-center ">
                        Our Core Values
                      </h2>
                      <img
                        src="images/hrImg.png"
                        alt=""
                        className="img-fluid"
                        style={{ paddingLeft: "0.8em" }}
                      />
                      <ul className="careerkeyPoints pt-3">
                        <li>
                          <h6> Trust</h6>
                        </li>
                        <li>
                          <h6>Team Spirit</h6>
                        </li>
                        <li>
                          <h6> Equality</h6>
                        </li>
                        <li>
                          <h6>Perseverance</h6>
                        </li>
                        <li>
                          <h6> Sportsmanship</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 py-4 padMob">
                    <div
                      className="BradCrumbContainer p-md-4 padMob"
                      style={{ boxShadow: "none" }}
                    >
                      <div className="row align-self-center">
                        <img
                          src="./images/benefits.svg"
                          alt="Benefits"
                          title="Benefits"
                          className="w-100"
                        />
                      </div>
                      <h2 className="row  my-2 pt-lg-4 pt-0 align-self-start careercardbox fontColorBlue align-self-start text-m-center ">
                        Benefits
                      </h2>
                      <img
                        src="images/hrImg.png"
                        alt=""
                        className="img-fluid"
                        style={{ paddingLeft: "0.8em" }}
                      />
                      <ul className="careerkeyPoints pt-3">
                        <li>
                          <h6> Professional Development</h6>
                        </li>
                        <li>
                          <h6>Opportunities to Expand </h6>
                        </li>
                        <li>
                          <h6>Plenty of Learning Opportunities</h6>
                        </li>
                        <li>
                          <h6>Flexible Work Time</h6>
                        </li>
                        <li>
                          <h6> Competitive Pay Structure</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 py-4 padMob">
                    <div
                      className="BradCrumbContainer  p-md-4 padMob"
                      style={{ boxShadow: "none" }}
                    >
                      <div className="row align-self-center">
                        <img
                          src="./images/unbetablePerks.svg"
                          alt="Unbeatable Perks"
                          title="Unbeatable Perks"
                          className="w-100"
                        />
                      </div>
                      <h2 className="row  my-2 pt-lg-4 pt-0 align-self-start careercardbox fontColorBlue align-self-start text-m-center ">
                        Unbeatable Perks
                      </h2>
                      <img
                        src="images/hrImg.png"
                        alt=""
                        className="img-fluid"
                        style={{ paddingLeft: "0.8em" }}
                      />
                      <ul className="careerkeyPoints pt-3">
                        <li>
                          <h6>5 Working Days</h6>
                        </li>
                        <li>
                          <h6>Work from Anywhere</h6>
                        </li>
                        <li>
                          <h6>Performance Bonus</h6>
                        </li>
                        <li>
                          <h6>Monthly Outings & Annual Trips</h6>
                        </li>
                        <li>
                          <h6>Maternity and Paternity Leave</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-0 container-fluid ">
        <div className="col-12 text-center">
          <h2 className="clr0E2B3D  headerFontSizeH6">Current Openings</h2>
          <img src="images/hrImg.png" alt="" className="img-fluid" />
        </div>
        <div className="px-lg-6 px-sm-6">
          <div className="">
            <div
              className="my-4 C_font2 grtPlcTxt"
              style={{
                lineHeight: "28px",
                textAlign: "justify",
              }}
            >
              We are a growing company that have constant requirements of adding
              new talents to the team frequently. We have several technical and
              non-technical openings at our current office location as mentioned
              below:
            </div>
          </div>
          <div className="my-3">
            <div className="row">
              <div className="col-lg-3 col-md-3 imgdnone">
                <ul className="removeLi carreerLifont">
                  <li className="my-4">
                    <span
                      style={{
                        color: scrollDataId == 100 ? "#EE720B" : "#181815",
                      }}
                      onClick={() => setScrollDataId(100)}
                    >
                      All
                    </span>
                  </li>
                  {careerData.map((data, i) => {
                    return (
                      <>
                        <li className="my-4">
                          <span
                            style={{
                              color:
                                scrollDataId == data.id ? "#EE720B" : "#181815",
                            }}
                            onClick={() => setScrollDataId(data.id)}
                          >
                            {data.positionName}
                          </span>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
              <div className="col-lg-9 col-md-9">
                {careerData.map((data, i) => {
                  return (
                    <>
                      {scrollDataId == 100 || scrollDataId == data.id ? (
                        <>
                          {data.data.map((datas, i) => {
                            return (
                              <div
                                className="bgF7F7F7 p-3 mb-md-4 mb-4"
                                style={{ borderRadius: "10px" }}
                                id={datas.positionId}
                                key={i}
                              >
                                <div className="row my-3">
                                  <div className="col-lg-7 col-md-7 col-12 my-lg-0 my-2 CurrentOpeningstitle fontColorBlue positionFont">
                                    {datas.positionName}
                                  </div>
                                  <div className="col-lg-5 col-md-5 col-12 my-lg-0 my-2 text-lg-end processWeFollowHeaderFont fontColorBlue">
                                    {`${datas.noOfPositions} - POSITION`}
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-lg-12 ServicePara dataText">
                                    {datas.desc}
                                  </div>
                                </div>
                                <div className="row text-lg-end text-center my-2">
                                  <div className="col-lg-12 ">
                                    <button
                                      className="btn cbtn2"
                                      onClick={() => {
                                        if (datas.btnLink != "")
                                          window.location.href = datas.btnLink;
                                      }}
                                    >
                                      View Job Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
                {careerData[scrollDataId]?.data.length == 0 && (
                  <>
                    <div
                      className="bgF7F7F7 p-3 mb-4"
                      style={{ borderRadius: "10px" }}
                    >
                      <div className="py-5 px-2 text-center CurrentOpeningstitle fontColorBlue">
                        We keep hiring frequently in different domains, do come
                        back in <br /> couple of days and you might find
                        appropriate job for you!
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LifeAtSprigStack />
      <div className="pt-md-5"></div>
    </>
  );
}
