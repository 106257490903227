import LazyLoadingImg from "./LazyLoadingImg";

export function BreadCrumbCard(props) {
  var isBredCrumbCard = "BradCrumbContainer";
  if (props.IsCard == "false") {
    isBredCrumbCard = "";
  } else if (props.IsCard == "processWeFollowContainer") {
    isBredCrumbCard = "processWeFollowContainer";
  }
  return (
    <>
      <div className={` ${props.ColSize}`}>
        <div
          className={`${props.borClass}${isBredCrumbCard} ${
            props.IsSetClass ? "p-lg-4" : "p-4"
          } ${props.CustomClass}`}
        >
          {props.Image && (
            <div
              className={`row align-self-center ${props.ImageContainerClass}`}
            >
              <LazyLoadingImg src={props.Image} className={props.ImageClass} />
            </div>
          )}

          {props.Heading4 !== true ? (
            <h3
              className={`row  my-2 pt-lg-4 pt-3 align-self-center ${props.CustomHeaderClass} `}
            >
              {props.Header}
            </h3>
          ) : (
            <h4
              className={`row  my-2 pt-lg-4 pt-0 align-self-center justify-content-center ${props.CustomHeaderClass} `}
            >
              {props.Header}
            </h4>
          )}
          <div className={`row  mt-2  ${props.CustomBodyClass} `}>
            {props.Body}
            {props.IsBulletsVisible && (
              <>
                <ul style={{ paddingLeft: "20px", marginTop: "20px" }}>
                  {props.UlData.split("&UlData&").map((data) => {
                    return <li>{data}</li>;
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
