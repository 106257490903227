import React, { useEffect, useState } from "react";

export default function SMBLPHONEHOVEREFFECT(props) {
  const [windowWidth, setWindowWidth] = useState("");
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });
  const [Img1, setImg1] = useState({ src: props.img1, Active: "" });
  const [Img2, setImg2] = useState({ src: props.img2, Active: "" });
  return (
    <>
      {windowWidth >= 768 ? (
        <>
          <div className="row d-flex align-items-center my-5">
            <div className="col-3 p-0">
              <h3 className="smblphonescreen1">{props.Title1}</h3>
            </div>
            <div className="col-2 p-0">
              <img
                className="img-fluid"
                src={`/images/${Img1.src}.png`}
                onMouseEnter={() => {
                  setImg1({ ...Img1, src: props.hoverImg1, Active: "active" });
                }}
                onMouseLeave={() => {
                  setImg1({ ...Img1, src: props.img1, Active: "" });
                }}
                alt=""
              />
            </div>
            <div className="col-2"></div>
            <div className="col-2 p-0">
              <img
                className="img-fluid"
                src={`/images/${Img2.src}.png`}
                onMouseEnter={() => {
                  setImg2({ ...Img2, src: props.hoverImg2, Active: "active" });
                }}
                onMouseLeave={() => {
                  setImg2({ ...Img2, src: props.img2, Active: "" });
                }}
                alt=""
              />
            </div>
            <div className="col-3 p-0">
              <h3 className="smblphonescreen1">{props.Title2}</h3>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row px-6 d-flex align-items-center">
            <div className="col-8 p-0">
              <h3 className="smblphonescreen1">{props.Title1}</h3>
            </div>
            <div className="col-4 p-0">
              <img
                className="img-fluid"
                src={`/images/${Img1.src}.png`}
                onMouseEnter={() => {
                  setImg1({ ...Img1, src: props.hoverImg1, Active: "active" });
                }}
                onMouseLeave={() => {
                  setImg1({ ...Img1, src: props.img1, Active: "" });
                }}
                alt=""
              />
            </div>
            <div className="col-4 p-0">
              <img
                className="img-fluid"
                src={`/images/${Img2.src}.png`}
                onMouseEnter={() => {
                  setImg2({ ...Img2, src: props.hoverImg2, Active: "active" });
                }}
                onMouseLeave={() => {
                  setImg2({ ...Img2, src: props.img2, Active: "" });
                }}
                alt=""
              />
            </div>
            <div className="col-8 p-0">
              <h3 className="smblphonescreen1">{props.Title2}</h3>
            </div>
          </div>
        </>
      )}
    </>
  );
}
