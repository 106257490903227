import React from "react";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import { DescriptiveCard } from "./Components/DescriptiveCard";
import { DevelopmentRecentWork } from "./Components/DevelopmentWork";
import { DevelopmentCommonFunc } from "./Components/DevelopmentCommon";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
import ProcessDynamicComp from "./DynamicComponent/ProcessDynamicComp";
import WhyChooseUsComp from "./Components/WhyChooseUsComp";

export function AppDevelopment() {
  //#region Web Development Services content
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/AppleIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="iPhone/iOS Apps"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="iOS is considered the market leader and trendsetter. A native iOS app for your business gives you a significant advantage. Displaying the iOS app to the users also provides a statement about the company's establishment and fosters confidence in the minds of the ultimate consumer."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/AndroidIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Android Apps"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Android applications for business cover a large portion of the total user base. Many consumers have Android phones with plenty of features, so your application may reach more people. It is one of the most effective ways to attract significant business."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/ReactNativeIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="React Native Apps"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="React is a framework trusted by Facebook. React Native is one of the most modern and ongoing technologies for cross-platform applications. It gives the ability to build applications for both iOS and Android platforms at the same time."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/FlutterIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Flutter Apps"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Flutter is a unique and efficient solution for creating cross-platform apps for your business. One technology handles both iOS and Android operating system apps concurrently. Flutter is trusted by google for exceptional performance."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/XemerinIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Xamarin in Apps"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="When it comes to cross-platform technologies, Microsoft is not far behind. Microsoft's Xamarin platform allows programmers to create a single application for iOS and Android devices. It is a perfectly useful and established cross-platform technique for developing mobile apps."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize "
      Image="./images/MobileHybrid.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Hybrid Mobile Apps"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Web applications can be easily modified into hybrid mobile applications. It is a widely used approach as this enables a user to access the app from any platform. Hybrid app creation is a money saver than developing individual mobile apps for each platform."
    />
  );
  //#endregion
  //#region Web App Benefit content
  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/webAppIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Better and Easy <br/>  Access for Users"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/crossPlatformIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Strong Identity"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/manageIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Offline Content <br/>  Offering"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/deployIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Boosted User <br/>  Friendliness"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/secureIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Inclusive of Complex <br/>  Features"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/redcuedCostIcon.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Value for Money"
      CustomClass="MajorBenefitsContainer"
    />
  );
  //#endregion
  var recentWorkContent = (
    <>
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader subRecentHeadingMob"
        DescriptionHeader="TreatMe - Doctor at your Fingertip"
        Image="./images/appDevImage1.png"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-4 m-0  mt-4 mb-1 recentWorkbox1 RecentContainer"
        Description="Technologies Used : React Native, Asp.net Core, Sql Server"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto"
        RedirectUrl=""
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-12 recentBox"
      />
      <DevelopmentRecentWork
        ImagePosition="right"
        CustomHeaderClass="recentWorkHeader subRecentHeadingMob"
        DescriptionHeader="Walletier - Payments Made Easy"
        Image="./images/WalletierImg.png"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 RecentContainer"
        Description="Technologies Used : React Native, Laravel, Payment Gateway APIs"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-lg-5 px-md-5"
        RedirectUrl=""
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-12"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader subRecentHeadingMob"
        DescriptionHeader="Euroriente - One Stop Travel Guide"
        Image="./images/EurorienteImg.png"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 RecentContainer"
        Description="Technologies Used : Flutter, Stripe APIs, Laravel, MySql"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto"
        RedirectUrl=""
        DevelopmentRecentWorkBtn={true}
        redirectNumber="/portfolio/development-of-an-erp-system-for-the-travel-industry"
        CardMargin="col-12"
      />
    </>
  );
  //#endregion
  //#region Why choose us content
  var whChooseUseLeftBox = [];
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Seamless Experience"
      Image="./images/appdevwhychooseusIcon1.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`The process that we follow is streamlined and transparent without any extra intervention. This will save time and ensure effectiveness from development to maintenance.`}
      CardMargin="pt-1 my-lg-0 mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Hassle-Free Version Control"
      Image="./images/appdevwhychooseusIcon2.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`For a smooth interface, updating versions and fixing bugs is essential. We verify that the version upgrade does not interfere with the user experience when using an app.`}
      CardMargin="pt-1 my-lg-0 my-4  mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Affordable Solutions"
      Image="./images/flexEngagementIcon.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`Our hybrid app development model fulfills clients' web and mobile-based application requirements. This model will ultimately save time and cost. `}
      CardMargin="pt-1 my-lg-0 my-4  mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  var whyChooseUsContent = [];
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/app-devChooseUsIcon1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue"
      Header="Seamless Experience"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={`The process that we follow is streamlined and transparent without any extra intervention. This will save time and ensure effectiveness from development to maintenance.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/app-devChooseUsIcon2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue "
      Header="Hassle-Free Version Control"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`For a smooth interface, updating versions and fixing bugs is essential. We verify that the version upgrade does not interfere with the user experience when using an app.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-5 pt-4"
      Image="./images/app-devChooseUsIcon3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue "
      Header="Affordable Solutions"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`Our hybrid app development model fulfills clients' web and mobile-based application requirements. This model will ultimately save time and cost. `}
    />
  );
  //#endregion
  var techonologiesContent = [
    {
      Technology: "IOS",
      Img: "/images/Technology/mobile1.png",
      alt: "IOS",
      title: "IOS",
    },
    {
      Technology: "Android",
      Img: "/images/Technology/mobile2.png",
      alt: "Android",
      title: "Android",
    },
    {
      Technology: "Swift",
      Img: "/images/Technology/mobile3.png",
      alt: "Swift",
      title: "Swift",
    },
    {
      Technology: "Flutter",
      Img: "/images/Technology/mobile4.png",
      alt: "Flutter ",
      title: "Flutter ",
    },
    {
      Technology: "React Native",
      Img: "/images/Technology/mobile5.png",
      alt: "React Native",
      title: "React Native",
    },
    {
      Technology: "JavaScript",
      Img: "/images/Technology/mobile6.png",
      alt: "Java Script",
      title: "Java Script",
    },
    {
      Technology: "Kotlin",
      Img: "/images/Technology/mobile7.png",
      alt: "Kotlin",
      title: "Kotlin",
    },
  ];
  var HeroSection = {
    ImagePosition: "left",
    DescriptionHeader: "Mobile Application Development Services",
    CustomHeaderClass: "C_font1",
    CustomBodyClass: "C_font2",
    Image: "./images/appDevMain.svg",
    alt: "Mobile App Development",
    title: "Mobile App Development Services",
    CustomImageClass: "",
    CardMargin: " px-lg-6 px-sm-6",
    CustomDescriptionBoxClass: "p-lg-4 px-4",
    Description:
      "We provide custom mobile app development services for iOS and Android platforms. You can rely on our developers for custom mobile applications that meet your needs and deliver an exceptional user experience. We use the latest tools and techniques for the fastest turnaround time. As an experienced mobile app development company, we offer hassle-free, tailored service to help your business unlock the potential of mobile technology.",
    customDescriptionItemFirst:
      "col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3",
    customDescriptionItemSecond: "col-lg-6 col-md-6 col-12 homePageGYB",
    bodyClassCareerRequirement: "d-none",
  };
  var Overview = {
    Header: "Overview",
    Content:
      "Mobile phone applications are the best way to showcase work because they are convenient and easy to use, as it is always a preferable platform to fulfill business needs and manage them. We are entirely committed to providing custom mobile application development services that are quick to build and simple to utilize. Our technological usability enables us in rapid mobile development, and most importantly, our team offers apps for iOS and Android.",
    ContentCustomClass: "pt-3 m-0",
    HeaderPadding: "overviewHeading",
    flag: true,
  };
  var OurKey = {
    BgColor: "#F7F7F7",
    Header: "Our Key App Development <br/> Services Include",
    ContainerBottomPadding: "pb-md-3 pt-5",
    HeaderPadding: "ourKeyHeadingApp",
    flag: true,
    Content: [serviceConent],
  };
  var PersonalizedCompFirst = {
    cardTitle:
      "Looking for a perfect team <br/> to develop your mobile<br/> apps?",
    TitleCss1: "personalizedtitle1App",
    ContainerColor: "FFFPersonalize",
    flag: "1",
  };
  var MajorBenefits = {
    BgColor: "",
    Header: `Major Benefits of Mobile Applications`,
    HeaderPadding: "majorBenefitHeading",
    ContentCustomClass: "",
    flag: true,
    Content: [webAppBenefitContent],
  };
  var UiUxProcess = {
    BgColor: "#F7F7F7",
    Header: `Mobile App Development Process that We Follow`,
    HeaderPadding: "webProcessHeadingApp",
    ContainerBottomPadding: "pb-2",
    ContentCustomClass: "justifyCenter weFollowContainer pt-4 pb-5",
    Content: <ProcessDynamicComp />,
  };
  var PersonalizedCompSecond = {
    cardTitle:
      "Share your idea and start application development straight away!",
    TitleCss: "titleHeadingCss",
    ContainerColor: "cA49F9D",
    flag: "2",
  };
  var RecentWork = {
    BgColor: "",
    Header: `Recent Mobile Apps Made by us`,
    HeaderPadding: "RecentWorkHeading",
    ContentCustomClass: "",
    flag: true,
    Content: [recentWorkContent],
  };
  var WhyChooseUs = {
    BgColor: "#F7F7F7",
    Header: `Why Choose us for Mobile <br /> Application Development?`,
    HeaderPadding: "whyChooseUsHeadingApp",
    ContentCustomClass: "whyChooseUsBox",
    Content: [whyChooseUsContent],
  };
  var Technology = {
    Tech: [techonologiesContent],
    MainHeader: "Technologies Used for Mobile Apps",
    HeaderCss: "technologiesHeading pt-4",
    SubHeaderCss: "technologySubHeader",
    SubHeader:
      "We have all the trendy technological stacks under our belt to <br /> develop and design great mobile apps.",
  };
  return (
    <>
      <HelmetMetaTag
        titleName="Custom Mobile App Development Services | SprigStack"
        desc="Get a mobile app that sets your business apart with our development services. We offer iOS/Android, React Native, Flutter, and Hybrid apps. Contact us now!"
        canonical="https://sprigstack.com/mobile-app-development"
        ogtitle="Custom Mobile App Development Services | SprigStack"
        ogdesc="Get a mobile app that sets your business apart with our development services. We offer iOS/Android, React Native, Flutter, and Hybrid apps. Contact us now!"
        ogtype="website"
        ogurl="https://sprigstack.com/mobile-app-development"
      />
      <DevelopmentCommonFunc
        // Title="App Development"
        HeroSection={HeroSection}
        Overviewsection={Overview}
        OurKeySection={OurKey}
        Space={true}
        PersonalizedCompOne={PersonalizedCompFirst}
        MajorBenefitsSection={MajorBenefits}
        UiUxProcessSection={UiUxProcess}
        PersonalizedCompTwo={PersonalizedCompSecond}
        RecentWorkSection={RecentWork}
        IsNewSection="yes"
        TechnologySection={Technology}
        WhyChooseUsSection={WhyChooseUs}
        FAQSection={<FAQDynamicComp />}
      />
    </>
  );
}
