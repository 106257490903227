import React, { useEffect, useState } from "react";
import "./MovingImages.css";
import Slider from "react-slick";
import $ from "jquery";

const images = [
  "/images/A2mobile1.png",
  "/images/A2mobile2.png",
  "/images/A2mobile3.png",
  "/images/A2mobile4.png",
  "/images/A2mobile5.png",
  "/images/A2mobile1.png",
  "/images/A2mobile2.png",
  "/images/A2mobile3.png",
  "/images/A2mobile4.png",
  "/images/A2mobile5.png",
];
const ImageCarousel = () => {
  const [imageHeight, setimageHeight] = useState("");
  useEffect(() => {
    const interval = setTimeout(() => {
      setimageHeight($(".img1").height());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Change images every 2 seconds (adjust the timing as needed).
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="image-carousel-container py-md-5 my-md-5">
        <Slider {...settings}>
          {images.map((imageUrl, index) => (
            <div key={index} className="carousel-image">
              <img
                className={`px-3 img${index}`}
                src={imageUrl}
                alt={`Image ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="col-12 mobileframe">
        <img
          style={{ height: imageHeight }}
          src="/images/A2mobileframe.svg"
          alt=""
        />
      </div>
    </>
  );
};

export default ImageCarousel;
