import React, { useEffect, useState } from "react";
import "./NewClientComp.css"; // Import your CSS file
import { HeaderWithDynamicContent } from "../Components/HeaderWithDynamicContent";

const NewClientComp = ({ dataSets }) => {
  const [currentDataSet, setCurrentDataSet] = useState(0);

  const handleNext = () => {
    setCurrentDataSet((prevDataSet) => (prevDataSet + 1) % dataSets.length);
  };

  const handlePrev = () => {
    setCurrentDataSet(
      (prevDataSet) => (prevDataSet - 1 + dataSets.length) % dataSets.length
    );
  };
  const [windowWidth, setWindowWidth] = useState("");
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });

  return (
    <div className="new-client-comp-container">
      <HeaderWithDynamicContent
        HrLine="true"
        BgColor=""
        Header="What Our Clients Think About Us!"
        ContainerBottomPadding="pb-md-3 pt-0"
        HeaderPadding=""
        flag={true}
      />
      <div className="new-client-comp-row">
        {dataSets.map((data, index) => (
          <div
            key={data.key}
            className={`new-client-comp-item col-12 ${
              currentDataSet === index ? "active" : ""
            }`}
          >
            <div className="row px-6 py-5">
              {data.videoUrl ? (
                <>
                  <div className="col-md-6 col-12 new-client-comp-video">
                    <iframe
                      className="IndianaVideo"
                      width="100%"
                      height="100%"
                      src={data.videoUrl}
                      title="Market Wagon and SprigStack"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    {/* <video className="IndianaVideo" controls src={data.videoUrl} /> */}
                  </div>
                  <div className="col-md-6 mt-4 mt-md-0 col-12 new-client-comp-text d-flex justify-content-center align-items-center">
                    <div className="row ">
                      <div className="col-12 IndianaContent text-center text-md-start">
                        <p className="text-center text-md-start">
                          "{data.textContent}"
                        </p>
                        <b className="text-center text-md-start">{`${data.Name}`}</b>
                        ( {data.Role} )
                      </div>
                      <div className="col-12 mt-md-5 mt-3 d-flex justify-content-end">
                        <button className="btnIndiana" onClick={handlePrev}>
                          {windowWidth <= 991 ? (
                            <img
                              src="/images/slick-prevImg.svg"
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <div
                              style={{ color: index === 0 ? "" : "#203f59" }}
                            >
                              Previous
                            </div>
                          )}
                        </button>
                        <button className="btnIndiana" onClick={handleNext}>
                          {windowWidth <= 991 ? (
                            <img
                              src="/images/slick-nextImg.svg"
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <div
                              style={{
                                color: index === data.length ? "" : "#203f59",
                              }}
                            >
                              Next
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-12 col-12 new-client-comp-video text-center">
                    <img src={data.img} />
                    {/* <video className="IndianaVideo" controls src={data.videoUrl} /> */}
                  </div>
                  <div className="col-md-12 col-12  new-client-comp-text d-flex justify-content-center align-items-center">
                    <div className="row text-center">
                      <div className="col-12 IndianaContent text-center">
                        <p>"{data.textContent}"</p>
                        <b>{`${data.Name}`}</b>( {data.Role} )
                      </div>
                      <div className="col-12 mt-md-0 mt-0 d-flex justify-content-end">
                        <button className="btnIndiana" onClick={handlePrev}>
                          {windowWidth <= 991 ? (
                            <img
                              src="/images/slick-prevImg.svg"
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <div
                              style={{ color: index === 0 ? "" : "#203f59" }}
                            >
                              Previous
                            </div>
                          )}
                        </button>
                        <button className="btnIndiana" onClick={handleNext}>
                          {windowWidth <= 991 ? (
                            <img
                              src="/images/slick-nextImg.svg"
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <div
                              style={{
                                color: index === data.length ? "#203f59" : "",
                              }}
                            >
                              Next
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="new-client-comp-controls"></div>
    </div>
  );
};

export default NewClientComp;
