import { useLocation, useNavigate } from "react-router-dom";
import ButtonHoverEffect from "./ButtonHoverEffect";
import HeroSectionProjects from "./HeroSectionProjects";

export function ContentAndImage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  var imagePositionRight = "";
  if (props.ImagePosition == "right") {
    imagePositionRight = "image-pos-right";
  }
  var headerClass = "ServiceHeader";
  if (
    props.CustomHeaderClass != undefined &&
    props.CustomHeaderClass != null &&
    props.CustomHeaderClass != ""
  ) {
    headerClass = props.CustomHeaderClass;
  }
  var bodyClass = "ServicePara";
  if (
    props.CustomBodyClass != undefined &&
    props.CustomBodyClass != null &&
    props.CustomBodyClass != ""
  ) {
    bodyClass = props.CustomBodyClass;
  }
  return (
    <>
      <div className={`row py-lg-3 ${props.CardMargin} ${imagePositionRight}`}>
        <div className={props.customDescriptionItemFirst}>
          <img
            alt={props.alt}
            title={props.title}
            src={props.Image}
            className={`mobile-width ${props.CustomImageClass}`}
            height={400}
          />
        </div>

        <div
          className={`${props.customDescriptionItemSecond}  ${props.CustomDescriptionBoxClass} `}
        >
          {props.h1Tag ? (
            <>
              <span className=" row mt-lg-0 mt-4 devTags">
                <h1 className="px-0 ">{props.DescriptionHeader}</h1>
              </span>
            </>
          ) : (
            <>
              <div
                className={`row mt-lg-0 mt-4  fontColorBlue ${headerClass} `}
              >
                {props.DescriptionHeader && (
                  <>
                    <h3 className={`ps-0 ${headerClass}`}>
                      {props.DescriptionHeader}
                    </h3>
                  </>
                )}
              </div>
            </>
          )}

          <div
            className={`row ${
              !props.DescriptionBottomLineAndButton && "d-none"
            }`}
          >
            <div className={`col-lg-3 col-md-3 ps-0 ${props.hrImgClass}`}>
              <img src="/images/hrImg.png" alt="" className="img-fluid"></img>
            </div>
          </div>
          <div
            className={`row my-3  ${bodyClass} `}
            style={{ textAlign: "justify" }}
          >
            {props.Description}
          </div>
          <div
            className={`row mb-4  ${props.bodyClassCareerRequirement} `}
            dangerouslySetInnerHTML={{ __html: props.careerRequirement }}
          ></div>
          <div
            className={`row ${
              !props.DescriptionBottomLineAndButton &&
              !props.IsShowButtonShow &&
              "d-none"
            } mb-lg-0 mb-4`}
          >
            {props.IsShowButton == "yes" ? (
              <ButtonHoverEffect
                RedirectUrl={props.RedirectUrl}
                IsRedirectToSamePage={props.IsRedirectToSamePage}
              />
            ) : props.IsShowButton == "webDevButton" ? (
              <>
                <div className="col-md-8 col-12 px-0">
                  <button
                    className="web-dev-Btn px-4"
                    onClick={() => {
                      props.setShow(true);
                    }}
                  >
                    Let's get started on your web app today!
                  </button>
                </div>
              </>
            ) : props.IsShowButton == "HeroSection" ? (
              <>
                <div className="col-md-8 col-12 px-0">
                  <button
                    className="web-dev-Btn px-4"
                    onClick={() => {
                      navigate("/contact-us", {
                        state: { pathname: location?.pathname },
                      });

                    }}
                  >
                    Let's get started!
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {props.HeroSection && (
        <>
          <HeroSectionProjects />
        </>
      )}
    </>
  );
}
