import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeroSectionCard from "./Components/HeroSectionCard";
import Popups from "./Modals/Popups";
import PersonalizedActionModal from "./Modals/PersonalizedActionModal";
import { TextandHoverComponenet } from "./Components/TextandHoverComponenet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import { HeaderWithDynamicContent } from "./Components/HeaderWithDynamicContent";
export default function (props) {
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState("");
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });

  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="ERP System Development for Travel Industry - SprigStack"
        desc="Revolutionize your travel operations with SprigStack's ERP system. Efficiently manage bookings, hotels, transfers, activities to drive success in the industry."
        canonical="https://sprigstack.com/portfolio/development-of-an-erp-system-for-the-travel-industry"
        ogtitle="ERP System Development for Travel Industry - SprigStack"
        ogdesc="Revolutionize your travel operations with SprigStack's ERP system. Efficiently manage bookings, hotels, transfers, activities to drive success in the industry."
        ogtype="website"
        ogurl="https://sprigstack.com/portfolio/development-of-an-erp-system-for-the-travel-industry"
        ogimg="https://sprigstack.com/images/EurorientePortfolioImg.webp"
      />
      <div>
        <div className="row Port_Bg  pt-md-5 pb-md-5">
          {windowWidth >= 768 ? (
            <div
              className={`col-12 col-md-6  col-lg-6 mx-4 mx-md-0 ${
                windowWidth >= 768 ? " pl-5" : ""
              }`}
            >
              <div className="col-12  col-md-12">
                <h1 className=" port-hero-text pt-5 pt-md-0">
                  {" "}
                  Development of an
                  <label className="port-hero-text1 px-1"> ERP System </label>
                  for <br />
                  the&nbsp;
                  <label className="port-hero-text1"> Travel Industry </label>
                </h1>
              </div>

              <div className="col-12 col-md-12 py-1">
                <span className="port-hero-text2">Business Name : </span>
                <span className="port-hero-text3"> Euroriente</span>
              </div>
              <div className="col-12 col-md-12 py-1">
                <span className="port-hero-text2">Industry : </span>
                <span className="port-hero-text3"> Travel</span>
              </div>
              <div className="col-12 col-md-12 py-1">
                <span className="port-hero-text2">Region : </span>
                <span className="port-hero-text3"> Turkey, Istanbul</span>
              </div>
            </div>
          ) : (
            <div
              className={`col-12 col-md-6  col-lg-6 mx-4 mx-md-0 ${
                windowWidth >= 768 ? " pl-5" : ""
              }`}
            >
              <div className="col-12  col-md-12">
                <h1 className=" port-hero-text pt-4 pt-md-0">
                  Development of an
                  <label className="port-hero-text1 px-1"> ERP System </label>
                  <br />
                  for the&nbsp;
                  <label className="port-hero-text1"> Travel Industry </label>
                </h1>
              </div>

              <div className="col-12 col-md-12 py-1">
                <span className="port-hero-text2">Business Name : </span>
                <span className="port-hero-text3"> Euroriente</span>
              </div>
              <div className="col-12 col-md-12 py-1">
                <span className="port-hero-text2">Industry : </span>
                <span className="port-hero-text3"> Travel</span>
              </div>
              <div className="col-12 col-md-12 py-1">
                <span className="port-hero-text2">Region : </span>
                <span className="port-hero-text3"> Turkey, Istanbul</span>
              </div>
            </div>
          )}
          {windowWidth >= 768 ? (
            <div className="col-12 col-md-6 col-lg-6">
              <img
                className="position-absolute"
                src="/images/Dotted-bg.svg"
                alt=""
              />
              <logo-slider>
                <div className="hero-port-logo ">
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Tour-booking.png"
                      alt="Tour Booking Icon"
                      title="Tour Booking "
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Transfer.png"
                      alt="Transfer Icon"
                      title="Transfer"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Activities.png"
                      alt="Activities Icon"
                      title="Activities"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Hotel-booking.png"
                      alt="Hotel Booking Icon"
                      title="Hotel Booking"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Vallet-system.png"
                      alt="Valet System  Icon"
                      title="Valet System"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Revenue-reports.png"
                      alt="Revenue Reports Icon"
                      title="Revenue Reports "
                    />
                  </div>
                </div>
                <div className="hero-port-logo">
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Tour-booking.png"
                      alt="Tour Booking Icon"
                      title="Tour Booking "
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Transfer.png"
                      alt="Transfer Icon"
                      title="Transfer"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Activities.png"
                      alt="Activities Icon"
                      title="Activities"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Hotel-booking.png"
                      alt="Hotel Booking Icon"
                      title="Hotel Booking"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Vallet-system.png"
                      alt="Valet System  Icon"
                      title="Valet System"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      effect="blur"
                      src="/images/Revenue-reports.png"
                      alt="Revenue Reports Icon"
                      title="Revenue Reports "
                    />
                  </div>
                </div>
              </logo-slider>
            </div>
          ) : (
            <div className="col-12 col-md-6 col-lg-6 pb-4">
              <logo-slider>
                <div className="hero-port-logo ">
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Tour-booking.png"
                      alt="Tour Booking Icon"
                      title="Tour Booking "
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Transfer.png"
                      alt="Transfer Icon"
                      title="Transfer"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Activities.png"
                      alt="Activities Icon"
                      title="Activities"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Hotel-booking.png"
                      alt="Hotel Booking Icon"
                      title="Hotel Booking"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Vallet-system.png"
                      alt="Valet System  Icon"
                      title="Valet System"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Revenue-reports.png"
                      alt="Revenue Reports Icon"
                      title="Revenue Reports "
                    />
                  </div>
                </div>
                <div className="hero-port-logo">
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Tour-booking.png"
                      alt="Tour Booking Icon"
                      title="Tour Booking "
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Transfer.png"
                      alt="Transfer Icon"
                      title="Transfer"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Activities.png"
                      alt="Activities Icon"
                      title="Activities"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Hotel-booking.png"
                      alt="Hotel Booking Icon"
                      title="Hotel Booking"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Vallet-system.png"
                      alt="Valet System  Icon"
                      title="Valet System"
                    />
                  </div>
                  <div className="d-flex align-items-center port-img-text-anc">
                    <LazyLoadImage
                      style={{ height: "200px" }}
                      effect="blur"
                      src="/images/Revenue-reports.png"
                      alt="Revenue Reports Icon"
                      title="Revenue Reports "
                    />
                  </div>
                </div>
              </logo-slider>
            </div>
          )}
        </div>
        <div className="pt-4 pt-lg-4">
          <div className="col-12 col-md-12 py-md-4 text-center pt-md-0">
            <h2 className="clr0E2B3D headerHeading setlineheight recenthdpd mb-0">
              Technology
              <span className="tech-txt1"> Stack</span>
            </h2>
            <img src="/images/hrImg.png" alt="" className="img-fluid " />
          </div>
        </div>
        {windowWidth >= 768 ? (
          <div className="rowType row-new-logo px-md-3 text-center1 pt-md-3 py-4 px-md-5 pb-md-3">
            <div className="wid-11">
              <TextandHoverComponenet
                alt="React Js"
                title="React Js logo"
                imgSrc="react-logo-simple"
                hoverSrc="react-hover"
              />
            </div>
            <div className="wid-11">
              <TextandHoverComponenet
                alt="DotNetcore"
                title="DotNetcore logo"
                imgSrc="DotNetcore-simple"
                hoverSrc="Dotnet-hover"
              />
            </div>
            <div className="wid-11">
              <TextandHoverComponenet
                alt="postgre"
                title="postgre logo"
                imgSrc="postgre-simple"
                hoverSrc="Postgre-hover"
              />
            </div>
            <div className="wid-11">
              <TextandHoverComponenet
                alt="Bootstrap"
                title="Bootstrap logo"
                imgSrc="Bootstrap-simple"
                hoverSrc="Bootstrap-hover"
              />
            </div>
            <div className="mt-3 mt-md-0 mt-lg-0 wid-11">
              <TextandHoverComponenet
                alt="Aws"
                title="Aws logo"
                imgSrc="Aws-simple"
                hoverSrc="Aws-hover"
              />
            </div>
            <div
              className="mt-3 mt-md-0 mt-lg-0 wid-11"
              style={{ height: "91px" }}
            >
              <TextandHoverComponenet
                alt="Stripe"
                title="Stripe logo"
                imgSrc="Stripe"
                hoverSrc="Stripe-hover"
              />
            </div>
            <div
              className="mt-3 mt-md-0 mt-lg-0 wid-11"
              style={{ height: "91px" }}
            >
              <TextandHoverComponenet
                alt="twillo"
                title="twillo logo"
                imgSrc="twillo"
                hoverSrc="twillo-hover"
              />
            </div>
          </div>
        ) : (
          <>
            <div className="rowType row-new-logo px-md-3 text-center1 pt-md-3 py-4 px-md-5 pb-md-3">
              <div className="wid-11">
                <TextandHoverComponenet
                  alt="React Js"
                  title="React Js logo"
                  imgSrc="react-logo-simple"
                  hoverSrc="react-hover"
                />
              </div>
              <div className="wid-11">
                <TextandHoverComponenet
                  alt="DotNetcore"
                  title="DotNetcore logo"
                  imgSrc="DotNetcore-simple"
                  hoverSrc="Dotnet-hover"
                />
              </div>
              <div className="wid-11">
                <TextandHoverComponenet
                  alt="postgre"
                  title="postgre logo"
                  imgSrc="postgre-simple"
                  hoverSrc="Postgre-hover"
                />
              </div>
              <div className="wid-11">
                <TextandHoverComponenet
                  alt="Bootstrap"
                  title="Bootstrap logo"
                  imgSrc="Bootstrap-simple"
                  hoverSrc="Bootstrap-hover"
                />
              </div>
              <div className="mt-3 mt-md-0 mt-lg-0 wid-11">
                <TextandHoverComponenet
                  alt="Aws"
                  title="Aws logo"
                  imgSrc="Aws-simple"
                  hoverSrc="Aws-hover"
                />
              </div>
              <div
                className="mt-3 mt-md-0 mt-lg-0 wid-11"
                style={{ height: "91px" }}
              >
                <TextandHoverComponenet
                  alt="Stripe"
                  title="Stripe logo"
                  imgSrc="Stripe"
                  hoverSrc="Stripe-hover"
                />
              </div>
              <div
                className="mt-3 mt-md-0 mt-lg-0 wid-11"
                style={{ height: "91px" }}
              >
                <TextandHoverComponenet
                  alt="twillo"
                  title="twillo logo"
                  imgSrc="twillo"
                  hoverSrc="twillo-hover"
                />
              </div>
            </div>
          </>
        )}
        <div>
          <div className="col-12 col-md-12 pt-md-4  pb-md-4 px-md-5">
            <div className="text-center">
              <h2 className="clr0E2B3D overviewheader setlineheight recenthdpd mb-0">
                Overview
              </h2>
              <img src="/images/hrImg.png" alt="" className="img-fluid mb-2 " />
            </div>
            <div
              className={`overviewPara pt-2 pt-md-4 p-4  ${
                windowWidth >= 768 ? "" : "pb-2"
              }`}
            >
              Euroriente is a well-known travel company in Turkey that
              specialises in trips to the Middle East. They advise wholesale
              agencies and tour operators worldwide about the best prices and
              the finest accommodation options in Turkey. It is an excellent ERP
              system for managing all tour operations smoothly. This ERP
              solution gives admins and operators different features to update,
              track, and manage tour details.
            </div>
          </div>
          {windowWidth >= 768 ? (
            <div className="">
              <div className="row pb50 px-md-4 mx-md-1 pb-md-5 px-2 pb-4">
                <div className="col-12 col-md-6 col-lg-6 ps-md-5 pe-md-0 h-100 ">
                  <div className="bor-card ">
                    <div className="col-12 col-md-12 text-md-start text-center py-md-1  pt-md-0">
                      <h3 className="clr0E2B3D  setlineheight recenthdpd hero-card-heading mb-0 px-0">
                        Client's requirement
                      </h3>
                      <img
                        src="/images/hrImg.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-md-4  ">
                      <div className="hero-card-txt mb-3 mb-md-0">
                        To develop a Travel & Tour management website that
                        consists of Admin and operator panels. These two panels
                        can be used to handle all travel activities and
                        operations. Both panels have different functions and
                        features. In addition, they required a tour itinerary
                        page accessible publicly to view the latest updates. The
                        client initially wanted a website that could handle
                        travel allocation and booking in their local region of
                        Turkey and other global regions.
                      </div>
                    </div>
                    <div className="col-12 col-md-12 py-md-1 text-md-start text-center pt-md-0">
                      <h3 className="clr0E2B3D  setlineheight recenthdpd hero-card-heading mb-0">
                        Problem faced by our Client
                      </h3>
                      <img
                        src="/images/hrImg.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-md-4 ">
                      <div className="hero-card-txt mb-3 mb-md-0">
                        The client faced difficulties managing their tour data
                        using Excel sheets, leading to a time consuming and
                        resource intensive process. They encountered challenges
                        in checking and tracking data, allocating and ensuring
                        accurate commission tracking and validation, monitoring
                        revenue, and generating reports was a significant
                        hurdle. In the tourism industry, travel agents and
                        operators face heavy workloads and time management
                        challenges. A streamlined system for handling operations
                        can relieve their burdens and enhance efficiency.
                      </div>
                    </div>
                    <div className="col-12 col-md-12 py-md-1 text-md-start text-center  pt-md-0">
                      <h3 className="clr0E2B3D  setlineheight recenthdpd hero-card-heading mb-0">
                        The major problem and Challenges
                      </h3>
                      <img
                        src="/images/hrImg.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-md-4 ">
                      <ul className="hero-card-txt">
                        <li>
                          Difficulty in gathering all the necessary information
                          efficiently.
                        </li>
                        <li>
                          No quick way to access, check, sort, and filter the
                          Data.
                        </li>
                        <li>
                          Challenges in allocating entities such as hotels and
                          transfers effectively.
                        </li>
                        <li>
                          Tracking and validating the commission cycle posed a
                          significant challenge.
                        </li>
                        <li>
                          Keeping track of revenue and generating comprehensive
                          reports was difficult.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center col-md-6 col-lg-6 pe-md-5 ps-md-0 h-100">
                  <LazyLoadImage
                    effect="blur"
                    className="joint-vector"
                    src="/images/side-image.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="row pb50 px-md-4 mx-md-1 pb-md-5 px-2 pb-4">
                <div className="col-12 col-md-6 col-lg-6 ps-md-5 pe-md-0 h-100 ">
                  <div className="bor-card ">
                    <div className="col-12 col-md-12 text-md-start text-left py-md-1  pt-md-0">
                      <h3 className="clr0E2B3D  setlineheight  hero-card-heading mb-0 px-0">
                        Client's requirement
                      </h3>
                      <img
                        src="/images/hrImg.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-md-4  ">
                      <div className="hero-card-txt mt-2 mb-3 mb-md-0">
                        To develop a Travel & Tour management website that
                        consists of Admin and operator panels. These two panels
                        can be used to handle all travel activities and
                        operations. Both panels have different functions and
                        features. In addition, they required a tour itinerary
                        page accessible publicly to view the latest updates. The
                        client initially wanted a website that could handle
                        travel allocation and booking in their local region of
                        Turkey and other global regions.
                      </div>
                    </div>
                    <div className="col-12 text-center col-md-6 col-lg-6 pe-md-5 mb-4 ps-md-0 h-100">
                      <LazyLoadImage
                        effect="blur"
                        className="joint-vector"
                        src="/images/Client-requirement-vector.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-12 py-md-1 text-md-start text-left pt-md-0">
                      <h3 className="clr0E2B3D  setlineheight  hero-card-heading ps-0 mb-0">
                        Problem faced by our Client
                      </h3>
                      <img
                        src="/images/hrImg.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-md-4 ">
                      <div className="hero-card-txt mt-2 mb-3 mb-md-0 pb-3">
                        The client faced difficulties managing their tour data
                        using Excel sheets, leading to a time consuming and
                        resource intensive process. They encountered challenges
                        in checking and tracking data, allocating and ensuring
                        accurate commission tracking and validation, monitoring
                        revenue, and generating reports was a significant
                        hurdle. In the tourism industry, travel agents and
                        operators face heavy workloads and time management
                        challenges. A streamlined system for handling operations
                        can relieve their burdens and enhance efficiency.
                      </div>
                    </div>

                    <div className="col-12 col-md-12 py-md-1 text-md-start text-left  pt-md-0">
                      <h3 className="clr0E2B3D  setlineheight  hero-card-heading10 mb-0">
                        The major problem and Challenges
                      </h3>
                      <img
                        src="/images/hrImg.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-md-4 px-3 ">
                      <ul className="hero-card-txt mt-2">
                        <li>
                          Difficulty in gathering all the necessary information
                          efficiently.
                        </li>
                        <li>
                          No quick way to access, check, sort, and filter the
                          Data.
                        </li>
                        <li>
                          Challenges in allocating entities such as hotels and
                          transfers effectively.
                        </li>
                        <li>
                          Tracking and validating the commission cycle posed a
                          significant challenge.
                        </li>
                        <li>
                          Keeping track of revenue and generating comprehensive
                          reports was difficult.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 text-center col-md-6 col-lg-6 pe-md-5 mb-4 ps-md-0 h-100">
                    <LazyLoadImage
                      effect="blur"
                      className="joint-vector"
                      src="/images/dualpc-vector.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row px-md-5 width100 px-0 px-md-2  pb-4 phoneMargin0">
            <HeroSectionCard
              bgclass="hero-card-heading ps-0"
              CustomCol="px-md-4 px-0 mx-md-3"
              header="SprigStack Solution"
              Desc="We allocated the appropriate resources and developers based on understanding the client's requirements and problems. We developed the custom development from scratch, using technologies such as React, AWS Cloud, Stripe, Twilio, .Net Core, .Net Core Web API, Bootstrap, Postgresql, HTML and CSS. Our all-rounder ERP software solution completely resolved client problems, providing various functions and features for managing and adding details (Tour Bookings, Hotel, Transfers, Activities) and much more. Using our solution, client can manage all the tour operations efficiently using our system seamlessly without any hassle."
            />
          </div>
        </div>
        <div className="pb-5">
          {windowWidth >= 768 ? (
            <logo-slider className="mt-md-0">
              <div className="hero-port-logo">
                <a className="d-flex align-items-center port-img-text-anc">
                  <LazyLoadImage
                    effect="blur"
                    src="/images/demo-img-1.png"
                    alt=""
                  />
                </a>
                <a className="d-flex align-items-center port-img-text-anc">
                  <LazyLoadImage
                    effect="blur"
                    src="/images/demo-img-2.png"
                    alt=""
                  />
                </a>
                <a className="d-flex align-items-center port-img-text-anc">
                  <LazyLoadImage
                    effect="blur"
                    src="/images/demo-img-3.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="hero-port-logo">
                <a className="d-flex align-items-center port-img-text-anc">
                  <LazyLoadImage
                    effect="blur"
                    src="/images/demo-img-1.png"
                    alt=""
                  />
                </a>
                <a className="d-flex align-items-center port-img-text-anc">
                  <LazyLoadImage
                    effect="blur"
                    src="/images/demo-img-2.png"
                    alt=""
                  />
                </a>
                <a className="d-flex align-items-center port-img-text-anc">
                  <LazyLoadImage
                    effect="blur"
                    src="/images/demo-img-3.png"
                    alt=""
                  />
                </a>
              </div>
            </logo-slider>
          ) : (
            <logo-slider className="mt-md-0">
              <div className="hero-port-logo">
                <a className="d-flex mx-2 align-items-center port-img-text-anc">
                  <img
                    style={{ width: "350px" }}
                    effect="blur"
                    src="/images/demo-img-1.png"
                    alt=""
                  />
                </a>
                <a className="d-flex mx-2 align-items-center port-img-text-anc">
                  <img
                    style={{ width: "350px" }}
                    effect="blur"
                    src="/images/demo-img-2.png"
                    alt=""
                  />
                </a>
                <a className="d-flex mx-2 align-items-center port-img-text-anc">
                  <img
                    style={{ width: "350px" }}
                    effect="blur"
                    src="/images/demo-img-3.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="hero-port-logo">
                <a className="d-flex mx-2 align-items-center port-img-text-anc">
                  <img
                    style={{ width: "350px" }}
                    effect="blur"
                    src="/images/demo-img-1.png"
                    alt=""
                  />
                </a>
                <a className="d-flex mx-2 align-items-center port-img-text-anc">
                  <img
                    style={{ width: "350px" }}
                    effect="blur"
                    src="/images/demo-img-2.png"
                    alt=""
                  />
                </a>
                <a className="d-flex mx-2 align-items-center port-img-text-anc">
                  <img
                    style={{ width: "350px" }}
                    effect="blur"
                    src="/images/demo-img-3.png"
                    alt=""
                  />
                </a>
              </div>
            </logo-slider>
          )}
        </div>
        <div className="bg-freecon mb-md-5">
          <div className="font30">Ready to turn your idea into reality?</div>
          <div className="font30">Let's get started!</div>
          <button
            className="btn-plane"
            onClick={() => {
              setShow(true);
            }}
          >
            GET A FREE CONSULTATION{" "}
            <img src="/images/orange-right-arrow.svg" alt="" />
          </button>
        </div>
        <div>
          <div>
            {windowWidth >= 768 ? (
              <div className="col-12 col-md-12 pt-md-4 bgF7F7F7 pb1 pt-5 pt-md-0">
                <div className="text-center">
                  <h2 className="clr0E2B3D headerFontSizeH6 setlineheight recenthdpd mb-0">
                    <span className="tech-txt1">Features : </span>
                    Admin panel
                  </h2>
                  <img src="/images/hrImg.png" alt="" className="img-fluid " />
                </div>
                <div className="row pe-md-4 ps-md-2 mx-md-5 mb-md-5">
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-4 col-lg-4"
                        : "col-md-5 col-lg-5"
                    } col-12 pe-md-0`}
                  >
                    <LazyLoadImage
                      className="img-fluid w-100 "
                      src="/images/Features-screen-1.svg"
                      alt=""
                      height={400}
                    />
                  </div>
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-8 col-lg-8"
                        : "col-md-7 col-lg-7"
                    } col-12  ps-md-0   bg-white hover-hero-card my-md-4 pt-4 pt-md-0`}
                    style={{
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <div className="mx-md-5 mt-md-2">
                      <div className="text-center text-md-start">
                        <h3 className="clr0E2B3D headerColorAndSize setlineheight recenthdpd mb-0">
                          Admin Dashboard
                        </h3>
                        <img
                          src="/images/hrImg.png"
                          alt=""
                          className="img-fluid "
                        />
                      </div>
                      <div className="text-justify ">
                        <ul>
                          <li
                            className={`${
                              windowWidth >= 1400 ? "py-2" : "pt-0"
                            } listColorAndSize`}
                          >
                            Display the admin's overall data and statistics,
                            including tour bookings, revenues, and average
                            prices.
                          </li>
                          <li
                            className={`${
                              windowWidth >= 1400 ? "py-2" : "pt-1"
                            } listColorAndSize`}
                          >
                            An overview of the total earnings graph showing
                            month-by-month earnings with insights into this
                            month, last month, and this year.
                          </li>
                          <li
                            className={`${
                              windowWidth >= 1400 ? "py-2" : "pt-1"
                            } listColorAndSize`}
                          >
                            Tour bookings graph with insights like this month,
                            last month, This year, Graph showing month-wise
                            booking status Approved, Pending, Rejected.
                          </li>
                          <li
                            className={`${
                              windowWidth >= 1400 ? "pt-2" : "pt-1"
                            } listColorAndSize`}
                          >
                            Latest Bookings This section allows admins to check
                            tour booking details and view details.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-md-3">
                  <div className="row pe-md-4 ps-md-2 mx-md-5 pe-md-0 mb-md-5 position-relative">
                    <div
                      className={`col-md-12 col-lg-12 col-12 border10  pb-md-5 pe-md-5 bg-white hover-hero-card my-md-4`}
                    >
                      <div className="row">
                        <div className="col-9 pe-md-5">
                          <div className="mx-md-5 mt-md-4">
                            <div className="text-start">
                              <h3 className="clr0E2B3D headerColorAndSize setlineheight recenthdpd mb-md-0">
                                Tour Booking
                              </h3>
                              <img
                                src="/images/hrImg.png"
                                alt=""
                                className="img-fluid mb-4"
                              />
                            </div>
                          </div>
                          <div className="text-justify divColorAndSize mx-md-5">
                            <div>
                              <label className="labelColorAndSize">
                                Tour booking Info :
                              </label>
                              <div>
                                Admins can view details and sort, refine, or
                                find data by applying various filters.When the
                                admin clicks on the tour booking ID, it will
                                land on the booking overview dashboard, which
                                displays Traveller, hotel, payment, and transfer
                                details; Payment and tour can be accepted or
                                rejected by the admin based on that information.
                              </div>
                            </div>
                          </div>
                          <hr className="divider"></hr>
                          <div className="text-justify divColorAndSize mx-md-5">
                            <div>
                              <label className="labelColorAndSize">
                                Tours :
                              </label>
                              <div>
                                Admin can check the Tour list, apply filters,
                                refine data or use a search box. Admin can
                                create new tours and manage existing ones; it
                                consists of basic information, Policies,
                                Day-By-Day.
                              </div>
                            </div>
                          </div>
                          <hr className="divider"></hr>
                          <div className="text-justify divColorAndSize mx-md-5 ">
                            <div>
                              <label className="labelColorAndSize">
                                Hotels :
                              </label>
                              <div>
                                Admin can check the list of hotel views with all
                                the details. Add Hotel: Hotel details,room
                                details,hotel policies. Allocated Hotels: Check
                                the details list of allocated hotels data which
                                can be exported, filtered, or searched.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        windowWidth >= 1300
                          ? "col-md-3 col-lg-3"
                          : "col-md-4 col-lg-4"
                      } col-12  ps-md-0 position-absolute pos-img`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        className="maxwid-450"
                        src="/images/Tour-booking-screen.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="px-md-4">
                  <div className="row bg-white hover-hero-card border10 px-3 mx-5 mb-5">
                    <div className="col-12 col-md-5 col-lg-5 d-flex align-items-center ">
                      <LazyLoadImage
                        effect="blur"
                        className="img-fluid wid-100"
                        src="/images/Tour-operations.svg"
                        alt=""
                      />
                    </div>
                    <div
                      className="col-12 col-md-7 col-lg-7 my-md-4"
                      style={{
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      <div className="mx-md-5 mt-md-3">
                        <div className="text-start">
                          <h3 className="clr0E2B3D headerColorAndSize setlineheight recenthdpd mb-md-0">
                            Tour Operators
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid mb-md-4"
                          />
                        </div>
                        <div className="text-justify divColorAndSize">
                          <div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Operators :&nbsp;
                              </label>
                              New tour operators can be added and deleted by the
                              admin.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Agents :&nbsp;
                              </label>
                              Agents can be added and deleted by the admin.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Transfers :&nbsp;
                              </label>
                              Manage all transfer-related activity.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Vehicle Types :&nbsp;
                              </label>
                              Allow admin to view Vehicle Type details and add a
                              new type of vehicle.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Vehicles :&nbsp;
                              </label>
                              Allow admin to view Vehicle details and add new
                              vehicles data.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Transfer Company :&nbsp;
                              </label>
                              Admin to view transfer details and add transfer
                              company.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Guide :&nbsp;
                              </label>
                              Allow admin to view guide details and add a new
                              guide.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Flights :&nbsp;
                              </label>
                              Allow admin to view flight details and add new
                              flights.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Route list :&nbsp;
                              </label>
                              Allow admin to view the list of routes, select the
                              specific date range, select vehicle type, search
                              and reset the list.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Transfer list :&nbsp;
                              </label>
                              Allow admin to view the list of transfers, select
                              the specific date range, select the vehicle,
                              select allocation status, search and reset the
                              list.
                            </div>
                            <div className="my-md-3">
                              <label className="labelColorAndSize">
                                Transfer Pool :&nbsp;
                              </label>
                              It allows the admin to choose dates, select hotel
                              pool, search and reset.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 px-3 bg-white hover-hero-card border10 mx-5 mb-5">
                    <div
                      className="col-12 col-md-8 col-lg-8 ps-md-0   my-md-4"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    >
                      <div className="mx-md-5 mt-md-3">
                        <div className="text-start">
                          <h3 className="clr0E2B3D headerColorAndSize setlineheight recenthdpd mb-md-0">
                            Configuration
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid mb-md-4"
                          />
                        </div>
                      </div>
                      <div className="text-justify divColorAndSize mx-md-5">
                        <div className="my-md-2">
                          <label className="labelColorAndSize">
                            Notification Emails :&nbsp;
                          </label>
                          Mail details can be added and saved for various
                          departments like (Administrator Email, Flight Booking
                          Department, Hotel Booking Department etc.)
                        </div>
                        <div className="my-md-2">
                          <label className="labelColorAndSize">
                            Policies :&nbsp;
                          </label>
                          View and add cancellation and child policy.
                        </div>
                        <div className="my-md-2">
                          <label className="labelColorAndSize">
                            Users :&nbsp;
                          </label>
                          Allow the admin to view the user list and add a new
                          user.
                        </div>
                        <div className="my-md-2">
                          <label className="labelColorAndSize">
                            Roles :&nbsp;
                          </label>
                          Allow admin to view role list and create role.
                        </div>
                        <div className="my-md-2">
                          <label className="labelColorAndSize">
                            Withdraw Request :&nbsp;
                          </label>
                          Admin can reject and accept all payment details added
                          by guide or tour operators.
                        </div>
                        <div className="my-md-2">
                          <label className="labelColorAndSize">
                            Common Additional feature for both the panels
                            :&nbsp;
                          </label>
                          Wherever Data is used in table format can be copied,
                          exported to pdf, and changed columns' visibility.
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 d-flex align-items-center  ps-md-0">
                      <LazyLoadImage
                        effect="blur"
                        className="img-fluid w-100"
                        src="/images/Configuration-screen.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 col-md-12 pt-md-4 bgF7F7F7 pb1 pt-4 pt-md-0">
                <div className="text-center mb-2">
                  <h2 className="clr0E2B3D headerFontSizeH6 setlineheight  mb-0">
                    <span className="tech-txt1">Features : </span>
                    Admin panel
                  </h2>
                  <img src="/images/hrImg.png" alt="" className="img-fluid " />
                </div>
                <div className="row px-md-3 bg-white border10 hover-hero-card mx-0 mb-md-5">
                  <div className="col-12 col-md-4 col-lg-4 pe-md-0 pt-4">
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid w-100 px-3 px-md-0"
                      src="/images/Features-screen-1.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-12 col-md-8 col-lg-8 ps-md-0   my-md-4 pt-4 pt-md-0">
                    <div className="mx-md-5 mt-md-3">
                      <div className="text-left text-md-start ps-2">
                        <h3 className="clr0E2B3D headerColorAndSize setlineheight ps-2 mb-0">
                          Admin Dashboard
                        </h3>
                        <img
                          src="/images/hrImg.png"
                          alt=""
                          className="img-fluid ps-2 mb-md-4"
                        />
                      </div>
                      <div className="py-1 pe-4">
                        <ul>
                          <li className="listColorAndSize py-1">
                            Display the admin's overall data and statistics,
                            including tour bookings, revenues, and average
                            prices.
                          </li>
                          <li className="listColorAndSize py-1">
                            An overview of the total earnings graph showing
                            month-by-month earnings with insights into this
                            month, last month, and this year.
                          </li>
                          <li className="listColorAndSize py-1">
                            Tour bookings graph with insights like this month,
                            last month, This year, Graph showing month-wise
                            booking status Approved, Pending, Rejected.
                          </li>
                          <li className="listColorAndSize py-1">
                            Latest Bookings This section allows admins to check
                            tour booking details and view details.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-md-3 mt-4">
                  <div className="row px-md-3 bg-white border10 mx-0 hover-hero-card mx-md-5 pe-md-5 mb-md-5 position-relative">
                    <div className="col-12   col-md-4 col-lg-4 ps-md-0 pt-4  pos-img ">
                      <LazyLoadImage
                        effect="blur"
                        className="img-fluid w-100 px-3"
                        src="/images/Tour-booking-screen.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12  col-md-9 col-lg-9 pb-md-5 pe-md-5  my-md-4">
                      <div className="mx-md-5 mt-4">
                        <div className="text-left pb-2 ps-2">
                          <h3 className="clr0E2B3D headerColorAndSize setlineheight ps-2 mb-0">
                            Tour Booking
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid ps-2 mb-md-4"
                          />
                        </div>
                      </div>
                      <div className="text-justify divColorAndSize mx-3">
                        <div>
                          <label className="labelColorAndSize">
                            Tour booking Info :
                          </label>
                          <div>
                            Admins can view details and sort, refine, or find
                            data by applying various filters.When the admin
                            clicks on the tour booking ID, it will land on the
                            booking overview dashboard, which displays
                            Traveller, hotel, payment, and transfer details;
                            Payment and tour can be accepted or rejected by the
                            admin based on that information.
                          </div>
                        </div>
                      </div>
                      <hr className="divider"></hr>
                      <div className="text-justify divColorAndSize mx-3">
                        <div>
                          <label className="labelColorAndSize">Tours :</label>
                          <div>
                            Admin can check the Tour list, apply filters, refine
                            data or use a search box. Admin can create new tours
                            and manage existing ones; it consists of basic
                            information, Policies, Day-By-Day.
                          </div>
                        </div>
                      </div>
                      <hr className="divider"></hr>
                      <div className="text-justify divColorAndSize mx-3 pb-4">
                        <div>
                          <label className="labelColorAndSize">Hotels :</label>
                          <div>
                            Admin can check the list of hotel views with all the
                            details. Add Hotel: Hotel details,room details,hotel
                            policies. Allocated Hotels: Check the details list
                            of allocated hotels data which can be exported,
                            filtered, or searched.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="row bg-white mx-0 border10 hover-hero-card  px-3  mb-4">
                    <div className="col-12 col-md-5 col-lg-5 d-flex align-items-center ">
                      <LazyLoadImage
                        effect="blur"
                        className="img-fluid wid-100 px-0 py-4"
                        src="/images/Tour-operations.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-7 col-lg-7 my-md-4">
                      <div className="mx-md-5 mt-md-3">
                        <div className="text-left">
                          <h3 className="clr0E2B3D headerColorAndSize setlineheight  ps-0 mb-0">
                            Tour Operators
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid ps-0 mb-md-4"
                          />
                        </div>
                        <div className="text-justify divColorAndSize">
                          <div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Operators :&nbsp;
                              </label>
                              New tour operators can be added and deleted by the
                              admin.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Agents :&nbsp;
                              </label>
                              Agents can be added and deleted by the admin.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Transfers :&nbsp;
                              </label>
                              Manage all transfer-related activity.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Vehicle Types :&nbsp;
                              </label>
                              Allow admin to view Vehicle Type details and add a
                              new type of vehicle.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Vehicles :&nbsp;
                              </label>
                              Allow admin to view Vehicle details and add new
                              vehicles data.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Transfer Company :&nbsp;
                              </label>
                              Admin to view transfer details and add transfer
                              company.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Guide :&nbsp;
                              </label>
                              Allow admin to view guide details and add a new
                              guide.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Flights :&nbsp;
                              </label>
                              Allow admin to view flight details and add new
                              flights.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Route list :&nbsp;
                              </label>
                              Allow admin to view the list of routes, select the
                              specific date range, select vehicle type, search
                              and reset the list.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Transfer list :&nbsp;
                              </label>
                              Allow admin to view the list of transfers, select
                              the specific date range, select the vehicle,
                              select allocation status, search and reset the
                              list.
                            </div>
                            <div className="my-2">
                              <label className="labelColorAndSize">
                                Transfer Pool :&nbsp;
                              </label>
                              It allows the admin to choose dates, select hotel
                              pool, search and reset.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  px-3 bg-white hover-hero-card border10 mx-0 mb-4">
                    <div className="col-12 col-md-8 col-lg-8 ps-md-0   my-md-4">
                      <div className="mx-md-5 mt-md-3">
                        <div className="col-12 col-md-4 col-lg-4 d-flex align-items-center  ps-md-0">
                          <LazyLoadImage
                            effect="blur"
                            className="img-fluid w-100 px-0 py-4"
                            src="/images/Configuration-screen.svg"
                            alt=""
                          />
                        </div>
                        <div className="text-left pt-2">
                          <h3 className="clr0E2B3D headerColorAndSize setlineheight  px-0 mb-0">
                            Configuration
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid ps-0 mb-md-4"
                          />
                        </div>
                      </div>
                      <div className="text-justify divColorAndSize mx-md-5 px-0">
                        <div className="my-2">
                          <label className="labelColorAndSize">
                            Notification Emails :&nbsp;
                          </label>
                          Mail details can be added and saved for various
                          departments like (Administrator Email, Flight Booking
                          Department, Hotel Booking Department etc.)
                        </div>
                        <div className="my-2">
                          <label className="labelColorAndSize">
                            Policies :&nbsp;
                          </label>
                          View and add cancellation and child policy.
                        </div>
                        <div className="my-2">
                          <label className="labelColorAndSize">
                            Users :&nbsp;
                          </label>
                          Allow the admin to view the user list and add a new
                          user.
                        </div>
                        <div className="my-2">
                          <label className="labelColorAndSize">
                            Roles :&nbsp;
                          </label>
                          Allow admin to view role list and create role.
                        </div>
                        <div className="my-2">
                          <label className="labelColorAndSize">
                            Withdraw Request :&nbsp;
                          </label>
                          Admin can reject and accept all payment details added
                          by guide or tour operators.
                        </div>
                        <div className="my-2">
                          <label className="labelColorAndSize">
                            Common Additional feature for both the panels
                            :&nbsp;
                          </label>
                          Wherever Data is used in table format can be copied,
                          exported to pdf, and changed columns' visibility.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* <div className="pt-md-1 pb-md-4  bgF7F7F7">
            <div className="text-center">
              <h2 className="clr0E2B3D headerFontSizeH6 setlineheight recenthdpd mb-md-0">
                Our Implementation Approach
              </h2>
              <img src="/images/hrImg.png" alt="" className="img-fluid " />
            </div>
            <LazyLoadImage
              className=" width100"
              src="/images/car_moving_animation.gif"
              alt=""
            />
          </div> */}
        </div>
        <div>
          {windowWidth >= 768 ? (
            <div className="row">
              <div className="col-12 col-md-12 pt-md-4 bgF7F7F7 pb70">
                <div className="text-center">
                  <h2 className="clr0E2B3D headerFontSizeH6 setlineheight recenthdpd mb-md-0">
                    <span className="tech-txt1">Features : </span>
                    Operator panel
                  </h2>
                  <img src="/images/hrImg.png" alt="" className="img-fluid " />
                </div>
                <div className="row pe-md-4 ps-md-2 mx-md-5 mb-md-5 ">
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-4 col-lg-4"
                        : "col-md-5 col-lg-5"
                    } col-12  pe-md-0`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid w-100"
                      src="/images/Operator-Dashboard.svg"
                      alt=""
                    />
                  </div>
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-8 col-lg-8"
                        : "col-md-7 col-lg-7"
                    } col-12  ps-md-0  bg-white hover-hero-card my-md-4`}
                    style={{
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <div className="mx-md-5 mt-md-3">
                      <div className="text-start">
                        <h3 className="clr0E2B3D headerColorAndSize setlineheight recenthdpd mb-md-0">
                          Operator Dashboard
                        </h3>
                        <img
                          src="/images/hrImg.png"
                          alt=""
                          className="img-fluid mb-md-4"
                        />
                      </div>
                      <div className="text-justify ">
                        <ul>
                          <li
                            className={`${
                              windowWidth >= 1400 ? "py-2" : "pt-1"
                            } listColorAndSize`}
                          >
                            Display the operator's overall data and statistics,
                            including tour bookings, revenues, and monthly
                            earnings.
                          </li>
                          <li
                            className={`${
                              windowWidth >= 1400 ? "py-2" : "pt-1"
                            } listColorAndSize`}
                          >
                            An overview of the total earnings graph showing
                            month-by-month earnings with insights into total
                            paid and total earnings.
                          </li>
                          <li
                            className={`${
                              windowWidth >= 1400 ? "py-2" : "pt-1"
                            } listColorAndSize`}
                          >
                            The latest tour Bookings section allows the operator
                            to check and view tour booking details.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-md-3 mx-md-5 mb-md-5 ps-md-4">
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-8 col-lg-8"
                        : "col-md-7 col-lg-7"
                    } col-12  ps-md-0  bg-white hover-hero-card my-md-4`}
                    style={{
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <div className="mx-md-5 mt-md-4">
                      <div className="text-start">
                        <h3 className="clr0E2B3D headerColorAndSize setlineheight recenthdpd mb-md-0">
                          Tour booking Info
                        </h3>
                        <img
                          src="/images/hrImg.png"
                          alt=""
                          className="img-fluid mb-md-4"
                        />
                      </div>
                      <div
                        className={`${
                          windowWidth >= 1400 ? "mb-md-4" : "mb-md-2"
                        } text-justify divColorAndSize `}
                      >
                        Operator can view details and sort, refine, or find data
                        by applying various filters.  Add tour bookings, check
                        previous bookings, take actions.
                      </div>
                    </div>
                    <hr className="divider" />
                    <div className="text-justify divColorAndSize mx-md-5">
                      <label className="labelColorAndSize my-md-3 mb-md-2">
                        Agent :
                      </label>
                    </div>
                    <div className="divColorAndSize mx-md-5">
                      In this section, the operator can add agents, view details
                      and sort, refine, or find data by applying various
                      filters.
                    </div>
                  </div>
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-4 col-lg-4"
                        : "col-md-5 col-lg-5"
                    } col-12  px-md-0`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid w-100"
                      src="/images/Tour-Booking-info.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="row pe-md-4 ps-md-2 mx-md-5 mb-md-5 ">
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-4 col-lg-4"
                        : "col-md-5 col-lg-5"
                    } col-12  pe-md-0`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid w-100"
                      src="/images/Tour-iternaryPage.svg"
                      alt=""
                    />
                  </div>
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-8 col-lg-8"
                        : "col-md-7 col-lg-7"
                    } col-12  pe-md-0  bg-white hover-hero-card my-md-4`}
                    style={{
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <div className="mx-md-5 mt-md-3">
                      <div className="text-start">
                        <h3 className="clr0E2B3D headerColorAndSize setlineheight recenthdpd mb-md-0">
                          Tour itinerary page
                        </h3>
                        <img
                          src="/images/hrImg.png"
                          alt=""
                          className="img-fluid mb-md-4"
                        />
                      </div>
                      <div className="text-justify divColorAndSize mb-md-3">
                        The latest itinerary details, updates, and policies are
                        provided through a publicly accessible dashboard portal.
                      </div>
                    </div>
                    <hr className="divider" />
                    <div
                      className={`${
                        windowWidth >= 1400 ? "my-md-4" : "my-md-2"
                      } text-justify divColorAndSize mx-md-5 `}
                    >
                      <label className="labelColorAndSize">
                        Itinerary :&nbsp;
                      </label>
                      Display allocated hotels,Transfer and Activities including
                      other details like  tour day inclusions, description,bus
                      transfer, Hotel Details.
                    </div>
                    <div
                      className={`${
                        windowWidth >= 1400 ? "my-md-3" : "my-md-2"
                      } text-justify divColorAndSize mx-md-5`}
                    >
                      <label className="labelColorAndSize">
                        Policies :&nbsp;
                      </label>
                      Display cancellation policy details.
                    </div>
                  </div>
                </div>
                <div className="row px-md-3 mx-md-5 ps-md-4">
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-8 col-lg-8"
                        : "col-md-7 col-lg-7"
                    } col-12  ps-md-0  bg-white hover-hero-card my-md-4`}
                    style={{
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <div
                      className={`${
                        windowWidth >= 1400 ? "mt-md-5" : "mt-md-3"
                      } mx-md-5 `}
                    >
                      <div className="text-start">
                        <h3 className="clr0E2B3D customheader  setlineheight recenthdpd mb-md-0">
                          Agent Panel :
                        </h3>
                        <img
                          src="/images/hrImg.png"
                          alt=""
                          className="img-fluid mb-md-4"
                        />
                      </div>
                      <div className="text-justify divColorAndSize">
                        Utilize the available features to efficiently manage and
                        allocate tour bookings, seamlessly add relevant details,
                        and easily update and modify data within the agent
                        panel.
                      </div>
                    </div>
                    <div className="mx-md-5 mt-md-3">
                      <div className="text-start">
                        <h3 className="clr0E2B3D customheader setlineheight recenthdpd mb-md-0">
                          Guide Panel :
                        </h3>
                        <img
                          src="/images/hrImg.png"
                          alt=""
                          className="img-fluid mb-md-4"
                        />
                      </div>
                      <div className="text-justify divColorAndSize">
                        Utilize the available features to manage and view
                        detailed information within the Guide panel.
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      windowWidth >= 1300
                        ? "col-md-4 col-lg-4"
                        : "col-md-5 col-lg-5"
                    } col-12  px-md-0`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      className="img-fluid w-100"
                      src="/images/Tour-Booking-info.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="row bgF7F7F7 ">
                <div className="col-12 col-md-12 pt-md-4 pt-0 pb70">
                  <div className="text-center mb-2">
                    <h2 className="clr0E2B3D headerFontSizeH6 setlineheight  mb-0">
                      <span className="tech-txt1">Features : </span>
                      Operator panel
                    </h2>
                    <img
                      src="/images/hrImg.png"
                      alt=""
                      className="img-fluid "
                    />
                  </div>
                  <div className="row bg-white hover-hero-card px-md-3 mx-0 border10 mb-md-5 ">
                    <div className="col-12 col-md-4 col-lg-4 py-4 pe-md-0">
                      <LazyLoadImage
                        effect="blur"
                        className="img-fluid w-100 px-3"
                        src="/images/Operator-Dashboard.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-8 col-lg-8 ps-md-0   my-md-4">
                      <div className="mx-md-5 mt-md-3">
                        <div className="text-left ps-2">
                          <h3 className="clr0E2B3D headerColorAndSize setlineheight ps-2 mb-0">
                            Operator Dashboard
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid ps-2 mb-md-4"
                          />
                        </div>
                        <div className="py-1 pe-4">
                          <ul>
                            <li className="listColorAndSize py-1">
                              Display the operator's overall data and
                              statistics, including tour bookings, revenues, and
                              monthly earnings.
                            </li>
                            <li className="listColorAndSize py-1">
                              An overview of the total earnings graph showing
                              month-by-month earnings with insights into total
                              paid and total earnings.
                            </li>
                            <li className="listColorAndSize py-1">
                              The latest tour Bookings section allows the
                              operator to check and view tour booking details.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 bg-white hover-hero-card mx-0 px-md-3 border10 mb-md-5 ps-md-4">
                    <div className="col-12 col-md-4 col-lg-4 px-md-0 py-4">
                      <LazyLoadImage
                        effect="blur"
                        className="img-fluid w-100 px-3"
                        src="/images/Tour-Booking-info.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-8 col-lg-8 ps-md-0   my-md-4">
                      <div className="mx-md-5 mt-md-4 px-2">
                        <div className="text-left mb-2">
                          <h3 className="clr0E2B3D headerColorAndSize setlineheight ps-2 mb-0">
                            Tour booking Info
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid ps-2 mb-md-4"
                          />
                        </div>
                        <div className="text-justify divColorAndSize mb-md-4 px-2">
                          Operator can view details and sort, refine, or find
                          data by applying various filters.  Add tour bookings,
                          check previous bookings, take actions.
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="text-justify divColorAndSize mx-md-5 px-3">
                        <label className="labelColorAndSize my-md-3 mb-md-2">
                          Agent :
                        </label>
                      </div>
                      <div className="divColorAndSize mx-md-5 pb-4 px-3">
                        In this section, the operator can add agents, view
                        details and sort, refine, or find data by applying
                        various filters.
                      </div>
                    </div>
                  </div>
                  <div className="row bg-white hover-hero-card mx-0 border10 mt-4 px-md-3 mx-md-5 mb-md-5 ">
                    <div className="col-12 col-md-4 col-lg-4 pe-md-0 py-4">
                      <LazyLoadImage
                        effect="blur"
                        className="img-fluid w-100 px-3"
                        src="/images/Tour-iternaryPage.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-8 col-lg-8 pe-md-0  my-md-4">
                      <div className="mx-md-5 mt-md-3">
                        <div className="text-left ps-2 mb-2">
                          <h3 className="clr0E2B3D headerColorAndSize setlineheight ps-2 mb-0">
                            Tour itinerary page
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid ps-2 mb-md-4"
                          />
                        </div>
                        <div className="text-justify divColorAndSize mb-3 px-3">
                          The latest itinerary details, updates, and policies
                          are provided through a publicly accessible dashboard
                          portal.
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="text-justify divColorAndSize mx-md-5 my-3 px-3">
                        <label className="labelColorAndSize">
                          Itinerary :&nbsp;
                        </label>
                        Display allocated hotels,Transfer and Activities
                        including other details like  tour day inclusions,
                        description,bus transfer, Hotel Details.
                      </div>
                      <div className="text-justify divColorAndSize mx-md-5 my-3 px-3">
                        <label className="labelColorAndSize">
                          Policies :&nbsp;
                        </label>
                        Display cancellation policy details.
                      </div>
                    </div>
                  </div>
                  <div className="row bg-white hover-hero-card mx-0 mt-4 border10 px-md-3 mx-md-5 ps-md-4">
                    <div className="col-12 col-md-4 col-lg-4 px-md-0 my-4">
                      <LazyLoadImage
                        effect="blur"
                        className="img-fluid w-100 px-3"
                        src="/images/Tour-Booking-info.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-md-8 col-lg-8 ps-md-0   my-md-4">
                      <div className="mx-md-5 mt-md-5">
                        <div className="text-left ps-2">
                          <h3 className="clr0E2B3D customheader ps-2  setlineheight  mb-0">
                            Agent Panel :
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid ps-2 mb-md-4"
                          />
                        </div>
                        <div className="text-justify divColorAndSize mt-1 mb-4 px-3">
                          Utilize the available features to efficiently manage
                          and allocate tour bookings, seamlessly add relevant
                          details, and easily update and modify data within the
                          agent panel.
                        </div>
                      </div>
                      <div className="mx-md-5 mt-md-3">
                        <div className="text-left ps-2">
                          <h3 className="clr0E2B3D customheader setlineheight ps-2 mb-0">
                            Guide Panel :
                          </h3>
                          <img
                            src="/images/hrImg.png"
                            alt=""
                            className="img-fluid ps-2 mb-md-4"
                          />
                        </div>
                        <div className="text-justify divColorAndSize mt-1 mb-4  px-3">
                          Utilize the available features to manage and view
                          detailed information within the Guide panel.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <HeaderWithDynamicContent
          HrLine={props.isHrLine}
          BgColor=""
          Header="What our clients think about us!"
          HeaderPadding="majorBenefitHeading"
          pt={true}
        />
        <div className="px-6 pb-5 text-center mt-4">
          <img
            src="/images/DummyAvtar.png"
            alt="client"
            style={{ borderRadius: "50%" }}
          />
          <h4 className="fontPoppins mt-3">Wani Dev</h4>
          <h6 className="fontPoppins">CEO, Fayanka Tech</h6>
          {/* <img src="/images/video_icon.png" alt="" className={`clickable`}></img> */}
          <h6 className="custom-blockquote fontPoppins text-justify mt-3">
            Everything in the software is working as imagined. We will every day
            lay down our plan and requirements in detail and every dev from
            their team will understand and deliver. All requirements our company
            had from booking tours to delivering them, can be handled
            efficiently now. Every milestone was delivered on and before time.
            Software developed by Sprigstack was better and efficient. They did
            a great Job!
          </h6>
        </div>

        {/* <div className="row">
          <div className="row pb50 pe-0">
            <div className="col-12 col-md-12 mt-md-5 text-center pt-4 pt-md-0 pe-0">
              <h2 className="clr0E2B3D default-Paraheader-card Card-ul-header setlineheight recenthdpd">
                What our client think about us!
              </h2>
              <img
                src="images/hrImg.png"
                alt="test"
                className="img-fluid "
              ></img>
            </div>
            <div className="row pt-md-3 px10">
              <div className="col-12 text-center col-md-3">
                <div className="row">
                  <div className="col-12 col-md-12 mb-3">
                    <img
                      className="ps-5 ps-md-0 pt-4"
                      src="./images/RyanSkidMore.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-12 col-md-12 mb-1 ps-5 pe-0  px-md-2">
                    <span className="paraName">Ryan SkidMore</span>
                  </div>
                  <div className="col-12 col-md-12 ps-5 ps-5 pe-0  px-md-2">
                    <span className="paraPost">Director at Berger’s</span>
                  </div>
                </div>
              </div>
              <div className="col-12 custom-blockquote3 spanPara col-md-9 ms-4 ms-md-0">
                <span className="QuotePara ">
                  The service that is served is so very charming with friendly
                  waiters and also a comfortable place so that it makes
                  customers feel at home for a long time in this shop and also
                  the tea shop owner provides free warm and pastries making the
                  atmosphere so beautiful, and this is a very pleasant
                  experience for me. and I will visit here again every weekend
                  with my family.
                </span>
              </div>
            </div>
          </div>
        </div> */}
        <Popups
          show={show}
          setShow={setShow}
          title="FREE CONSULTATION"
          size="lg"
        >
          <PersonalizedActionModal />
        </Popups>
      </div>
    </React.Fragment>
  );
}
