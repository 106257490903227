import React, { useState, useRef, useReducer, useEffect } from "react";
import { formReducer, INITIAL_STATE } from "../Reducer/UserDetailsReduser";
import PhoneInput from "react-phone-input-2";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
export default function ContactUsComp(props) {
  const [country, setCountry] = useState(sessionStorage.getItem("Country"));
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [windowWidth, setWindowWidth] = useState("");
  const [successMsg, setSuccessMsg] = useState({
    reCaptchaErrMsg: "",
    mailSentMsg: false,
  });
  const ReCAPTCHAValue = useRef();
  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);
  const CUSubmit = (e) => {
    e.preventDefault();
    setSuccessMsg({ ...successMsg, reCaptchaErrMsg: "" });
    if (ReCAPTCHAValue.current.getValue() == "") {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please complete the captcha",
      });
    } else if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please Enter Phone Number",
      });
    } else {
      var formdata = new FormData();
      formdata.append("Name", state.Name);
      formdata.append("ContactNumber", $("#ContactNumber").val());
      formdata.append("Email", state.Email);
      formdata.append("ProjectDetail", state.ProjectDetail);
      formdata.append("ProjectBrief", state.ProjectBrief);
      formdata.append("GoogleCaptcha", ReCAPTCHAValue.current.getValue());
      formdata.append("Subject", "From Contact Us Page");
      // formdata.append("PageName", props?.pageName);
      formdata.append(
        "PageName",
        `${props?.pageName} (${
          props?.prevPage === "/" ? "/home-page" : props?.prevPage
        })`
      );

      var requestOptions = {
        method: "POST",
        body: formdata,
      };
      fetch("https://app.addresstwo.com/SprigstackEmail/Index", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var results = JSON.parse(result);
          if (results.Status == "Success" && results.Message == "Success")
            setSuccessMsg({ ...successMsg, mailSentMsg: true });
          else if (
            results.Status == "Failed" &&
            results.Message == "Please Validate Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Please Validate Captcha",
            });
          else if (
            results.Status == "Failed" &&
            results.Message == "Error in Verify Google Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Error in Verify Google Captcha",
            });
        })
        .catch((error) => console.error(error));
    }
  };
  return (
    <React.Fragment>
      <div
        className={
          windowWidth > 786
            ? "row px-6  mb-3 pt-3"
            : "row px-3 pt-5   bg-F7F7F7"
        }
      >
        <div className="col-md-4 col-12   pe-md-0">
          {!successMsg.mailSentMsg ? (
            <>
              <form className="blogForm" onSubmit={CUSubmit}>
                <div className="row px-md-5 px-3">
                  <div className="col-12 mt-3 text-center">
                    {props.header ? (
                      <h2 className={props.headerClass}>{props.header}</h2>
                    ) : (
                      <br />
                    )}
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name*"
                      onChange={handleChange}
                      name="Name"
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email ID*"
                      onChange={handleChange}
                      name="Email"
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <PhoneInput
                      country={country}
                      autoFormat="true"
                      inputProps={{
                        name: "ContactNumber",
                        required: true,
                        id: "ContactNumber",
                        className: " form-control",
                      }}
                      placeholder="Phone Number*"
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <select
                      id="ProjectDetail"
                      name="ProjectDetail"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option defaultChecked value="Request a consultation">
                        Request a consultation
                      </option>
                      <option value="Hire Developers">Hire Developers</option>
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="App Development">App Development</option>
                      <option value="API Integration Services">
                        API Integration Services
                      </option>
                      <option value="UI/UX project">UI/UX project</option>
                      <option value="SEO services">SEO services</option>
                      <option value="DevOps">DevOps</option>
                      <option value="Project Management">
                        Project Management
                      </option>
                      <option value="Project Estimation">
                        Project Estimation
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-12 mb-3">
                    <textarea
                      className="form-control"
                      placeholder="How can we help you?"
                      onChange={handleChange}
                      name="ProjectBrief"
                      rows="4"
                    />
                  </div>
                  <div className="col-12 justify-content-center d-flex ">
                    <ReCAPTCHA
                      ref={ReCAPTCHAValue}
                      sitekey={process.env.REACT_APP_EMAIL_RECAPTCHA_SITE_KEY}
                    />
                  </div>
                  <div className="col-12 justify-content-center d-flex">
                    <span className="text-danger my-2">
                      {successMsg.reCaptchaErrMsg}
                    </span>
                  </div>
                  <div className="col-12 text-center  mb-3">
                    <button
                      className="btn btn-primary blogModalBtn"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>
              <form
                style={{ height: windowWidth <= 991 ? "" : "400px" }}
                className="IndianaForm bg-white d-flex justify-content-center align-items-center"
              >
                <div
                  className="text-center h3 bg-white mx-5 py-5"
                  style={{ fontSize: windowWidth <= 991 ? "17px" : "24px" }}
                >
                  Thank you for reaching out to us. We will get back to you
                  shortly!
                </div>
              </form>
            </>
          )}
        </div>
        <div className="col-md-8 bg-F7F7F7 d-flex align-items-md-center my-md-5 my-3 mb-5 blogfooterbg">
          <div className="row ps-md-3">
            <div className="text-md-start text-center col-12 blogFooter">
              <p className="clr0E2B3D  mb-md-0 text-md-start text-center">
                Connect with Us!
              </p>
              <img src="/images/hrImg.png" alt="" className="img-fluid " />
            </div>
            <div className="col-12">
              <div className="row text-md-start text-center px-md-1">
                <div className="col-md-2 col-lg-3 col-12">
                  <div className="row">
                    <div className="col-12 my-3">
                      <img src="./images/blogIndiaFlag.png" alt="" />
                    </div>
                    <div className="col-12">+91 932 825 3054</div>
                  </div>
                </div>
                <div className="col-md-2 col-lg-3 col-12">
                  <div className="row">
                    <div className="col-12 my-3">
                      <img src="./images/blogusaflag.png" alt="" />
                    </div>
                    <div className="col-12">+1 (317) 460-1173</div>
                  </div>
                </div>
                <div className="col-md-2 col-lg-3 col-12">
                  <div className="row">
                    <div className="col-12 my-3">
                      <img src="./images/blogGmail.png" alt="" />
                    </div>
                    <div className="col-12">connect@sprigstack.com</div>
                  </div>
                </div>
                {/* <div className="col-md-2 col-lg-3 col-12">
                  <div className="row">
                    <div className="col-12 my-3">
                      <img src="./images/blogSkype.png" alt="" />
                    </div>
                    <div className="col-12">
                      <a
                        style={{ color: "#EE720B" }}
                        href="https://join.skype.com/invite/fSgRyeSGlOAq"
                        target="_blank"
                      >
                        live:preetshah789
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-12 mt-4 text-md-start text-center">
                <a
                  className="p-0 me-4 me-md-0"
                  href="https://instagram.com/sprigstack"
                  rel="nofollow"
                  target="_blank"
                >
                  <img className="" src="./images/blogInsta.png" alt="" />
                </a>

                <a
                  href="https://www.linkedin.com/company/sprig-stack"
                  rel="nofollow"
                  target="_blank"
                >
                  <img className="mx-3" src="./images/blogLink.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
