import React, { useState, useRef, useEffect, useReducer } from "react";
import { formReducer, INITIAL_STATE } from "../Reducer/UserDetailsReduser";

import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import $ from "jquery";
import axios from "axios";

export default function PersonalizedActionModal(props) {
  const [country, setCountry] = useState(sessionStorage.getItem("Country"));
  const loaction = useLocation();
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);

  const [successMsg, setSuccessMsg] = useState({
    reCaptchaErrMsg: "",
    mailSentMsg: false,
  });
  const ReCAPTCHAValue = useRef();

  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const PersonalizedSubmit = (e) => {
    e.preventDefault();
    setSuccessMsg({ ...successMsg, reCaptchaErrMsg: "" });
    if (ReCAPTCHAValue.current.getValue() == "") {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please complete the captcha",
      });
    } else if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please Enter Phone Number",
      });
    } else {
      var formdata = new FormData();
      formdata.append("Name", state.Name);
      formdata.append("ContactNumber", $("#ContactNumber").val());
      formdata.append("Email", state.Email);
      formdata.append("ProjectDetail", state.ProjectDetail);
      formdata.append("ProjectBrief", state.ProjectBrief);
      formdata.append("GoogleCaptcha", ReCAPTCHAValue.current.getValue());
      formdata.append("Subject", "FREE CONSULTATION");
      formdata.append(
        "PageName",
        `${loaction?.pathname == "/" ? "/home-page" : loaction?.pathname} ${
          props.modalSource ? props.modalSource : "(CTA)"
        }`
      );
      var requestOptions = {
        method: "POST",
        body: formdata,
      };

      fetch("https://app.addresstwo.com/SprigstackEmail/Index", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var results = JSON.parse(result);
          if (results.Status == "Success" && results.Message == "Success")
            setSuccessMsg({ ...successMsg, mailSentMsg: true });
          else if (
            results.Status == "Failed" &&
            results.Message == "Please Validate Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Please Validate Captcha",
            });
          else if (
            results.Status == "Failed" &&
            results.Message == "Error in Verify Google Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Error in Verify Google Captcha",
            });
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <>
      {!successMsg.mailSentMsg ? (
        <div className="row freeQuoteModal m-lg-3 m-3 ">
          <div className="col-lg-6 col-md-6 col-12">
            <form onSubmit={PersonalizedSubmit}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name*"
                    onChange={handleChange}
                    name="Name"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email ID*"
                    onChange={handleChange}
                    name="Email"
                    required
                  />
                </div>
              </div>
              <div className="row mt-lg-0">
                <div className="col-lg-12 col-md-12 col-12 mb-4">
                  {/* <input
                    type="number"
                    className="form-control"
                    placeholder="Phone Number*"
                    
                    name="ContactNumber"
                    required
                  /> */}
                  <PhoneInput
                    country={country}
                    inputProps={{
                      name: "ContactNumber",
                      required: true,
                      id: "ContactNumber",
                      placeholder: "Phone Number*",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 mb-4">
                  <select
                    style={{
                      width: "100%",
                      appearance: "auto",
                      height: "35px",
                    }}
                    id="ProjectDetail"
                    name="ProjectDetail"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option value="Request a consultation">
                      Request a consultation
                    </option>
                    <option value="Hire Developers">Hire Developers</option>
                    <option value="Website Development">
                      Website Development
                    </option>
                    <option value="App Development">App Development</option>
                    <option value="API Integration Services">
                      API Integration Services
                    </option>
                    <option value="UI/UX project">UI/UX project</option>
                    <option value="SEO services">SEO services</option>
                    <option value="DevOps">DevOps</option>
                    <option value="Project Management">
                      Project Management
                    </option>
                    <option value="Project Estimation">
                      Project Estimation
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-12 mt-lg-0 mb-2">
                  <textarea
                    className="form-control"
                    placeholder="How can we help you?"
                    onChange={handleChange}
                    name="ProjectBrief"
                  />
                </div>
                <div className="row my-2">
                  <ReCAPTCHA
                    ref={ReCAPTCHAValue}
                    sitekey={process.env.REACT_APP_EMAIL_RECAPTCHA_SITE_KEY}
                  />

                  <span className="text-danger my-2">
                    {successMsg.reCaptchaErrMsg}
                  </span>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-12 text-center mb-3 m-auto">
                    <input
                      className="btn btn-primary phpModalBtn"
                      type="submit"
                      value="Send Message"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            {/* <div className="">
              <img
                className="img-fluid"
                src="/images/PersonalizedModalImg.png"
                alt=""
              />
            </div> */}

            <div className="text-center">
              <img
                src="/images/free-quote-hands.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div>
              <img
                src="/images/free-quote-image.svg"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="row my-md-5 my-5">
              <div className="col-lg-6 col-md-6 col-6 ">
                <img
                  src="/images/clutch-freequote.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-6">
                <img
                  src="/images/designrush-freequote.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="text-center h3">
            Thank you for reaching out to us. We will get back to you shortly!
          </div>
        </>
      )}
    </>
  );
}
