import { HeaderWithDynamicContent } from "./Components/HeaderWithDynamicContent";
import { TimeSlider } from "./Components/TimeSlider";
import $ from "jquery";
import TimeSliderMobile from "./Components/TimeSliderMobile";

export function TempTestPage(props) {
  var width = $(window).width();
  return (
    <>
      <HeaderWithDynamicContent
        BgColor=""
        Header={props.mainheader}
        SubHeader={props.subHeader}
        HeaderPadding={props.HeaderPadding}
        SubHeaderCss={props.SubHeaderCss}
        pt={props.pt}
        flag={props.flag}
        Content={
          width >= 991 ? (
            <>
              <TimeSlider
                TechnologyContent={props.TechName}
                TechnologyImages={props.TechContent}
              />
            </>
          ) : (
            <>
              <TimeSliderMobile
                TechnologyContent={props.TechName}
                TechnologyImages={props.TechContent}
              />
            </>
          )
        }
      />
    </>
  );
}
