import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Popups from "./Modals/Popups";
import PersonalizedActionModal from "./Modals/PersonalizedActionModal";
import { TimeSliderMpc } from "./Components/TimeSliderMpc";
import HelmetMetaTag from "./Components/HelmetMetaTag";

var techonologiesName = [
  {
    Technology: "Frontend/Backend",
  },
  {
    Technology: "APIs Used",
  },
  {
    Technology: "Database & Integration",
  },
];

var techstackImages = [
  {
    Technology: "Frontend/Backend",
    Images: [
      {
        Img: "/images/html-mpc.svg",
        alt: "HTML",
        title: "HTML",
      },
      {
        Img: "/images/css-mpc.svg",
        alt: "Css",
        title: "CSS",
      },
      {
        Img: "/images/bootstrap-mpc.svg",
        alt: "Bootstrap",
        title: "Bootstrap",
      },
      {
        Img: "/images/shopify-mpc.svg",
        alt: "Shopify",
        title: "Shopify",
      },
      {
        Img: "/images/laravel-mpc.svg",
        alt: "Laravel",
        title: "Laravel",
      },
      {
        Img: "/images/jquery-mpc.svg",
        alt: "jquery",
        title: "jquery",
      },
    ],
  },

  {
    Technology: "Api's used",
    Images: [
      {
        Img: "/images/rest-api.svg",
        alt: "Rest-api",
        title: "Rest-api",
      },
      {
        Img: "/images/fedex.svg",
        alt: "Fedex",
        title: "Fedex",
      },
      {
        Img: "/images/market-wagon.svg",
        alt: "Market-wagon",
        title: "Market-wagon",
      },
      {
        Img: "/images/uds.svg",
        alt: "United Delivery Service",
        title: "United Delivery Service",
      },
      {
        Img: "/images/now-delivery.svg",
        alt: "Now-delivery",
        title: "Now-delivery",
      },
    ],
  },

  {
    Technology: "Database & Integration",
    Images: [
      {
        Img: "/images/sql.svg",
        alt: "MySql",
        title: "MySql",
      },
      {
        Img: "/images/webhooks.svg",
        alt: "Webhooks",
        title: "Webhooks",
      },
      {
        Img: "/images/sendgrid-mpc.svg",
        alt: "Sendgrid",
        title: "Sendgrid",
      },
      {
        Img: "/images/twilio.svg",
        alt: "Twilio",
        title: "Twilio",
      },
      {
        Img: "/images/mailchimp.svg",
        alt: "Mailchimp",
        title: "Mailchimp",
      },
      {
        Img: "/images/zendesk.svg",
        alt: "Zendesk",
        title: "Zendesk",
      },
    ],
  },
];

// var frondenebackendimages = [
//   "/images/frontend-old-new-mobile.svg",
//   "/images/backend-old-new-mobile.svg",
// ];

export default function Ecommerce() {
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentImage, setCurrentImage] = useState(
    "/images/frontend-old-new.svg"
  );
  const [currentMobileImage, setCurrentMobileImage] = useState(
    "/images/frontend-old-new-mobile.svg"
  );
  const [contentHeight, setContentHeight] = useState(0);
  const [videoPlaying, setVideoPlaying] = useState(false);

  var backgroundImages = [
    "/images/background1.svg",
    "/images/background2.svg",
    "/images/background3.svg",
    "/images/background4.svg",
    "/images/background5.svg",
    "/images/background6.svg",
  ];

  var backgroundImagesMobile = [
    "/images/background1-mobile.svg",
    "/images/background2-mobile.svg",
    "/images/background3-mobile.svg",
    "/images/background4-mobile.svg",
    "/images/background5-mobile.svg",
    "/images/background6-mobile.svg",
  ];
  var mpcBackgroundImages =
    windowWidth >= 768 ? backgroundImages : backgroundImagesMobile;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const contentDiv = document.getElementById("content-div");
    const height = contentDiv.offsetHeight;
    setContentHeight(height);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => {
        return prevImage === "/images/frontend-old-new.svg"
          ? "/images/backend-old-new.svg"
          : "/images/frontend-old-new.svg";
      });

      setCurrentMobileImage((prevMobileImage) => {
        return prevMobileImage === "/images/frontend-old-new-mobile.svg"
          ? "/images/backend-old-new-mobile.svg"
          : "/images/frontend-old-new-mobile.svg";
      });
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="MPC Ecommerce Raw Pet Food Delivery | SprigStack"
        desc="My Pet Carnivore delivers raw, healthy pet food for dogs, cats & more. Get convenient online ordering, automated deliveries, & the fastest shipping options."
        canonical="https://sprigstack.com/portfolio/ecommerce-website-development-for-my-pet-carnivore"
        ogtitle="MPC Ecommerce Raw Pet Food Delivery | SprigStack"
        ogdesc="My Pet Carnivore delivers raw, healthy pet food for dogs, cats & more. Get convenient online ordering, automated deliveries, & the fastest shipping options."
        ogtype="website"
        ogurl="https://sprigstack.com/portfolio/ecommerce-website-development-for-my-pet-carnivore"
        ogimg="https://sprigstack.com/images/ecommerce.png"
      />
      <div className="container-fluid px-0">
        <div className="image-container position-relative">
          <div className="row">
            <Carousel fade interval={1000} indicators={false} controls={false}>
              {mpcBackgroundImages.map((imageUrl, index) => (
                <Carousel.Item
                  key={index}
                  style={{
                    height: windowWidth >= 768 ? `${contentHeight}px` : "auto",
                  }}
                >
                  <img className="" src={imageUrl} alt={`Image ${index + 1}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div
            id="content-div"
            className={`row position-absolute contentContainer pe-0 top-0`}
          >
            <div className="col-md-6 pb-4">
              <h1 className={`ecommerceHeader mt-5 px-lg-0 px-md-0 px-5`}>
                Pet Food E-commerce with Automated Delivery System
              </h1>
              <div className="pt-3">
                <label className={`clientText mb-1 px-5 px-md-0`}>
                  About Client:
                </label>
                <p className={`descText text-justify px-5 px-md-0`}>
                  &nbsp;sells raw pet food for dogs, cats, and other animals,
                  believing it to be the optimal healthy diet for them. Bringing
                  raw carnivorous diets with convenient online delivery for pet
                  parents who care.
                </p>
              </div>
              <div className="row mt-4 d-flex justify-content-center">
                <div className="col-md-4 col-8 my-2">
                  <div className="ecommerceCard p-2">
                    <span>
                      <label className="ecommerceCardText">500+</label>
                      <br />
                      Daily Orders
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-8 my-2">
                  <div className="ecommerceCard p-2">
                    <span>
                      <label className="ecommerceCardText">10000+</label>
                      <br />
                      Happy Customers
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-8 my-2">
                  <div className="ecommerceCard p-2">
                    <span>
                      <label className="ecommerceCardText">10+</label>
                      <br />
                      Couriers Integrated
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-end px-0">
              <img
                src="/images/ecommerce.png"
                className="img-fluid"
                style={{
                  height: windowWidth >= 768 ? `${contentHeight}px` : "345px",
                }}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="text-center technology mt-md-5 mt-4">
            <h2 className="technology">Technology Stack</h2>
            <div>
              <img
                src="/images/hrImg.png"
                alt=""
                className="img-fluid mb-md-5 mb-2"
              />
            </div>
          </div>

          <div className="">
            <TimeSliderMpc
              TechnologyContent={techonologiesName}
              TechnologyImages={techstackImages}
            />
          </div>
        </div>

        <div className="p-2 client-requirements py-md-2 py-2">
          <div className="text-center technology">
            <div className="p-2 client-requirements mt-3">
              <div className="text-center technology mt-md-5">
                <h2 className="technology">Client Requirements</h2>
                <div>
                  <img
                    src="/images/hrImg.png"
                    alt=""
                    className="img-fluid mb-md-5 mb-3"
                  />
                </div>
              </div>
              <div className="text-center">
                <LazyLoadImage
                  effect="blur"
                  src={
                    windowWidth >= 991
                      ? "/images/client-requirements.svg"
                      : "/images/client-requirements-mobile.svg"
                  }
                  className="img-fluid ps-md-5 p-1"
                  alt="client-requirements"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="text-center technology mt-md-5 mt-4 mobile">
          <h2 className="technology">SprigStack Solution</h2>
          <div>
            <img
              src="/images/hrImg.png"
              alt=""
              className="img-fluid mb-md-5 mb-3"
            />
          </div>
        </div>
        <div className="row px-6 mt-4 d-flex align-items-center">
          <div className="col-md-6">
            <LazyLoadImage
              effect="blur"
              src="/images/sprigstack-solution.svg"
              className="img-fluid"
              alt="sprigstack-solution"
            />
          </div>

          <div className="col-md-6 mt-5 p-md-2">
            <div className="desktop">
              <h2 className="technology">SprigStack Solution</h2>
              <div>
                <img
                  src="/images/hrImg.png"
                  alt=""
                  className="img-fluid mb-md-5 mb-3"
                />
              </div>
            </div>
            <div className="col-md-6 mt-2 d-none">
              <LazyLoadImage
                effect="blur"
                src="/images/sprigstack-solution.svg"
                className="img-fluid"
                alt="sprigstack-solution"
              />
            </div>
            <p className="ss-para text-justify">
              <p>
                SprigStack transformed MPC online store with a custom solution
                designed for growth, which included migrating from WordPress to
                Shopify.
              </p>
              <p className="mt-2">
                We improved the user experience by incorporating an upgraded
                modern interface, integrating a new delivery partner,
                implementing innovative delivery solutions, and automating the
                purchasing process.
              </p>
              <p className="mt-2">
                This internal efficiency translates to quicker order processing,
                faster response times for customer inquiries, and an overall
                more positive customer experience.
              </p>
            </p>
          </div>
        </div>

        <div className="text-center technology mt-md-5 mt-2">
          <h2 className="technology">Major UI & UX Improvements </h2>
          <div>
            <img
              src="/images/hrImg.png"
              alt=""
              className="img-fluid mb-md-5 mb-3"
            />
          </div>

          {windowWidth >= 768 ? (
            <>
              {/* <img
                src="/images/frontend-old-new.svg"
                alt="Default Image"
                className="img-fluid"
                onMouseOver={(e) =>
                  (e.currentTarget.src = "/images/backend-old-new.svg")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.src = "/images/frontend-old-new.svg")
                }
              /> */}
              <img
                src={currentImage}
                alt="Ui improvements"
                className="img-fluid"
              />
            </>
          ) : (
            <>
              {/* <Carousel interval={2000} indicators={false} controls={false}>
                {frondenebackendimages.map((imageUrl, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="img-fluid mt-3"
                      src={imageUrl}
                      alt={`Image ${index + 1}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel> */}
              <img
                src={currentMobileImage}
                alt="Ui improvements"
                className="img-fluid"
              />
            </>
          )}
        </div>

        <div className="text-center technology mt-md-5 mt-4">
          <h2 className="technology">Automated Order Fulfillment</h2>
          <div>
            <img
              src="/images/hrImg.png"
              alt=""
              className="img-fluid mb-md-5 mb-3"
            />
          </div>
          <div className="row mt-3 position-relative m-0">
            <div className="col-md-6 p-0">
              <LazyLoadImage
                effect="blur"
                src="/images/then.png"
                className="img-fluid"
                alt="then"
              />
            </div>

            <div className="col-md-6 p-0">
              <LazyLoadImage
                effect="blur"
                src="/images/now.png"
                className="img-fluid"
                alt="now"
              />
            </div>
            <div className="col-md-12 position-absolute top-50 start-50 translate-middle hide-arrows">
              <LazyLoadImage
                src="/images/then-now-arrows.png"
                alt="Arrow"
                className="arrow-animation"
              />
            </div>
          </div>
        </div>

        <div
          className="mt-4"
          style={{
            backgroundImage: 'url("/images/img-bg.png")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
          }}
        >
          <div className="row mt-4 d-flex align-items-center">
            <div className="col-md-5 my-2 p-3 p-md-0 text-md-end text-center">
              <img
                src="/images/mpc-animation.gif"
                className="img-fluid mpc-animation"
                alt=""
              />
            </div>
            <div className="col-md-7 text-center custom-padding">
              <label className="mpc-idea mt-3">
                Want a Unique Online Store?
              </label>
              <br />
              <div className="mpc-idea">
                Let's Build Your Custom Ecommerce Website.
              </div>
              <br />
              <button
                className="btn btn mpcBtn mt-4 px-4"
                onClick={() => {
                  setShow(true);
                }}
              >
                GET A FREE CONSULTATION
                <img
                  className="img-fluid ms-2"
                  src="/images/personalizedWhiteArrow.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>

        <div className="text-center p-5 mt-4 purchasing-mpc">
          <h2 className="technology">
            Purchasing Tools & Smart Delivery Solution
          </h2>
          <div>
            <img
              src="/images/hrImg.png"
              alt=""
              className="img-fluid mb-md-5 mb-3"
            />
          </div>
          <br />

          <LazyLoadImage
            effect="blur"
            src={
              windowWidth >= 991
                ? "/images/automated-notifications.svg"
                : "/images/automated-notifications-mobile.svg"
            }
            className="img-fluid ps-md-5 p-1"
            alt="automated-notifications"
          />
        </div>

        <div className="text-center technology my-md-4 my-4 p-md-3 position-relative bgF7F7F7">
          <h2 className="technology">What our client say..!</h2>
          <div>
            <img
              src="/images/hrImg.png"
              alt=""
              className="img-fluid mb-md-5 mb-3"
            />
          </div>

          <div className="row mt-1 mt-md-0 px-5">
            <div className="slide-0 text-center w-20 true">
              <span className="text-slide">
                <div>
                  <img src="/images/DummyAvtar.png" alt="Josh" title="Our Client Josh" className="img-fluid h-13 mySlides" style={{ borderRadius: '50%' }} />
                  <div className="active">
                    <h4 className="fontPoppins mt-3">Josh</h4>
                    <h6 className="fontPoppins">General Manager at Tellos</h6>
                    <img src="/images/video_icon.png" alt="" className="clickable false" onClick={() => setVideoPlaying(true)} />
                    <div className="clickable videoFeedbackLink false">Watch Video Feedback</div>
                  </div>
                </div>
              </span>
            </div>
            {/* <div className="col-md-3 d-flex align-items-center adjsutImage position-relative divhover">
              <LazyLoadImage
                effect="blur"
                src="/images/client-mpc.svg"
                className="img-fluid mpcImg"
                alt=""
              />
              {!videoPlaying && (
                <div className="play-button">
                  <LazyLoadImage
                    effect="blur"
                    src="/images/play-button-mpc.png"
                    className="img-fluid"
                    alt="play"
                    onClick={() => setVideoPlaying(true)}
                  />
                </div>
              )}
            </div> */}

            {/* <div className="col-md-9 mpcClientDiv">
              <div className="mpc-blockquote">
                <label className="mpcClientTxt no-after-quote text-justify mt-2">
                  Ronak and his team handles multiple projects for our telecom
                  channel partner. They develop and maintain our frontend part
                  where we sell products online on desktop and mobile.They also
                  take care of backend and database interactions. Some of our
                  existing softwares shares the usage of same database so
                  SprigStack team makes sure everything is in order.They have
                  been part of our IT team for more than 2 years now and we are
                  very happy with their services.
                </label>
                <div className="mpcClientTxt fw-bold text-justify mt-2">
                  <p>
                    Josh
                    <br />
                    General Manager at Tellos
                  </p>
                </div>
              </div>
            </div> */}

            {videoPlaying && (
              <div className="video-wrapper col-md-12">
                <div className="video-container">
                  <div
                    className="videoClose"
                    onClick={() => setVideoPlaying(false)}
                  >
                    X
                  </div>
                  <iframe
                    className="video-iframe"
                    src="https://www.youtube.com/embed/suR2KwSPuxc?si=hywGRt1TpTUE5GZi"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <Popups
          show={show}
          setShow={setShow}
          title="FREE CONSULTATION"
          size="lg"
        >
          <PersonalizedActionModal />
        </Popups>
      </div>
    </React.Fragment>
  );
}
