import React, { useEffect, useState } from "react";
import { TextandHoverComponenet } from "./Components/TextandHoverComponenet";
import Popups from "./Modals/Popups";
import PersonalizedActionModal from "./Modals/PersonalizedActionModal";
import ImageCarousel from "./imageslider";
import ImageCarousel1 from "./animationA2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Carousel } from "react-bootstrap";
import $ from "jquery";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import { HeaderWithDynamicContent } from "./Components/HeaderWithDynamicContent";
export default function A2portfolio(props) {
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState("");
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });
  const [imageHeight, setimageHeight] = useState("");
  useEffect(() => {
    const interval = setTimeout(() => {
      setimageHeight($(".img1").height());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  return (
    <React.Fragment>
      <div>
        <HelmetMetaTag
          titleName="CRM Development for Businesses - AddressTwo | SprigStack"
          desc="AddressTwo CRM Solutions for Small Businesses. SprigStack developed this CRM with Angular & Asp.net core APIs & Google, Outlook, & other integrations."
          canonical="https://sprigstack.com/portfolio/custom-crm-development-for-address-two"
          ogtitle="CRM Development for Businesses - AddressTwo | SprigStack"
          ogdesc="AddressTwo CRM Solutions for Small Businesses. SprigStack developed this CRM with Angular & Asp.net core APIs & Google, Outlook, & other integrations."
          ogtype="website"
          ogurl="https://sprigstack.com/portfolio/custom-crm-development-for-address-two"
          ogimg="https://sprigstack.com/images/AddressTwoMockup.png"
        />
        {windowWidth >= 768 ? (
          <>
            <div className="row d-flex align-items-center justify-content-center p-5 pt-3 bgF7F7F7">
              <div className="col-1">
                <img
                  className="img-fluid"
                  src="/images/A2headerleft.png"
                  alt=""
                />
              </div>
              <div className="col-7 p-0">
                <div className="A2Header text-center">
                  <h1>
                    Custom CRM Development for <label>AddressTwo</label>
                  </h1>
                </div>
              </div>
              <div className="col-1">
                <img
                  className="img-fluid"
                  src="/images/A2headerright.png"
                  alt=""
                />
              </div>
            </div>
            <ImageCarousel1 />
            <div className="row px-lg-6">
              <div className="col-lg-4 col-md-4 bgF3F3F3 border20 my-5 d-flex align-items-center justify-content-center mlr">
                <div className="row a2content w-100">
                  <div className="col-6">
                    <label>5M+</label> <br />
                    Daily Emails Delivered
                  </div>
                  <div className="col-6">
                    <label>100+</label> <br />
                    Monthly New Signups
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-6 bgF3F3F3 border20 my-5 ">
                <div className="rowType pt-md-3 text-center1 justify-content-center border20">
                  <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                    <TextandHoverComponenet
                      alt="Asp.net-core"
                      title="Asp.net-core"
                      imgSrc="asp_net_core"
                      hoverSrc="asp_net_core_hover"
                    />
                  </div>
                  <div className="wid-15">
                    <TextandHoverComponenet
                      alt="Angular Js"
                      title="Angular Js logo"
                      imgSrc="Angular_Fade"
                      hoverSrc="Angular_hover"
                    />
                  </div>
                  <div className="wid-15">
                    <TextandHoverComponenet
                      alt="MsSQl"
                      title="MsSQl"
                      imgSrc="Ms_sql"
                      hoverSrc="Ms_sql_hover"
                    />
                  </div>
                  <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                    <TextandHoverComponenet
                      alt="css"
                      title="css logo"
                      imgSrc="css_fade"
                      hoverSrc="css_fade_hover"
                    />
                  </div>
                  <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                    <TextandHoverComponenet
                      alt="html"
                      title="html logo"
                      imgSrc="html_fade"
                      hoverSrc="html_fade_hover"
                    />
                  </div>
                  <div className="wid-15">
                    <TextandHoverComponenet
                      alt="Bootstrap"
                      title="Bootstrap logo"
                      imgSrc="bootstrap_fade"
                      hoverSrc="bootstrap_fade_hover"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-lg-6">
              <div className="col-lg-7 col-md-6 a2overview position-relative">
                <h2 className="mx-5 mb-5">Project Overview</h2>
                <div className="a2overviewText">Overview</div>
                <p className="pe-5">
                  <br />
                  AddressTwo, a CRM software for small businesses, acts as a
                  dedicated assistant for managing contacts, leads, and sales
                  priorities. Affordable and user-friendly, it simplifies
                  marketing and enhances customer relationships.
                  <br />
                  <br /> In response to client needs, we aimed to boost the
                  scalability of their CRM system, prioritizing security,
                  technology innovation, and sticking to industry best
                  practices. Challenges included migrating features, updating
                  outdated technology, and addressing performance issues due to
                  increased user numbers. It provides a streamlined solution,
                  ensuring efficiency, security, and growth for small
                  businesses.
                </p>
              </div>
              <div className="col-lg-5 col-md-6 text-center">
                <img
                  className="img-fluid"
                  src="/images/A2sideimage1.png"
                  alt=""
                />
              </div>
            </div>
            <div className="row px-lg-6 my-lg-5">
              <div className="col-lg-5 col-md-6 text-start">
                <img
                  className="img-fluid"
                  src="/images/A2sideimage2.png"
                  alt=""
                />
              </div>
              <div className="col-lg-7 col-md-6 a2overview position-relative">
                <h2 className="mx-5 mb-5">SprigStack Solution</h2>
                <div className="a2overviewText">SprigStack</div>
                <p className="pe-5">
                  <br />
                  <br />
                  To fulfill client expectations and to tackle challenges in CRM
                  development, our team developed a robust CRM solution.
                  Utilizing Angular, ASP.NET Core APIs, and SQL, we introduced
                  features for seamless integration, automation, and payment
                  gateways. The updated theme aligns with market trends while
                  addressing challenges such as email scheduling and simplifying
                  email marketing for a more user-friendly CRM.
                </p>
              </div>
            </div>
            <div className="row px-lg-6 my-5">
              <div className="col-12 col-md-12 py-md-4 text-center pt-md-0">
                <h2 className="a2lineheader  setlineheight recenthdpd mb-md-0">
                  The Simple CRM For Small Buisness
                </h2>
                <img className="img-fluid" src="/images/hrImg.png" alt="" />
              </div>
              <div className="col-12 row">
                <div className="col-3 text-center crmsection px-5">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        className="img-fluid"
                        src="/images/crm1.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3">
                      <p>CRM Contact Manager</p>
                    </div>
                  </div>
                </div>
                <div className="col-3 text-center crmsection px-5">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        className="img-fluid"
                        src="/images/crm2.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3">
                      <p>Reporting form your CRM</p>
                    </div>
                  </div>
                </div>
                <div className="col-3 text-center crmsection px-5">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        className="img-fluid"
                        src="/images/crm3.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3">
                      <p>CRM with Email Marketing</p>
                    </div>
                  </div>
                </div>
                <div className="col-3 text-center crmsection px-5">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        className="img-fluid"
                        src="/images/crm4.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3">
                      <p>Mobile and iPad CRM</p>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-4 text-center crmsection px-5">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        className="img-fluid"
                        src="/images/crm5.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3">
                      <p>CRM Activity Tracking</p>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-4 text-center crmsection px-5">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        className="img-fluid"
                        src="/images/crm6.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3">
                      <p>Task & Reminder</p>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-4 text-center crmsection px-5">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        className="img-fluid"
                        src="/images/crm7.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3">
                      <p>CRM with Direct Mail</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-lg-6 a2Features text-center position-relative">
              <h2>Features</h2>
              <div className="a2FeaturesText">Features</div>
            </div>
            <div className="row px-lg-6 my-5 pt-5 a2mainsection">
              <div className="col-lg-5 col-md-6">
                <img
                  className="img-fluid"
                  src="/images/A2sideimage8.png"
                  alt=""
                />
              </div>
              <div className="col-lg-7 col-md-6 position-relative">
                <h3 className="px-5 ">Campaign and Automation</h3>
                <div className="sidesectionimage1">Campaign</div>
                <p className="pe-3 py-5">
                  <br />
                  <b>Schedule Mail:</b> Plan and set the timing for email
                  distribution.
                  <br />
                  <br />
                  <b> Decide Frequency:</b> Choose the frequency for sending out
                  campaign emails.
                  <br />
                  <br />
                  <b>Automate Mail Sending:</b> Implement automation for
                  streamlined and timely email dispatch in campaigns.
                </p>
              </div>
            </div>
            <div className="row px-lg-6 my-5 pt-5 a2mainsection">
              <div className="col-lg-7 col-md-6 position-relative">
                <h3 className="px-5">Customized Queries</h3>
                <div className="sidesectionimage1">Queries</div>
                <p className="pe-3 py-5">
                  <br />
                  Locate contacts with specific characteristics by applying
                  custom filters, generate a list based on the criteria, and
                  organize them accordingly for efficient data management.
                  <br />
                  <br />
                  <b>Search Bar:</b> Users can search for any contact, details,
                  account, email, or phone number.
                </p>
              </div>
              <div className="col-lg-5 col-md-6 text-end px-4">
                <img
                  className="img-fluid"
                  src="/images/A2sideimage7.png"
                  alt=""
                />
              </div>
            </div>
            <div className="row px-lg-6 my-5 pt-5 a2mainsection">
              <div className="col-lg-5 col-md-6">
                <img
                  className="img-fluid"
                  src="/images/A2sideimage8.png"
                  alt=""
                />
              </div>
              <div className="col-lg-7 col-md-6 position-relative">
                <h3 className="px-5">Virtual Admin</h3>
                <div className="sidesectionimage1">Admin</div>
                <p className="pe-3 py-5">
                  <br />
                  <b>Custom Fields:</b> View all custom field data across
                  features.
                  <br />
                  <br />
                  <b>Daily Task List:</b> Assign and view tasks on a daily
                  basis, accessible account-wise.
                  <br />
                  <br />
                  <b>Import Contacts and Data:</b> Save mappings for efficient
                  data import.
                </p>
              </div>
            </div>
            <div className="row px-lg-6 my-5 pt-5 a2mainsection">
              <div className="col-lg-7 col-md-6 position-relative">
                <h3 className="px-0">Marketing Dashboard and Statistics</h3>
                <div className="sidesectionimage1">Marketing</div>
                <p className="pe-3 py-2">
                  <b>Template Editor:</b> Easily customize templates for various
                  purposes.
                  <br />
                  <br />
                  <b>Preview:</b> Review a preview of the template before
                  finalizing.
                  <br />
                  <br />
                  <b>Mail Merge Fields:</b> Personalize content by using mail
                  merge fields.
                  <br />
                  <br />
                  <b>Advanced Editor:</b> Access advanced editing options for
                  complex customization.
                  <br />
                  <br />
                  <b>Share Template:</b> Collaborate efficiently by sharing
                  templates with others.
                  <br />
                  <br />
                  <b>Statistics:</b> The dashboard provides detailed graphs of
                  tasks and activity statistics.
                </p>
              </div>
              <div className="col-lg-5 col-md-6 text-end px-4">
                <img
                  className="img-fluid"
                  src="/images/A2sideimage4.jpg"
                  alt=""
                />
              </div>
            </div>

            <div className="px-lg-6 py-5 ms-2 me-4">
              <div className="row d-flex align-items-center bg-a2">
                <div className="col-6 px-5 a2fontbg">
                  <h2>
                    Your business, your way! Custom CRM Development brings your
                    ideas to life with precision and impact.
                  </h2>
                  <p className="py-3"></p>
                  <button
                    className="btn-a2 p-3"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Tell us your goals
                  </button>
                </div>
                <div className="col-6 py-5">
                  <img
                    className="bgEFF0F6 img-fluid"
                    src="/images/CRMDetails.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row px-lg-6 a2Features text-center position-relative ">
              <h2>Mobile friendly</h2>
              <div className="a2FeaturesText">Mobile Users</div>
              <div className="row position-relative">
                <ImageCarousel />
              </div>
            </div>
            <div className="row px-lg-6 a2Features text-center position-relative pb-5">
              <h2>Data Sync</h2>
              <div className="a2FeaturesText">Data Sync</div>
            </div>
            <div className="row pe-5 py-5">
              <img className="img-fluid" src="/images/DataSync.png" alt="" />
            </div>

            <HeaderWithDynamicContent
              HrLine={props.isHrLine}
              BgColor=""
              Header="What our clients think about us!"
              HeaderPadding="majorBenefitHeading"
              pt={true}
            />
            <div className="px-6 pb-5 text-center mt-4">
              <img
                src="/images/address2-client.svg"
                alt="client"
                style={{ borderRadius: "50%" }}
              />
              <h4 className="fontPoppins mt-3">Jason Barresi</h4>
              <h6 className="fontPoppins">Director at Address Two</h6>
              {/* <img src="/images/video_icon.png" alt="" className={`clickable`}></img> */}
              <h6 className="custom-blockquote fontPoppins text-justify mt-3">
                We've been working with SprigStack for several years now and
                couldn't be happier with the partnership. As a result, we use
                them as our only third-party agency for this and new projects
                across our subsidiaries.
              </h6>
            </div>
          </>
        ) : (
          <>
            <div className="row d-flex align-items-center justify-content-center pt-3 px-5 bgF7F7F7">
              <div className="col-12">
                <div className="A2Header text-center">
                  Custom CRM Development for <label>AddressTwo</label>
                </div>
              </div>
            </div>
            <Carousel
              className="bgF7F7F7 px-4"
              indicators={false}
              interval={5000}
              controls={false}
            >
              <Carousel.Item interval={5000}>
                <div className="A2mobileCarousel">
                  <LazyLoadImage
                    className="img-fluid ms-md-5"
                    src="/images/A2image1.png"
                    alt=""
                    height={"546px"}
                    width={"786px"}
                  />
                  <div className="A2Header">
                    <h5>Lead MANAGMENT</h5>
                    <p>
                      Easily manage contacts and tasks and follow up to predict
                      lead scores based on their responses. Addy simplifies
                      contact checking, while a search bar and action center
                      offer quick access to details and upcoming tasks.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <div className="A2mobileCarousel">
                  <LazyLoadImage
                    className="img-fluid ms-md-5"
                    src="/images/A2image2.png"
                    alt=""
                    height={"546px"}
                    width={"786px"}
                  />
                  <div className="A2Header">
                    <h5>EMAIL MARKETING</h5>
                    <p>
                      Customize templates easily, preview, use trigger links,
                      A/B testing, craft email campaigns, analyze performance
                      and collaborate by sharing templates.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <div className="A2mobileCarousel">
                  <LazyLoadImage
                    className="img-fluid ms-md-5"
                    src="/images/A2image3.png"
                    alt=""
                    height={"546px"}
                    width={"786px"}
                  />
                  <div className="A2Header">
                    <h5>Third Party Integration</h5>
                    <p>
                      CRM third-party integration enhances efficiency by
                      connecting the software with external tools, streamlining
                      processes through automation.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <div className="A2mobileCarousel">
                  <LazyLoadImage
                    className="img-fluid ms-md-5"
                    src="/images/A2image4.png"
                    alt=""
                    height={"546px"}
                    width={"786px"}
                  />
                  <div className="A2Header">
                    <h5>White Label</h5>
                    <p>
                      Custom CRM to fit your business, adjusting features and
                      appearance according to customer preferences, with added
                      customization for specific industries.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
            <div className="row px-lg-6">
              <div className="col-lg-4 col-md-4 bgF3F3F3  d-flex align-items-center justify-content-center mlr">
                <div className="row a2content py-2 w-100">
                  <div className="col-6">
                    <label>5M+</label> <br />
                    Daily Emails Delivered
                  </div>
                  <div className="col-6">
                    <label>100+</label> <br />
                    Monthly New Signups
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-6 border20 mb-4 pt-4 px-4">
                <div className="rowType pt-md-3 text-center1 justify-content-center border20">
                  <div className="wid-15">
                    <TextandHoverComponenet
                      alt="Asp.net-core"
                      title="Asp.net-core"
                      imgSrc="asp_net_core"
                      hoverSrc="asp_net_core_hover"
                    />
                  </div>
                  <div className="wid-15">
                    <TextandHoverComponenet
                      alt="Angular Js"
                      title="Angular Js logo"
                      imgSrc="Angular_Fade"
                      hoverSrc="Angular_hover"
                    />
                  </div>
                  <div className="wid-15">
                    <TextandHoverComponenet
                      alt="MsSQl"
                      title="MsSQl"
                      imgSrc="Ms_sql"
                      hoverSrc="Ms_sql_hover"
                    />
                  </div>
                  <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                    <TextandHoverComponenet
                      alt="css"
                      title="css logo"
                      imgSrc="css_fade"
                      hoverSrc="css_fade_hover"
                    />
                  </div>
                  <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                    <TextandHoverComponenet
                      alt="html"
                      title="html logo"
                      imgSrc="html_fade"
                      hoverSrc="html_fade_hover"
                    />
                  </div>
                  <div className="mt-3 mt-md-0 mt-lg-0 wid-15">
                    <TextandHoverComponenet
                      alt="Bootstrap"
                      title="Bootstrap logo"
                      imgSrc="bootstrap_fade"
                      hoverSrc="bootstrap_fade_hover"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-lg-6">
              <div className="col-lg-7 col-md-6 a2overview position-relative">
                <h2 className="mx-5 mb-5 text-center">Project Overview</h2>
                <div className="a2overviewText">Overview</div>
                <img
                  className="img-fluid px-4"
                  src="/images/A2sideimage1.png"
                  alt=""
                />
                <p className="px-4 m-0">
                  <br />
                  AddressTwo, a CRM software for small businesses, acts as a
                  dedicated assistant for managing contacts, leads, and sales
                  priorities. Affordable and user-friendly, it simplifies
                  marketing and enhances customer relationships.
                  <br />
                  <br />
                  In response to client needs, we aimed to boost the scalability
                  of their CRM system, prioritizing security, technology
                  innovation, and sticking to industry best practices.
                  Challenges included migrating features, updating outdated
                  technology, and addressing performance issues due to increased
                  user numbers. It provides a streamlined solution, ensuring
                  efficiency, security, and growth for small businesses.
                </p>
              </div>
            </div>
            <div className="row px-lg-6 pt-5">
              <div className="col-lg-7 col-md-6 a2overview position-relative">
                <h2 className="mx-5 mb-5 text-center">SprigStack Solution</h2>
                <div className="a2overviewText1">SprigStack</div>
                <img
                  className="img-fluid px-4"
                  src="/images/A2sideimage2.png"
                  alt=""
                />
                <p className="px-4 m-0">
                  <br />
                  To fulfill client expectations and to tackle challenges in CRM
                  development, our team developed a robust CRM solution.
                  Utilizing Angular, ASP.NET Core APIs, and SQL, we introduced
                  features for seamless integration, automation, and payment
                  gateways. The updated theme aligns with market trends while
                  addressing challenges such as email scheduling and simplifying
                  email marketing for a more user-friendly CRM.
                </p>
              </div>
            </div>
            <div className="row px-lg-6 mt-5 mb-4 px-4">
              <div className="col-12 col-md-12 px-0 py-md-4 text-center pt-md-0">
                <h2 className="a2lineheader  setlineheight recenthdpd mb-0">
                  The Simple CRM For Small Buisness
                </h2>
                <img className="img-fluid" src="/images/hrImg.png" alt="" />
              </div>
              <div className="col-12 row d-content">
                <div className="col-6 mt-4 text-center crmsection px-4">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        style={{ height: "34px" }}
                        className="img-fluid"
                        src="/images/crm1.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3 px-0">
                      <p>CRM Contact Manager</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4 text-center crmsection px-4">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        style={{ height: "34px" }}
                        className="img-fluid"
                        src="/images/crm2.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3 px-0">
                      <p>Reporting form your CRM</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 text-center mt-4 crmsection px-4">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        style={{ height: "34px" }}
                        className="img-fluid"
                        src="/images/crm3.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3 px-0">
                      <p>CRM with Email Marketing</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 text-center mt-4 crmsection px-4">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        style={{ height: "34px" }}
                        className="img-fluid"
                        src="/images/crm4.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3 px-0">
                      <p>Mobile and iPad CRM</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4 text-center crmsection px-4">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        style={{ height: "34px" }}
                        className="img-fluid"
                        src="/images/crm5.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3 px-0">
                      <p>CRM Activity Tracking</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4 text-center crmsection px-4">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        style={{ height: "34px" }}
                        className="img-fluid"
                        src="/images/crm6.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3 px-0">
                      <p>Task & Reminder</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4 text-center crmsection px-4">
                  <div className="row hoverBorder">
                    <div className="col-12 pt-3">
                      <img
                        style={{ height: "34px" }}
                        className="img-fluid"
                        src="/images/crm7.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-12 py-3 px-0">
                      <p>CRM with Direct Mail</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-lg-6 a2Features text-center position-relative">
              <h2>Features</h2>
              <div className="a2FeaturesText">Features</div>
            </div>
            <hr className="a2divider mx-4"></hr>
            <div className="row px-lg-6 mt-3 a2mainsection position-relative">
              <div className="col-lg-5 col-md-6 text-center">
                <h3 className="px-2 mb-5 pb-3">Campaign and Automation</h3>
                <div className="sidesectionimage1">Campaign</div>
                <img
                  className="img-fluid px-4"
                  src="/images/A2sideimage1.jpg"
                  alt=""
                />
                <p className="px-4 pt-4">
                  <b>Schedule Mail:</b> Plan and set the timing for email
                  distribution.
                  <br />
                  <br />
                  <b> Decide Frequency:</b> Choose the frequency for sending out
                  campaign emails.
                  <br />
                  <br />
                  <b>Automate Mail Sending:</b> Implement automation for
                  streamlined and timely email dispatch in campaigns.
                </p>
              </div>
            </div>
            <div className="row px-lg-6 mt-3 a2mainsection position-relative">
              <div className="col-lg-5 col-md-6 text-center">
                <h3 className="px-5 mb-5 pb-3">Customized Queries</h3>
                <div className="sidesectionimage3">Queries</div>
                <img
                  className="img-fluid px-4"
                  src="/images/A2sideimage7.png"
                  alt=""
                />
                <p className="px-4 pt-4">
                  Locate contacts with specific characteristics by applying
                  custom filters, generate a list based on the criteria, and
                  organize them accordingly for efficient data management.
                  <br />
                  <br />
                  <b>Search Bar:</b> Users can search for any contact, details,
                  account, email, or phone number.
                </p>
              </div>
            </div>
            <div className="row px-lg-6 mt-3 a2mainsection position-relative">
              <div className="col-lg-5 col-md-6 text-center">
                <h3 className="px-5 mb-5 pb-3">Virtual Admin</h3>
                <div className="sidesectionimage4">Admin</div>
                <img
                  className="img-fluid px-4"
                  src="/images/A2sideimage3.jpg"
                  alt=""
                />
                <p className="px-4 pt-4">
                  <b>Custom Fields:</b> View all custom field data across
                  features.
                  <br />
                  <br />
                  <b>Daily Task List:</b> Assign and view tasks on a daily
                  basis, accessible account-wise.
                  <br />
                  <br />
                  <b>Import Contacts and Data:</b> Save mappings for efficient
                  data import.
                </p>
              </div>
            </div>
            <div className="row px-lg-6 mt-3 a2mainsection position-relative">
              <div className="col-lg-5 col-md-6 text-center">
                <h3 className="px-5 mb-5 pb-3">
                  Marketing Dashboard and Statistics
                </h3>
                <div
                  className="sidesectionimage3"
                  style={{ left: "85px", fontSize: "50px" }}
                >
                  Marketing
                </div>
                <img
                  className="img-fluid px-4"
                  src="/images/A2sideimage4.jpg"
                  alt=""
                />
                <p className="px-4 pt-4">
                  <b>Template Editor:</b> Easily customize templates for various
                  purposes.
                  <br />
                  <br />
                  <b>Preview:</b> Review a preview of the template before
                  finalizing.
                  <br />
                  <br />
                  <b>Mail Merge Fields:</b> Personalize content by using mail
                  merge fields.
                  <br />
                  <br />
                  <b>Advanced Editor:</b> Access advanced editing options for
                  complex customization.
                  <br />
                  <br />
                  <b>Share Template:</b> Collaborate efficiently by sharing
                  templates with others.
                  <br />
                  <br />
                  <b>Statistics:</b> The dashboard provides detailed graphs of
                  tasks and activity statistics.
                </p>
              </div>
            </div>

            <div className="px-lg-6 mt-5 px-4 bgF7F7F7 bg-a2">
              <div className="col-12 py-4">
                Your business, your way! Custom CRM Development brings your
                ideas to life with precision and impact.
              </div>
              <div className="col-12">
                <img
                  className="img-fluid"
                  src="/images/bg-a2mobile.svg"
                  alt=""
                />
              </div>
              <div className="col-12 py-4">
                <button
                  className="btn-a2 p-3"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Tell us your goals
                </button>
              </div>
            </div>
            <div className="row px-lg-6 mt-5 a2Features text-center ">
              <div className="col-12 position-relative ">
                <h2>Mobile friendly</h2>
                <div
                  className="a2FeaturesText"
                  style={{ fontSize: "45px", top: "0px", left: "70px" }}
                >
                  Mobile Users
                </div>
              </div>
              <div className="col-12 position-relative">
                <div className="row position-relative py-5 px-4">
                  <ImageCarousel />
                </div>
              </div>
            </div>
            <div className="row px-lg-6 a2Features text-center position-relative pb-5">
              <h2>Data Sync</h2>
              <div className="a2FeaturesText" style={{ top: "0px" }}>
                Data Sync
              </div>
            </div>
            <div className="row px-4 py-4">
              <img
                className="img-fluid"
                src="/images/DataSyncMobile.png"
                alt=""
              />
            </div>
            <HeaderWithDynamicContent
              HrLine={props.isHrLine}
              BgColor=""
              Header="What our clients think about us!"
              HeaderPadding="majorBenefitHeading"
              pt={true}
            />
            <div className="px-6 pb-5 text-center mt-4">
              <img
                src="/images/address2-client.svg"
                alt="client"
                style={{ borderRadius: "50%" }}
              />
              <h4 className="fontPoppins mt-3">Jason Barresi</h4>
              <h6 className="fontPoppins">Director at Address Two</h6>
              {/* <img src="/images/video_icon.png" alt="" className={`clickable`}></img> */}
              <h6 className="custom-blockquote fontPoppins text-justify mt-3">
                We've been working with SprigStack for several years now and
                couldn't be happier with the partnership. As a result, we use
                them as our only third-party agency for this and new projects
                across our subsidiaries.
              </h6>
            </div>
          </>
        )}
      </div>
      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">
        <PersonalizedActionModal />
      </Popups>
    </React.Fragment>
  );
}
