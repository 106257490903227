import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Getcall, { PostCall } from "./ApiRequest";
import Popups from "../../Modals/Popups";
import PersonalizedActionModal from "../../Modals/PersonalizedActionModal";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { Spinner } from "react-bootstrap";
import HelmetMetaTag from "../../Components/HelmetMetaTag";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Blogpostpage() {
  const CUSTOM_URL = process.env.REACT_APP_CUSTOM_URL;
  const param = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const MoveToTop = () => {
    document.documentElement.scrollTo(0, 0);
  };
  const [scrollProgress, setScrollProgress] = useState(0);
  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollableHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = window.scrollY;
      const progress = (scrolled / scrollableHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", updateScrollProgress);

    return () => {
      window.removeEventListener("scroll", updateScrollProgress);
    };
  }, []);

  const [postData, setpostData] = useState([]);
  const [postsData, setpostsData] = useState([]);
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState("");
  const [authorData, setAuthorData] = useState([]);
  const [TitleName, setTitleName] = useState("");
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    getData();
  }, [param]);
  async function getData() {
    setLoading(true);
    await Getcall(
      `/get-post-by-title?title=${param.title.replace(/-/g, " ")}`,
      CUSTOM_URL
    ).then((result) => {
      var postresults = JSON.parse(result);
      if (postresults?.data?.status == 404) {
        navigate("/not-found")
        return
      }
      if (postresults && postresults.ID) {
        Getcall("/posts/" + postresults.ID)
          .then((result) => {
            var results = JSON.parse(result);
            let utm_source = searchParams?.get("utm_source")
            let utm_medium = searchParams?.get("utm_medium")
            let utm_campaign = searchParams?.get("utm_campaign")
            let utm_content = searchParams?.get("utm_content")
            if (utm_source != null || utm_medium != null || utm_campaign != null || utm_content != null) {
              const raw = JSON.stringify({
                post_id: results?.id,
                utm_source: utm_source,
                utm_medium: utm_medium,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
              })
              PostCall("/post_campaign_data", raw).then((result) => {
                console.log(result)
              })
            }
            Getcall("/users/" + results.author)
              .then((result) => {
                var results = JSON.parse(result);
                setAuthorData(results);
              })
              .catch((error) => alert("error" + error));

            setpostData(results);
            setTitleName(results?.acf?.meta_title);
          })
          .catch((error) => console.log("error", error));
      }
    });

    await Getcall("/posts")
      .then((result) => {
        var results = JSON.parse(result);
        setpostsData(results);
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  }
  const questionsAndAnswers = [];
  postData?.yoast_head_json?.schema["@graph"].forEach((element) => {
    if (element["@type"] === "Question") {
      const question = element.name;
      const answer = element.acceptedAnswer.text;
      questionsAndAnswers.push({ question, answer });
    }
  });
  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://sprigstack.com/blog/${param?.title}`,
    },
    headline: `${postData?.acf?.meta_title}`,
    description: `${postData?.acf?.meta_description}`,
    image: `${postData?.acf?.hero_image?.url}`,
    author: {
      "@type": "Person",
      name: `${authorData?.name}`,
      url: `https://sprigstack.com/blog/author/${authorData?.name
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()}}`,
    },
    publisher: {
      "@type": "Organization",
      name: "SprigStack",
      logo: {
        "@type": "ImageObject",
        url: "https://sprigstack.com/images/NavLogo.svg",
      },
    },
    datePublished: `${postData.date}`,
    dateModified: `${postData.date}`,
  };
  return (
    <React.Fragment>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      ) : (
        <>
          <HelmetMetaTag
            titleName={`${postData?.acf?.meta_title}`}
            canonical={window.location.href}
            ogtitle={`${postData?.acf?.meta_title}`}
            ogtype="website"
            ogurl={window.location.href}
            ogimg={postData?.acf?.hero_image?.url}
            desc={postData?.acf?.meta_description}
            ogdesc={postData?.acf?.meta_description}
            twittercard="summary"
            twitterimg={postData?.acf?.hero_image?.url}
            twittersite="@Sprigstack"
            twittertitle={`${postData?.acf?.meta_title}`}
            twitterdesc={postData?.acf?.meta_description}
          />
          <HelmetProvider>
            <Helmet>
              <script type="application/ld+json">
                {JSON.stringify(blogSchema)}
              </script>
            </Helmet>
          </HelmetProvider>
          <div className="px-6 text-center">
            <div className="row px-md-6 mx-md-5 mt-md-4 mt-3 px-0">
              {scrollProgress > 0 ? (
                <div
                  onClick={MoveToTop}
                  style={{
                    width: "70px",
                    height: "70px",
                    position: "fixed",
                    bottom: "0",
                    right: "0",
                  }}
                >
                  <CircularProgressbarWithChildren
                    value={scrollProgress}
                    background={true}
                    strokeWidth={10}
                    styles={buildStyles({
                      pathColor: "#ee720b",
                      trailColor: "#D6D6D6",
                      strokeLinecap: "butt",
                      backgroundColor: "transparent",
                    })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.7rem"
                      viewBox="0 0 384 512"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                        fill="#ee720b"
                      />
                    </svg>
                  </CircularProgressbarWithChildren>
                </div>
              ) : null}
              <div className="col-12">
                <h1 className=" text-center postHeaders">
                  {postData?.acf?.page_title}
                </h1>
                <img src="/images/hrImg.png" alt="" className="img-fluid " />
              </div>
            </div>
            <div className="col-12 text-center postDate my-3 mt-md-3 mt-2 ">
              {/* <b>Reading time : </b>
              {postData?.yoast_head_json?.twitter_misc["Est. reading time"]} */}
              {/* <b>Date : </b> {postData?.date?.split("T")[0]} */}
              {new Date(postData?.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              {windowWidth > 786 ? <></> : <br />}
              {postData?.yoast_head_json?.description
                ?.split(", ")
                .map((item, index) => (
                  <button className="btnPosts">
                    <Link
                      to={`/blog/category/${item
                        ?.split(" ")
                        ?.join("-")
                        .toLowerCase()}`}
                    >
                      {item}
                    </Link>
                  </button>
                ))}
            </div>
            <div className="col-12 mt-md-4 mt-3">
              <img
                className="img-fluid width100 contentImage"
                src={postData?.acf?.content_image?.url}
                alt={postData?.acf?.content_image?.alt}
              />
            </div>
            <div className="col-12 my-3">
              <div className="row">
                <div className="col-md-6 col-6 pe-0 text-start authorBlog">
                  <Link
                    to={`/blog/author/${authorData?.name
                      ?.split(" ")
                      ?.join("-")
                      ?.toLowerCase()}`}
                  >
                    <img
                      className="authorImage"
                      src={authorData?.yoast_head_json?.og_image[0]?.url}
                      alt={authorData?.yoast_head_json?.og_image[0]?.alt}
                    />
                    {authorData?.name}
                  </Link>
                </div>
                <div className="col-md-6 col-6 ps-0 text-end socialicons">
                  <a
                    rel="nofollow"
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=https://sprigstack.com${location?.pathname}`}
                  >
                    <img src="../images/linkedinBlog.svg" alt="" />
                  </a>
                  <a
                    rel="nofollow"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://sprigstack.com${location?.pathname}`}
                  >
                    <img src="../images/facebookblog.svg" alt="" />
                  </a>
                  <a
                    rel="nofollow"
                    href={`https://pinterest.com/pin/create/button/?url=https://sprigstack.com${location?.pathname}`}
                  >
                    <img src="../images/PinterestBlog.svg" alt="" />
                  </a>
                  <a
                    rel="nofollow"
                    href={`https://twitter.com/intent/tweet?url=https://sprigstack.com${location?.pathname}`}
                  >
                    <img src="../images/xblog.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-12 mt-md-4 mt-3 htmlMobile text-start anchorOrange"
              dangerouslySetInnerHTML={{ __html: postData?.content?.rendered }}
            ></div>
          </div>
          {questionsAndAnswers.count > 0 ? (
            <div className="container-fluid pt-lg-5 pt-md-4">
              <div className="col-12 text-center">
                <h2 className="clr0E2B3D  headerFontSizeH6">
                  Frequently Asked Questions
                </h2>
                <img
                  src="../images/hrImg.png"
                  alt=""
                  className="img-fluid "
                ></img>
              </div>
              <div
                className="row  mx-lg-5 mx-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="accordion mt-4 mb-3 px-md-2 ps-0 pe-0"
                  id="accordionExample"
                >
                  {questionsAndAnswers?.map((data, i) => {
                    return (
                      <>
                        <div className="accordion-item mb-3">
                          <h3 className="accordion-header" id="headingOne">
                            <button
                              className={`accordions-button ${i != 0 && "collapsed"
                                }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${i}`}
                              aria-expanded={i == 0 ? "true" : "false"}
                              aria-controls={`collapse${i}`}
                            >
                              {data?.question}
                            </button>
                          </h3>
                          <div
                            id={`collapse${i}`}
                            className={`accordion-collapse collapse ${i == 0 && "show"
                              }`}
                            collapse
                            show
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body pb-3 pt-0">
                              {data?.answer}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}

          <div className="row mt-md-4 px-6">
            <div className="col-12">
              <div className="row bg203F59 bor-10">
                <div className="col-md-5 p-0">
                  <img
                    className="img-fluid width100"
                    src={
                      windowWidth > 786
                        ? "../images/Blogpostsfree.png"
                        : "../images/Blogpostsfreemobile.png"
                    }
                    alt=""
                  />
                </div>
                <div className="col-md-7 text-start Blogpostsfree">
                  <p className="m-0">{postData?.acf?.post_cta}</p>
                  <button
                    className="btn-plane mb-4 mb-md-0"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    GET A FREE CONSULTATION
                    <img src="../images/orange-right-arrow.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className=" px-6 mx-5 mt-md-4 mt-4 text-center">
            <h2
              className="px-6 text-center postHeaders"
              style={{ fontSize: "32px" }}
            >
              Latest Blogs
            </h2>
            <img src="/images/hrImg.png" alt="" className="img-fluid " />
          </div>
          <div className="row px-6 mt-md-1 mb-3">
            {postsData.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              postsData.slice(0, 3)?.map((item) => (
                <>
                  <div className="col-md-4 col-12 my-3 px-4 anchorOrange">
                    <Link
                      className=""
                      to={`/blog/${item?.title?.rendered
                        ?.split(" ")
                        .join("-")}`}
                    >
                      <div className="row postBox h-100">
                        <div className="col-12">
                          <img
                            className="img-fluid mt-3"
                            src={item?.acf?.hero_image?.url}
                            alt={item?.acf?.hero_image?.alt}
                          />
                        </div>
                        <div className="col-12">
                          <p className="postHeader my-3">
                            {item?.acf?.page_title}
                          </p>
                          <p className="postPara">{item?.acf?.hero_para}</p>
                        </div>
                        <div className="col-12">
                          {item?.yoast_head_json?.description
                            ?.split(", ")
                            ?.map((article, index) => (
                              <button className="postBtn">
                                <Link
                                  to={`/blog/category/${article
                                    ?.split(" ")
                                    ?.join("-")
                                    .toLowerCase()}`}
                                >
                                  {article}
                                </Link>
                              </button>
                            ))}
                        </div>
                        <div className="col-12 my-3">
                          <div className="row ">
                            <div className="col-7 HeroDate">
                              {/* {item?.date?.split("T")[0]} */}
                              {new Date(item?.date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </div>
                            <div
                              className="col-5 "
                              style={{ textAlign: "right" }}
                            >
                              <div className="clr-orange">Read More »</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ))
            )}
          </div>
        </>
      )}

      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">
        <PersonalizedActionModal />
      </Popups>
    </React.Fragment>
  );
}
