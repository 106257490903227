import React from "react";
import { Link, useLocation } from "react-router-dom";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import "../Css/Sitemap.css";
export default function Sitemap() {
  const location = useLocation();
  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="Sitemap | SprigStack"
        desc="Discover all of SprigStack's available services and website pages for seamless navigation."
        canonical="https://sprigstack.com/sitemap.html"
        ogtitle="Sitemap | SprigStack"
        ogdesc="Discover all of SprigStack's available services and website pages for seamless navigation."
        ogtype="website"
        ogurl="https://sprigstack.com/sitemap.html"
      />
      <div className="my-md-5  p-5 p-md-0 SitemapHeader">
        <h1>Sitemap</h1>
        <div className="row mt-5 px-6">
          <div className="col-md-3 col-12">
            <h2 className="category-heading">Pages</h2>
            <ul className="category Pages">
              <li>
                <Link to="/">Home </Link>
              </li>
              <li>
                <Link to="/web-development">Web Development</Link>
              </li>
              <li>
                <Link to="/web-application-development">
                  Web App Development
                </Link>
              </li>
              <li>
                <Link to="/mobile-app-development">Mobile App Development</Link>
              </li>
              <li>
                <Link to="/api-integration-services">
                  API Integration Services
                </Link>
              </li>
              <li>
                <Link to="/crm-development-services">CRM Development</Link>
              </li>
              <li>
                <Link to="/erp-development-services">ERP Development</Link>
              </li>
              <li>
                <Link to="/uiux-design">UI/UX Design </Link>
              </li>
              <li>
                <Link to="/seo">SEO </Link>
              </li>
              <li>
                <Link to="/devops">DevOps </Link>
              </li>
              <li>
                <Link to="/project-management">Project Management</Link>
              </li>
              <li>
                <Link to="/about-us">About Us </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-12">
            <h2 className="category-heading">Portfolio</h2>
            <ul className="category Portfolio">
              <li>
                <Link to="/portfolio">Portfolio </Link>
              </li>
              <li>
                <Link to="/portfolio/development-of-an-erp-system-for-the-travel-industry">
                  ERP System for Travel Industry
                </Link>
              </li>
              <li>
                <Link to="/portfolio/website-development-for-K12">
                  Website Development for K12
                </Link>
              </li>
              <li>
                <Link to="/portfolio/hybrid-application-development-for-al-wajeeh">
                  Hybrid Application Development For Al Wajeeh
                </Link>
              </li>
              <li>
                <Link to="/portfolio/custom-crm-development-for-address-two">
                  Custom Crm Development For Address Two
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-12">
            <h2 className="category-heading">Contact</h2>
            <ul className="category Pages">
              <li>
                <Link to="/career">Career </Link>
              </li>
              <li>
                <Link to="/contact-us" state={{ pathname: location?.pathname }}>
                  Contact Us{" "}
                </Link>
              </li>
            </ul>
          </div>
          {/*<div className="col-md-3 col-12">
            <h2 className="category-heading">Locations</h2>
            <ul className="category Locations">
              <li>
                <Link to="/web-development-company-in-indianapolis">
                  Web Development in Indianapolis
                </Link>
              </li>
            </ul>
          </div>
           <div className="col-md-12 col-12">
            <h2 className="category-heading">Blog</h2>
            <ul className="category Locations">
              <div className="row">
                <li className="col-md-3">
                  <Link to="/blog/10-Convincing-Reasons-Why-PHP-Rules-the-Web-Development-World">
                    10 Convincing Reasons Why PHP Rules the Web Development
                    World
                  </Link>
                </li>
                <li className="col-md-3">
                  <Link to="/blog/web-app-security-best-practices-principles-and-threat-mitigation">
                    Web App Security: Best Practices, Principles and Threat
                    Mitigation Techniques
                  </Link>
                </li>
                <li className="col-md-3">
                  <Link to="/blog/decoding-the-future-will-ai-truly-replace-web-developers">
                    Decoding the Future: Will AI Truly Replace Web Developers?
                  </Link>
                </li>
                <li className="col-md-3">
                  <Link to="/blog/build-a-lightning-fast-ecommerce-site-that-crushes-the-competition">
                    How to Build a Lightning-Fast Ecommerce Site That Crushes
                    the Competition
                  </Link>
                </li>
                <li className="col-md-3">
                  <Link to="/blog/what-is-an-api-get-started-with-integration-and-development">
                    What is an API? How to Get Started with Integration &
                    Development
                  </Link>
                </li>
              </div>
            </ul>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}
