import React, { useState, useEffect, useRef } from 'react';
import "../../Css/timerSlider.css";

export function TimeSliderMpc(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  
  useEffect(() => {
    setIsMounted(true);

    if (props.TechnologyContent.length > 0) {
      setCurrentIndex(0);
    }
    startInterval();
  
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [props.TechnologyContent.length]);
  
  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % props.TechnologyContent.length);
    }, 5000);
  };
  
  const handleSliderClick = (index) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setCurrentIndex(index);
    startInterval();
  };
 
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentIndex(prevIndex => (prevIndex + 1) % props.TechnologyContent.length);
  //   }, 5000);

  //   return () => clearInterval(intervalId);
  // }, [props.TechnologyContent.length]);

  // const handleSliderClick = (index) => {
  //   setCurrentIndex(index);
  // };

  return (
    <>
      <div className="row timeSliderHeaderContainer pt-2 justify-content-center">
        {props.TechnologyContent.map((tech, index) => (
          <div
            key={index}
            className={`customTS-Col mx-0 crsr-pointer timeSliderHeader ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleSliderClick(index)}
            
          >
            <span className="techMpcFont">{tech.Technology}</span>
            <div className="progress sliderProgressContainer bgNewGrey">
              <div className={`myBar`} 
              style={{
                width: isMounted && index === currentIndex ? '100%' : '0%',
                transition: isMounted && index === currentIndex ? 'width 5s ease-in-out' : 'none',
                backgroundColor: isMounted && index === currentIndex ? '#b73d46' : '' 
              }}
              ></div>
            </div>
          </div>
        ))}
      </div>

      <div className="container">
        {props.TechnologyImages.map((tech, index) => (
          <div
            key={index}
            className={`deskDispFlex p-md-5 pb-0 justify-content-center roww ${index === currentIndex ? 'show' : 'd-none'}`}
          >
            {tech.Images.map((image, imageIndex) => (
              <div className="px-4 px-md-3 col6 text-center py-4" key={imageIndex}>
                <div className="techClass">
                  <img
                    alt={image.alt}
                    title={image.title}
                    src={image.Img}
                    className="img-fluid"
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>


    </>
  );
}
