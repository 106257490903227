import React from "react";
import { ContentAndImage } from "./Components/ContentAndImage";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import { DescriptiveCard } from "./Components/DescriptiveCard";
import { DevelopmentCommonFunc } from "./Components/DevelopmentCommon";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
export function SeoPage() {
  var techonologiesContent = [
    {
      Technology: "Google Analytics",
      Img: "/images/Technology/SeoTools1.svg",
      alt: " Google Analytics",
      title: "Seo tool Google Analytics",
    },
    {
      Technology: "Google Console",
      Img: "/images/Technology/SeoTools2.svg",
      alt: " Google Console",
      title: "Seo tool Google Console",
    },
    {
      Technology: "Google Adwords",
      Img: "/images/Technology/SeoTools3.svg",
      alt: " Google Adwords",
      title: "Seo tool Google Adwords",
    },
    {
      Technology: "Aherfs",
      Img: "/images/Technology/SeoTools4.svg",
      alt: " Aherfs	Aherfs",
      title: "Seo tool Aherfs",
    },
    {
      Technology: "Semrush",
      Img: "/images/Technology/SeoTools5.svg",
      alt: " Semrush",
      title: "Seo tool Semrush",
    },
    {
      Technology: "Moz",
      Img: "/images/Technology/SeoTools6.svg",
      alt: " Moz",
      title: "Seo tool Moz",
    },
    {
      Technology: "Sitebulb",
      Img: "/images/Technology/SeoTools7.svg",
      alt: " Sitebulb",
      title: "Seo tool Sitebulb",
    },
    {
      Technology: "Screaming Frog",
      Img: "/images/Technology/frogNew.svg",
      alt: " Screaming Frog",
      title: "Seo tool Screaming Frog",
    },
  ];
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/OurKey1.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Website Audit"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="An SEO audit is an in-depth assessment of a website's technical health, content, backlink profile, keyword strategy, and performance tracking. It helps identify opportunities to improve search engine visibility, user experience, and overall performance."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/OurKey2.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="On Page Optimization"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="We aim to improve a website's visibility and effectiveness for search engines by altering its content and layout, which involves optimizing page titles, meta descriptions, header tags, internal links, and other elements."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/OurKey3.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Off Page Optimization"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Our approach to off-page optimization includes the use of outreach, press releases, and quality link-building to increase your website's visibility, credibility, and authority."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/OurKey4.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Keyword Research & Strategy"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="The process involves identifying relevant keywords and phrases that potential customers use to search for products or services, incorporating them into the website's content and SEO strategy, and optimizing it to improve search engine rankings and attract the target audience."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/OurKey5.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Content Optimization"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Enhancing the quality of website content to attract more visitors and enhance search engine rankings is a crucial aspect of this process. This may involve creating high-quality content such as blog posts, videos, infographics, and other types of content."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/OurKey6.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header=" Reporting & Refinement"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Monitoring website performance and timely updates are crucial for optimal website functionality. Our team conducts regular data analysis and evaluates the overall website performance to stay informed about the website's market position."
    />
  );
  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg1.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Increase Brand <br/> Awareness"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg2.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Boost your <br/> Credibility"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg3.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Rank Higher  <br/>In the SERPs"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg4.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header=" Boost Website  <br/>Conversion Rate"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg5.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Increase Organic <br/> Traffic"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg6.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Get more <br/> leads"
      CustomClass="MajorBenefitsContainer"
    />
  );
  var processWeFollowConent = [];
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/processWeFollow/SeoProcess1.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="1. Technical SEO Audit & Fixes"
      CustomClass="textandItemCenter weFollow_pdng"
      CustomBodyClass="processWeFollowFonts"
      IsSetClass={true}
      Body="It is important to review a website's technical elements, such as page speed, site structure, URL structure, and internal linking. The technical audit guarantees that a website follows search engine guidelines and is crawlable and indexable."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/processWeFollow/SeoProcess2.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="2. Competitor Analysis & Research"
      CustomClass="textandItemCenter weFollow_pdng"
      CustomBodyClass="processWeFollowFonts"
      IsSetClass={true}
      Body="It is equally important to analyse competitors and identify their SEO strengths. This may help in developing strategies that help our clients to outperform their competitors in terms of online traffic and rankings."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/processWeFollow/SeoProcess3.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="3. Content Audit & Evaluation "
      CustomClass="textandItemCenter weFollow_pdng"
      CustomBodyClass="processWeFollowFonts"
      IsSetClass={true}
      Body="This involves reviewing a website's content and identifying areas for improvement in terms of quality, relevance, and engagement. This includes evaluating the format, structure, and style of the content during the audit."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/processWeFollow/SeoProcess4.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="4. Keyword Research & Optimization"
      CustomClass="textandItemCenter weFollow_pdng"
      CustomBodyClass="processWeFollowFonts"
      IsSetClass={true}
      Body="The process includes identifying high-traffic and relevant keywords for the website, as well as optimizing the content, metadata, and structure for those keywords. This ensures that the website targets the appropriate keywords and is effectively integrated into the optimization strategy."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/processWeFollow/SeoProcess5.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="5. Backlink Analysis "
      CustomClass="textandItemCenter weFollow_pdng"
      CustomBodyClass="processWeFollowFonts"
      IsSetClass={true}
      Body="In this step, we analyze the quality and quantity of backlinks pointing to the website. It identifies areas for improvement and new opportunities for link building. It also helps us discover unnatural or spammy links, which results in strong link building and improves site backlink profile."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/processWeFollow/SeoProcess6.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="6. Custom SEO Strategy  "
      CustomClass="textandItemCenter weFollow_pdng"
      CustomBodyClass="processWeFollowFonts"
      IsSetClass={true}
      Body="Based on the results of the technical audit and business goals, a custom SEO strategy is developed to achieve website rankings and traffic. The strategy should include on-page and off-page optimization, keyword targeting, content creation, and link building."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/processWeFollow/SeoProcess7.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="7. Implementation"
      CustomClass="textandItemCenter weFollow_pdng"
      CustomBodyClass="processWeFollowFonts"
      IsSetClass={true}
      Body="This process involves implementing all the research and analysis into action using custom SEO strategy and optimising a website's technical elements, content, and backlinks so that it can rank higher in search engine results and gain more traffic."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/processWeFollow/SeoProcess8.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="8. Results"
      CustomClass="textandItemCenter weFollow_pdng"
      CustomBodyClass="processWeFollowFonts"
      IsSetClass={true}
      Body="With all the aforementioned points in place, you will now have a website that is suitable for SEO. Today's competition is fierce, and you must maintain your position at the top to keep up. We'll assist you by providing the ideal outcomes in this regard."
      IsCard="processWeFollowContainer"
    />
  );
  var whChooseUseLeftBox = [];
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader"
      DescriptionHeader=" Expert Skills & Clear Communication "
      Image="./images/seowhychooseusIcon1.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`As an SEO company, our main goal is to simplify the SEO process by understanding our clients' requirements and developing custom SEO strategies that meet their business objectives.`}
      CardMargin="pt-1 my-lg-0  mb-1 mx-0 whchmob"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader"
      DescriptionHeader="Customized & Data-Driven Approach"
      Image="./images/seowhychooseusIcon2.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`Our approach is customized and data-driven, based on in-depth research into your target audience, competition, and ensuring the best SEO practices to be followed.`}
      CardMargin="pt-1 my-lg-0 my-4 mb-1 mx-0 whchmob"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader"
      DescriptionHeader="Transparent Reporting & Results"
      Image="./images/seowhychooseusIcon3.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`We provide transparent reporting on our SEO efforts and their impact on your business, with detailed data available around the clock to ensure that you can see the measurable results of our efforts.`}
      CardMargin="pt-1 my-lg-0 my-4  mb-1 mx-0 whchmob"
    />
  );
  var whyChooseUsContent = (
    <ContentAndImage
      ImagePosition="left"
      DescriptionHeader=""
      Image="./images/whChooseSeoNew.svg"
      alt="SEO"
      title="Seo tool SEO infographic"
      CardMargin="d-flex align-items-center mt-lg-4 pt-lg-0 pt-4 whyChooseContentCss"
      CustomDescriptionBoxClass="p-lg-2"
      Description={whChooseUseLeftBox}
      customDescriptionItemFirst="col-lg-6 col-md-6 col-12"
      customDescriptionItemSecond="col-lg-6 col-md-6 col-12"
      CustomHeaderClass="WhyChooseContentbox"
      bodyClassCareerRequirement="d-none"
    />
  );
  var HeroSection = {
    ImagePosition: "left",
    DescriptionHeader:
      " Drive More Traffic & Leads with Professional SEO Services",
    CustomHeaderClass: "C_font1",
    CustomBodyClass: "C_font2",
    Image: "./images/SeoMain.svg",
    alt: " Search Engine Optimization",
    title: "Search Engine Optimization Services",
    CustomImageClass: "heroImgCss",
    CardMargin: " px-lg-6 px-sm-6",
    CustomDescriptionBoxClass: "p-lg-4 px-4 ",
    Description:
      "We provide professional SEO services designed to increase organic traffic and drive maximum results for your brand. Our team of SEO experts uses technical SEO, on-page SEO, and off-page SEO techniques to optimize your website and boost its search engine rankings. We offer customized SEO strategies tailored to your business needs, which are continuously updated to keep up with the latest trends and developments in the industry. Our specialities include e-commerce SEO, SaaS SEO, local SEO, international SEO, and strategic link building.",
    customDescriptionItemFirst:
      "col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3",
    customDescriptionItemSecond: "col-lg-6 col-md-6 col-12 homePageGYB",
    bodyClassCareerRequirement: "d-none",
  };
  var Overview = {
    Header: "Overview",
    Content:
      "Our comprehensive SEO analysis and customized SEO strategies are tailored to your specific business needs. As the SEO industry is constantly evolving, we stay up-to-date with the latest trends and developments to ensure that your brand grows exponentially. We specialize in e-commerce SEO, SaaS SEO, local SEO, international SEO, and strategic link building.",
    ContentCustomClass: "pt-3 m-0",
    HeaderPadding: "overviewHeading",
    flag: true,
  };
  var OurKey = {
    BgColor: "#F7F7F7",
    Header: " Our Key SEO Services Include",
    ContainerBottomPadding: "pb-md-3 pt-5",
    HeaderPadding: "ourKeyHeadingApp",
    flag: true,
    Content: [serviceConent],
  };
  var PersonalizedCompFirst = {
    cardTitle:
      "Take the first step towards <br/> a successful  <br/> SEO campaign!",
    TitleCss1: "personalizedtitle1App",
    ContainerColor: "FFFPersonalize",
    flag: "1",
  };
  var MajorBenefits = {
    BgColor: "",
    Header: `Major Benefits of SEO Services`,
    HeaderPadding: "majorBenefitHeading",
    pt: "",
    ContentCustomClass: "",
    flag: true,
    Content: [webAppBenefitContent],
  };
  var UiUxProcess = {
    BgColor: "#F7F7F7",
    Header: `SEO Process that we follow`,
    HeaderPadding: "seoProcessHeadingApp",
    ContainerBottomPadding: "pb-2",
    ContentCustomClass: "justifyCenter weFollowContainer",
    Content: [processWeFollowConent],
  };
  var Technology = {
    Tech: [techonologiesContent],
    MainHeader: "Tools we use for SEO",
    SubHeader: " We utilize the latest and most effective SEO Tools.",
  };
  var PersonalizedCompSecond = {
    cardTitle:
      "Struggling with Google rankings? <br/> Our expert assistance can make all the difference.  ",
    ContainerColor: "cA49F9D",
    flag: "2",
  };
  var WhyChooseUs = {
    BgColor: "#f7f7f7",
    Header: ` Why Choose us for SEO?`,
    HeaderPadding: "whyChooseUsHeadingSeo",
    ContentCustomClass: "whyChooseUsBox",
    Content: [whyChooseUsContent],
  };
  return (
    <>
      <HelmetMetaTag
        titleName="Professional Search Engine Optimization Services | SprigStack"
        desc="Want to boost your website traffic and conversion rate? From website audit to quality links, we offer everything you need. Contact us now!"
        canonical="https://sprigstack.com/seo"
        ogtitle="Professional Search Engine Optimization Services | SprigStack"
        ogdesc="Want to boost your website traffic and conversion rate? From website audit to quality links, we offer everything you need. Contact us now!"
        ogtype="website"
        ogurl="https://sprigstack.com/seo"
      />
      <DevelopmentCommonFunc
        // Title="SEO"
        HeroSection={HeroSection}
        Overviewsection={Overview}
        OurKeySection={OurKey}
        PersonalizedCompOne={PersonalizedCompFirst}
        MajorBenefitsSection={MajorBenefits}
        UiUxProcessSection={UiUxProcess}
        Space={true}
        TechnologySection={Technology}
        PersonalizedCompTwo={PersonalizedCompSecond}
        // RecentWorkSection={RecentWork}
        IsNewSection="yes"
        WhyChooseUsSection={WhyChooseUs}
        FAQSection={<FAQDynamicComp />}
      />
    </>
  );
}
