import React, { useState, useReducer, useEffect, useRef } from "react";
import { formReducer, INITIAL_STATE } from "../Reducer/AboutUsReducer";
import $ from "jquery";
import CountSetInterval from "../Components/CounterComp";
import PersonalizedComp from "../Components/PersonalizedComp";
import { ClientComp } from "../Components/ClientComp";
import { HeaderWithDynamicContent } from "../Components/HeaderWithDynamicContent";
import { BreadCrumbCard } from "../Components/BreadCrumbCard";
import HoverComponent from "../Components/HoverComponent";
import { Tooltip } from "react-bootstrap";
import axios from "axios";
import HelmetMetaTag from "../Components/HelmetMetaTag";
import LazyLoadingImg from "../Components/LazyLoadingImg";
import Overlay from "react-bootstrap/Overlay";
export default function NewAboutUsTest() {
  const [show, setShow] = useState({
    show1: false,
    show2: false,
    show3: false,
  });
  const target1 = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);

  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [countNumber, setCountNumber] = useState({
    Count1: 0,
    Count2: 0,
    Count3: 0,
    Count4: 0,
  });
  const [teamPlayersData, setTeamPlayersData] = useState([]);
  useEffect(() => {
    axios
      .get("/jsonFiles/EmployeeDetails.json")
      .then((res) => {
        setTeamPlayersData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    $("#AS_Box1").click();
    window.addEventListener("scroll", CounterFun);
    return () => {
      window.removeEventListener("scroll", CounterFun);
    };
  }, []);

  const CounterFun = () => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 500) {
      setCountNumber({
        ...countNumber,
        Count1: 100,
        Count2: 50,
        Count3: 20,
        Count4: 6,
      });
    }
  };

  var transperancyAndEthicsConent = [];
  var clientCentricApproch = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4"
      Image="./images/clientHandShake.svg"
      ImageClass="aboutUsIndImage"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Client-centric Approach"
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody"
      Body="Client’s business idea is greater than anything else. We keep that factor in center and build everything else surrounding it. We recommend things but do not replace. We only commit if idea is executable by us, no fake promises!"
    />
  );
  transperancyAndEthicsConent.push(clientCentricApproch);

  var serviceDeliveredInTime = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4"
      Image="./images/serviceDeliveredInTime.svg"
      ImageClass="aboutUsIndImage"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Realistic Timelines"
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody"
      Body="We provide reasonable and achievable timelines to our clients. We do not promise over-night turn arounds which is not realistic. Our vastly experienced scrum masters and delivery managers mae sure we match what we commit every single time!"
    />
  );
  transperancyAndEthicsConent.push(serviceDeliveredInTime);

  var effectiveCollaboration = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4"
      Image="./images/effectiveCollaboration.svg"
      ImageClass="aboutUsIndImage"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Effective Management"
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody"
      Body="When clients hire dedicated technical resources, we make sure they are managed well by experienced managers and no extra costs are levied on clients’ shoulders. We provide one point of contact to clients in case of larger teams."
    />
  );
  transperancyAndEthicsConent.push(effectiveCollaboration);

  var employeeBenefits = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4"
      ImageClass="aboutUsIndImage"
      Image="./images/pocketFriendlyApproch.svg"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Pocket Friendly Approach"
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody"
      Body="Unlike other money digging agencies, we provide up to the point and straightforward costing to our clients. Giving our clients transparent and cost effective solutions as per their needs is our priority."
    />
  );
  transperancyAndEthicsConent.push(employeeBenefits);

  var teamWork = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4"
      ImageClass="aboutUsIndImage"
      Image="./images/teamWork.webp"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Fruitful Relations"
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody"
      Body="We do less projects but long projects. Maintaining long term relations and keeping clients happy is what we believe in and execute. Once a client of SprigStack is always a client."
    />
  );
  transperancyAndEthicsConent.push(teamWork);

  var qualityOfService = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4"
      Image="./images/qualityOfService.webp"
      ImageClass="aboutUsIndImage"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Data Security"
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody"
      Body="We do NDA for all of our clients. We provide highest level of security to data and ideas of all the clients. We also do NDAs with our employees. We do not leave any loop holes when it comes to data security subjects. "
    />
  );
  transperancyAndEthicsConent.push(qualityOfService);
  var brandsWeWorkedForContent = (
    <div className="container-fluid p-0">
      <div className="colorWhite py-2 text-center">
        <div className="row align-items-center pb-lg-5 pb-0">
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent imgSrc="client1" hoverSrc="client1Hover" />
          </div>
          <div className="col-md-2 col-6 my-lg-0 my-3">
            <HoverComponent imgSrc="client2" hoverSrc="client2Hover" />
          </div>
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent imgSrc="client3" hoverSrc="client3Hover" />
          </div>
          <div className="col-md-2 col-6 my-lg-0 my-3">
            <HoverComponent imgSrc="client4" hoverSrc="client4Hover" />
          </div>
          <div className="col-md-2 col-12 my-lg-0 my-4">
            <HoverComponent imgSrc="client5" hoverSrc="client5Hover" />
          </div>
        </div>
        <div className=" row align-items-center pb-lg-5 pb-0">
          <div className="col-md-3 col-6  my-lg-0 my-3">
            <HoverComponent imgSrc="client6" hoverSrc="client6Hover" />
          </div>
          <div className="col-md-2 col-6 my-lg-0 my-3">
            <HoverComponent imgSrc="client7" hoverSrc="client7Hover" />
          </div>
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent imgSrc="client8" hoverSrc="client8Hover" />
          </div>
          <div className="col-md-2 col-6 my-lg-0 my-3">
            <HoverComponent imgSrc="client9" hoverSrc="client9Hover" />
          </div>
          <div className="col-md-2 col-12  my-lg-0 my-4">
            <HoverComponent imgSrc="client10" hoverSrc="client10Hover" />
          </div>
        </div>
        <div className="row align-items-center pb-lg-4 pb-0">
          <div className="col-md-3 col-6  my-lg-0 my-3">
            <HoverComponent imgSrc="client11" hoverSrc="client11Hover" />
          </div>
          <div className="col-md-2 col-6 my-lg-0 my-3">
            <HoverComponent imgSrc="client12" hoverSrc="client12Hover" />
          </div>
          <div className="col-md-2 col-6 text-center my-lg-0 my-3">
            <HoverComponent imgSrc="client13" hoverSrc="client13Hover" />
          </div>
          <div className="col-md-3 col-6 text-center my-lg-0 my-3">
            <HoverComponent imgSrc="client14" hoverSrc="client14Hover" />
          </div>
          <div className="col-md-2 col-12  my-lg-0 my-4">
            <HoverComponent imgSrc="client15" hoverSrc="client15Hover" />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <HelmetMetaTag
        titleName="We are best web and mobile application development & design company in India - SprigStack"
        title="SprigStack | About Us"
        desc="About Us"
      />
      <div className="row pt-5">
        <div className="col-12 fontColorBlue text-center px-4">
          <div className="col-12 aboutUsHeader">
            We are dedicated and skilled
          </div>
          <img src="./images/PortfolioHR.png" alt="" className="img-fluid" />
          <LazyLoadingImg
            src="./images/aboutUsBanner.svg"
            alt="About Us infographic"
            title="About Us infographic"
          />
        </div>
      </div>
      <div className="container-fluid px-0 pt-5">
        <div className="bgF7F7F7">
          <div className="col-12 text-center px-4 py-4">
            <div className="clr0E2B3D headerFontSizeH6">About Sprigstack</div>
            <div className="clr0E2B3D headerFontSizeH3">
              Small but skilled IT engineering team
            </div>
            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div className="px-5">
            <div className="row mx-lg-5" style={{ textAlign: "justify" }}>
              <div className="col-12 aufont2">
                We at SprigStack, are small but skilled engineering team
                providing different set of IT services globally. We design
                websites, design mobile applications, develop websites and
                mobile applictions, maintain them on cloud or in-house, optimize
                them for search engines and finally help them market to
                appropriate user audience as well. We provide end to end
                solutions to all sort of businesses to create & maintain their
                online identity. If you are looking to launch your website,
                place your apps on app stores for iPhone & Android, migrate your
                old website or application to new technology, get your business
                data to cloud or any similar technological service assistance,
                you have come to the perfect place.
              </div>
              <div className="col-12 aufont2 py-4">
                We provide web development and web designing services at very
                competitive rates. Our solutions are budget friendly so creating
                and maintaining online business is not very difficult to handle
                for any size of businesses. We are expert in migrating your
                existing old website or application to new trendy and secure
                platform. We also migrate your in-house server or virtual
                machine efficiently on cloud without affecting your application
                availability and data security. We have been serving in IT
                engineering services for more than 6 years now, our founders are
                having over 15 years of industry experience.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" id="CountSetIntervalDiv" tabindex="100">
        <div className="row auContainer py-4 px-6">
          <div className="col-lg-3 col-md-3 col-12 p-3 text-center">
            <div className="row">
              <div className="AU_Font8 fontfff">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count1}
                  duration={parseInt(2)}
                />
                +
              </div>
            </div>
            <div className="row">
              <div className="AU_Font6 fontfff">Projects Completed</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12 p-3 text-center">
            <div className="row">
              <div className="AU_Font8 fontfff">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count2}
                  duration={parseInt(2)}
                />
                +
              </div>
            </div>
            <div className="row">
              <div className="AU_Font6 fontfff">Happy Clients</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12 p-3 text-center">
            <div className="row">
              <div className="AU_Font8 fontfff">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count3}
                  duration={parseInt(2)}
                />
                +
              </div>
            </div>
            <div className="row">
              <div className="AU_Font6 fontfff">Experts in Team</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12 p-3 text-center">
            <div className="row">
              <div className="AU_Font8 fontfff">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count4}
                  duration={parseInt(2)}
                />
                +
              </div>
            </div>
            <div className="row">
              <div className="AU_Font6 fontfff">Successful Years</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row px-5 bgF7F7F7 py-5">
          <div className="col-md-4 px-5">
            <div
              className="row AU_Box px-3 py-4 my-2 active crsr-pointer"
              id="AS_Box1"
              onClick={(e) => {
                dispatch({
                  type: "CHANGE_TEXT",
                  title: "Founded in 2016",
                  desc: "After more than 7 years of industry experience, founder Ronak Shah started this venture with a small team under him of 4 developers and 2 clients under his belt based in USA. Initially we were only foucsed on programming different web applications and softwares for our existing clients.  We kept adding more resources as we gained further expertise and expanded our abilities. We have recently completed 6 successful years in business and grown ourselves to around 20 skilled engineers in different domains. ",
                });
                $("#AS_Box1").addClass("active");
                $("#AS_Box2,#AS_Box3,#AS_Box4").removeClass("active");
              }}
            >
              <div className="col-3 d-flex justifyCenter">
                <img src="./images/sprigstack_white_logo.png" alt=""></img>
              </div>
              <div
                className="col-9 h5"
                style={{
                  color: $("#AS_Box1").hasClass("active") ? "white" : "black",
                }}
              >
                Background
              </div>
            </div>
            <div
              className="row AU_Box px-3 py-4 my-2 crsr-pointer"
              id="AS_Box2"
              onClick={(e) => {
                dispatch({
                  type: "CHANGE_TEXT",
                  title: "Our Initial Vision & Why Do We Exist",
                  desc: "When we started up, we had a vision of helping other small start-ups like us in a cost effective and time effective manner. New start-ups are often seen strugling with big amount of cash on hand and longer times to wait for their tech products. We came to existance to solve those problems mainly. We helped more than 20 small start-ups to convert their ideas into tech products and create their online identity. Start-ups choose us to be their tech partner for two major reasons. 1) We give them budget friendly and transparent quotation to include their most important features for MVP. 2) We turn around their ideas into reality in less amount of time than their expecations since we have vast experience in this domain.",
                });
                $("#AS_Box2").addClass("active");
                $("#AS_Box1,#AS_Box3,#AS_Box4 ").removeClass("active");
              }}
            >
              <div className="col-3 d-flex justifyCenter">
                <img src="./images/sprigstack_white_logo.png" alt=""></img>
              </div>
              <div
                className="col-9 h5"
                style={{
                  color: $("#AS_Box2").hasClass("active") ? "white" : "black",
                }}
              >
                Why We Exist
              </div>
            </div>
            <div
              className="row AU_Box px-3 py-4 my-2 crsr-pointer"
              id="AS_Box3"
              onClick={(e) => {
                dispatch({
                  type: "CHANGE_TEXT",
                  title: "Technical Leaders Who Lead From The Front",
                  desc: "Our founders are programmers and technical leaders themselves. If a day comes when developers get stuck or if there is a query which is not giving ideal performance, founders roll up the sleeves and get to it themselves. Even the Business Developers and Sales Reps are also from the IT background so that we do not make fake promises or commitment to clients at front and then put pressure on development team to execute it. Our front personals are aware about our exact abilities and close appropriate business only. Moreover, being technical developers in the past, our leaders know exactly about what tasks take how much to execute in a best possible way.",
                });
                $("#AS_Box3").addClass("active");
                $("#AS_Box1,#AS_Box2,#AS_Box4").removeClass("active");
              }}
            >
              <div className="col-3 d-flex justifyCenter">
                <img src="./images/sprigstack_white_logo.png" alt=""></img>
              </div>
              <div
                className="col-9 h5"
                style={{
                  color: $("#AS_Box3").hasClass("active") ? "white" : "black",
                }}
              >
                Impactful Leaders
              </div>
            </div>
            <div
              className="row AU_Box px-3 py-4 my-2 crsr-pointer"
              id="AS_Box4"
              onClick={(e) => {
                dispatch({
                  type: "CHANGE_TEXT",
                  title: "Not only Clients, Employees are equally important!",
                  desc: "We have been able to retain 100% customer satisfaction so far, for which a big credit goes to all of our team members. We try to treat them as friends, not employees. We make sure to appreciate them enough and do not give any chance to complaint. We also give chance to let them learn new trends and techniques in their free time via different programs. We believe in working hard and enjoying the success equally hard. All of our employees are true asset for us.",
                });
                $("#AS_Box4").addClass("active");
                $("#AS_Box1,#AS_Box3,#AS_Box2 ").removeClass("active");
              }}
            >
              <div className="col-3 d-flex justifyCenter">
                <img src="./images/sprigstack_white_logo.png" alt=""></img>
              </div>
              <div
                className="col-9 h5"
                style={{
                  color: $("#AS_Box4").hasClass("active") ? "white" : "black",
                }}
              >
                Employee Friendly
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-2">
            <div className="row ">
              <div className="clr0E2B3D headerFontSizeH6">{state.title}</div>
            </div>
            <div className="row my-3">
              <div className="aufont2">{state.desc}</div>
            </div>
          </div>
        </div>
      </div>
      {/* personalized action */}
      <PersonalizedComp
        cardTitle="Looking for an estimation<br/> for your website?"
        ContainerColor="FFFPersonalize"
        flag="1"
      />
      {/* Team Players*/}
      <div className="container-fluid" style={{ paddingTop: "2.5rem" }}>
        <div className="col-12 text-center">
          <div className="clr0E2B3D headerFontSizeH6">Team Players</div>
          <img src="images/hrImg.png " alt="" className="img-fluid" />
        </div>
        <div className="row px-6 pt-4 justify-content-center text-center">
          <div className="col-4 col-lg-4">
            <img src="./images/EmployeePhotos/RonakSir.jpg" />
            <div className="row pt-2 col-12 col-lg-6 m-auto">
              <div className="col-4">
                <a href="https://www.linkedin.com/in/preet-shah-241883122/">
                  <img src="images/linkedin final.svg" />
                </a>
              </div>
              <div className="col-4">
                <a href="mailto:ronak@sprigstack.com">
                  <img src="images/mail final.svg" />
                </a>
              </div>
              <div className="col-4">
                <img
                  src="images/info final.svg"
                  ref={target1}
                  onClick={() => show.setShow(!show.show1)}
                />
                <Overlay
                  target={target1.current}
                  show={show.show1}
                  placement="right"
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      Ronak, core programmer himself, have around 13 years of
                      industry experience. He founded SprigStack backing up his
                      technical expertise along with few more junior developers.
                      He is responsible for managing day to day operations of
                      the company and keep it moving. He loves solving new
                      challenges in web world and deliver quality work for his
                      clients. Ronak believes strongly in quality of balance
                      between work and life. He engages himself in sports and
                      traveling quite often, loves spending time and creating
                      memories with his daughter.
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </div>
          </div>

          <div className="col-4 col-lg-4">
            <img src="./images/maleAvatarBig.jpg" />
            <div className="row pt-2 col-12 col-lg-6 m-auto">
              <div className="col-4">
                <a href="https://www.linkedin.com/in/nickcarterentrepreneur/">
                  <img src="images/linkedin final.svg" />
                </a>
              </div>
              <div className="col-4">
                <a href="mailto:nick@sprigstack.com">
                  <img src="images/mail final.svg" />
                </a>
              </div>
              <div className="col-4">
                <img
                  src="images/info final.svg"
                  ref={target2}
                  onClick={() => show.setShow(!show.show2)}
                />
                <Overlay
                  target={target2.current}
                  show={show.show2}
                  placement="right"
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      Nick, farmer by born and entreprenuer by choice, is a
                      skilled businessman. He co-founded SprigStack with Ronak
                      backing his commercial reach and experience to deal with
                      businesses. Nick prefers smart work and believes strongly
                      in "zero investment" strategy. He even has a book written
                      on this fundamental called{" "}
                      <a href="https://www.goodreads.com/en/book/show/15762387-unfunded">
                        Unfunded
                      </a>
                      He loves to farm in his big farm in the backyard of his
                      home and he loves spending his free time with his adorable
                      family.
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </div>
          </div>

          <div className="col-4 col-lg-4">
            <img src="./images/EmployeePhotos/PreetS.jpg" />
            <div className="row pt-2 col-12 col-lg-6 m-auto">
              <div className="col-4">
                <a href="https://www.linkedin.com/in/preet-shah-241883122/">
                  <img src="images/linkedin final.svg" />
                </a>
              </div>
              <div className="col-4">
                <a href="mailto:connect@sprigstack.com">
                  <img src="images/mail final.svg" />
                </a>
              </div>
              <div className="col-4">
                <img
                  src="images/info final.svg"
                  ref={target3}
                  onClick={() => setShow(!show.show3)}
                />
                <Overlay
                  target={target3.current}
                  show={show.show3}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      Preet has 5 years of industry experience in Sales
                      department. He uses his experience to help businesses
                      providing digital transformation deals at best rates and
                      quality services. Not only he closes new deals but he also
                      makes sure clients are happy and satisfied as long as they
                      are in business with SprigStack after on-boarding. He
                      loves to explore music and OTT content in his free time.
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-6 py-2  text-center">
          {teamPlayersData.map((data, i) => {
            return (
              <>
                {data.id > 3 && (
                  <>
                    <div className="customTP-Col pt-4" key={data.id}>
                      <LazyLoadingImg
                        src={`images/${data.avatarSrc}.jpg`}
                        className=""
                        alt={data.alt}
                        title={data.title}
                      />
                      {data.id == 11 ? (
                        <>
                          <div className="col-12 mb-0 mt-4 px-3 TpPlaceFont clr0E2B3D">
                            {data.name}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-12 mb-0 pt-4 ColorNavbar">
                            <b>{data.name}</b>
                          </div>
                          <div className="col-12 pb-0 pt-2 TpPositionFont">
                            {data.position}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>

      <ClientComp />

      {/* personalized action */}
      <PersonalizedComp
        cardTitle="Share your idea and start application development straight away!"
        ContainerColor="cA49F9D"
        flag="2"
      />
      {/* Clients section */}
      <div className="pt-5"></div>
      <HeaderWithDynamicContent
        Header="Brands we worked for"
        SubHeader="Some of the many global brands we have worked with."
        BgColor="#F7F7F7"
        Content={brandsWeWorkedForContent}
        ContainerBottomPadding="pb-4"
      />
      <HeaderWithDynamicContent
        Header="Why we are best in Industry!"
        SubHeader="We have setup standards for high quality deliverables and we stick to them. Our<br/> Transparency and Process make us the best choice."
        Content={transperancyAndEthicsConent}
      />
      <div className="pt-4"></div>
    </>
  );
}
