import React from "react";
import LazyLoadingImg from "./LazyLoadingImg";
export default function PortfolioComp(props) {
  const OpenUrl = (vid) => {
    window.open(`${props.redirecturl}`, "_blank");
  };
  return (
    <>
      {props.flag == 1 ? (
        <>
          <div
            className="position-relative p-2"
            style={{ backgroundColor: props.containerColor }}
          >
            <div className="row">
              <div className="col-lg-6 col-12">
                <LazyLoadingImg
                  src={`/images/${props.imgsrc}`}
                  className={props.imgHeight}
                />
              </div>
              <div className="col-lg-6 col-12" style={{ alignSelf: "center" }}>
                <h3
                  className="my-3 fontColorBlue"
                  style={{
                    fontSize: `${props.fontsize}px`,
                    fontWeight: "600",
                    color: "#203f59",
                  }}
                >
                  {props.text1}
                </h3>
                <div
                  className="my-3"
                  style={{ fontSize: `${props.text2size}px` }}
                >
                  {props.text2}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="position-relative p-2"
            style={{ backgroundColor: props.containerColor }}
          >
            <div className="row justify-content-center SetDivImage">
              <div className="row">
                <LazyLoadingImg
                  src={`/images/${props.imgsrc}`}
                  className={props.imgHeight}
                />
              </div>
              <div
                className={`px-3 row mx-2 ${props.paddingClass}`}
                style={{ alignSelf: "center" }}
              >
                <div className="col-lg-10 px-0">
                  <h3
                    className="my-3 fontColorBlue"
                    style={{
                      fontSize: `${props.fontsize}px`,
                      fontWeight: "600",
                      color: "#203f59",
                    }}
                  >
                    {props.text1}
                  </h3>
                  <div
                    className="my-3"
                    style={{ fontSize: `${props.text2size}px` }}
                  >
                    {props.text2}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
