import React, { useLayoutEffect, useState } from "react";
import CareerApplyNow from "../Components/CareerApplyNow";
import { ContentAndImage } from "../Components/ContentAndImage";
import LifeAtSprigStack from "../Components/LifeAtSprigStack";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function CareerDynamicComp() {
  const [RequirementData, setRequirementData] = useState([]);
  const Params = useParams();
  useLayoutEffect(() => {
    var validUrl = true;
    axios
      .get("/jsonFiles/CareerRequirements.json")
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (Params.name == res.data[i].url) {
            setRequirementData(res.data[i]);
            validUrl = false;
          }
        }
        if (validUrl) window.location.href = "/career";
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <ContentAndImage
        ImagePosition="right"
        DescriptionHeader={RequirementData?.HeroTitle}
        CustomHeaderClass="C_font1"
        CustomBodyClass="C_font2 ourKeyCardtext"
        Image={RequirementData?.ImageSrc}
        CustomImageClass="newCarrerClass"
        CardMargin="mt-3 px-lg-6 px-sm-6"
        CustomDescriptionBoxClass="p-lg-4"
        Description={RequirementData?.HeroDesc}
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 text-center"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 homePageGYB"
        bodyClassCareerRequirement="TpPlaceFont NwfontColorBlue"
        careerRequirement={RequirementData?.HeroJobDesc}
      />
      <div className="row px-lg-6 px-sm-6">
        <div className="row  my-3 remMarPad">
          <div className="col-lg-3 cl-md-3 col-8">
            <div className="C_phpSpace">
              <div className="text-start NwPlaceFont fontColorBlue">
                Responsibilities
              </div>
              <div className="text-end AU_Font5 pl_left">:</div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-12 remPad">
            <ul className="C_phpUL">
              {RequirementData.Responsibilities?.map((data, i) => {
                return (
                  <>
                    {i == 0 ? (
                      <li className="mt-1 listFntSize">{data}</li>
                    ) : (
                      <li className="listFntSize">{data}</li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row  my-3 remMarPad">
          <div className="col-lg-3 cl-md-3 col-8">
            <div className="C_phpSpace">
              <div className="text-start NwPlaceFont fontColorBlue">
                Requirements
              </div>
              <div className="text-end AU_Font5 pl_left">:</div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-12 remPad">
            <ul className="C_phpUL">
              {RequirementData.Requirements?.map((data, i) => {
                return (
                  <>
                    {i == 0 ? (
                      <li className="mt-1 listFntSize">{data}</li>
                    ) : (
                      <li className="listFntSize">{data}</li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row  my-3 align-items-center remMarPad">
          <div className="col-lg-3 cl-md-3 col-8">
            <div className="C_phpSpace">
              <div className="text-start NwPlaceFont fontColorBlue">
                Company name
              </div>
              <div className="text-end AU_Font5 pl_left2">:</div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-4 companyAddress  padMob marginMinus">
            SprigStack
          </div>
        </div>
        <div className="row  my-3 align-items-center pr_Right">
          <div className="col-lg-3 cl-md-3 col-8 pleft0">
            <div className="C_phpSpace">
              <div className="text-start NwPlaceFont fontColorBlue">
                Location
              </div>
              <div className="text-end AU_Font5 pl_left">:</div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-12 companyAddress listFntSize pr_Right pleft0">
            D-402, THE FIRST, behind Keshavbaug Party Plot, I I M, Vastrapur,
            Ahmedabad, Gujarat 380015
          </div>
        </div>
        <div className="C_phpSpace mt-3 mb-4 ">
          <div
            className="wordBreakM"
            style={{ fontWeight: "600", fontSize: "20px" }}
          >
            If you have any query please contact us on this{" "}
            <a href="mailto:hr@sprigstack.com">
              <span className="fontColorBlue">hr@sprigstack.com</span>
            </a>
          </div>
        </div>
        <div className="px-0 container-fluid pb-4">
          <div className="col-12 text-center py-4">
            <div className="clr0E2B3D  headerFontSizeH6">Apply Now</div>
            <img src="/images/hrImg.png" alt="" className="img-fluid" />
          </div>
          <div className="px-lg-5 pb-4">
            <CareerApplyNow
              RequirementDataPageName={RequirementData.PageName}
            />
          </div>
        </div>
      </div>
      <div className="px-0 container-fluid ">
        <div className="col-12 text-center">
          <h2 className="clr0E2B3D  headerFontSizeH6 whchSSHeading">
            Why Choose SprigStack?
          </h2>
          <img src="/images/hrImg.png" alt="" className="img-fluid" />
        </div>
        <div className="px-lg-6 px-sm-6">
          <div className="row">
            <div className="col-md-4 col-12 py-4 padMob">
              <div
                className="BradCrumbContainer p-md-4 pt-md-0 padMob"
                style={{ boxShadow: "none" }}
              >
                <div className="row align-self-center">
                  <img
                    src="../images/ourcorevalue.svg"
                    alt="logo"
                    className="w-100"
                  />
                </div>
                <h2 className="row  my-2 pt-lg-4 pt-0 align-self-start careercardbox fontColorBlue align-self-start text-m-center ">
                  Our Core Values
                </h2>
                <ul className="careerkeyPoints pt-3">
                  <li>
                    <h6> Trust</h6>
                  </li>
                  <li>
                    <h6>Team Spirit</h6>
                  </li>
                  <li>
                    <h6> Equality</h6>
                  </li>
                  <li>
                    <h6>Perseverance</h6>
                  </li>
                  <li>
                    <h6> Sportsmanship</h6>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-12 py-4 padMob ">
              <div
                className="BradCrumbContainer p-md-4 pt-md-0 padMob"
                style={{ boxShadow: "none" }}
              >
                <div className="row align-self-center">
                  <img
                    src="../images/benefits.svg"
                    alt="logo"
                    className="w-100"
                  />
                </div>
                <h2 className="row  my-2 pt-lg-4 pt-0 align-self-start careercardbox fontColorBlue align-self-start text-m-center ">
                  Benefits
                </h2>

                <ul className="careerkeyPoints pt-3">
                  <li>
                    <h6> Professional Development</h6>
                  </li>
                  <li>
                    <h6>Opportunities to Expand </h6>
                  </li>
                  <li>
                    <h6>Plenty of Learning Opportunities</h6>
                  </li>
                  <li>
                    <h6>Flexible Work Time</h6>
                  </li>
                  <li>
                    <h6> Competitive Pay Structure</h6>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-12 py-4 padMob">
              <div
                className="BradCrumbContainer p-md-4 pt-md-0 padMob"
                style={{ boxShadow: "none" }}
              >
                <div className="row align-self-center">
                  <img
                    src="../images/unbetablePerks.svg"
                    alt="logo"
                    className="w-100"
                  />
                </div>
                <h2 className="row  my-2 pt-lg-4 pt-0 align-self-start careercardbox fontColorBlue align-self-start text-m-center ">
                  Unbeatable Perks
                </h2>
                <ul className="careerkeyPoints pt-3">
                  <li>
                    <h6>5 Working Days</h6>
                  </li>
                  <li>
                    <h6>Work from Anywhere</h6>
                  </li>
                  <li>
                    <h6>Performance Bonus</h6>
                  </li>
                  <li>
                    <h6>Monthly Outings & Annual Trips</h6>
                  </li>
                  <li>
                    <h6>Maternity and Paternity Leave</h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LifeAtSprigStack />
      <div className="pt-md-5"></div>
    </>
  );
}
