export function NamePlateCard(props) {
  return (
    <>
      <div className={` ${props.ColSize} `}>
        <div className={`BradCrumbContainer p-md-4  ${props.CustomClass}`}>
          <div className="w-100 d-flex align-items-center NamePlateMob ">
            <h3
              className={`col-md-10 col-10 my-2 ${props.CustomHeaderClass} `}
              dangerouslySetInnerHTML={{ __html: props.Header }}
            ></h3>
            <div className="col-md-2 col-12 NamePlateImg">
              <img
                src={props.Image}
                alt={props.alt}
                title={props.title}
                className=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
