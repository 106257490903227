import React, { useState } from "react";

export default function TimeSliderMobile(props) {
  var [technologyContent, setTechnologyContent] = useState(
    props.TechnologyContent
  );
  var [technologyImages, settechnologyImages] = useState(0);

  return (
    <>
      <div className=" pt-3">
        <div className="col-12 techmobilebox text-center">
          {technologyContent.map((tech, i) => {
            return (
              <>
                {i < 3 && (
                  <>
                    <div className="col-4 techHover " key={i}>
                      <span
                        className="py-2"
                        onClick={() => settechnologyImages(i)}
                      >
                        {tech.Technology}
                      </span>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
      <div className=" pt-3">
        <div
          className="col-12 techmobilebox text-center"
          style={{ placeContent: "center" }}
        >
          {technologyContent.map((tech, i) => {
            return (
              <>
                {i >= 3 && (
                  <>
                    <div className="col-6 techHover " key={i}>
                      <span
                        className="py-2"
                        onClick={() => settechnologyImages(i)}
                      >
                        {tech.Technology}
                      </span>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>

      <div className="row my-4 mx-auto">
        {technologyImages == 0 && (
          <>
            <div className="col-12">
              {props.TechnologyImages?.map(function (tech, i) {
                return (
                  <div key={i} className="deskDispFlex roww">
                    {tech.Frontend.map((frontend, i) => {
                      return (
                        <div className="px-4 col-4  text-center py-2" key={i}>
                          <div className="techClass">
                            <img
                              src={frontend.Img}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="techNameClass mb-3 mt-2 ">
                            {frontend.Technology}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {technologyImages == 1 && (
          <>
            <div className="col-12">
              {props.TechnologyImages?.map(function (tech, i) {
                return (
                  <div key={i} className="deskDispFlex roww">
                    {" "}
                    {tech.Backend.map((backend, i) => {
                      return (
                        <div className="px-4 col-4  text-center py-2" key={i}>
                          <div className="techClass">
                            <img
                              src={backend.Img}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="techNameClass mb-3 mt-2">
                            {backend.Technology}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {technologyImages == 2 && (
          <>
            <div className="col-12">
              {props.TechnologyImages?.map(function (tech, i) {
                return (
                  <div key={i} className="deskDispFlex roww">
                    {" "}
                    {tech.CMS.map((cms, i) => {
                      return (
                        <div className="px-4 col-4  text-center py-2" key={i}>
                          <div className="techClass">
                            <img src={cms.Img} alt="" className="img-fluid" />
                          </div>
                          <div className="techNameClass mb-3 mt-2">
                            {cms.Technology}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {technologyImages == 3 && (
          <>
            <div className="col-12">
              {props.TechnologyImages?.map(function (tech, i) {
                return (
                  <div key={i} className="deskDispFlex roww">
                    {" "}
                    {tech.DataBases.map((db, i) => {
                      return (
                        <div className="px-4 col-4  text-center py-2" key={i}>
                          <div className="techClass">
                            <img src={db.Img} alt="" className="img-fluid" />
                          </div>
                          <div className="techNameClass mb-3 mt-2">
                            {db.Technology}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {technologyImages == 4 && (
          <>
            <div className="col-12">
              {props.TechnologyImages?.map(function (tech, i) {
                return (
                  <div key={i} className="deskDispFlex roww">
                    {" "}
                    {tech.CloudServices.map((cloud, i) => {
                      return (
                        <div className="px-4 col-4  text-center py-2" key={i}>
                          <div className="techClass">
                            <img src={cloud.Img} alt="" className="img-fluid" />
                          </div>
                          <div className="techNameClass mb-3 mt-2">
                            {cloud.Technology}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}
