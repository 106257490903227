import React from "react";
import { ContentAndImage } from "./Components/ContentAndImage";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import { DescriptiveCard } from "./Components/DescriptiveCard";
import { DevelopmentCommonFunc } from "./Components/DevelopmentCommon";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
export default function ProjectManagement() {
  var techonologiesName = [
    {
      Technology: "Frontend",
    },
    {
      Technology: "Backend",
    },
    {
      Technology: "Mobile",
    },
    {
      Technology: "UI/UX",
    },
    {
      Technology: "SEO",
    },
  ];
  var techonologiesContent = [
    {
      Frontend: [
        {
          Technology: "React.js",
          Img: "/images/Technology/frontendlogo1.png",
        },
        {
          Technology: "Vue.js",
          Img: "/images/Technology/frontendlogo2.png",
        },
        {
          Technology: "Next.js",
          Img: "/images/Technology/frontendlogo3.png",
        },
        {
          Technology: "Angular",
          Img: "/images/Technology/frontendlogo4.png",
        },
        {
          Technology: "Html",
          Img: "/images/Technology/frontendlogo5.png",
        },
        {
          Technology: "Css",
          Img: "/images/Technology/frontendlogo6.png",
        },
        {
          Technology: "Bootstrap",
          Img: "/images/Technology/frontendlogo7.png",
        },
      ],
      Backend: [
        {
          Technology: "Asp.net core",
          Img: "/images/Technology/backendlogo1.png",
        },
        {
          Technology: "Asp.net MVC",
          Img: "/images/Technology/backendlogo2.png",
        },
        {
          Technology: "Node.js",
          Img: "/images/Technology/backendlogo3.png",
        },
        {
          Technology: "Php",
          Img: "/images/Technology/backendlogo4.png",
        },
        {
          Technology: "Laravel",
          Img: "/images/Technology/backendlogo5.png",
        },
        {
          Technology: "Code Igniter",
          Img: "/images/Technology/backendlogo6.png",
        },
      ],
      CMS: [
        {
          Technology: "IOS",
          Img: "/images/Technology/mobile1.png",
        },
        {
          Technology: "Android",
          Img: "/images/Technology/mobile2.png",
        },
        {
          Technology: "Swift",
          Img: "/images/Technology/mobile3.png",
        },
        {
          Technology: "Flutter",
          Img: "/images/Technology/mobile4.png",
        },
        {
          Technology: "React Native",
          Img: "/images/Technology/mobile5.png",
        },
        {
          Technology: "JavaScript",
          Img: "/images/Technology/mobile6.png",
        },
        {
          Technology: "Kotlin",
          Img: "/images/Technology/mobile7.png",
        },
      ],
      DataBases: [
        {
          Technology: "Figma",
          Img: "/images/Technology/figma.png",
        },
        {
          Technology: "Adobe XD",
          Img: "/images/Technology/AdobeXD.png",
        },
        {
          Technology: "Adobe Photoshop",
          Img: "/images/Technology/PhotoShop.png",
        },
        {
          Technology: "Adobe illustrator",
          Img: "/images/Technology/illustrator.png",
        },
        {
          Technology: "Adobe Premierpro",
          Img: "/images/Technology/Premierpro.png",
        },
        {
          Technology: "Adobe After Effect",
          Img: "/images/Technology/AdobeAfterEffect.png",
        },
      ],
      CloudServices: [
        {
          Technology: "Google Analytics",
          Img: "/images/Technology/SeoTools1.svg",
        },
        {
          Technology: "Google Console",
          Img: "/images/Technology/SeoTools2.svg",
        },
        {
          Technology: "Google Ads",
          Img: "/images/Technology/SeoTools3.svg",
        },
        {
          Technology: "Aherfs",
          Img: "/images/Technology/SeoTools4.svg",
        },
        {
          Technology: "Semrush",
          Img: "/images/Technology/SeoTools5.svg",
        },
        {
          Technology: "Moz",
          Img: "/images/Technology/SeoTools6.svg",
        },
      ],
    },
  ];
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/pmMigrationImg1.jpg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Bad Hosting Provider"
      CustomBodyClass="serviceBodyFonts"
      Body="Some hosting providers offer attractive prices to host your websites but their services are not worth. If your online website faces lots of downtime or slow response time, you may loose lots of sales and users. Consider a migration to a best hosting or cloud with our help and provide better experience to your users."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/pmMigrationImg2.jpg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Platform Update"
      CustomBodyClass="serviceBodyFonts"
      Body="If your online website or apps are developed using ancient technologies which do not work anymore and that is costing you loosing business, you need to consider migrating it to newer, faster and safer tech stack. We help you choose the best suitable platform and technology for your ongoing website and give it a whole new identity."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/pmMigrationImg3.jpg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Features Upgrade"
      CustomBodyClass="serviceBodyFonts"
      Body="Sometimes websites are developed using CMS like wordpress or shopify, they they face limitations by CMS to add more customized features for their users. We help migrating existing CMS projects to a customized tech stack and enable it for better control in client’s hand. Add as many customized features as you want for your users."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/pmMigrationImg4.jpg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Website or Apps Redesigning"
      CustomBodyClass="serviceBodyFonts"
      Body="Customer facing websites and apps so often requires redesigning to match with new trend and competition. Our expert designers provide a fancy look which is trendy and user friendly. Our expert programmers make sure backend is not affected by this change so cost of migrating is not significant. "
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-4"
      Image="./images/pmMigrationImg5.jpg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Budget Friendly Services"
      CustomBodyClass="serviceBodyFonts"
      Body="Big corporate companies charge filthy amount to manage your projects. We provide better services at lower costs and we are more client friendly. When you decide to manage or migrate your project with us, you save a considerable amount every month that you can utilize in other developments for your business. "
    />
  );
  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg1.svg"
      CustomHeaderClass="AU_Font5"
      Header="It boosts your <br/> credibility"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg2.svg"
      CustomHeaderClass="AU_Font5"
      Header="It improves User <br/> Engagement Rates"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg3.svg"
      CustomHeaderClass="AU_Font5"
      Header="Allows You To<br/> Reach More People"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg4.svg"
      CustomHeaderClass="AU_Font5"
      Header="Gives You A <br/>Competitive Advantage"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg5.svg"
      CustomHeaderClass="AU_Font5"
      Header="Supports Organic<br/> Content"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg6.svg"
      CustomHeaderClass="AU_Font5"
      Header="Cost Effective<br/> Marketing"
    />
  );
  var processWeFollowConent = [];
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3 "
      Image="./images/processWeFollow/requirementGathering.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="1. Requirement Gathering"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Very first step for building an impactful website or application is putting a well defined requirements together. That is the first and key step we follow."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/processWeFollow/uiUxdesign.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="2. Defining Technology"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Websites can be built in numerous different technologies. We choose the best suitable according to the needs and capabilities of the website we are building."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/processWeFollow/prototype.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="3. UI/UX Design"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Look & feel is very important for websites to attract more and more users. Overall experience and user friendlyness is equally important. This is key step in website development."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/processWeFollow/development.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="4. Prototype"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Based on theme and look that we finalized in the previous step, we provide working prototype to clients to let them understand how will be the end result."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/processWeFollow/qualityAssurance.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="5. Development & QA"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Development phase is started as soon as requirements are clear and all or some designs are already finalized. This is the heart of web application and it has to be done by experts."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/processWeFollow/deployment.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="6. Deployment"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="When development & QA process is complete and website is ready to be moved to production server, that phase is called Deployment. We take care of it and make sure transition is smooth."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/processWeFollow/supportMaintenance.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="7. Support & Maintenance"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="After successful deployment on production server, we make sure everything works as expected and we maintain them as long as clients want us to. We have 100% maintenance retention ratio for our work. "
      IsCard="processWeFollowContainer"
    />
  );
  var whChooseUseLeftBox = [];
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader=" Competitive Pricing "
      Image="./images/competivePricing.svg"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`We provide cost-effective project management services by optimizing timelines, identifying and mitigating risks, and using agile methodologies. We offer transparent pricing and can develop customized plans to meet budgetary constraints.`}
      CardMargin="pt-1 my-lg-0 mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="On Time Delivery "
      Image="./images/ontimedelivery.svg"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`We ensure on-time delivery by creating detailed schedules with milestones, monitoring progress regularly, and using agile methodologies to adapt to changes. We work closely with clients to align stakeholders with the project timeline.`}
      CardMargin="pt-1 my-lg-0 my-4 mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Hybrid Approach"
      Image="./images/hybridapproach.svg"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`We use a hybrid project management approach that combines various methodologies to suit the unique needs of each project, using agile for flexibility and traditional techniques for structure. This approach ensures efficient and effective results for our clients.`}
      CardMargin="pt-1 my-lg-0 my-4 mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  var whyChooseUsContent = (
    <>
      <ContentAndImage
        ImagePosition="left"
        DescriptionHeader=""
        Image="./images/PMImg.jpg"
        alt="Project Management"
        title="Project Management Infographic"
        CardMargin="d-flex align-items-center mt-lg-4 pt-lg-0 pt-4 whyChooseContentCss"
        CustomDescriptionBoxClass="p-lg-2"
        Description={whChooseUseLeftBox}
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12"
        CustomHeaderClass="WhyChooseContentbox"
        bodyClassCareerRequirement="d-none"
      />
    </>
  );
  var HeroSection = {
    ImagePosition: "left",
    DescriptionHeader: "Project Management Services",
    CustomHeaderClass: "C_font1",
    CustomBodyClass: "C_font2 herotextJustify",
    Image: "./images/projectManagementMainImg.svg",
    alt: " Project Management",
    title: "Project Management Services",
    CustomImageClass: "",
    CardMargin: " px-lg-6 px-sm-6",
    CustomDescriptionBoxClass: "p-lg-4 px-4",
    Description:
      "We help manage projects for clients with services like migration, maintenance, and management. Our team offers platform and technology guidance, cloud and database advice, and dedicated project managers to ensure smooth execution. Our migration services are affordable, with quick turnaround times and 40-hour weekly support. We're available 24/7 for emergencies. Your projects are in safe hands with us.",
    customDescriptionItemFirst:
      "col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3",
    customDescriptionItemSecond: "col-lg-6 col-md-6 col-12 homePageGYB",
    bodyClassCareerRequirement: "d-none",
  };
  var Overview = {
    Header: "Overview",
    Content:
      "Our services include migration, maintenance, and management. In addition to platform and technology guidance, we offer cloud and database advice through our dedicated project managers. We offer budget-friendly migration services with reliable and fast turnaround times. With 40-hour weekly support and 24/7 availability for emergencies, you get the worth of every penny spent with us.",
    ContentCustomClass: "pt-3 m-0 herotextJustify",
    HeaderPadding: "overviewHeading",
    flag: true,
  };
  var OurKey = {
    BgColor: "",
    Header: "",
    ContainerBottomPadding: "pt-0",
    Content: "",
    flag: true,
  };
  var PersonalizedCompFirst = {
    cardTitle:
      "Ready to take your projects <br/> to the next level? <br/> Let us guide you.",
    ContainerColor: "FFFPersonalize",
    flag: "1",
  };
  //Ready to take your projects to the next level? Let us guide you.
  var UiUxProcess = {
    BgColor: "#F7F7F7",
    Header: `Web Development Process That We Follow`,
    ContainerBottomPadding: "pb-2",
    ContentCustomClass: "justifyCenter",
    Content: [processWeFollowConent],
  };
  var PersonalizedCompSecond = {
    cardTitle:
      " Minimize downtime and maximize productivity with our expert project management.",
    ContainerColor: "cA49F9D",
    flag: "2",
  };
  // Minimize downtime and maximize productivity with our expert project management.
  var WhyChooseUs = {
    BgColor: "#F7F7F7",
    Header: ` Why Choose us for Project Management? `,
    HeaderPadding: "whyChooseUsHeadingApp",
    ContentCustomClass: "whyChooseUsBox",
    Content: [whyChooseUsContent],
  };
  var DevOpsServices = [];
  var ProcessAutomation = (
    <>
      <div className="bgF7F7F7 mt-md-4 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="right"
          DescriptionHeader="1. Technology Migration"
          DescriptionBottomLineAndButton="true"
          Image="./images/offshoreServiceImg1.svg"
          alt="Technology Migration"
          title="Technology Migration Services"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="Every day, technology advances. With that advancement, adaptability is also necessary. Our preference is to start the project using the technology that is appropriate. Every firm may improve its online brand by utilising our tech shift solution. "
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(ProcessAutomation);
  var SupportMonitoring = (
    <>
      <div className="bgF7F7F7 mt-3 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="left"
          DescriptionHeader="2. Platform Migration"
          DescriptionBottomLineAndButton="true"
          Image="./images/offshoreServiceImg2.svg"
          alt="Platform Migration "
          title="Platform Migration Services	Platform Migration Services"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="In today's market, any organisation must store data on the cloud that is accessible from          anywhere. You may expand your business with the help of our team's expertise in migration. "
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(SupportMonitoring);
  var UpdatePipelineQuality = (
    <>
      <div className="bgF7F7F7 mt-3 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="right"
          DescriptionHeader="3. Maintenance & Enhancements"
          DescriptionBottomLineAndButton="true"
          Image="./images/offshoreServiceImg3.svg"
          alt="Maintenance & Enhancements"
          title="Maintenance & Enhancements Services"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="We are a group of qualified individuals who do development from scrap or new development to maintenance on software built by ourselves or others. It is also essential to enhance the project that is competent with the market structure. "
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(UpdatePipelineQuality);
  var DevOpsConsultingServices = (
    <>
      <div className="bgF7F7F7 mt-3 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="left"
          DescriptionHeader="4. Project Management"
          DescriptionBottomLineAndButton="true"
          Image="./images/offshoreServiceImg4.svg"
          alt="Project Management"
          title="Project Management"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="Mere development doesn't satisfy the requirement, it is also necessary to update the features as per the requirement. We ensure that the project is correctly handled and managed without any downtime. "
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(DevOpsConsultingServices);
  return (
    <>
      <HelmetMetaTag
        titleName="Effective Project Management Solutions | SprigStack"
        desc="Streamline your process with our DevOps services. Our experts help you automate, optimize, monitor your applications for seamless operation. Contact us now!"
        canonical="https://sprigstack.com/project-management"
        ogtitle="Effective Project Management Solutions | SprigStack"
        ogdesc="Streamline your process with our DevOps services. Our experts help you automate, optimize, monitor your applications for seamless operation. Contact us now!"
        ogtype="website"
        ogurl="https://sprigstack.com/project-management"
      />
      <DevelopmentCommonFunc
        HeroSection={HeroSection}
        Overviewsection={Overview}
        isDevOpsSection={true}
        isHrLine={false}
        isProjectManagementSection={true}
        DevOpsHeaderTitle="Offshore Services we Offer"
        SubHeader=""
        DevOpsServices={DevOpsServices}
        OurKeySection={OurKey}
        PersonalizedCompOne={PersonalizedCompFirst}
        Space={false}
        isProjectSection={true}
        TechnologySection={
          <>
            <div className="bgF7F7F7 mb-3 py-lg-0 py-5">
              <div className="col-12 text-center pt-lg-5 mt-5">
                <h2 className="headerFontSizeH6 fontColorBlue ProjectTechMob">
                  Tools we use for Project Management
                </h2>
                <img
                  src="/images/hrImg.png"
                  alt=""
                  className="img-fluid mt-1"
                ></img>
              </div>
              <div className="row">
                <div className="container">
                  <div className="deskDispFlex px-5 py-4 justify-content-center gridClass">
                    <div className="px-4 text-center py-2">
                      <div className="techClass">
                        <img
                          src="./images/Technology/jira.svg"
                          className="img-fluid"
                          style={{ height: "4em" }}
                          alt="Jira"
                          title="Jira"
                        />
                      </div>
                      <h3 className="techNameClass">Jira</h3>
                    </div>
                    <div className="px-4 text-center py-2">
                      <div className="techClass">
                        <img
                          src="./images/Technology/clickup.svg"
                          className="img-fluid"
                          style={{ height: "4em" }}
                          alt="Clickup"
                          title="Clickup"
                        />
                      </div>
                      <h3 className="techNameClass">Clickup</h3>
                    </div>
                    <div className="px-4 text-center py-2">
                      <div className="techClass">
                        <img
                          src="./images/Technology/trello.svg"
                          className="img-fluid"
                          style={{ height: "4em" }}
                          alt="Trello"
                          title="Trello"
                        />
                      </div>
                      <h3 className="techNameClass">Trello</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ------------------------------------------------ */}
            <>
              <div className="bgF7F7F7  py-lg-0 py-4">
                <div className="col-12 text-center pt-lg-5">
                  <h2 className="headerFontSizeH6 fontColorBlue ProjectTechMob">
                    Tools Used for Version Control in Website & App Development
                  </h2>
                  <img
                    src="/images/hrImg.png"
                    alt=""
                    title=""
                    className="img-fluid mt-1"
                  ></img>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="deskDispFlex px-5 py-4 justify-content-center gridClass2 gridClass">
                      <div className="px-4 text-center py-2">
                        <div className="techClass">
                          <img
                            src="./images/Technology/github.svg"
                            className="img-fluid"
                            style={{ height: "4em" }}
                            alt="Github"
                            title="Github"
                          />
                        </div>
                        <h3 className="techNameClass"> Github </h3>
                      </div>
                      <div className="px-4 text-center py-2">
                        <div className="techClass">
                          <img
                            src="./images/Technology/gitlab.svg"
                            className="img-fluid"
                            style={{ height: "4em" }}
                            alt="Gitlab"
                            title="Gitlab"
                          />
                        </div>
                        <h3 className="techNameClass">Gitlab </h3>
                      </div>
                      <div className="px-4 text-center py-2">
                        <div className="techClass">
                          <img
                            src="./images/Technology/visualstudio.svg"
                            className="img-fluid"
                            style={{ height: "4em" }}
                            alt="Team Foundation Server"
                            title="Team Foundation Server"
                          />
                        </div>
                        <h3 className="techNameClass">
                          Team Foundation Server
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </>
        }
        PersonalizedCompTwo={PersonalizedCompSecond}
        IsNewSection="no"
        WhyChooseUsSection={WhyChooseUs}
        FAQSection={<FAQDynamicComp />}
      />
    </>
  );
}
