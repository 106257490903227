import React, { useState } from "react";
import PersonalizedActionModal from "../Modals/PersonalizedActionModal";
import Popups from "../Modals/Popups";
import $ from "jquery";
import { ContentAndImage } from "./ContentAndImage";

export default function PersonalizedComp(props) {
  const [show, setShow] = useState(false);
  var width = $(window).width();
  return (
    <>
      {props.flag == 1 ? (
        width >= 991 ? (
          <div className="container-fluid pt-5 px-lg-6 px-sm-6 position-relative">
            <img
              src="/images/personalizedGroup.svg"
              alt=""
              className="img-fluid w-100"
            />
            <div className="personalizedImageDivcol-5">
              <div
                className="col-12 mt-2Point5 ps-3 AU_Font7 "
                style={{ fontSize: "30px" }}
                dangerouslySetInnerHTML={{ __html: props.cardTitle }}
              ></div>
              <div className="col-12 ps-4 mt-4">
                <button
                  className="btn btn CaseStudydBtn px-4"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  GET A FREE ESTIMATION
                  <img
                    className="img-fluid ms-2"
                    src="/images/personalizedWhiteArrow.svg"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="row p-3 my-lg-0 mt-5"
            style={{ background: "#203F59" }}
          >
            <div className="col-lg-6 col-md-6 col-5 setctaimg">
              <img
                src="./images/CTA_m1.svg"
                alt="logo"
                className="image-fluid "
                style={{ height: "95% ", marginTop: "5px" }}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-7 px-0 m-auto">
              <div className="col-12">
                <h2
                  className={`setheader pe-2 cta1_mob ${props.TitleCss1}`}
                  dangerouslySetInnerHTML={{ __html: props.cardTitle }}
                ></h2>
              </div>
              <div className="col-12 mt-4">
                <button
                  className={`btn btn setbtn CaseStudydBtn px-1 ${props.Cta1Css}`}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <img
                    className="img-fluid me-2 "
                    src="./images/right_arrow.svg"
                    alt=""
                    style={{ marginLeft: "-3px" }}
                  />
                  GET A FREE ESTIMATION
                </button>
              </div>
            </div>
          </div>
        )
      ) : props.flag == 3 ? (
        <>
          {/* <div className="bgF7F7F7 py-lg-0 mt-lg-5 py-4 px-4 divHoverEffect ">
            <ContentAndImage
              ImagePosition="right"
              DescriptionHeader="Custom Web Application Development Services"
              h1Tag={false}
              alt="Custom Web Application Development Services"
              DescriptionBottomLineAndButton={false}
              Image="./images/web-dev.svg"
              CustomImageClass="webDpManImage"
              CardMargin="mx-lg-5 mx-4"
              CustomDescriptionBoxClass=""
              Description="We specialize in creating web-based software on various devices and platforms to deliver exceptional user experience. Our team of experts uses the latest web technologies to create high-quality web applications and web portals. Our custom web apps align with your business objectives and maximize resource utilization as we provide the best web app development services within the estimated timeframe."
              customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
              customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB ps-md-4 pe-md-5"
              RedirectUrl="/web-development"
              IsShowButton="webDevButton"
              IsShowButtonShow={true}
              IsRedirectToSamePage="yes"
              CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
              bodyClassCareerRequirement="d-none"
              CustomBodyClass="textalignleft"
              setShow={setShow}
            />
          </div> */}
        </>
      ) : (
        <>
          {width >= 991 ? (
            <div className="container-fluid pt-5 px-6 px-sm-6 ">
              <div className="px-3">
                <div className={`row ${props.ContainerColor}`}>
                  <div className="col-lg-5 col-md-5 col-12 px-0">
                    <img
                      className="img-fluid float-left"
                      src="/images/personalizedImgNew.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-7 col-md-7 col-12 text-center ps-0 m-auto">
                    <div
                      className="col-12 mt-3 ps-1 AU_NewFont w-60 cffffff"
                      style={{ fontSize: "30px" }}
                      dangerouslySetInnerHTML={{ __html: props.cardTitle }}
                    ></div>
                    <div className="col-12 my-4 text-center">
                      <button
                        className="btn btn personalizedBtn px-4"
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        GET A FREE CONSULTATION
                        <img
                          className="img-fluid ms-2"
                          src="/images/personalizedArrow.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row p-5" style={{ background: "#203F59" }}>
              <h2
                className={`setheader ${props.TitleCss}`}
                style={{ fontSize: "15px", lineHeight: "24px" }}
                dangerouslySetInnerHTML={{ __html: props.cardTitle }}
              ></h2>
              <button
                className="btn btn setbtn CaseStudydBtn px-3 mt-4 w-75"
                onClick={() => {
                  setShow(true);
                }}
              >
                <img
                  className="img-fluid mx-2 "
                  src="./images/right_arrow.svg"
                  alt=""
                />
                GET A FREE CONSULTATION
              </button>
            </div>
          )}
        </>
      )}
      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">
        <PersonalizedActionModal />
      </Popups>
    </>
  );
}
