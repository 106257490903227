import React from "react";
import { ContentAndImage } from "./ContentAndImage";
import { HeaderWithDynamicContent } from "./HeaderWithDynamicContent";
import PersonalizedComp from "./PersonalizedComp";
import { TechnologiesFunction } from "./UiUxTechnologies";
import { ClientComp } from "./ClientComp";
export function DevelopmentCommonFunc(props) {
  return (
    <>
      <ContentAndImage
        h1Tag={true}
        ImagePosition={props.HeroSection.ImagePosition}
        DescriptionHeader={props.HeroSection.DescriptionHeader}
        CustomHeaderClass={props.HeroSection.CustomHeaderClass}
        CustomBodyClass={props.HeroSection.CustomBodyClass}
        Image={props.HeroSection.Image}
        alt={props.HeroSection.alt}
        title={props.HeroSection.title}
        CustomImageClass={props.HeroSection.CustomImageClass}
        CardMargin={props.HeroSection.CardMargin}
        CustomDescriptionBoxClass={props.HeroSection.CustomDescriptionBoxClass}
        Description={props.HeroSection.Description}
        customDescriptionItemFirst={
          props.HeroSection.customDescriptionItemFirst
        }
        customDescriptionItemSecond={
          props.HeroSection.customDescriptionItemSecond
        }
        bodyClassCareerRequirement={
          props.HeroSection.bodyClassCareerRequirement
        }
        IsShowButton="HeroSection"
        IsShowButtonShow={true}
        HeroSection={true}
      />
      {props.isDevOpsSection && (
        <>
          <HeaderWithDynamicContent
            Header={props.DevOpsHeaderTitle}
            HeaderPadding={props.DevOpsWeOfr}
            SubHeader={props.SubHeader}
            isFullWidth={true}
            pt={"pt"}
            //SubHeader="We have setup standards for high quality deliverables and we stick to them. Our Transparency and Process make us the best choice."
            Content={props.DevOpsServices}
          />
          <PersonalizedComp
            cardTitle={props.PersonalizedCompOne?.cardTitle}
            ContainerColor={props.PersonalizedCompOne?.ContainerColor}
            flag={props.PersonalizedCompOne?.flag}
          />
        </>
      )}
      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor={props.OurKeySection?.BgColor}
        Header={props.OurKeySection?.Header}
        ContainerBottomPadding={props.OurKeySection?.ContainerBottomPadding}
        Content={props.OurKeySection?.Content}
        HeaderPadding={props.OurKeySection?.HeaderPadding}
        flag={props.OurKeySection?.flag}
        pt={true}
      />
      {props.isDevOpsSection && (
        <>
          {props.IsNewSection == "yes" ? (
            <TechnologiesFunction
              Tech={props.TechnologySection?.Tech[0]}
              MainHeader={props.TechnologySection?.MainHeader}
              SubHeader={props.TechnologySection?.SubHeader}
            />
          ) : (
            <>{props.TechnologySection}</>
          )}
          {!props.isProjectManagementSection && props.isReactTech && (
            <>
              <PersonalizedComp
                cardTitle={props.PersonalizedCompTwo?.cardTitle}
                ContainerColor={props.PersonalizedCompTwo?.ContainerColor}
                flag={props.PersonalizedCompTwo?.flag}
              />
            </>
          )}
        </>
      )}
      {!props.isDevOpsSection && (
        <>
          <PersonalizedComp
            cardTitle={props.PersonalizedCompOne?.cardTitle}
            ContainerColor={props.PersonalizedCompOne?.ContainerColor}
            TitleCss1={props.PersonalizedCompOne?.TitleCss1}
            Cta1Css={props.PersonalizedCompOne?.Cta1Css}
            flag={props.PersonalizedCompOne?.flag}
          />
          {props.MajorBenefitsSection && (
            <HeaderWithDynamicContent
              BgColor={props.MajorBenefitsSection?.BgColor}
              Header={props.MajorBenefitsSection?.Header}
              HeaderPadding={props.MajorBenefitsSection?.HeaderPadding}
              ContentCustomClass={
                props.MajorBenefitsSection?.ContentCustomClass
              }
              Content={props.MajorBenefitsSection?.Content}
              flag={props.MajorBenefitsSection?.flag}
            />
          )}
        </>
      )}
      {props.Space == true ? (
        <HeaderWithDynamicContent
          HrLine={props.isHrLine}
          BgColor={props.UiUxProcessSection?.BgColor}
          Header={props.UiUxProcessSection?.Header}
          HeaderPadding={props.UiUxProcessSection?.HeaderPadding}
          ContainerBottomPadding={
            props.UiUxProcessSection?.ContainerBottomPadding
          }
          ContentCustomClass={props.UiUxProcessSection?.ContentCustomClass}
          Content={props.UiUxProcessSection?.Content}
        />
      ) : (
        <></>
      )}
      {!props.isDevOpsSection && (
        <>
          {props.IsNewSection == "yes" ? (
            <TechnologiesFunction
              Tech={props.TechnologySection?.Tech[0]}
              MainHeader={props.TechnologySection?.MainHeader}
              HeaderCss={props.TechnologySection?.HeaderCss}
              SubHeaderCss={props.TechnologySection?.SubHeaderCss}
              SubHeader={props.TechnologySection?.SubHeader}
            />
          ) : (
            <>{props.TechnologySection}</>
          )}
          <PersonalizedComp
            cardTitle={props.PersonalizedCompTwo?.cardTitle}
            ContainerColor={props.PersonalizedCompTwo?.ContainerColor}
            flag={props.PersonalizedCompTwo?.flag}
          />
          {props.RecentWorkSection && (
            <HeaderWithDynamicContent
              BgColor={props.RecentWorkSection?.BgColor}
              Header={props.RecentWorkSection?.Header}
              HeaderPadding={props.RecentWorkSection?.HeaderPadding}
              ContentCustomClass={props.RecentWorkSection?.ContentCustomClass}
              ContainerBottomPadding={
                props.RecentWorkSection?.ContainerBottomPadding
              }
              Content={props.RecentWorkSection?.Content}
              flag={props.RecentWorkSection?.flag}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: props.NewSection }}></div>
        </>
      )}
      {props.isProjectManagementSection && (
        <>
          <PersonalizedComp
            cardTitle={props.PersonalizedCompTwo?.cardTitle}
            TitleCss={props.PersonalizedCompTwo?.titleCss}
            ContainerColor={props.PersonalizedCompTwo?.ContainerColor}
            flag={props.PersonalizedCompTwo?.flag}
          />
        </>
      )}
      {props.IsClientComp && (
      <>
      <ClientComp/>
      </>
      )}
      {props.WhyChooseUsSection && (
        <HeaderWithDynamicContent
          BgColor={props.WhyChooseUsSection?.BgColor}
          Header={props.WhyChooseUsSection?.Header}
          HeaderPadding={props.WhyChooseUsSection?.HeaderPadding}
          ContentCustomClass={props.WhyChooseUsSection?.ContentCustomClass}
          Content={props.WhyChooseUsSection?.Content}
        />
      )}
      {props.FAQSection}
    </>
  );
}
