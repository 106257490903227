import React from "react";
const DEVELOPMENT_URL = process.env.REACT_APP_DEVELOPMENT_URL;
const CUSTOM_URL = process.env.REACT_APP_CUSTOM_URL;
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
export default function Getcall(url, CustomUrl = DEVELOPMENT_URL) {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return fetch(CustomUrl + url, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
}
export function PostCall(url, raw) {
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  return fetch(CUSTOM_URL + url, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
}
