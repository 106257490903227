import React from "react";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import PortfolioComp from "./Components/PortfolioComp";
import PersonalizedComp from "./Components/PersonalizedComp";
import IndustryServingsComp from "./Components/IndustryServingsComp";
import HomePortfolioComponent from "./Components/HomePortfolioComponent";
import { TempTestPage } from "./TempTestPage";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import LazyLoadingImg from "./Components/LazyLoadingImg";

export function Portfolio() {
  var width = $(window).width();
  var techonologiesName = [
    {
      Technology: "Frontend",
    },
    {
      Technology: "Backend",
    },
    {
      Technology: "Mobile",
    },
    {
      Technology: "UI/UX",
    },
    {
      Technology: "SEO",
    },
  ];
  var techonologiesContent = [
    {
      Frontend: [
        {
          Technology: "React.js",
          Img: "/images/Technology/frontendlogo1.png",
          alt: "React logo",
          title: "Frontend Technology React ",
        },
        {
          Technology: "Vue.js",
          Img: "/images/Technology/frontendlogo2.png",
          alt: "Vue.js logo",
          title: "Frontend Technology Vue.js",
        },
        {
          Technology: "Next.js",
          Img: "/images/Technology/frontendlogo3.png",
          alt: "Next.js logo",
          title: "Frontend Technology Next.js",
        },
        {
          Technology: "Angular",
          Img: "/images/Technology/frontendlogo4.png",
          alt: "Angular logo",
          title: "Frontend Technology Angular",
        },
        {
          Technology: "Html",
          Img: "/images/Technology/frontendlogo5.png",
          alt: "Html logo",
          title: "Frontend Technology Html",
        },
        {
          Technology: "Css",
          Img: "/images/Technology/frontendlogo6.png",
          alt: "CSS logo",
          title: "Frontend Technology CSS",
        },
        {
          Technology: "Bootstrap",
          Img: "/images/Technology/frontendlogo7.png",
          alt: "BootStrap logo",
          title: "Frontend Technology BootStrap",
        },
      ],
      Backend: [
        {
          Technology: "Asp.net core",
          Img: "/images/Technology/backendlogo1.png",
          alt: "Asp.net core logo  ",
          title: "Backend Technology Asp.net core ",
        },
        {
          Technology: "Asp.net MVC",
          Img: "/images/Technology/backendlogo2.png",
          alt: "Asp.net MVC logo",
          title: "Backend Technology Asp.net MVC ",
        },
        {
          Technology: "Node.js",
          Img: "/images/Technology/backendlogo3.png",
          alt: "Node.js logo",
          title: "Backend Technology Node.js",
        },
        {
          Technology: "Php",
          Img: "/images/Technology/backendlogo4.png",
          alt: "Php logo",
          title: "Backend Technology Php",
        },
        {
          Technology: "Laravel",
          Img: "/images/Technology/backendlogo5.png",
          alt: "Laravel logo",
          title: "Backend Technology Laravel",
        },
        {
          Technology: "Code Igniter",
          Img: "/images/Technology/backendlogo6.png",
          alt: "Code Igniter logo",
          title: "Backend Technology Code Igniter",
        },
      ],
      CMS: [
        {
          Technology: "IOS",
          Img: "/images/Technology/mobile1.png",
          alt: "IOS",
          title: "IOS",
        },
        {
          Technology: "Android",
          Img: "/images/Technology/mobile2.png",
          alt: "Android",
          title: "Android",
        },
        {
          Technology: "Swift",
          Img: "/images/Technology/mobile3.png",
          alt: "Swift ",
          title: "Swift ",
        },
        {
          Technology: "Flutter",
          Img: "/images/Technology/mobile4.png",
          alt: "Flutter",
          title: "Flutter",
        },
        {
          Technology: "React Native",
          Img: "/images/Technology/mobile5.png",
          alt: "React Native",
          title: "React Native",
        },
        {
          Technology: "JavaScript",
          Img: "/images/Technology/mobile6.png",
          alt: "Java Script",
          title: "Java Script",
        },
        {
          Technology: "Kotlin",
          Img: "/images/Technology/mobile7.png",
          alt: "Kotlin",
          title: "Kotlin",
        },
      ],
      DataBases: [
        {
          Technology: "Figma",
          Img: "/images/Technology/figma.png",
          alt: "Figma",
          title: "Technology Figma",
        },
        {
          Technology: "Adobe XD",
          Img: "/images/Technology/AdobeXD.png",
          alt: "Adobe XD",
          title: "Technology Adobe XD",
        },
        {
          Technology: "Adobe Photoshop",
          Img: "/images/Technology/PhotoShop.png",
          alt: "Adobe Photoshop",
          title: "Technology Adobe Photoshop",
        },
        {
          Technology: "Adobe illustrator",
          Img: "/images/Technology/illustrator.png",
          alt: "Adobe illustrator",
          title: "Technology Adobe illustrator",
        },
        {
          Technology: "Adobe Premierpro",
          Img: "/images/Technology/Premierpro.png",
          alt: "Adobe Premiere Pro",
          title: "Technology Adobe Premiere Pro",
        },
        {
          Technology: "Adobe After Effect",
          Img: "/images/Technology/AdobeAfterEffect.png",
          alt: "Adobe After Effect",
          title: "Technology Adobe After Effect",
        },
      ],
      CloudServices: [
        {
          Technology: "Google Analytics",
          Img: "/images/Technology/SeoTools1.svg",
          alt: "Google Analytics",
          title: "Google Analytics",
        },
        {
          Technology: "Google Console",
          Img: "/images/Technology/SeoTools2.svg",
          alt: "Google Console",
          title: "Google Console",
        },
        {
          Technology: "Google Ads",
          Img: "/images/Technology/SeoTools3.svg",
          alt: "Google Adwords",
          title: "Google Adwords",
        },
        {
          Technology: "Aherfs",
          Img: "/images/Technology/SeoTools4.svg",
          alt: "Aherfs",
          title: "Aherfs",
        },
        {
          Technology: "Semrush",
          Img: "/images/Technology/SeoTools5.svg",
          alt: "Semrush",
          title: "Semrush",
        },
        {
          Technology: "Moz",
          Img: "/images/Technology/SeoTools6.svg",
          alt: "Moz",
          title: "Moz",
        },
        {
          Technology: "Sitebulb",
          Img: "/images/Technology/SeoTools7.svg",
          alt: "Sitebulb",
          title: "Sitebulb",
        },
        {
          Technology: "Screaming Frog",
          Img: "/images/Technology/frog.svg",
          alt: "Screaming Frog",
          title: "Screaming Frog",
        },
      ],
    },
  ];
  return (
    <>
      <HelmetMetaTag
        titleName="Explore Our Portfolio of Innovative IT Solutions | SprigStack"
        desc="Explore SprigStack's portfolio for top-notch web development and design projects, showcasing our expertise in frontend, backend, mobile, UI/UX, and SEO."
        canonical="https://sprigstack.com/portfolio"
        ogtitle="Explore Our Portfolio of Innovative IT Solutions | SprigStack"
        ogdesc="Explore SprigStack's portfolio for top-notch web development and design projects, showcasing our expertise in frontend, backend, mobile, UI/UX, and SEO."
        ogtype="website"
        ogurl="https://sprigstack.com/portfolio"
      />
      <div className="pb-5">
        <div className="container-fluid px-0">
          <div className="row  px-lg-6 px-sm-6">
            <div className="col-lg-6 col-md-6 col-12 p-md-5 p-3 ">
              <LazyLoadingImg
                src="/images/portfolioHeroImg1.png"
                alt="Portfolio"
                title="Portfolio Infographic"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-12 homePageGYB  p-lg-4 ps-3">
              <span className=" row mt-lg-0  devTags">
                <h1 className=""> Our Creative Portfolio </h1>
              </span>
              <div
                className="row mb-3  C_font2 "
                style={{ textAlign: "justify", padding: "1rem" }}
              >
                Our proficiency in technology allows us to design custom
                solutions that cater to the unique needs of our clients.
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-md-5 px-4 pb-4">
          <div className="col-12 text-center">
            <h2 className="clr0E2B3D headerFontSizeH6 recentportfolioheading">
              Recent Projects Developed with Varied Technologies
            </h2>
            <img src="/images/PortfolioHR.png" alt="" className="img-fluid" />
          </div>
        </div>
        <HomePortfolioComponent
          ImagePosition="right"
          mainClass="recentPPCards"
          PortfolioCardBGC="#EFF2FF"
          title="ERP System for the Travel Industry"
          borderColor="#EFF2FF"
          customSectionCol="col-lg-8 col-md-8 col-12"
          customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
          customSection2Col="col-lg-6 col-md-6 col-6"
          customSection2ColPadding="1.8rem"
          sectionOneTextOne="100K+"
          sectionOneTextTwo="Tour Package Bookings"
          imgSrc="EurorientePortfolioImg.webp"
          sectionTextColor="cl2D4BE8"
          linkTitle="Technologies Used : React, .Net Core, PostgreSql, Bootstrap, Aws Cloud, Stripe, Twilio"
          TitleCustomClasses="fontColorBlue"
          portfolioBtn={true}
          cardClass="leftPortfolioPara newportpadding"
          IndustryServingsHoverCompimgSrc="images/Vector (2).png"
          IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
          customLineHeight="45px"
          customMarginImg="maxwid-55 pt-5"
          IsRedirectToSamePage="yes"
          redirectNumber="/portfolio/development-of-an-erp-system-for-the-travel-industry"
        />
        <HomePortfolioComponent
          ImagePosition="left"
          mainClass="recentPPCards"
          PortfolioCardBGC="#EEFFFD"
          title="K-12 : Web Based Solution for the Ed-Tech Industry"
          borderColor="rgb(234, 245, 255)"
          customSectionCol="col-lg-8 col-md-8 col-12"
          customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
          customSection2Col="col-lg-6 col-md-6 col-6"
          customSection2ColPadding="1.8rem"
          sectionOneTextOne="1000+"
          sectionOneTextTwo="Device's Repaired"
          imgSrc="K12-mockup.webp"
          sectionTextColor="cl3CBBA5"
          linkTitle="Technologies Used : Laravel, React, Bootstrap, MySql, Aws Cloud, Fedex Api's "
          TitleCustomClasses="fontColorBlue"
          portfolioBtn={true}
          cardClass="p-md-5 newportpadding"
          IndustryServingsHoverCompimgSrc="images/Vector (2).png"
          IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
          customLineHeight="45px"
          customMarginImg="max70 py-4"
          IsRedirectToSamePage="yes"
          redirectNumber="/portfolio/website-development-for-K12"
        />
        <HomePortfolioComponent
          ImagePosition="right"
          mainClass="recentPPCards"
          PortfolioCardBGC="rgb(239, 242, 255)"
          title="AddressTwo - Small Business CRM"
          borderColor="rgb(32,63,89)"
          customSectionCol="col-lg-8 col-md-8 col-12"
          customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
          customSection2Col="col-lg-6 col-md-6 col-6"
          customSection2ColPadding="1.8rem"
          sectionOneTextOne="5M+"
          sectionOneTextTwo="Daily Emails Delivered"
          sectionTwoTextOne="100+"
          sectionTwoTextTwo="Monthly New Signups"
          imgSrc="AddressTwoMockup.webp"
          sectionTextColor="fontColorBlue"
          linkTitle="Technologies Used :Asp.net core, Angular.js, MySql, Bootstrap"
          TitleCustomClasses="fontColorBlue"
          portfolioBtn={true}
          cardClass="leftPortfolioPara newportpadding"
          IndustryServingsHoverCompimgSrc="images/Vector (2).png"
          IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
          redirectNumber="/portfolio/custom-crm-development-for-address-two"
          customLineHeight="34px"
          customMarginImg="max60 py-4"
        />
        <HomePortfolioComponent
          ImagePosition="left"
          mainClass="recentPPCards"
          PortfolioCardBGC="#F0ECE2"
          title="Hybrid Application Development for Al-Wajeeh"
          borderColor="#F0ECE2"
          customSectionCol="col-lg-8 col-md-8 col-12"
          customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
          customSection2Col="col-lg-6 col-md-6 col-6"
          customSection2ColPadding="1.8rem"
          sectionOneTextOne="4.5"
          sectionOneTextTwo="Average Rating"
          imgSrc="SMBLMockup.png"
          sectionTextColor="clA4894B"
          linkTitle="Technologies Used : Laravel, pusher, React, AWS, twilio, MySql"
          TitleCustomClasses="fontColorBlue"
          portfolioBtn={true}
          cardClass="p-md-5 newportpadding"
          IndustryServingsHoverCompimgSrc="images/Vector (2).png"
          IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
          customLineHeight="45px"
          customMarginImg="max70 py-4"
          IsRedirectToSamePage="yes"
          redirectNumber="/portfolio/hybrid-application-development-for-al-wajeeh"
        />
        <HomePortfolioComponent
          ImagePosition="right"
          mainClass="recentPPCards"
          PortfolioCardBGC="#FCF1F2"
          title="My Pet Carnivore"
          borderColor="#00671c"
          customSectionCol="col-lg-8 col-md-8 col-12"
          customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
          customSection2Col="col-lg-6 col-md-6 col-6"
          customSection2ColPadding="1.8rem"
          sectionOneTextOne="10000+"
          sectionOneTextTwo="Happy Customers"
          sectionTwoTextOne="500+"
          sectionTwoTextTwo="Daily Orders"
          imgSrc="mpc-portfolio.png"
          sectionTextColor="clB60117"
          linkTitle="Technologies Used : Bootstrap, MySql, Laravel, Webhooks"
          TitleCustomClasses="fontColorBlue"
          portfolioBtn={true}
          cardClass="leftPortfolioPara newportpadding pb-4"
          IndustryServingsHoverCompimgSrc="images/Vector (2).png"
          IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
          customLineHeight="45px"
          customMarginImg="max80 py-4"
          IsRedirectToSamePage="yes"
          redirectNumber="/portfolio/ecommerce-website-development-for-my-pet-carnivore"
        />
        <HomePortfolioComponent
          ImagePosition="left"
          mainClass="recentPPCards"
          PortfolioCardBGC="#ECF2EB"
          title=" MarketWagon - Local Food Delivered"
          borderColor="#00671c "
          customSectionCol="col-lg-8 col-md-8 col-12"
          customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
          customSection2Col="col-lg-6 col-md-6 col-6"
          customSection2ColPadding="1.8rem"
          sectionOneTextOne="1M+"
          sectionOneTextTwo="Orders Delivered"
          sectionTwoTextOne="4.5"
          sectionTwoTextTwo="Average Rating"
          imgSrc="portfolio1.webp"
          sectionTextColor="Cl00671C"
          linkTitle="Technologies Used : Laravel, Vue.js, Alpine.js, MySql"
          TitleCustomClasses="fontColorBlue"
          portfolioBtn={false}
          cardClass="p-md-5 newportpadding"
          IndustryServingsHoverCompimgSrc="images/LearnMoreGreenIcon.png"
          IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
          redirectNumber=""
          customLineHeight="34px"
        />
        <HomePortfolioComponent
          ImagePosition="right"
          mainClass="recentPPCards"
          PortfolioCardBGC="#F2EBEB"
          title="Gocoworq - Marketplace for Coworking"
          borderColor="#ff7700"
          customSectionCol="col-lg-9 col-md-9 col-12"
          customSection1Col="col-lg-5 col-md-5 col-6 ps-4"
          customSection2Col="col-lg-5 col-md-5 col-6"
          customSection2ColPadding="2rem"
          sectionOneTextOne="10K+"
          sectionOneTextTwo="Daily Searches"
          sectionTwoTextOne="100+"
          sectionTwoTextTwo="Daily Inquires"
          imgSrc="portfolio2.webp"
          sectionTextColor="Clff7700"
          linkTitle="Technologies Used : Laravel, Vue.js, MySql, Bootstrap"
          TitleCustomClasses="fontColorBlue"
          portfolioBtn={false}
          cardClass="leftPortfolioPara newportpadding"
          IndustryServingsHoverCompimgSrc="images/LearnMoreOrangeIcon.png"
          IndustryServingsHoverComphoverImg="images/LearnMoreOrangeFullIcon.png"
          redirectNumber=""
          customLineHeight="34px"
        />
        <HomePortfolioComponent
          ImagePosition="left"
          mainClass="recentPPCards"
          PortfolioCardBGC="#FFF0EB"
          title="Berger's Table Pads - Custom-made Table Pads Online"
          borderColor="#00671c"
          customSectionCol="col-lg-8 col-md-8 col-12"
          customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
          customSection2Col="col-lg-6 col-md-6 col-6"
          customSection2ColPadding="1.8rem"
          sectionOneTextOne="100+"
          sectionOneTextTwo="Daily Online Orders"
          sectionTwoTextOne="Top 3"
          sectionTwoTextTwo="SEO Ranking"
          imgSrc="Mockup_6 3.png"
          sectionTextColor="Cl7C331E"
          linkTitle="Technologies Used : React.js, Asp.net core, Sql server, Authorize.net"
          TitleCustomClasses="fontColorBlue"
          portfolioBtn={false}
          cardClass="p-md-5 newportpadding"
          IndustryServingsHoverCompimgSrc="images/Vector (2).png"
          IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
          redirectNumber=""
          customLineHeight="45px"
        />

        {/* images section start */}
        {width >= 991 ? (
          <div className="mx-md-5 pt-md-5 ">
            <div className="row pt-md-3">
              <div className="col-md-6 py-md-2 ">
                <div className="col-lg-12 col-12 pt-md-2 ">
                  <PortfolioComp
                    imgsrc="portfolioCardImg9.png"
                    imgHeight="Setheight "
                    containerColor="#F7FFE9"
                    fontsize="22"
                    text1="BoxScore - Real Time Feedback"
                    text2="Tech: Php, Bootstrap, MySql, Jquery, Google Maps"
                    flag={2}
                    cardClass="p-md-5 "
                    paddingClass="py-md-0 mb-md-1 ps-md-5 newportpadding "
                    IsRedirectToSamePage="yes"
                    redirectNumber="/portfolio"
                  />
                </div>
              </div>
              <div className="col-md-6 py-2 ">
                <div className="col-lg-12 col-12 ">
                  <div className="row">
                    <div className="col-lg-6 col-12 pt-2">
                      <PortfolioComp
                        imgsrc="portfolioCardImg10.png"
                        imgHeight="addHeight"
                        containerColor="#EDFCFF"
                        fontsize="16"
                        text2size="14"
                        text1="Talevation - Hiring Assessment"
                        text2="Tech: Wordpress, MySql, Javascript"
                        flag={2}
                        paddingClass="pt-2"
                        redirecturl="https://www.talevation.com/"
                      />
                    </div>
                    <div className="col-lg-6 col-12 pt-2 ">
                      <PortfolioComp
                        imgsrc="portfolioCardImg11.png"
                        imgHeight="addHeight"
                        containerColor="#F4F4F4"
                        fontsize="16"
                        text2size="14"
                        text1="Tulamama - Maternity Goods"
                        text2="Tech: Wordpress, Woo-Commerce, JS"
                        flag={2}
                        paddingClass="pt-2"
                        redirecturl="https://tulamama.com/"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12 pt-5 ">
                  <PortfolioComp
                    imgsrc="portfolioCardImg12.png"
                    containerColor="#FFF5ED"
                    fontsize="18"
                    text2size="14"
                    text1="SecureRoofing - Roof Fitting Company ERP"
                    text2="Tech: Asp.net Core, Jqeury, Sql Server, Google Charts, SSRS Reports"
                    flag={1}
                    redirecturl="https://devsecureroofing.azurewebsites.net/"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Carousel
            showArrows={false}
            showThumbs={false}
            autoPlay={true}
            transitionTime={0.8}
            infiniteLoop={true}
            showStatus={false}
            showIndicators={false}
          >
            <div className="mx-md-5 pt-md-5 recentPPCards">
              <div className="row pt-md-3">
                <div className="col-md-6 py-md-2">
                  <div className="col-lg-12 col-12 pt-md-2 ">
                    <PortfolioComp
                      imgsrc="portfolioCardImg9.png"
                      imgHeight="Setheight setHeightMob"
                      containerColor="#F7FFE9"
                      text1="BoxScore - Real Time Feedback"
                      text2="Tech: Php, Bootstrap, MySql, Jquery, Google Maps"
                      flag={2}
                      cardClass="p-md-5 "
                      paddingClass="py-md-4 ps-md-5 newportpadding setmobileicon"
                      redirecturl="https://www.boxscoreme.com/"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 py-md-2 ">
              <div className="col-lg-12 col-12 pt-md-2 setmobilepadding">
                <PortfolioComp
                  imgsrc="portfolioCardImg10.png"
                  imgHeight="addHeight"
                  containerColor="#EDFCFF"
                  text1="Talevation - Hiring Assessment"
                  text2="Tech: Wordpress, MySql, Javascript"
                  flag={2}
                  cardClass="p-md-5 "
                  paddingClass="py-md-4 ps-md-5 newportpadding setmobileicon"
                  redirecturl="https://www.talevation.com/"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12 pt-md-2 setmobilepadding">
              <PortfolioComp
                imgsrc="portfolioCardImg11.png"
                imgHeight="addHeight"
                containerColor="#F4F4F4"
                text1="Tulamama - Maternity Goods"
                text2="Tech: Wordpress, Woo-Commerce, JS"
                flag={2}
                paddingClass="pt-2 setmobileicon"
                redirecturl="https://tulamama.com/"
              />
            </div>

            <div className="col-lg-12 col-12 pt-4 setmobilepadding">
              <PortfolioComp
                imgsrc="portfolioCardImg12.png"
                containerColor="#FFF5ED"
                text1="SecureRoofing - Roof Fitting Company ERP"
                text2="Tech: Asp.net Core, Jqeury, Sql Server, Google Charts, SSRS Reports"
                flag={2}
                redirecturl="https://devsecureroofing.azurewebsites.net/"
                paddingClass="setmobileicon"
              />
            </div>
          </Carousel>
        )}
        <PersonalizedComp
          cardTitle="Share your idea and let's <br/> make it happen!"
          ContainerColor="FFFPersonalize"
          flag="1"
        />
        <div className="pt-md-5 pt-3">
          <HomePortfolioComponent
            ImagePosition="right"
            mainClass="recentPPCards"
            PortfolioCardBGC="#EFFAFF"
            title="TreatMe - Doctor at your Fingertip"
            borderColor="#03658c"
            customSectionCol="col-lg-8 col-md-8 col-12"
            customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
            customSection2Col="col-lg-6 col-md-6 col-6"
            customSection2ColPadding="1.8rem"
            sectionOneTextOne="100K+"
            sectionOneTextTwo="App Booked"
            sectionTwoTextOne="4.5"
            sectionTwoTextTwo="Average Rating"
            imgSrc="appDevImage1.png"
            sectionTextColor="cls03658C"
            linkTitle="Technologies Used : React Native, Asp.net Core, Sql Server"
            TitleCustomClasses="fontColorBlue"
            portfolioBtn={false}
            cardClass="leftPortfolioPara pb-md-5 newportpadding"
            IndustryServingsHoverCompimgSrc="images/LearnMoreGreenIcon.png"
            IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
            redirectNumber=""
            customLineHeight="34px"
          />
          <HomePortfolioComponent
            ImagePosition="left"
            mainClass="recentPPCards"
            PortfolioCardBGC="#EFEEFF "
            title="Walletier - Payments Made Easy"
            borderColor="#201E47"
            customSectionCol="col-lg-8 col-md-8 col-12"
            customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
            customSection2Col="col-lg-6 col-md-6 col-6"
            customSection2ColPadding="1.8rem"
            sectionOneTextOne="100K+"
            sectionOneTextTwo="Daily Transactions"
            sectionTwoTextOne="15+"
            sectionTwoTextTwo="Payment Options"
            imgSrc="WalletierImg.png"
            sectionTextColor="cls201E47"
            linkTitle="Technologies Used : React Native, Laravel, Payment Gateway APIs"
            TitleCustomClasses="fontColorBlue"
            portfolioBtn={false}
            cardClass="p-md-5 newportpadding"
            IndustryServingsHoverCompimgSrc="images/LearnMoreGreenIcon.png"
            IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
            redirectNumber=""
            customLineHeight="34px"
          />
          <HomePortfolioComponent
            ImagePosition="right"
            mainClass="recentPPCards"
            PortfolioCardBGC="#EAF5FF"
            title="Euroriente - One Stop Travel Guide"
            borderColor="#0E8BFF"
            customSectionCol="col-lg-9 col-md-9 col-12"
            customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
            customSection2Col="col-lg-6 col-md-6 col-6"
            customSection2ColPadding="1.8rem"
            sectionOneTextOne="20K+"
            sectionOneTextTwo="Downloads"
            sectionTwoTextOne="100K+"
            sectionTwoTextTwo="Tour Package Bookings"
            imgSrc="EurorienteImg.png"
            sectionTextColor="cls0E8BFF"
            linkTitle="Technologies Used : Flutter, Stripe APIs, Laravel, MySql"
            TitleCustomClasses="fontColorBlue"
            portfolioBtn={false}
            cardClass="leftPortfolioPara pb-md-5 newportpadding"
            IndustryServingsHoverCompimgSrc="images/LearnMoreGreenIcon.png"
            IndustryServingsHoverComphoverImg="images/LearnMoreGreenFullIcon.png"
            redirectNumber="/portfolio/development-of-an-erp-system-for-the-travel-industry"
            customLineHeight="34px"
          />
        </div>
        <PersonalizedComp
          cardTitle="Ready to turn your idea into reality? <br/> Let's get started!"
          ContainerColor="cA49F9D"
          flag="2"
        />
        <TempTestPage
          TechName={techonologiesName}
          TechContent={techonologiesContent}
          mainheader="Technologies that we expertise!"
          HeaderPadding="porttechHeading"
          SubHeaderCss="porttechSubHeading"
          flag={true}
          subHeader="Top Technologies for Developing High-Quality Websites & Web Apps."
        />
        {/* Industry Servings */}
        <IndustryServingsComp bgColorClass="mt-4" HeaderPadding="py-4" />
      </div>
    </>
  );
}
