import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function TextandHoverComponenet(props) {
  const [Img, setImg] = useState(props.imgSrc);
  const [logoClass, setlogoClass] = useState(props.customClass);
  return (
    <React.Fragment>
      <div>
        <LazyLoadImage
          onMouseEnter={() => {
            setImg(props.hoverSrc);
            setlogoClass(props.hoverColor);
          }}
          onMouseLeave={() => {
            setImg(props.imgSrc);
            setlogoClass(props.customClass);
          }}
          effect="blur"
          src={`/images/${Img}.svg`}
          alt={props.alt}
          title={props.title}
          className={`${
            props.pointer ? "img-fluidM " : "crsr-pointer img-fluid"
          } desktop hoverEffect mb-2`}
        />
        <LazyLoadImage
          effect="blur"
          src={`/images/${props.hoverSrc}.svg`}
          alt={props.alt}
          title={props.title}
          className={`${
            props.pointer ? "img-fluidM" : "crsr-pointer img-fluidM"
          } mobile mb-2 ${props.height}`}
        />
        <span className={`${logoClass}`}>{props.Name}</span>
      </div>
    </React.Fragment>
  );
}
