import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function FAQDynamicComp() {
  const [RequirementData, setRequirementData] = useState([]);
  const location = useLocation();
  useLayoutEffect(() => {
    var validUrl = true;
    axios
      .get("/jsonFiles/FAQData.json")
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (location.pathname == res.data[i].PageName) {
            setRequirementData(res.data[i]);
            validUrl = false;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`${JSON.stringify(
          RequirementData?.SchemaData
        )}`}</script>
      </Helmet>
      <div className="container-fluid pt-lg-5 pt-4">
        <div className="col-12 text-center">
          <h2 className="clr0E2B3D  headerFontSizeH6">
            Frequently Asked Questions
          </h2>
          <img src="images/hrImg.png" alt="" className="img-fluid "></img>
        </div>
        <div
          className="row  mx-lg-5 mx-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="accordion mt-4 mb-3 px-md-2 ps-0 pe-0"
            id="accordionExample"
          >
            {RequirementData?.SchemaData?.mainEntity?.map((data, i) => {
              return (
                <>
                  {location.pathname == "/" && i == 8 ? (
                    <div className="accordion-item mb-3" key={parseInt(i)}>
                      <h3 className="accordion-header" id="headingOne">
                        <button
                          className={`accordions-button ${
                            i != 0 && "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${i}`}
                          aria-expanded={i == 0 ? "true" : "false"}
                          aria-controls={`collapse${i}`}
                        >
                          How to choose the right software development company?
                        </button>
                      </h3>
                      <div
                        id={`collapse${i}`}
                        className={`accordion-collapse collapse ${
                          i == 0 && "show"
                        }`}
                        collapse
                        show
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body pb-3 pt-0">
                          The process of choosing a software development company
                          can be challenging.Here are some factors to consider
                          while selecting a software development company:
                          <br />
                          <br />
                          <strong>Expertise:</strong> Look for a company that
                          has experience in developing software solutions
                          similar to your requirements, industry, and technology
                          stack.
                          <br />
                          <br />
                          <strong>Quality:</strong> Check the company's
                          portfolio, client testimonials, case studies, reviews,
                          and awards to assess their quality and reputation.
                          <br />
                          <br />
                          <strong>Communication:</strong> Ensure that the
                          company has excellent communication skills and can
                          provide timely updates, feedback, and collaboration
                          throughout the project.
                          <br />
                          <br />
                          <strong>Process:</strong> Verify that the company
                          follows a structured and agile development process
                          that aligns with your project's requirements, budget,
                          and timeline.
                          <br />
                          <br />
                          <strong>Cost:</strong> Evaluate the company's pricing
                          model, payment terms, and hidden costs to ensure that
                          it fits your budget and financial goals.
                        </div>
                      </div>
                    </div>
                  ) : location.pathname == "/api-integration-services" &&
                    i == 3 ? (
                    <>
                      <div className="accordion-item mb-3" key={parseInt(i)}>
                        <h3 className="accordion-header" id="headingOne">
                          <button
                            className={`accordions-button ${
                              i != 0 && "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${i}`}
                            aria-expanded={i == 0 ? "true" : "false"}
                            aria-controls={`collapse${i}`}
                          >
                            How do I start with an API integration project?
                          </button>
                        </h3>
                        <div
                          id={`collapse${i}`}
                          className={`accordion-collapse collapse ${
                            i == 0 && "show"
                          }`}
                          collapse
                          show
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pb-3 pt-0">
                            Starting an API integration project involves several
                            steps:
                            <br />
                            <br />
                            <strong>Define Goals:</strong> Clearly define the
                            purpose and objectives of the integration.
                            <br />
                            <br />
                            <strong>Select APIs:</strong>Identify the APIs you
                            need to integrate based on the functionalities you
                            want to achieve.
                            <br />
                            <br />
                            <strong>Design Flow:</strong> Plan how data will
                            flow between systems using these APIs.
                            Authentication: Implement proper authentication and
                            authorization mechanisms to ensure secure access.
                            <br />
                            <br />
                            <strong>Development:</strong> Write code to connect
                            and interact with the chosen APIs.
                            <br />
                            <br />
                            <strong>Testing:</strong> Thoroughly test the
                            integration to identify and resolve any issues.
                            <br />
                            <br />
                            <strong>Deployment:</strong> Deploy the integration
                            and monitor its performance.
                            <br />
                            <br />
                            <strong>Maintenance:</strong> Regularly update and
                            maintain the integration as needed.
                          </div>
                        </div>
                      </div>
                    </>
                  ) : location.pathname == "/api-integration-services" &&
                    i == 4 ? (
                    <>
                      <div className="accordion-item mb-3" key={parseInt(i)}>
                        <h3 className="accordion-header" id="headingOne">
                          <button
                            className={`accordions-button ${
                              i != 0 && "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${i}`}
                            aria-expanded={i == 0 ? "true" : "false"}
                            aria-controls={`collapse${i}`}
                          >
                            How securely do you implement the API integration?
                          </button>
                        </h3>
                        <div
                          id={`collapse${i}`}
                          className={`accordion-collapse collapse ${
                            i == 0 && "show"
                          }`}
                          collapse
                          show
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pb-3 pt-0">
                            Implementing API integration securely is crucial to
                            protect sensitive data and maintain system
                            integrity. Here's how to ensure security:
                            <br />
                            <br />
                            <strong>Authentication:</strong> To control access,
                            use secure authentication methods like OAuth, API
                            keys, or JWT tokens.
                            <br />
                            <br />
                            <strong>Encryption:</strong> Employ encryption
                            (HTTPS) to secure data transmission between systems.
                            <br />
                            <br />
                            <strong>Input Validation:</strong> Validate all
                            input data to prevent injection attacks
                            <br />
                            <br />
                            <strong>Error Handling:</strong> Implement proper
                            error handling to prevent exposure to sensitive
                            information.
                            <br />
                            <br />
                            <strong>Rate Limiting:</strong> Set rate limits to
                            prevent excessive API requests, protecting against
                            abuse.
                            <br />
                            <br />
                            <strong>Monitoring and Logging:</strong> Monitor API
                            usage and log activities to detect and respond to
                            suspicious behavior.
                            <br />
                            <br />
                            <strong>Regular Updates:</strong> Keep APIs and
                            libraries updated to patch security vulnerabilities.
                          </div>
                        </div>
                      </div>
                    </>
                  ) 
                  : location.pathname == "/crm-development-services" &&
                    i == 2 ? (
                    <>
                      <div className="accordion-item mb-3" key={parseInt(i)}>
                        <h3 className="accordion-header" id="headingOne">
                          <button
                            className={`accordions-button ${
                              i != 0 && "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${i}`}
                            aria-expanded={i == 0 ? "true" : "false"}
                            aria-controls={`collapse${i}`}
                          >
                            Why should I choose custom CRM development?
                          </button>
                        </h3>
                        <div
                          id={`collapse${i}`}
                          className={`accordion-collapse collapse ${
                            i == 0 && "show"
                          }`}
                          collapse
                          show
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pb-3 pt-0">
                          Here are some reasons why you might choose custom CRM development:
                            <br />
                            <br />
                            <strong>Improved Efficiency:</strong> A custom CRM can streamline your workflows and processes, saving your team time and effort.
                            <br />
                            <br />
                            <strong>Enhanced Customer Service:</strong> Custom CRMs can provide your team with a centralized view of all customer data, which can help them provide better service.
                            <br />
                            <br />
                            <strong>Increased Sales:</strong> By automating tasks and improving customer relationships, custom CRMs can help you close more deals.
                            <br />
                            <br />
                            <strong>Better Data Management:</strong> Custom CRMs can be designed to capture and store the specific data that is important to your business.
                            <br />
                            <br />
                            <strong>Scalability:</strong> Custom CRMs can be built to grow with your business.
                          </div>
                        </div>
                      </div>
                    </>
                  ) 
                  : location.pathname == "/erp-development-services" &&
                    i == 2 ? (
                    <>
                      <div className="accordion-item mb-3" key={parseInt(i)}>
                        <h3 className="accordion-header" id="headingOne">
                          <button
                            className={`accordions-button ${
                              i != 0 && "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${i}`}
                            aria-expanded={i == 0 ? "true" : "false"}
                            aria-controls={`collapse${i}`}
                          >
                           What factors should I consider when choosing an ERP development company?
                          </button>
                        </h3>
                        <div
                          id={`collapse${i}`}
                          className={`accordion-collapse collapse ${
                            i == 0 && "show"
                          }`}
                          collapse
                          show
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pb-3 pt-0">
                          Here are some key factors to consider:
                            <br />
                            <br />
                            <strong>Experience:</strong> Look for a company with a proven track record of successful ERP development projects in your industry.
                            <br />
                            <br />
                            <strong>Expertise:</strong> Choose a company with a team of experienced ERP developers who understand your specific needs.
                            <br />
                            <br />
                            <strong>Customization:</strong> Ensure the company offers custom development services to tailor the ERP system to your business.
                            <br />
                            <br />
                            <strong>Scalability:</strong>  Select a company that builds systems designed to grow alongside your business.
                            <br />
                            <br />
                            <strong>Support:</strong> Choose a company that provides ongoing support and maintenance services.
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="accordion-item mb-3">
                      <h3 className="accordion-header" id="headingOne">
                        <button
                          className={`accordions-button ${
                            i != 0 && "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${i}`}
                          aria-expanded={i == 0 ? "true" : "false"}
                          aria-controls={`collapse${i}`}
                        >
                          {data?.name}
                        </button>
                      </h3>
                      <div
                        id={`collapse${i}`}
                        className={`accordion-collapse collapse ${
                          i == 0 && "show"
                        }`}
                        collapse
                        show
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body pb-3 pt-0">
                          {data?.acceptedAnswer?.text}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
