import React from "react";
import { ContentAndImage } from "./Components/ContentAndImage";
import { HeaderWithDynamicContent } from "./Components/HeaderWithDynamicContent";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import IndustryServingsHoverComp from "./Components/IndustryServingsHoverComp";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
import CrmErpCta from "./Components/CrmErpCta";
import ProcessDynamicComp from "./DynamicComponent/ProcessDynamicComp";
import { TempTestPage } from "./TempTestPage";
import WhyChooseUsComp from "./Components/WhyChooseUsComp";
import { ClientComp } from "./Components/ClientComp";
import { DevelopmentRecentWork } from "./Components/DevelopmentWork";
import HelmetMetaTag from "./Components/HelmetMetaTag";

export default function WepApp(props) {
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/erp-service1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Custom Web Application Development"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Invest in custom web app services  crafted from scratch to surpass pre-made options. Collaborate closely with our team to pinpoint your business goals and needs. We design, develop, and deploy a web app that aligns with your vision and integrates seamlessly with your systems."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/ecommerce-service2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="Front end Development Services"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Our front-end developers craft stunning, intuitive, and responsive user interfaces (UI) for your web application. Using HTML5, CSS3, and JavaScript frameworks like React or Angular, we guarantee functionality and captivating design across all devices."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/webapp-service3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="Progressive Web Apps Development"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="PWAs blend website and mobile app features, offering app-like functionality, including offline access and push notifications, without needing an app store installation. This widens accessibility and boosts user engagement. With our PWA development services, your app will be performant, reliable, and offer a native app-like experience."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/webapp-service4.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="Backend Development Services"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="An application's back end handles the crucial behind-the-scenes operations of the application.
      Our back-end developers build scalable and secure web applications. They handle data storage, retrieval, manipulation, server communication, and application logic.  Our aim is to swiftly and securely launch your web application."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/webapp-service5.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="E-commerce Web Apps Development"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Our team specializes in creating powerful, scalable e-commerce web applications. For seamless online sales, payment gateways and comprehensive product management systems. Leveraging Shopify, Magento, and custom frameworks, we help you build e-shops and B2B eCommerce portals that drive your growth."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/webapp-service6.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="Web Application Support and Maintenance"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Our web application support and maintenance services ensure your application functions flawlessly. We promptly address bugs or technical issues, keeping your application secure with the latest updates. Additionally, we provide ongoing maintenance to optimize performance, functionality, and user experience."
    />
  );

  var recentWorkContent = (
    <>
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="AddressTwo - Small Business CRM"
        Image="./images/AddressTwoRecentImg.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 align-items-center RecentContainer"
        Description="Technologies Used : Laravel, Vue.js, MySql, Bootstrap"
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12  px-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 "
        RedirectUrl="https://www.addresstwo.com/"
        DevelopmentRecentWorkBtn={true}
        redirectNumber="/portfolio/custom-crm-development-for-address-two"
        CardMargin="col-md-6"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="ERP System for the Travel Industry"
        Image="./images/erp-web-dev.png"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-3 mx-md-2 mt-2 mb-2 py-4 align-items-center RecentContainer"
        Description="Technologies Used : React, .Net Core, PostgreSql, Bootstrap, Aws Cloud, Stripe, Twilio"
        customDescriptionItemFirst="col-lg-5 col-md-5 col-12  px-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 "
        DevelopmentRecentWorkBtn={true}
        redirectNumber="/portfolio/development-of-an-erp-system-for-the-travel-industry"
        CardMargin="col-md-6"
      />
    </>
  );

  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit1.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Enhanced Security & <br/> Data Protection"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit2.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Intuitive User Experience"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit3.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Streamline Workflows"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit4.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Increased Efficiency"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit5.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Integration with Existing <br/> Systems"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit6.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Scalability and Flexibility"
      CustomClass="MajorBenefitsContainer"
    />
  );

  var whyChooseUsContent = [];
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/webapp-whychooseus1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Timely Project Delivery"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={`Ensuring your web app launches within budget is a priority for us. We place a strong emphasis on timely delivery to ensure your project hits the ground running.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/webapp-whychooseus2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="In-Depth Industry Knowledge"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`Our deep industry knowledge empowers us to craft web apps that seamlessly integrate with your unique business landscape.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-5 pt-4"
      Image="./images/ecommerce-whychooseus3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Agile Development practices"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`We embrace an agile development approach, fostering close collaboration to adapt to your needs and deliver a valuable web app quickly.`}
    />
  );
  var techonologiesName = [
    {
      Technology: "Frontend",
    },
    {
      Technology: "Backend",
    },
    {
      Technology: "CMS",
    },
    {
      Technology: "Databases",
    },
    {
      Technology: "Cloud Services",
    },
  ];

  var techonologiesContent = [
    {
      Frontend: [
        {
          Technology: "React.js",
          Img: "/images/Technology/frontendlogo1.png",
          alt: "React logo",
          title: "Frontend Technology React ",
        },
        {
          Technology: "Vue.js",
          Img: "/images/Technology/frontendlogo2.png",
          alt: "Vue.js logo",
          title: "Frontend Technology Vue.js",
        },
        {
          Technology: "Next.js",
          Img: "/images/Technology/frontendlogo3.png",
          alt: "Next.js logo",
          title: "Frontend Technology Next.js",
        },
        {
          Technology: "Angular",
          Img: "/images/Technology/frontendlogo4.png",
          alt: "Angular logo",
          title: "Frontend Technology Angular",
        },
        {
          Technology: "Html",
          Img: "/images/Technology/frontendlogo5.png",
          alt: "Html  logo",
          title: "Frontend Technology Html",
        },
        {
          Technology: "Css",
          Img: "/images/Technology/frontendlogo6.png",
          alt: "CSS logo",
          title: "Frontend Technology CSS",
        },
        {
          Technology: "Bootstrap",
          Img: "/images/Technology/frontendlogo7.png",
          alt: "BootStrap logo",
          title: "Frontend Technology BootStrap",
        },
      ],
      Backend: [
        {
          Technology: "Asp.net core",
          Img: "/images/Technology/backendlogo1.png",
          alt: "Asp.net core logo",
          title: "Backend Technology Asp.net core ",
        },
        {
          Technology: "Asp.net MVC",
          Img: "/images/Technology/backendlogo2.png",
          alt: "Asp.net MVC logo",
          title: "Backend Technology Asp.net MVC",
        },
        {
          Technology: "Node.js",
          Img: "/images/Technology/backendlogo3.png",
          alt: "Node.js logo",
          title: "Backend Technology Node.js",
        },
        {
          Technology: "Php",
          Img: "/images/Technology/backendlogo4.png",
          alt: "Php logo",
          title: "Backend Technology Php",
        },
        {
          Technology: "Laravel",
          Img: "/images/Technology/backendlogo5.png",
          alt: "Laravel logo",
          title: "Backend Technology Laravel",
        },
        {
          Technology: "Code Igniter",
          Img: "/images/Technology/backendlogo6.png",
          alt: "Code Igniter logo",
          title: "Backend Technology Code Igniter",
        },
      ],
      CMS: [
        {
          Technology: "Wordpress",
          Img: "/images/wordpress.png",
          alt: "Wordpress logo",
          title: "CMS Wordpress",
        },
        {
          Technology: "Shopify",
          Img: "/images/Technology/cms2.png",
          alt: "Shopify logo",
          title: "CMS Shopify",
        },
        {
          Technology: "Joomla",
          Img: "/images/Technology/cms3.png",
          alt: "Joomla logo",
          title: "CMS Joomla",
        },
        {
          Technology: "Wix",
          Img: "/images/Technology/cms4.png",
          alt: "Wix logo",
          title: "CMS Wix",
        },
        {
          Technology: "Webflow",
          Img: "/images/Technology/cms5.png",
          alt: "Webflow logo",
          title: "CMS Webflow  ",
        },
      ],
      DataBases: [
        {
          Technology: "Sql Server",
          Img: "/images/Technology/database1.png",
          alt: "Sql Server logo",
          title: "Data Base Sql Server",
        },
        {
          Technology: "MySql Server",
          Img: "/images/Technology/database2.png",
          alt: "MySql Server logo",
          title: "Data Base MySql Server",
        },
        {
          Technology: "MongoDB",
          Img: "/images/Technology/database3.png",
          alt: "MongoDB logo",
          title: "Data Base MongoDB",
        },
        {
          Technology: "PostgreSql",
          Img: "/images/Technology/database4.png",
          alt: "PostgreSql logo",
          title: "Data Base PostgreSql",
        },
        {
          Technology: "AWS RDS",
          Img: "/images/Technology/database5.png",
          alt: "AWS RDS logo",
          title: "Data Base AWS RDS",
        },
      ],
      CloudServices: [
        {
          Technology: "Amazon AWS",
          Img: "/images/Technology/CloudService1.png",
          alt: "Amazon AWS logo",
          title: "Data Base Amazon AWS",
        },
        {
          Technology: "Microsoft Azure",
          Img: "/images/Technology/CloudService2.png",
          alt: "Microsoft Azure logo",
          title: "Data Base Microsoft Azure",
        },
        {
          Technology: "Google Cloud",
          Img: "/images/Technology/CloudService3.png",
          alt: "Google Cloud logo",
          title: "Data Base Google Cloud",
        },
      ],
    },
  ];

  return (
    <>
      <HelmetMetaTag
        titleName="Custom Web Application Development Services | SprigStack"
        desc="Grow your business with SprigStack's Custom Web App Development Services, delivering high-performing, secure apps designed for flawless multi-device usage."
        canonical={window.location.href}
        ogtitle="Custom Web Application Development Services | SprigStack"
        ogdesc="Grow your business with SprigStack's Custom Web App Development Services, delivering high-performing, secure apps designed for flawless multi-device usage."
        ogtype="website"
        ogurl={window.location.href}
      />
      <ContentAndImage
        h1Tag={true}
        ImagePosition="left"
        DescriptionHeader="Custom Web Application Development Services"
        CustomHeaderClass="C_font1"
        CustomBodyClass="C_font2"
        Image="./images/web-app-hero.svg"
        alt="Web Application Development Services"
        title="Web Application Development Services"
        CustomImageClass="img-fluid"
        CardMargin=" px-lg-6 px-sm-6"
        CustomDescriptionBoxClass="p-lg-4 px-4"
        Description="Looking to enhance your business with a web app that delivers exceptional user experiences? We've got you covered. With our custom web application development services, we specialize in crafting outstanding user experiences through custom web applications that function flawlessly across any device or platform. Our team of experts uses the latest web technologies to create high-quality web apps and web portals. As a web application development company, we prioritize scalable web app solutions for optimal performance and security, ensuring your custom web app aligns with your business goals and is delivered on time."
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3 text-center"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 homePageGYB"
        bodyClassCareerRequirement="d-none"
        IsShowButton="HeroSection"
        IsShowButtonShow={true}
        HeroSection={true}
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor="#F7F7F7"
        Header="Our key Web App Development <br/> Services include"
        ContainerBottomPadding="pb-md-3 pt-5"
        Content={serviceConent}
        HeaderPadding="ourKeyHeading"
        flag={true}
        pt={true}
      />

      <HeaderWithDynamicContent
        BgColor=""
        Header="Major Benefits of our custom Web Development services"
        HeaderPadding="majorBenefitHeading"
        ContentCustomClass=""
        Content={webAppBenefitContent}
        flag={true}
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor="#F7F7F7"
        Header="Web Application Process that We Follow"
        HeaderPadding="webProcessHeading"
        ContainerBottomPadding="pb-2 "
        ContentCustomClass="justifyCenter weFollowContainer py-4"
        Content={<ProcessDynamicComp />}
      />

      <TempTestPage
        TechName={techonologiesName}
        TechContent={techonologiesContent}
        mainheader="Technologies Used for Web Applications"
        HeaderPadding="technologiesHeading"
        pt={true}
      />

      <CrmErpCta
        CtaDiv="px-6 mt-md-5 mt-4"
        quoteName="webappQuote"
        TextCols="col-md-7 col-12 d-flex align-items-center px-5 py-3 py-md-5"
        ImgCols="col-md-5 col-12 p-3 p-md-5"
        ImgPadding="py-1 py-md-0"
        quoteText="A custom Web Application from scratch!"
        CtaBtn="CaseStudydBtn"
        arrowImg="personalizedWhiteArrow.svg"
        quoteImg="/images/web-app-cta.svg"
      />

      <HeaderWithDynamicContent
        BgColor=""
        Header="Recent Web App Development Work"
        HeaderPadding="RecentWorkHeading"
        ContentCustomClass=""
        ContainerBottomPadding="RecentworkPadding"
        flag={true}
        Content={recentWorkContent}
      />
      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="Industries Served with our Web App Development Services"
        HeaderPadding="majorBenefitHeading"
        pt={true}
      />
      <div className="row mt-2 px-6">
        <IndustryServingsHoverComp
          imgSrc="/images/Retail_Blue.svg"
          hoverImg="/images/Retail_Orange.svg"
          cardTitle="E-commerce"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Communication-blue.svg"
          hoverImg="/images/Communication_orange.svg"
          cardTitle="Telecommunication"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/hospitality-crm-blue.svg"
          hoverImg="/images/hospitality-crm-orange.svg"
          cardTitle="Hospitality"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
          imgBorder=""
        />
        <IndustryServingsHoverComp
          imgSrc="/images/manufacturinfg-crm-blue.svg"
          hoverImg="/images/manufacturinfg-crm-orange.svg"
          cardTitle="Manufacturing"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
          imgBorder=""
        />
        <IndustryServingsHoverComp
          imgSrc="/images/healthcare-crm-blue.svg"
          hoverImg="/images/healthcare-crm-orange.svg"
          cardTitle="Healthcare"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/construction-crm-blue.svg"
          hoverImg="/images/construction-crm-orange.svg"
          cardTitle="Construction"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/ItSolution__Blue.svg"
          hoverImg="/images/ItSolution_Orange.svg"
          cardTitle="IT Solution"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Travel_Blue.svg"
          hoverImg="/images/Travel_Orange.svg"
          cardTitle="Travel"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Education_Blue.svg"
          hoverImg="/images/Education_Orange.svg"
          cardTitle="Education"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Banking&FinanceBlue.svg"
          hoverImg="/images/Banking&FinanceOrange.svg"
          cardTitle="Banking & Finance"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/logistics-erp-blue.svg"
          hoverImg="/images/logistics-erp-orange.svg"
          cardTitle="Logistic"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/RealEstate_Blue.svg"
          hoverImg="/images/RealEstate_Orange.svg"
          cardTitle="Real Estate"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
        />
      </div>

      {/* <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="What our clients think about us!"
        HeaderPadding="majorBenefitHeading"
        pt={true}
      />
      <div className="px-6 text-center mt-4">
        <img
          src="/images/DummyAvtar.png"
          alt="client"
          style={{ borderRadius: "50%" }}
        />
        <h4 className="fontPoppins mt-3">Jason Barresi</h4>
        <h6 className="fontPoppins">Director at Address Two</h6>
        <img src="/images/video_icon.png" alt="" className={`clickable`}></img>
        <h6 className="custom-blockquote fontPoppins text-justify mt-3">
          We've been working with SprigStack for several years now and couldn't
          be happier with the partnership. As a result, we use them as our only
          third-party agency for this and new projects across our subsidiaries.
        </h6>
      </div> */}
      <ClientComp />

      <HeaderWithDynamicContent
        BgColor="#F7F7F7"
        Header="Why choose us for web app services?"
        HeaderPadding="whyChooseUsHeading"
        ContentCustomClass="whyChooseUsBox"
        Content={whyChooseUsContent}
      />
      <FAQDynamicComp />
    </>
  );
}
