import React, { useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function HoverComponent(props) {
  const [Img, setImg] = useState(props.imgSrc);
  let className = props.pointer ? "img-fluidM" : "crsr-pointer img-fluidM";
  if (props.addImgFluidClass) {
    className = "img-fluid";
  }
  return (
    <>
      <img
        src={`images/${Img}.svg`}
        alt={props.alt}
        title={props.title}
        className={`${className} desktop`}
        onMouseEnter={() => {
          setImg(props.hoverSrc);
        }}
        onMouseLeave={() => {
          setImg(props.imgSrc);
        }}
      />
      <img
        src={`images/${props.hoverSrc}.svg`}
        alt={props.alt}
        title={props.title}
        className={`${className} mobile`}
      />
    </>
  );
}
