import React, { useState, useRef, useEffect, useReducer } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { formReducer, INITIAL_STATE } from "../Reducer/UserDetailsReduser";
import { FileUploader } from "react-drag-drop-files";
export default function CareerApplyNow(props) {
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [file, setFile] = useState(null);
  const ReCAPTCHAValue = useRef();
  const [successMsg, setSuccessMsg] = useState({
    reCaptchaErrMsg: "",
    mailSentMsg: false,
    fileErr: "",
  });
  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const fileTypes = ["pdf", "doc", "docx"];
  const handleChangeFile = (file) => {
    setSuccessMsg({ ...successMsg, fileErr: "" });
    setFile(file);
  };
  const phpModalForm = (e) => {
    e.preventDefault();
    setSuccessMsg({ ...successMsg, reCaptchaErrMsg: "", fileErr: "" });
    let ifValid = true;
    var fileValue = file;
    if (fileValue == undefined) {
      setSuccessMsg({ ...successMsg, fileErr: "Please upload file" });
      ifValid = false;
      return;
    } else if (!/\.(pdf|doc|docx)$/i.test(fileValue.name)) {
      setSuccessMsg({
        ...successMsg,
        fileErr: "Please select an pdf, doc, docx file only",
      });
      ifValid = false;
      return;
    } else if (fileValue.size / 1024 / 1024 > 2) {
      setSuccessMsg({ ...successMsg, fileErr: "Max.file size: 2 MB." });
      ifValid = false;
      return;
    } else if (ReCAPTCHAValue.current.getValue() == "") {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please complete the captcha",
        fileErr: "",
      });
      ifValid = false;
      return;
    } else ifValid = true;
    if (ifValid) {
      var formdata = new FormData();
      formdata.append("Name", state.Name);
      formdata.append("ContactNumber", state.ContactNumber);
      formdata.append("Email", state.Email);
      // urlencoded.append("ProjectDetail", state.ProjectDetail);
      // urlencoded.append("ProjectBrief", state.ProjectBrief);
      formdata.append("Experience", state.Experience);
      formdata.append("GoogleCaptcha", ReCAPTCHAValue.current.getValue());
      formdata.append("Attachment", fileValue, fileValue.name);
      formdata.append("Subject", props.RequirementDataPageName);
      formdata.append("PageName", "Career-Page");
      var requestOptions = {
        method: "POST",
        body: formdata,
      };
      fetch("https://app.addresstwo.com/SprigstackEmail/Index", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var results = JSON.parse(result);
          if (results.Status == "Success" && results.Message == "Success")
            setSuccessMsg({ ...successMsg, mailSentMsg: true });
          else if (
            results.Status == "Failed" &&
            results.Message == "Please Validate Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Please Validate Captcha",
            });
          else if (
            results.Status == "Failed" &&
            results.Message == "Error in Verify Google Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Error in Verify Google Captcha",
            });
        })
        .catch((error) => console.log("error", error));
    }
  };
  return (
    <>
      {!successMsg.mailSentMsg ? (
        <div>
          <form
            method="post"
            onSubmit={phpModalForm}
            encType="multipart/form-data"
          >
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-12 mb-lg-5 mb-4">
                <input
                  type="text"
                  className="form-control requirementCustomInput px-0"
                  placeholder="Name*"
                  onChange={handleChange}
                  name="Name"
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12 mb-lg-5 mb-4">
                <input
                  type="email"
                  className="form-control requirementCustomInput px-0"
                  placeholder="Email Id*"
                  onChange={handleChange}
                  name="Email"
                  required
                />
              </div>
            </div>
            <div className="row mt-lg-0">
              <div className="col-lg-6 col-md-6 col-12 mb-lg-5 mb-4">
                <input
                  type="number"
                  min={0}
                  className="form-control requirementCustomInput px-0"
                  placeholder="Contact Number*"
                  onChange={handleChange}
                  name="ContactNumber"
                  required
                />
              </div>
              <div className="col-lg-6 mb-lg-5 mb-4">
                <input
                  type="number"
                  min={0}
                  max={10}
                  className="form-control requirementCustomInput px-0"
                  placeholder="Relevent Experience (In Years)*"
                  onChange={handleChange}
                  name="Experience"
                  required
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-6 col-12 mt-lg-0 mt-4 text-center ">
                <div className="requirementUploadBox">
                  <FileUploader
                    handleChange={handleChangeFile}
                    name="file"
                    types={fileTypes}
                    maxSize={2}
                    minSize={0}
                    label="Upload/Drag & Drop C.V Here*"
                    onSizeError={(file) =>
                      setSuccessMsg({ ...successMsg, fileErr: file })
                    }
                    onTypeError={(err) =>
                      setSuccessMsg({ ...successMsg, fileErr: err })
                    }
                    classes="fileUploadPadding"
                  >
                    <div className="row pt-lg-3 pt-md-3 ">
                      <div className="col-2 ">
                        <img
                          className="img-fluid"
                          src="/images/uploadBoxImg.svg"
                          alt=""
                        />
                      </div>
                      <div
                        className="col-10"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="">Upload/Drag & Drop C.V Here*</div>
                      </div>
                    </div>
                    <div className="row pt-lg-2 pb-lg-3 pt-md-2 pb-md-3">
                      <span>{file ? file.name : "No files uploaded yet"}</span>
                    </div>
                  </FileUploader>
                </div>
                <div className="row mt-2 text-start">
                  <span className="text-danger">{successMsg.fileErr}</span>
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                <ReCAPTCHA
                  ref={ReCAPTCHAValue}
                  sitekey={process.env.REACT_APP_EMAIL_RECAPTCHA_SITE_KEY}
                />
                <span className="text-danger my-2">
                  {successMsg.reCaptchaErrMsg}
                </span>
              </div>
              <div className="col-lg-12 my-3 fileTxt">
                <h6 className="fntSize">
                  Accepted file types: pdf, doc, docx. Max.file size: 2 MB.
                </h6>
              </div>
              <div className="col-lg-2 col-md-2 col-md-12 pe-lg-5 txtcntr">
                <button className="btn btn-primary phpModalBtn" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <>
          <div className="text-center h3">
            Thank you for applying for this position. We will reach out to you
            shortly!
          </div>
        </>
      )}
    </>
  );
}
