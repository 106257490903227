export function HeaderWithDynamicContent(props) {
  return (
    <>
      <div className={`container-fluid ${props.pt ? "pt-0" : "pt-5"} px-0`}>
        <div
          className={`${props.ContainerBottomPadding} `}
          style={{ backgroundColor: props.BgColor }}
        >
          <div className={`${props.isFullWidth ? "px-4" : "px-lg-6 px-sm-6 "}`}>
            <div
              className={`col-12 text-center ${
                props.flag === true ? "" : "pt-5"
              }`}
            >
              {props.Header ? (
                <h2
                  className={`fontColorBlue headerFontSizeH6 ${props.HeaderPadding}`}
                  dangerouslySetInnerHTML={{ __html: props.Header }}
                ></h2>
              ) : (
                <></>
              )}

              {props.SubHeader ? (
                <h3
                  className={`fontColorBlue headerFontSizeH3 ${props.SubHeaderCss}`}
                  dangerouslySetInnerHTML={{ __html: props.SubHeader }}
                ></h3>
              ) : (
                <></>
              )}
              {props.HrLine != false ? (
                <img src="/images/hrImg.png" alt="" className="img-fluid" />
              ) : (
                <></>
              )}
            </div>
            <div
              className={`row C_font2 ${props.ContentCustomClass} `}
              style={{ textAlign: "justify" }}
            >
              {props.Content}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
