import React, { useState } from "react";
import PersonalizedActionModal from "../Modals/PersonalizedActionModal";
import Popups from "../Modals/Popups";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  var width = $(window).width();
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="container-fluid blackBg">
        {width >= 991 ? (
          <div className="row colorWhite pt-4 text-center">
            <span className="" style={{ fontWeight: "500", fontSize: "28px" }}>
              Want to Work with us?{" "}
              <span
                className=" clickable"
                style={{
                  fontWeight: "600",
                  fontSize: "33px",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  navigate("/contact-us", {
                    state: { pathname: location?.pathname },
                  });
                }}
              >
                Let's Discuss!
              </span>
            </span>
          </div>
        ) : (
          <div className="row colorWhite pt-4 text-center">
            <div className="" style={{ fontWeight: "500", fontSize: "28px" }}>
              Want to Work with us?
            </div>
            <div
              className=" clickable"
              style={{
                fontWeight: "600",
                fontSize: "33px",
                textDecoration: "underline",
              }}
              onClick={() => {
                navigate("/contact-us", {
                  state: { pathname: location?.pathname },
                });
              }}
            >
              Let's Discuss!
            </div>
          </div>
        )}
        <div className="row colorWhite px-lg-5 px-4">
          <div className="col-md-4 footerLinkColor pt-5">
            <br />
            <div
              className="fw-bold ps-2 desktop"
              style={{ letterSpacing: "1px" }}
            >
              Location
            </div>
            <label className="row pt-lg-2 pt-3">
              <div className="col-1 pe-0 text-center">
                <img src="/images/location.svg" alt="" className="img-fluid" />
              </div>
              <div className="col-10  ps-3" style={{ letterSpacing: "0.5px" }}>
                <span>
                  India (HQ)
                  <br />
                  <div className="pt-1">
                    D-402, THE FIRST, behind Keshavbaug Party Plot, I I M,
                    Vastrapur, Ahmedabad, Gujarat 380015
                  </div>
                </span>
              </div>
            </label>
            <label className="row pt-lg-2 pt-3">
              <div className="col-1 pe-0 text-center">
                <img src="/images/location.svg" alt="" className="img-fluid" />
              </div>
              <div className="col-10  ps-3" style={{ letterSpacing: "0.5px" }}>
                <span>
                  USA
                  <br />
                  <div className="pt-1">
                    6802 Hillsdale Ct, Indianapolis, IN 46250
                  </div>
                </span>
              </div>
            </label>
          </div>
          <div className="col-md-3 col-7 footerLinkColor pt-5">
            <div className="fw-bold pt-4" style={{ letterSpacing: "1px" }}>
              Services
            </div>
            <div className="col-12 fs-15 pt-2 px-0">
              <Link to="web-development">
                <label className="py-2 clickable">Web Development</label>
              </Link>
              <br />
              <Link to="web-application-development">
                <label className="py-2 clickable" style={{ textAlign: "left" }}>
                  Web App development
                </label>
              </Link>
              <br />
              {/* <Link to="ecommerce-services">
                <label className="py-2 clickable">Ecommerce Services</label>
              </Link>
              <br /> */}
              <Link to="mobile-app-development">
                <label
                  style={{ textAlign: "left" }}
                  className="py-2 clickable "
                >
                  Mobile App Development
                </label>
              </Link>
              <br />
              <Link to="api-integration-services">
                <label className="py-2 clickable ">API Integration</label>
              </Link>
              <br />
              <Link to="crm-development-services">
                <label className="py-2 clickable ">CRM Development</label>
              </Link>
              <br />
              <Link to="erp-development-services">
                <label className="py-2 clickable ">ERP Development</label>
              </Link>
              <br />
              <Link to="uiux-design">
                <label className="py-2 clickable ">UI/UX Design</label>
              </Link>
              <br />
              <Link to="seo">
                <label className="py-2 clickable ">SEO</label>
              </Link>
              <br />
              <Link to="devops">
                <label className="py-2 clickable ">DevOps</label>
              </Link>
              <br />
              <Link to="project-management">
                <label className="py-2 clickable ">Project Management</label>
              </Link>
            </div>
          </div>
          <div className="col-md-2 col-5 footerLinkColor pt-5 ">
            <div className="fw-bold pt-4" style={{ letterSpacing: "1px" }}>
              Company
            </div>
            <div className="col-12 fs-15 pt-2 px-0">
              <Link to="/portfolio">
                <label className="py-2 clickable">Portfolio</label>
              </Link>
              <br />
              <Link to="/about-us">
                <label className="py-2 clickable">About Us</label>
              </Link>
              <br />
              <Link to="/career">
                <label className="py-2 clickable">Career</label>
              </Link>
              <br />
              <Link to="/blog">
                <label className="py-2 clickable">Blog</label>
              </Link>
              <br />
              <Link to="/contact-us" state={{ pathname: location?.pathname }}>
                <label className="py-2 clickable">Contact Us</label>
              </Link>
              <br />
              <Link to="/sitemap.html">
                <label className="py-2 clickable">Sitemap</label>
              </Link>
            </div>
          </div>
          <div className="col-md-3 px-0 footerLinkColor pt-5 ps-3 ps-ms-0">
            <div className="fw-bold pt-4" style={{ letterSpacing: "1px" }}>
              Contact
            </div>
            <label className="row pt-2">
              <div className="col-1 pe-0 text-center">
                <img src="/images/mobile.svg" alt="" className="img-fluid" />
              </div>
              <div
                className="col-10 fs-15 ps-3"
                style={{ letterSpacing: "0.5px" }}
              >
                +1 (317) 460-1173
              </div>
            </label>
            <label className="row pt-2">
              <div className="col-1 pe-0 text-center">
                <img src="/images/mobile.svg" alt="" className="img-fluid" />
              </div>
              <div
                className="col-10 fs-15 ps-3"
                style={{ letterSpacing: "0.5px" }}
              >
                +91 932 825 3054
              </div>
            </label>
            <label className="row pt-3">
              <div className="col-1 pe-0 text-end">
                <img src="/images/mail.svg" alt="" className="img-fluid" />
              </div>
              <div
                className="col-10 fs-15 ps-3"
                style={{ letterSpacing: "0.5px" }}
              >
                <a
                  href="mailto: connect@sprigstack.com"
                  className="colorWhite text-decoration-none"
                >
                  connect@sprigstack.com
                </a>
              </div>
            </label>
            <div className="social_icons">
              <div className="pt-4 row">
                <div className="col-5 d-flex">
                  <div className="col-5 me-1">
                    <a
                      href=" https://instagram.com/sprigstack"
                      rel="nofollow"
                      target="_blank"
                    >
                      <img
                        src="/images/instagram.svg"
                        alt="Instagram icon"
                        title="Instagram profile"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="col-5">
                    <a
                      href="https://www.linkedin.com/company/sprig-stack"
                      rel="nofollow"
                      target="_blank"
                    >
                      <img
                        src="/images/linkedin.svg"
                        alt="Linkedin icon"
                        title="Linkedin profile"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_icons">
              <div className="pt-4 row">
                <div className="col-12 d-flex">
                  <div className="col-md-4">
                    <a
                      href="https://clutch.co/profile/sprigstack"
                      rel="nofollow"
                      target="_blank"
                    >
                      <img
                        src="/images/clutch-footer.svg"
                        alt="Clutch icon"
                        title="Clutch profile"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="col-md-6 me-1">
                    <a
                      href="https://www.designrush.com/agency/profile/sprigstack"
                      rel="nofollow"
                      target="_blank"
                    >
                      <img
                        src="/images/sprigstack_designrush.svg"
                        className="img-fluid pe-4 crsr-pointer mx-3 mt-2"
                        alt="Designrush icon"
                        title="Designrush SprigStack Profile"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 pt-md-5 pt-4 pb-3 text-center "
            style={{ letterSpacing: "0.5px" }}
          >
            <div>© SprigStack. All Rights Reserved.</div>
          </div>
        </div>
      </div>
      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">
        <PersonalizedActionModal />
      </Popups>
    </>
  );
}
