import React, { useState } from "react";
import SprigStackGallery from "../Gallery/SprigStackGallery";
import { dynamicData } from "../Helper/HelperFunctions";
import Popups from "../Modals/Popups";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function IndustryServingsHoverComp(props) {
  const [Img, setImg] = useState({ src: props.imgSrc, Active: "" });
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        className={` ${props.ContainerSizeClass}`}
        onMouseEnter={() => {
          setImg({ ...Img, src: props.hoverImg, Active: "active" });
        }}
        onMouseLeave={() => {
          setImg({ ...Img, src: props.imgSrc, Active: "" });
        }}
        style={{ position: props.hoverDisplay && "relative" }}
      >
        <div
          className={`${props.ContainerSizeName} ${Img.Active} ${
            props.hoverDisplay && "LS_divImgBor"
          } ${
            props.hoverDisplay && Img.Active == "active" && "ImgBorOpacity"
          } clickable `}
          style={{
            height: "inherit",

            backgroundImage:
              props.hoverDisplay &&
              Img.Active == "active" &&
              `url('${props.imgSrc}')`,
          }}
        >
          <LazyLoadImage
            alt=""
            effect="blur"
            delayMethod
            threshold={100}
            src={Img.src}
            className={` img-fluid ${props.imgBorder} ${
              props.hoverDisplay && Img.Active != "" && "d-none"
            }`}
            onClick={() => {
              if (props.redirect != null || props.redirect != undefined)
                dynamicData(props.redirect);
            }}
          />
          {props.cardTitle ? (
            <h6 className="clr0E2B3D mt-3 boldtext setfontsize">
              {props.cardTitle}
            </h6>
          ) : (
            <></>
          )}
        </div>
        {Img.Active == "active" && props.hoverDisplay && (
          <>
            <div
              className=" p-md-3 p-5 dFlexSpaceBtw w-100 clickable"
              onClick={() => {
                if (props.galleryImages != "") setShow(true);
              }}
              style={{ height: "inherit", position: "absolute", top: "0" }}
            >
              <div className="row">
                <div className="lifessfont">{props.hoverText}</div>
              </div>

              <div className="row">
                <div className="col-12 text-end">
                  {props.galleryImages == "" ? (
                    <>Coming Soon</>
                  ) : (
                    <>
                      <span className="">
                        Show More
                        <img
                          style={{ width: "auto" }}
                          className="ms-2"
                        
                          src="/images/rightArrow.png"
                          alt=""
                        />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Popups
        show={show}
        setShow={setShow}
        title={props.hoverText}
        size="lg"
        customHeader="py-1 px-3"
        customClassName="modalXl"
      >
        <SprigStackGallery galleryImages={props.galleryImages} />
      </Popups>
    </>
  );
}
