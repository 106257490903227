import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
export default function LazyLoadingImg(props) {
  return (
    <>
      <LazyLoadImage
        alt={props.alt}
        title={props.title}
        effect={"blur"}
        delayMethod
        threshold={100}
        src={props.src}
        placeholderSrc={props.src}
        className={`${props.className} img-fluid`}
      />
    </>
  );
}
