import React from "react";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import { DescriptiveCard } from "./Components/DescriptiveCard";
import { DevelopmentRecentWork } from "./Components/DevelopmentWork";
import { DevelopmentCommonFunc } from "./Components/DevelopmentCommon";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
import ProcessDynamicComp from "./DynamicComponent/ProcessDynamicComp";
import WhyChooseUsComp from "./Components/WhyChooseUsComp";
export function UiUxDesign() {
  var techonologiesContent = [
    {
      Technology: "Figma",
      Img: "/images/Technology/figma.png",
      alt: "Figma",
      title: "Technology Figma",
    },
    {
      Technology: "Adobe XD",
      Img: "/images/Technology/AdobeXD.png",
      alt: " Adobe XD",
      title: "Technology Adobe XD",
    },
    {
      Technology: "Adobe Photoshop",
      Img: "/images/Technology/PhotoShop.png",
      alt: " Adobe Photoshop",
      title: "Technology Adobe Photoshop",
    },
    {
      Technology: "Adobe illustrator",
      Img: "/images/Technology/illustrator.png",
      alt: " Adobe illustrator",
      title: "Technology Adobe illustrator",
    },
    {
      Technology: " Adobe Premiere Pro",
      Img: "/images/Technology/Premierpro.png",
      alt: " Adobe Premiere Pro	Adobe Premiere Pro",
      title: "Technology Adobe Premiere Pro",
    },
    {
      Technology: "Adobe After Effect",
      Img: "/images/Technology/AdobeAfterEffect.png",
      alt: " Adobe After Effect",
      title: "Technology Adobe After Effect",
    },
  ];
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/MobileApps.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Mobile Apps Design"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Mobile apps are at the core of any scalable organization as it is the essence of the products and services. The design of any app depends on the client's initial thoughts. "
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/ResponsiveWeb.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Responsive Web Design"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Depending upon its online presence, every business must stand out from the competition and provide a flawless user experience. Our Web Design and Development process helps create easy-to-use and responsive websites for any device."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/LogoDesign.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Logo Design & Branding"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="For any company, establishing brand awareness is very important, and the logo is considered the best source of branding. A logo is the representation of the company and its business segment."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/ThemeDesign.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Theme Design"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="It is very important to have a predefined theme and color palette for every website and app. Based on the client’s preference, we define a unified theme for all the sections."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/UserExperienced.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="User Experience Enhanced"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="We believe user interface is very important for the smooth working of any website and app. So, with our skilled and imaginative team, we redefine and restructure the website and mobile apps."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/AnimatedImages.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Animated Images & Videos"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Visual representation is always the best method to convey important information in a form that is easily understood. Our original pieces and unique videos are free of any copyright infringements."
    />
  );
  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg1.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Unique Look & Feel"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg2.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Impactful<br/> Impression"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg3.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Express Louder<br/> Meanings"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg4.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Strong Impression"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg5.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Brand Consistency"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/BenefitsImg6.svg "
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Value For Money"
      CustomClass="MajorBenefitsContainer"
    />
  );
  var recentWorkContent = (
    <>
      <DevelopmentRecentWork
        ImagePosition="right"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="Berger’s Table Pads - Custom made table pads online"
        Image="./images/UiUxImage1.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-4 m-0  mt-4 mb-1 recentWorkbox1 RecentContainer"
        Description="Tools : Figma, Adobe Photoshop, Adobe illustrator"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto  px-lg-5 px-md-5"
        RedirectUrl="https://tablepads.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-12 recentBox"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="Gocoworq - Marketplace for Coworking"
        Image="./images/UiUxImage2.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-4 mx-md-2 mt-2 mb-2 py-4 RecentContainer"
        Description="Tools : Figma, Adobe Photoshop, Adobe illustrator"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-lg-5 px-md-5"
        RedirectUrl="https://www.gocoworq.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-12"
      />
      <DevelopmentRecentWork
        ImagePosition="right"
        CustomHeaderClass="recentWorkHeader"
        DescriptionHeader="Alwajeeh - Privileged Member App for a Community"
        Image="./images/UiUxImage3.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor="DevCards my-md-4 mx-md-2 mt-2 mb-2 py-4 RecentContainer"
        Description="Tools : Figma, Adobe Photoshop, Adobe illustrator"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto  px-lg-5 px-md-5"
        RedirectUrl="https://alwajeeh.link/"
        DevelopmentRecentWorkBtn={true}
        redirectNumber="/portfolio/hybrid-application-development-for-al-wajeeh"
        CardMargin="col-12"
      />
    </>
  );
  var whChooseUseLeftBox = [];
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Creative Designs"
      Image="./images/appdevwhychooseusIcon1.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`Our Web design Services are imaginative and distinctive enough to comply with the trend and fit the company's target market.`}
      CardMargin="pt-1 my-lg-0  mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Strong Visual Identity"
      Image="./images/appdevwhychooseusIcon2.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`Design, programming, APIs, deployment, and versioning are necessary to create an efficient mobile application. Each stage is handled with ease by our team of specialists.`}
      CardMargin="pt-1 my-lg-0 my-4 mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  whChooseUseLeftBox.push(
    <DescriptiveCard
      ImagePosition="left"
      CustomHeaderClass="whChooseUseHeader text-m-center"
      DescriptionHeader="Pocket-Friendly"
      Image="./images/flexEngagementIcon.svg"
      SubIconImgCss="whchIconCss"
      CustomBodyClass="whChooseUseBody my-md-2"
      Description={`Creating an effective mobile application always requires a team effort as it needs design, development, APIs, deployment and versioning. Our team of experts take care of each step quite effortlessly. `}
      CardMargin="pt-1 my-lg-0 my-4  mb-1 mx-0 whchmob"
      imgClass="h-lg-35"
    />
  );
  var whyChooseUsContent = [];
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/uiuxDevChooseUsIcon1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue"
      Header="Creative Designs"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={`Our Web design Services are imaginative and distinctive enough to comply with the trend and fit the company's target market.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/uiuxDevChooseUsIcon2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue "
      Header="Strong Visual Identity"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`Normally, mobile apps are open to any user who considers them impactful to their routine life. To ensure smooth flow, we always take care of the guidelines of the hosting store.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-5 pt-4"
      Image="./images/uiuxDevChooseUsIcon3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue "
      Header="Pocket-Friendly"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`Design, programming, APIs, deployment, and versioning are necessary to create an efficient mobile application. Each stage is handled with ease by our team of specialists.`}
    />
  );
  var HeroSection = {
    ImagePosition: "left",
    DescriptionHeader: "User Interface and User Experience Services",
    CustomHeaderClass: "C_font1",
    CustomBodyClass: "C_font2 herocontainertext",
    Image: "./images/uiuxMain.svg",
    alt: "Ui UX design",
    title: "User Interface and User Experience Services",
    CustomImageClass: "",
    CardMargin: " px-lg-6 px-sm-6",
    CustomDescriptionBoxClass: "p-lg-4 px-4",
    Description:
      "With our UI UX design services, we provide seamless user experiences for a website or mobile app. Our team of creative and imaginative designers specialize in UI UX design and development and provides an interface that is always eye-catching and as per the trend and ultimately helps a company stand out from the crowd. We stay up-to-date with the latest technology to ensure website designs are beautiful and user-friendly.",
    customDescriptionItemFirst:
      "col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3 text-center",
    customDescriptionItemSecond: "col-lg-6 col-md-6 col-12 homePageGYB",
    bodyClassCareerRequirement: "d-none",
  };
  var Overview = {
    Header: "Overview",
    Content:
      "Our most creative and imaginative team of designers works on specialized ui ux design for websites and mobile applications. We always utilize the color scheme specified for ui ux development. Based on our recent projects, our clients are always exploring new technology. So, we always engage ourselves with modern technology because we believe that design is the foundation for any development; thus, we consider it excellent and user pleasant.",
    ContentCustomClass: "pt-3 m-0",
    HeaderPadding: "overviewHeading",
    flag: true,
  };
  var OurKey = {
    BgColor: "#F7F7F7",
    Header: "Our Key UI & UX Design <br/> Services Include",
    ContainerBottomPadding: "pb-md-3 pt-5",
    HeaderPadding: "ourKeyHeading",
    flag: true,
    Content: [serviceConent],
  };
  var PersonalizedCompFirst = {
    cardTitle:
      "Looking for UI/UX design that <br/>stands out and looks<br/> beautiful?",
    ContainerColor: "FFFPersonalize",
    TitleCss1: "personalizedtitle1_UIUX",
    Cta1Css: "est_btn_css",
    flag: "1",
  };
  var MajorBenefits = {
    BgColor: "",
    Header: `Major Benefits of UI & UX Services`,
    HeaderPadding: "majorBenefitHeading",
    ContentCustomClass: "",
    flag: true,
    Content: [webAppBenefitContent],
  };
  var UiUxProcess = {
    BgColor: "#F7F7F7",
    Header: `UI & UX Process that We Follow`,
    HeaderPadding: "webProcessHeading",
    ContainerBottomPadding: "pb-2",
    ContentCustomClass: "justifyCenter weFollowContainer pt-4 pb-5",
    Content: <ProcessDynamicComp />,
  };
  var Technology = {
    Tech: [techonologiesContent],
    MainHeader: "Technologies Used for UI & UX",
    SubHeader: "Discover Our Professional Designing Tools",
    HeaderCss: "technologiesHeadingUIUX pt-4",
    SubHeaderCss: "technologySubHeaderUIUX",
  };
  var PersonalizedCompSecond = {
    cardTitle:
      "From logo to complete design,<br/> we've got you covered. Start now!",
    ContainerColor: "cA49F9D",
    flag: "2",
  };
  var RecentWork = {
    BgColor: "",
    Header: `Recent UI & UX Design Work`,
    HeaderPadding: "RecentWorkHeading",
    ContentCustomClass: "",
    flag: true,
    Content: [recentWorkContent],
  };
  var WhyChooseUs = {
    BgColor: "#F7F7F7",
    Header: `Why Choose us for UI & UX Design?`,
    HeaderPadding: "whyChooseUsHeading",
    ContentCustomClass: "whyChooseUsBox",
    Content: [whyChooseUsContent],
  };
  var recentworkadded =
    '<div class="pt-5 px-lg-6 px-sm-6 imgdnone">' +
    '<div class = "row px-0" >' +
    '<div class="col-6 RecentContainer">' +
    '<div class="DevCards imgdnone">' +
    '<img src="./images/UiUxImage4.webp" title="TreatMe - Doctor at Your Fingertip" alt="TreatMe - Doctor at Your Fingertip" class="img-fluid" />' +
    "</div>" +
    "</div>" +
    '<div class="col-6 RecentContainer">' +
    '<div class="DevCards imgdnone">' +
    '<img src="./images/UiUxImage5.webp" title="Euroriente - One Stop Travel Guide" alt="Euroriente - One Stop Travel Guide" class="img-fluid" />' +
    "</div>" +
    "</div> " +
    "</>" +
    "</div>";
  return (
    <>
      <HelmetMetaTag
        titleName="Best UI UX Design and Development Services | SprigStack"
        title="SprigStack | UI/UX Design Service For Your Web Application."
        desc="Transform your website or app with our modern UI & UX design. Our Services include mobile app & responsive web design, logo, & more. Contact us now!"
        canonical="https://sprigstack.com/uiux-design"
        ogtitle="Best UI UX Design and Development Services | SprigStack"
        ogdesc="Transform your website or app with our modern UI & UX design. Our Services include mobile app & responsive web design, logo, & more. Contact us now!"
        ogtype="website"
        ogurl="https://sprigstack.com/uiux-design"
      />
      <DevelopmentCommonFunc
        HeroSection={HeroSection}
        Overviewsection={Overview}
        OurKeySection={OurKey}
        PersonalizedCompOne={PersonalizedCompFirst}
        MajorBenefitsSection={MajorBenefits}
        UiUxProcessSection={UiUxProcess}
        TechnologySection={Technology}
        Space={true}
        PersonalizedCompTwo={PersonalizedCompSecond}
        RecentWorkSection={RecentWork}
        IsNewSection="yes"
        NewSection={recentworkadded}
        WhyChooseUsSection={WhyChooseUs}
        FAQSection={<FAQDynamicComp />}
      />
    </>
  );
}
