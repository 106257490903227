import React from "react";
import { ContentAndImage } from "./Components/ContentAndImage";
import { HeaderWithDynamicContent } from "./Components/HeaderWithDynamicContent";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import CrmFeatures from "./Components/CrmFeatures";
import IndustryServingsHoverComp from "./Components/IndustryServingsHoverComp";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
import CrmErpCta from "./Components/CrmErpCta";
import CrmErpLatestProjects from "./Components/CrmErpLatestProjects";
import WhyChooseUsComp from "./Components/WhyChooseUsComp";
import HelmetMetaTag from "./Components/HelmetMetaTag";
export default function ErpDevelopment(props) {
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/erp-service1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Custom ERP Solutions"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="We create custom ERP systems that flawlessly match your particular requirements and optimize processes. Our customized options combine smoothly, enhancing efficiency and removing the need for several separate systems; we work for various industries."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/erp-service2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="ERP implementation"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="With our team of experienced professionals, your ERP implementation will go smoothly and efficiently. We handle the entire implementation process, integrating your custom ERP with your existing infrastructure and ensuring a seamless switch-over for your team."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/erp-service3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="ERP Mobile Applications"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="We provide intuitive mobile apps that give you anytime, anywhere access to your ERP data in real-time. This enhances teamwork and overall business agility by enabling your team to make smart judgments while on the move."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/erp-service4.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="ERP Maintenance and Support"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Our continuous maintenance and support services will provide you peace of mind. Our committed staff will ensure that your ERP system continues to operate at its best by fixing any problems that may occur and keeping it tuned for maximum efficiency."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/crm-service5.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Cloud-Based ERP Solutions"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Take advantage of the scalability and security of the cloud. We develop and implement cloud-based ERP solutions that are flexible and accessible. From any device, your entire team can access the system, promoting collaboration and reducing costs."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/crm-service6.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="ERP Consulting"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Our team of experienced ERP experts can offer you professional advice. In order to create a strategic roadmap for your ERP journey, we will work with you to evaluate your business needs and establish your goals."
    />
  );

  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit1.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Enhanced Security"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit2.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Personalized interface and <br/> user experience"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit3.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Improved Efficiency and <br/> Productivity"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit4.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Enhanced Business <br/> Insights"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit5.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Smart integration with <br/> third-party services"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/erp-benefit6.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Scalability and Future - <br/> Proofing"
      CustomClass="MajorBenefitsContainer"
    />
  );

  var whyChooseUsContent = [];
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/erp-whychooseus1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Smooth Integration"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={`We don't just build your ERP system; we integrate it seamlessly with your existing systems. In this way, disruptions are minimized, and all data flows smoothly, resulting in an efficient and unified operational ecosystem.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/erp-whychooseus2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Robust Security measures"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`Your company's data is its vitality. We use strong security measures to safeguard your private data since we take security seriously. Our technologies are built to protect your data from unwanted access and guarantee compliance to industry standards`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-5 pt-4"
      Image="./images/erp-whychooseus3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="End-to-End Support"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={`Our dedicated team offers complete support from the first consultation through implementation, training, and continuous maintenance. Whenever you have questions or concerns, you will be able to reach a group of professionals who care about your success.`}
    />
  );

  return (
    <>
      <HelmetMetaTag
        titleName="Custom ERP Software Development Services | SprigStack"
        desc="Sprigstack's custom ERP development services build secure, scalable ERP. Protect your data, gain real-time insights & future-proof your business."
        canonical="https://sprigstack.com/erp-development-services"
        ogtitle="Custom ERP Software Development Services | SprigStack"
        ogdesc="Sprigstack's custom ERP development services build secure, scalable ERP. Protect your data, gain real-time insights & future-proof your business."
        ogtype="website"
        ogurl="https://sprigstack.com/erp-development-services"
        ogimg="https://sprigstack.com/images/erp.svg"
      />
      <ContentAndImage
        h1Tag={true}
        ImagePosition="left"
        DescriptionHeader="Custom ERP Software Development services"
        CustomHeaderClass="C_font1"
        CustomBodyClass="C_font2"
        Image="./images/erp.svg"
        alt="ERP Software Development services"
        title="ERP Software Development services"
        CustomImageClass="img-fluid"
        CardMargin=" px-lg-6 px-sm-6"
        CustomDescriptionBoxClass="p-lg-4 px-4"
        Description="Ready to give up generic ERP software and find a system that truly works for you? As an ERP  software development company, we craft custom ERP solutions that are built specifically for your business. We provide custom functionality that effortlessly interacts with your current operations, eliminating the limitations of existing software. Our Custom ERP development services can enhance efficiency, make data-driven decisions for peak performance, and enable scalability to grow your business to its full potential."
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 px-md-5 pt-md-4 pb-0 p-3 text-center"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 homePageGYB"
        bodyClassCareerRequirement="d-none"
        IsShowButton="HeroSection"
        IsShowButtonShow={true}
        HeroSection={true}
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor="#F7F7F7"
        Header="Our key ERP Development <br/> Services include"
        ContainerBottomPadding="pb-md-3 pt-5"
        Content={serviceConent}
        HeaderPadding="ourKeyHeading"
        flag={true}
        pt={true}
      />

      <HeaderWithDynamicContent
        BgColor=""
        Header="Major Benefits of our custom ERP Development"
        HeaderPadding="majorBenefitHeading"
        ContentCustomClass=""
        Content={webAppBenefitContent}
        flag={true}
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="Our Latest ERP projects"
        HeaderPadding="majorBenefitHeading"
        flag={true}
      />
      <CrmErpLatestProjects
        ProjectBackground="/images/erp-travel.png"
        contentOrder="imageFirst"
        ProjectImage="EurorientePortfolioImg.webp"
        imgwidth="max60"
        Project="Eurorient"
        TextColor="white"
        ProjectName="ERP System for the Travel Industry"
        ProjectTech="React, .Net Core, PostgreSql, Bootstrap, Aws Cloud, Stripe, Twilio"
        Btneffect="crmBtnEffect"
        BtnMobileEffect="crmBtnMobileEffect"
        ProjectUrl="/portfolio/development-of-an-erp-system-for-the-travel-industry"
      />
      <br />
      <CrmErpLatestProjects
        ProjectBackground="/images/k12-erp.png"
        contentOrder="textFirst"
        ProjectImage="K12-mockup.webp"
        imgwidth="max60"
        Project="K12"
        TextColor="#203F59"
        ProjectName="K-12 : Web Based Solution for the Ed-Tech Industry"
        ProjectTech="Laravel, React, Bootstrap, MySql, Aws Cloud, Fedex Api's"
        Btneffect="hoverBtnEffect"
        BtnMobileEffect="hoverBtnMobileEffect"
        flag="1"
        ProjectUrl="/portfolio/website-development-for-K12"
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="ERP Features"
        HeaderPadding="majorBenefitHeading"
        pt={true}
      />
      <div className="row px-6 mt-2">
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-1-hover.svg"
          imgUrl="/images/erp-feature-1.svg"
          text="Financial Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-2-hover.svg"
          imgUrl="/images/erp-feature-2.svg"
          text="Manufacturing Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-3-hover.svg"
          imgUrl="/images/erp-feature-3.svg"
          text="Human Resources Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-4-hover.svg"
          imgUrl="/images/erp-feature-4.svg"
          text="Customer Relationship Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-5-hover.svg"
          imgUrl="/images/erp-feature-5.svg"
          text="Supply Chain Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-6-hover.svg"
          imgUrl="/images/erp-feature-6.svg"
          text="Project Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-7-hover.svg"
          imgUrl="/images/erp-feature-7.svg"
          text="Inventory Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-8-hover.svg"
          imgUrl="/images/erp-feature-8.svg"
          text="Marketing and Sales"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-9-hover.svg"
          imgUrl="/images/erp-feature-9.svg"
          text="Document Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-10-hover.svg"
          imgUrl="/images/erp-feature-10.svg"
          text="Business Intelligence (BI) and Data Analytics"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-11-hover.svg"
          imgUrl="/images/erp-feature-11.svg"
          text="Service Operations Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-12-hover.svg"
          imgUrl="/images/erp-feature-12.svg"
          text="Compliance and Security"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-13-hover.svg"
          imgUrl="/images/erp-feature-13.svg"
          text="Customizable Dashboards"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-14-hover.svg"
          imgUrl="/images/erp-feature-14.svg"
          text="Mobile Applications"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/erp-feature-15-hover.svg"
          imgUrl="/images/erp-feature-15.svg"
          text="Collaboration Tools"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-13-hover.svg"
          imgUrl="/images/crm-feature-13.svg"
          text="Chatbots and Virtual Assistants"
        />
      </div>

      <CrmErpCta
        CtaDiv="px-6 mt-md-5 mt-4"
        quoteName="erpQuote"
        TextCols="col-md-7 col-12 d-flex align-items-center px-5 py-3 py-md-5"
        ImgCols="col-md-5 col-12 p-1 p-md-0"
        ImgPadding="py-1"
        quoteText="Grow Faster ! Transform Your Business with Custom ERP Development."
        CtaBtn="CaseStudydBtn"
        arrowImg="personalizedWhiteArrow.svg"
        quoteImg="/images/erp-quote.svg"
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="Industries Served with our ERP Development Services"
        HeaderPadding="majorBenefitHeading"
        pt={true}
      />

      <div className="row mt-2 px-6">
        <IndustryServingsHoverComp
          imgSrc="/images/Retail_Blue.svg"
          hoverImg="/images/Retail_Orange.svg"
          cardTitle="E-commerce"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Communication-blue.svg"
          hoverImg="/images/Communication_orange.svg"
          cardTitle="Communication"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/hospitality-crm-blue.svg"
          hoverImg="/images/hospitality-crm-orange.svg"
          cardTitle="Hospitality"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
          imgBorder=""
        />
        <IndustryServingsHoverComp
          imgSrc="/images/manufacturinfg-crm-blue.svg"
          hoverImg="/images/manufacturinfg-crm-orange.svg"
          cardTitle="Manufacturing"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
          imgBorder=""
        />
        <IndustryServingsHoverComp
          imgSrc="/images/healthcare-crm-blue.svg"
          hoverImg="/images/healthcare-crm-orange.svg"
          cardTitle="Healthcare"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/construction-crm-blue.svg"
          hoverImg="/images/construction-crm-orange.svg"
          cardTitle="Construction"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/ItSolution__Blue.svg"
          hoverImg="/images/ItSolution_Orange.svg"
          cardTitle="IT Solution"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Travel_Blue.svg"
          hoverImg="/images/Travel_Orange.svg"
          cardTitle="Travel"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Education_Blue.svg"
          hoverImg="/images/Education_Orange.svg"
          cardTitle="Education"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Banking&FinanceBlue.svg"
          hoverImg="/images/Banking&FinanceOrange.svg"
          cardTitle="Banking & Finance"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/logistics-erp-blue.svg"
          hoverImg="/images/logistics-erp-orange.svg"
          cardTitle="Logistic"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/entertainment-erp-blue.svg"
          hoverImg="/images/entertainment-erp-orange.svg"
          cardTitle="Media & Entertainment"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
      </div>
      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="What our clients think about us!"
        HeaderPadding="majorBenefitHeading"
        pt={true}
      />
      <div className="px-6 text-center mt-4">
        <img
          src="/images/DummyAvtar.png"
          alt="client"
          style={{ borderRadius: "50%" }}
        />
        <h4 className="fontPoppins mt-3">Wani Dev</h4>
        <h6 className="fontPoppins">CEO, Fayanka Tech</h6>
        {/* <img src="/images/video_icon.png" alt="" className={`clickable`}></img> */}
        <h6 className="custom-blockquote fontPoppins text-justify mt-3">
          Everything in the software is working as imagined. We will every day
          lay down our plan and requirements in detail and every dev from their
          team will understand and deliver. All requirements our company had
          from booking tours to delivering them, can be handled efficiently now.
          Every milestone was delivered on and before time. Software developed
          by Sprigstack was better and efficient. They did a great Job!
        </h6>
      </div>

      <HeaderWithDynamicContent
        BgColor="#F7F7F7"
        Header="Why choose us for ERP development Services?"
        HeaderPadding="whyChooseUsHeading"
        ContentCustomClass="whyChooseUsBox"
        Content={whyChooseUsContent}
      />
      <FAQDynamicComp />
    </>
  );
}
