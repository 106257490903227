import React from "react";
import LazyLoadingImg from "./LazyLoadingImg";

export default function WhyChooseUsComp(props) {
  return (
    <>
      <div className={`${props.ColSize}`} >
       <div className="pb-md-5 pb-4 pt-4 whyChooseUsDiv">
          {props.Image && (
            <div className={`row align-self-center ${props.ImageContainerClass}`}>
              <LazyLoadingImg src={props.Image} className={props.ImageClass} />
            </div>
          )}

          <h3
            className={`row  my-2 pt-lg-4 pt-3 align-self-center ${props.CustomHeaderClass} `}
          >
            {props.Header}
          </h3>
          <div className={`row  mt-2  ${props.CustomBodyClass} `}>
            {props.Body}
          </div>
          </div>
      </div>
    </>
  );
}
