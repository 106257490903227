import React, { useEffect, useState } from "react";
import CountSetInterval from "../Components/CounterComp";
import { NamePlateCard } from "../Components/NamePlateCard";
import Popups from "../Modals/Popups";
import PersonalizedActionModal from "../Modals/PersonalizedActionModal";
import IndustryServingsHoverComp from "../Components/IndustryServingsHoverComp";
import PersonalizedComp from "../Components/PersonalizedComp";
import FAQDynamicComp from "../DynamicComponent/FAQDynamicComp";
import HelmetMetaTag from "../Components/HelmetMetaTag";
import { useLocation, useNavigate } from "react-router-dom";

export default function ApiIntegration() {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [countNumber, setCountNumber] = useState({
    Count1: 0,
    Count2: 0,
    Count3: 0,
    Count4: 0,
  });
  useEffect(() => {
    window.addEventListener("scroll", CounterFun);
    return () => {
      window.removeEventListener("scroll", CounterFun);
    };
  }, []);
  const CounterFun = () => {
    const scrollTop = window.scrollY;

    if (scrollTop >= 0) {
      setCountNumber({
        ...countNumber,
        Count1: 50,
        Count2: 75,
        Count3: 50,
        Count4: 50,
      });
    }
  };
  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="API Integration Services | Custom API Development Company"
        desc="Build scalable & future-proof systems with Sprigstack's API development & integration services. Our custom APIs adapt & grow alongside your business needs."
        canonical="https://sprigstack.com/api-integration-services"
        ogtitle="API Integration Services | Custom API Development Company"
        ogdesc="Build scalable & future-proof systems with Sprigstack's API development & integration services. Our custom APIs adapt & grow alongside your business needs."
        ogtype="website"
        ogurl="https://sprigstack.com/api-integration-services"
      />
      <div>
        <div className="row px-6 my-md-5 my-2 d-flex align-items-center">
          <div className="col-md-6 col-12 px-md-5 px-0 pt-4 pt-md-0 text-end">
            <img
              className="img-fluid"
              src="/images/ApiHero.svg"
              alt="API Integration"
              title="API  Integration and Development"
            />
          </div>
          <div className="col-md-6 col-12 pt-4 pt-md-0 ApiHeroSection text-center text-md-start mb-4 mb-md-0">
            <h1 className="text-center text-md-start my-md-4 my-3">
              API Development and <br />
              API Integration Services
            </h1>
            <p className="my-4">
              Enhance your business operations and maximize efficiency by
              partnering with Sprigstack, your reliable API development company
              specializing in creating unique solutions for your company through
              API development and custom API integration services. Our team of
              experts excels at crafting personalized application programming
              interfaces, ensuring the flawless integration of your systems.
              Count on us to establish secure, reliable, and frictionless data
              flows across your platforms, connecting your business to essential
              information and data through our expertly designed custom API
              solutions.
            </p>
            <button
              className="web-dev-Btn px-4"
              onClick={() => {
                navigate("/contact-us", {
                  state: { pathname: location?.pathname },
                });
              }}
            >
              Let's get started!
            </button>
          </div>
        </div>
        <div
          className="container-fluid mb-md-5 mb-4"
          id="CountSetIntervalDiv"
          tabIndex="100"
        >
          <div className="row auContainer auContainer1 py-4 px-6">
            <div className="col-lg-3 d-flex justify-content-center col-md-3 col-12 p-3 text-center">
              <div className="row">
                <div className="col-5 p-0 p-md-2 col-md-3 d-block d-md-flex align-self-center">
                  <img
                    className="md-30"
                    src="/images/FlawlessIntegrations.svg"
                    alt="Flawless Integrations"
                    title="Flawless Integrations"
                  />
                </div>
                <div
                  style={{ textAlign: "left" }}
                  className="col-7 col-md-9  AU_Font8 fontBlue"
                >
                  <div className="col-12">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count1}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12">
                    <div className="AU_Font6 fontBlue">
                      Flawless Integrations
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex justify-content-center col-md-3 col-12 p-3 text-center">
              <div className="row">
                <div className="col-5 col-md-4 d-flex align-self-center">
                  <img
                    className="md-30"
                    src="/images/BoostedEfficiency.svg"
                    alt="Boosted Efficiency Icon"
                    title="Boosted Efficiency"
                  />
                </div>
                <div
                  style={{ textAlign: "left" }}
                  className="col-7 col-md-8 px-0  AU_Font8 fontBlue"
                >
                  <div className="col-12">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count2}
                      duration={parseInt(2)}
                    />
                    %
                  </div>
                  <div className="col-12">
                    <div className="AU_Font6 fontBlue">Boosted Efficiency</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex justify-content-center col-md-3 col-12 p-3 text-center">
              <div className="row">
                <div className="col-5 col-md-4 d-flex align-self-center">
                  <img
                    src="/images/CostReduction.svg"
                    alt="Cost Reduction Icon"
                    title="Cost Reduction "
                  />
                </div>
                <div
                  style={{ textAlign: "left" }}
                  className="col-7 col-md-8  AU_Font8 fontBlue"
                >
                  <div className="col-12">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count3}
                      duration={parseInt(2)}
                    />
                    %
                  </div>
                  <div className="col-12">
                    <div className="AU_Font6 fontBlue">Cost Reduction</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex justify-content-center col-md-3 col-12 p-3 text-center">
              <div className="row">
                <div className="col-5 col-md-4 d-flex align-self-center">
                  <img
                    className="md-30"
                    src="/images/HappyClientsBlue.svg"
                    alt="Happy Clients Icon"
                    title="Happy Clients"
                  />
                </div>
                <div
                  style={{ textAlign: "left" }}
                  className="col-7 col-md-8  AU_Font8 fontBlue"
                >
                  <div className="col-12">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count4}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12">
                    <div className="AU_Font6 fontBlue">Happy Clients</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-6 text-center1 d-flex justify-content-center">
          <div className="col-md-12 text-center px-4 pb-md-4 pb-3">
            <h2 className="Api-Title clr0E2B3D">
              Our Key API Integration Services include
            </h2>

            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div className="col-md-4 col-12 mb-md-5 mb-4">
            <div className="card ApiCard p-4">
              <h3 className="c-txt-header  text-start mb-3">
                Custom API Development
              </h3>
              <p className="c-txt  text-start">
                Boost your business with our custom API development services.
                Crafted for your specific needs, our service encompasses secure
                internal APIs and dedicated solutions for web services.
                Experience seamless integration, efficient data exchange, and
                elevated performance. Transform workflows, unlock integration
                potential, and elevate customer experiences.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-md-5 mb-4">
            <div className="card ApiCard p-4">
              <h3 className="c-txt-header  text-start mb-3">
                Cloud-Based API Integration
              </h3>
              <p className="c-txt  text-start">
                Harness cloud-based APIs for direct or cross-platform data
                requests. Our developers excel at integrating applications into
                the cloud, ensuring universal access across platforms while
                leveraging cloud technology's flexibility. Connect applications
                and data across multiple clouds to enhance business services,
                performance, and accessibility.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-md-5 mb-4">
            <div className="card ApiCard p-4">
              <h3 className="c-txt-header  text-start mb-3">
                Payment Gateway API Integration
              </h3>
              <p className="c-txt  text-start">
                Ensure secure and seamless online transactions by integrating
                payment gateways into your applications and adhering to industry
                security protocols. Elevate your site's payment process with our
                service, seamlessly connecting to significant gateways like
                PayPal, Stripe, and more. Enhance financial interactions with
                top security and user satisfaction.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-md-5 mb-4">
            <div className="card ApiCard p-4">
              <h3 className="c-txt-header  text-start mb-3">
                Third Party API Integration
              </h3>
              <p className="c-txt  text-start">
                Our API developers have the potential to leverage third-party
                and open-source APIs, offering diverse solutions for improved
                connectivity between apps, websites, and your business processes
                and systems.This approach increases efficiency and productivity,
                seamlessly incorporating unique features and external services.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-md-5 mb-4">
            <div className="card ApiCard p-4">
              <h3 className="c-txt-header  text-start mb-3">
                API Testing Automation
              </h3>
              <p className="c-txt  text-start">
                Ensure API accuracy and performance with our meticulous testing.
                Skilled developers implement thorough simulations, generating
                reports and optimizations. They ensure rich API performance
                assessment and are proficient in automated testing. Streamline
                your workflow, ensuring API reliability, functionality, and
                software quality.
              </p>
            </div>
          </div>
        </div>
        <div className="row px-6 my-md-4 mt-2 mb-4">
          <div className="col-12 text-center px-4 pb-2">
            <h2 className="Api-Title clr0E2B3D">
              Major Benefits of API Integration
            </h2>
            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div className="col-12">
            <div className="row">
              <NamePlateCard
                ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
                Image="/images/WebApiHighPerformance.svg"
                CustomHeaderClass="AU_Font5 NamePlateCardHeading"
                Header="Delivers exceptional <br/> performance"
                CustomClass="MajorBenefitsContainer px-md-5"
                alt="Web API High Performance Icon"
                title="Web API High Performance "
              />
              <NamePlateCard
                ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
                Image="/images/ApiInnovativeTab.svg"
                CustomHeaderClass="AU_Font5 NamePlateCardHeading"
                Header="Empowers inventive <br/> automation"
                CustomClass="MajorBenefitsContainer px-md-5"
                alt="Innovative API Tab Icon"
                title="Innovative API Tab "
              />
              <NamePlateCard
                ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
                Image="/images/ApiCustomerExperience.svg"
                CustomHeaderClass="AU_Font5 NamePlateCardHeading"
                Header="Faster turnaround <br/> time "
                CustomClass="MajorBenefitsContainer px-md-5"
                alt="API Customer Experience Icon"
                title="API Customer Experience "
              />
              <NamePlateCard
                ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
                Image="/images/ApiHumanError.svg"
                CustomHeaderClass="AU_Font5 NamePlateCardHeading"
                Header="Diminishes human errors"
                CustomClass="MajorBenefitsContainer px-md-5"
                alt="API Human Error Icon"
                title="API Human Error"
              />
              <NamePlateCard
                ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
                Image="/images/ApiDataExchange.svg"
                CustomHeaderClass="AU_Font5 NamePlateCardHeading"
                Header="Facilitates seamless <br/> data exchange"
                CustomClass="MajorBenefitsContainer px-md-5"
                alt="API Data Exchange Icon"
                title="API Data Exchange "
              />
              <NamePlateCard
                ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
                Image="/images/ApiBoost.svg"
                CustomHeaderClass="AU_Font5 NamePlateCardHeading"
                Header="Boosts scalability &<br/> flexibility"
                CustomClass="MajorBenefitsContainer px-md-5"
                alt="API Boost Icon"
                title="API Boost"
              />
            </div>
          </div>
        </div>
        <div className="row px-6 mb-md-5 mb-4 d-block">
          <div className="col-12  ">
            <div className="row ApiQuote mx-0">
              <div className="col-md-8 col-12 d-flex align-items-center  px-md-5 px-4">
                <div className="px-md-5 d-md-blockF d-flex justify-content-center align-items-center flex-column px-0">
                  <div className="ApiQuoteHeader">
                    Explore the Power of API Integration Today!
                  </div>
                  <br />
                  <button
                    className="btn btn CaseStudydBtn px-4 mb-4 mb-md-0"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    GET A FREE ESTIMATION
                    <img
                      className="img-fluid ms-2"
                      src="/images/personalizedWhiteArrow.svg"
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="col-md-4 col-12 text-center">
                <img
                  className="img-fluid py-4"
                  src="/images/ApiQuote.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row px-md-5 bgF7F7F7 pt-4 pb-md-5 HoverClass Feature">
            <div className="col-12 text-center px-4 py-3">
              <h2 className="clr0E2B3D fw-600">
                API Integration and API Development Protocols
              </h2>
              <img src="images/hrImg.png" alt="" className="img-fluid"></img>
            </div>
            <div className="row ms-md-2 getToKnowCardContainer ">
              <div className="col-md-6 col-12 getToKnowCard  ">
                <div className="col-12 AU_Box px-3 py-4 my-2 hover-bgBlue">
                  <h3 className="BlueClass fw-600 fs-18 mb-0">
                    REST (Representational State transfer)
                  </h3>
                  <div className="row pt-2 " style={{ textAlign: "justify" }}>
                    <div className="fs-15">
                      REST isn't a protocol; it's a set of principles guiding
                      web services design. REST APIs have long been favored as
                      lightweight protocols due to their easy development and
                      user experience, along with compatibility across
                      programming languages and platforms. But REST may not
                      always be ideal when dealing with complex data types or
                      security-sensitive apps.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 getToKnowCard">
                <div className="col-12 AU_Box px-3 py-4 my-2 hover-bgBlue">
                  <h3 className="BlueClass fw-600 fs-18 mb-0">
                    SOAP (Simple Object Access Protocol)
                  </h3>
                  <div
                    className="row pt-2 "
                    style={{ textAlign: "justify", height: "100%" }}
                  >
                    <div className="fs-15">
                      SOAP is a robust protocol with features like handling
                      complex data types and security. Its feature set is quite
                      broad and includes taking complex data types such as
                      attachments and nested structures. Consequently, it is
                      capable of integrating and exchanging complex data. Secure
                      data transmissions rely on encryption and message signing.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 getToKnowCard">
                <div className="col-12 AU_Box px-3 py-4 my-2 hover-bgBlue">
                  <h3 className="BlueClass fw-600 fs-18 mb-0">
                    XML-RPC (XML Remote Procedure Call)
                  </h3>
                  <div className="row pt-2 " style={{ textAlign: "justify" }}>
                    <div className="fs-15">
                      XML-RPC is a remote procedure call protocol designed for
                      remotely running server procedures from a distance, using
                      XML as with SOAP but more simply. HTTP messages typically
                      carry messages. While not as commonly employed as REST or
                      SOAP protocols, XML-RPC may still find uses within older
                      applications or when lightweight protocols are desired.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 getToKnowCard">
                <div className="col-12 AU_Box px-3 py-4 my-2 hover-bgBlue">
                  <h3 className="BlueClass fw-600 fs-18 mb-0">GraphQL</h3>
                  <div className="row pt-2 ">
                    <div className="fs-15">
                      GraphQL is a modern protocol, rising in popularity thanks
                      to its flexibility and user-friendliness. Clients can
                      request only what data they need from APIs without
                      downloading entire datasets; while its fast and secure
                      nature are appealing features. Unfortunately though,
                      GraphQL has yet to become widely adopted like some other
                      protocols are.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-md-5 mt-3 px-6">
          <div className="col-12 text-center px-4 pb-2">
            <h2 className="Api-Title clr0E2B3D">
              Our API Solutions and providers we work with
            </h2>
            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div className="col-12 pt-3">
            <div className="row">
              <div className="col-md-4 col-12  mb-3">
                <div className="ApiContent">
                  <h3>E-commerce APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/AmazonApi.svg"
                        alt="Amazon API Icon"
                        title="Amazon API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/StripeApi.svg"
                        alt="Stripe API Icon"
                        title="Stripe API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/PayPalApi.svg"
                        alt="PayPal API Icon"
                        title="PayPal API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Enterprise Grade CRM APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-3 text-center">
                      <img
                        className="img-fluid"
                        src="/images/A2Api.svg"
                        alt="A2 API Icon"
                        title="A2 API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/SalesForceApi.svg"
                        alt="Salesforce API Icon"
                        title="Salesforce API "
                      />
                    </div>
                    <div className="col-5 text-center">
                      <img
                        className="img-fluid"
                        src="/images/HubSpotApi.svg"
                        alt="HubSpot API Icon"
                        title="HubSpot API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>File Storage APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-2 text-center">
                      <img
                        className="img-fluid"
                        src="/images/DropBoxApi.svg"
                        alt="Dropbox API Icon"
                        title="Dropbox API "
                      />
                    </div>
                    <div className="col-2 text-center">
                      <img
                        className="img-fluid"
                        src="/images/DriveApi.svg"
                        alt="Drive API Icon"
                        title="Drive API "
                      />
                    </div>
                    <div className="col-2 text-center">
                      <img
                        className="img-fluid"
                        src="/images/CloudApi.svg"
                        alt="Cloud API Icon"
                        title="Cloud API "
                      />
                    </div>
                    <div className="col-5 text-center">
                      <img
                        className="img-fluid"
                        src="/images/AwsS3Api.svg"
                        alt="AWS S3 API Icon"
                        title="AWS S3 API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Issue Tracking & Project Management APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-3 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ZendeskApi.svg"
                        alt="Zendesk API Icon"
                        title="Zendesk API"
                      />
                    </div>
                    <div className="col-5 text-center">
                      <img
                        className="img-fluid"
                        src="/images/FreshDeskApi.svg"
                        alt="FreshDesk API Icon"
                        title="FreshDesk API "
                      />
                    </div>
                    <div className="col-2 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ApiIntegration1.svg"
                      />
                    </div>
                    <div className="col-2 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ApiIntegration2.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Email Marketing APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/RapidApi.svg"
                        alt="Rapid API Icon"
                        title="Rapid API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/SendGridApi.svg"
                        alt="SendGrid API Icon"
                        title="SendGrid API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/OutApi.svg"
                        alt="Out API Icon"
                        title="Out API - SprigStack"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Calendar APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/GoogleCalenderApi.svg"
                        alt="Google Calendar API Icon"
                        title="Google Calendar API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/OutLookCalender.svg"
                        alt="Outlook Calendar Icon"
                        title="Outlook Calendar "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/CalendyApi.svg"
                        alt="Calendy API Icon"
                        title="Calendy API"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Customer Support APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ZendeskApi.svg"
                        alt="Zendesk API Icon"
                        title="Zendesk API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ZohoApi.svg"
                        alt="Zoho API Icon"
                        title="Zoho API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/FreshDeskApi.svg"
                        alt="FreshDesk API Icon"
                        title="FreshDesk API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Cloud Service Platform (CSP) APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/AwsApi.svg"
                        alt="AWS API Icon"
                        title="AWS API "
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ApiIntegration3.svg"
                        alt=""
                      />
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/GoogleCloudApi.svg"
                        alt="Google Cloud API Icon"
                        title="Google Cloud API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Accounting APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-6 text-center">
                      <img
                        className="img-fluid"
                        src="/images/FreshBooksApi.svg"
                        alt="FreshBooks API Icon"
                        title="FreshBooks API "
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        className="img-fluid"
                        src="/images/QbApi.svg"
                        alt="Qb API Icon"
                        title="Qb API "
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ApiIntegration4.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Google Console APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-3 text-center">
                      <img
                        className="img-fluid"
                        src="/images/GoogleCalenderApi.svg"
                        alt="Google Calendar API Icon"
                        title="Google Calendar API "
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        className="img-fluid"
                        src="/images/DriveApi.svg"
                        alt="Drive API Icon"
                        title="Drive API "
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        className="img-fluid"
                        src="/images/GoogleSheetsApi.svg"
                        alt="Google Sheets API Icon"
                        title="Google Sheets API"
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        className="img-fluid"
                        src="/images/RecaptchaApi.svg"
                        alt="reCAPTCHA API Icon"
                        title="reCAPTCHA API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Time Registration APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-md-5 col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ClockifyApi.svg"
                        alt="Clockify API Icon"
                        title="Clockify API "
                      />
                    </div>
                    <div className="col-md-5 col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ClickupApi.svg"
                        alt="ClickUp API Icon"
                        title="ClickUp API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Subscription Billing APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-6 text-center">
                      <img
                        className="img-fluid"
                        src="/images/RazorpayApi.svg"
                        alt="Razorpay API Icon"
                        title="Razorpay API "
                      />
                    </div>
                    <div className="col-6 text-center">
                      <img
                        className="img-fluid"
                        src="/images/EasypayApi.svg"
                        alt="Easypay API Icon"
                        title="Easypay API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Automation APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-6 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ZendeskApi.svg"
                        alt="Zendesk API Icon"
                        title="Zendesk API "
                      />
                    </div>
                    <div className="col-6 text-center">
                      <img
                        className="img-fluid"
                        src="/images/ZaiperApi.svg"
                        alt="Zapier API Icon"
                        title="Zapier API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>Shipping Carriers APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-md-5 col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/FedexApi.svg"
                        alt="FedEx API Icon"
                        title="FedEx API "
                      />
                    </div>
                    <div className="col-md-5 col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/DhlApi.svg"
                        alt="DHL API Icon"
                        title="DHL API "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div className="ApiContent">
                  <h3>SMS and Communication APIs:</h3>
                  <div className="row mt-2 d-flex justify-content-center align-items-center">
                    <div className="col-md-5 col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/PlivoApi.svg"
                        alt="Plivo API Icon"
                        title="Plivo API "
                      />
                    </div>
                    <div className="col-md-5 col-4 text-center">
                      <img
                        className="img-fluid"
                        src="/images/NexApi.svg"
                        alt="Nexmo API Icon"
                        title="Nexmo API "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-md-5 mt-3">
          <div className="col-12 text-center px-4 pb-2">
            <h2 className="Api-Title clr0E2B3D">
              Industries Served with our API Integration Services
            </h2>
            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div className="col-12 pt-3">
            <div className="row  px-6">
              <IndustryServingsHoverComp
                imgSrc="/images/Retail_Blue.svg"
                hoverImg="/images/Retail_Orange.svg"
                cardTitle="Retails & e-commerce"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Communication-blue.svg"
                hoverImg="/images/Communication_orange.svg"
                cardTitle="Communication"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Logistics_blue.svg"
                hoverImg="/images/Logistics_orange.svg"
                cardTitle="Logistics"
                ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
                imgBorder=""
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Sales_Blue.svg"
                hoverImg="/images/Sales_Orange.svg"
                cardTitle="Sales"
                ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
                imgBorder=""
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Weather_Blue.svg"
                hoverImg="/images/Weather_Orange.svg"
                cardTitle="Weather"
                ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Marketing_Blue.svg"
                hoverImg="/images/Marketing_Orange.svg"
                cardTitle="Marketing"
                ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Maps_Blue.svg"
                hoverImg="/images/Maps_Orange.svg"
                cardTitle="Navigation and Maps"
                ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/ItSolution__Blue.svg"
                hoverImg="/images/ItSolution_Orange.svg"
                cardTitle="IT Solution"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Sports_Blue.svg"
                hoverImg="/images/Sports_Orange.svg"
                cardTitle="Sports"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Coworking_Blue.svg"
                hoverImg="/images/Coworking_Orange.svg"
                cardTitle="Coworking"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />

              <IndustryServingsHoverComp
                imgSrc="/images/RealEstate_Blue.svg"
                hoverImg="/images/RealEstate_Orange.svg"
                cardTitle="Real Estate"
                ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
                imgBorder=""
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Travel_Blue.svg"
                hoverImg="/images/Travel_Orange.svg"
                cardTitle="Travel"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Education_Blue.svg"
                hoverImg="/images/Education_Orange.svg"
                cardTitle="Education"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/FoodDeliveryBlue.svg"
                hoverImg="/images/FoodDeliveryOrange.svg"
                cardTitle="Food Delivery"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Banking&FinanceBlue.svg"
                hoverImg="/images/Banking&FinanceOrange.svg"
                cardTitle="Banking & Finance"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
              <IndustryServingsHoverComp
                imgSrc="/images/Lifestyle&FashionBlue.svg"
                hoverImg="/images/Lifestyle&FashionOrange.svg"
                cardTitle="Lifestyle & Fashion"
                ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
                ContainerSizeName="IS_Container p-3"
              />
            </div>
          </div>
        </div>

        <div className="row pb-md-5 pt-4 pt-md-0 pb-0">
          <PersonalizedComp
            cardTitle="Get API Integration Services now!"
            ContainerColor="bgCl0E2B3D"
            flag="2"
          />
        </div>
        <div className="row pt-md-5 pt-4 bgF7F7F7">
          <div className="col-12 text-center px-4 pb-2">
            <h2 className="Api-Title clr0E2B3D">
              Why choose us for API Integration Services ?
            </h2>
            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div className="col-12">
            <div className="row px-6 pb-md-5 pb-4 pt-4">
              <div className="col-md-4 col-12">
                <div className="ApiService">
                  <div>
                    <img
                      className="p-4 img-fluid"
                      src="/images/ApiService1.svg"
                      alt="Seamless Growth-Oriented Solutions"
                      title="Seamless Growth-Oriented Solutions"
                    />
                    <h3 className="px-4  ApiServiceHeader">
                      Seamless Growth-Oriented Solutions
                    </h3>
                    <p className="px-4 pb-4">
                      Our API integration services are designed to expand with
                      you quickly. It's like having solutions that grow as your
                      needs change, making adjusting and meeting new demands
                      simple.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4 mt-md-0 col-12">
                <div className="ApiService">
                  <div>
                    <img
                      className="p-4 img-fluid"
                      src="/images/ApiService2.svg"
                      alt="Flawless API Integration Expertise"
                      title="Flawless API Integration Expertise"
                    />
                    <h3 className="px-4  ApiServiceHeader">
                      Flawless API Integration Expertise
                    </h3>
                    <p className="px-4 pb-4">
                      Our skilled developers ensure that integrating APIs is
                      smooth and easy, enabling you to access various functions
                      and third-party services without any impact on
                      performance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4 mt-md-0 col-12">
                <div className="ApiService">
                  <div>
                    <img
                      className="p-4 img-fluid"
                      src="/images/ApiService3.svg"
                      alt="Reliable Assistance for Your Needs"
                      title="Reliable Assistance for Your Needs"
                    />
                    <h3 className="px-4  ApiServiceHeader">
                      Reliable Assistance for Your Needs
                    </h3>
                    <p className="px-4 pb-4">
                      We value lasting client relationships. Reach out anytime
                      for help with API-related matters. Trust us for consistent
                      assistance during integration and beyond.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FAQDynamicComp />

        <Popups
          show={show}
          setShow={setShow}
          title="FREE CONSULTATION"
          size="lg"
        >
          <PersonalizedActionModal />
        </Popups>
      </div>
    </React.Fragment>
  );
}
