import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

export default function SprigStackGallery(props) {
  return (
    <>
      <ImageGallery
        useBrowserFullscreen={false}
        showIndex={true}
        autoPlay={true}
        loading={true}
        items={props.galleryImages}
      />
    </>
  );
}
