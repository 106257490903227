import React, { useState } from "react";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import { DescriptiveCard } from "./Components/DescriptiveCard";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
import { HeaderWithDynamicContent } from "./Components/HeaderWithDynamicContent";
import { ContentAndImage } from "./Components/ContentAndImage";
import PersonalizedActionModal from "./Modals/PersonalizedActionModal";
import Popups from "./Modals/Popups";
import IndustryServingsHoverComp from "./Components/IndustryServingsHoverComp";
import CrmFeatures from "./Components/CrmFeatures";
import { useNavigate } from "react-router-dom";
import CrmErpCta from "./Components/CrmErpCta";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import CrmErpLatestProjects from "./Components/CrmErpLatestProjects";
import WhyChooseUsComp from "./Components/WhyChooseUsComp";

export default function CrmDevelopment(props) {
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/crm-service1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Custom CRM Development Solutions"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Our expertise is in creating unique CRM software solutions that automate marketing, sales, and customer support to boost productivity and maintain relationships with clients. Our services optimize marketing strategies and service processes for smooth management by providing direct access to customer data."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/crm-service2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="CRM Integration"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Integrate your CRM with other business systems seamlessly to guarantee efficient data flow and boost productivity. Our area of expertise is integrating multiple platforms with CRM systems to provide unified customer data throughout your business's operations."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/crm-service3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="CRM Migration"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Maintain data integrity and business continuity by moving your CRM data to a new system in a seamless and minimally disruptive manner. Our in-house CRM development services enable a secure and quick migration while maintaining productivity throughout the shift."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/crm-service4.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="Mobile CRM Solutions"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Give your sales staff the tools they need with mobile CRM apps to manage and access client data while on the road, increasing efficiency and response. Using smartphones, tablets, and other portable devices, our mobile client management solutions enable real-time data access and collaborative processes."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/crm-service5.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Cloud-Based CRM Development"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Secure, scalable cloud-based CRM solutions that guarantee flexibility, accessibility, and real-time data synchronization from any location are our specialty. Easily maintain contact with your consumers by taking advantage of round-the-clock access to vital client data, whether in the office or in distant places."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/crm-service6.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center"
      Header="CRM Consulting"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Our services help you choose the best CRM system and adapt your approach to yield the greatest possible returns. Consultants with experience offer strategic advice that maximizes return on investment and ensures alignment with business objectives."
    />
  );

  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/crm-benefit1.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Centralized Customer <br/> Data"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/crm-benefit2.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Perfect Business- <br/> Technology Fit"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/crm-benefit3.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="A Simple to Use Custom <br/> Solution"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/crm-benefit4.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Improved User Adoption <br/> and Satisfaction"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/crm-benefit5.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Better Customer <br/> Retention"
      CustomClass="MajorBenefitsContainer"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
      Image="./images/crm-benefit6.svg"
      CustomHeaderClass="AU_Font5 NamePlateCardHeading"
      Header="Increased Sales Results"
      CustomClass="MajorBenefitsContainer"
    />
  );

  var whyChooseUsContent = [];
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/crm-whychooseus1.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Expert Team, Agile Approach"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={` Our experienced CRM developers combine their expertise with a stable yet adaptable development process to deliver results on time and within budget.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-2 pt-4"
      Image="./images/crm-whychooseus2.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Quality You Can Trust"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={`We deliver high-quality, well-coded CRM solutions that are built to last, ensuring a smooth user experience and a strong return on your investment.`}
    />
  );
  whyChooseUsContent.push(
    <WhyChooseUsComp
      ColSize="col-md-4 col-12 pb-md-5 pb-5 pt-4"
      Image="./images/crm-whychooseus3.svg"
      CustomHeaderClass="TpPlaceFont fontColorBlue align-self-center text-m-center"
      Header="Future-Proof Technology"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body={`We leverage the latest CRM technologies to create cutting-edge solutions that can scale with your business and stay ahead of the curve.`}
    />
  );
  return (
    <>
      <HelmetMetaTag
        titleName="Custom CRM Development Services | CRM Software Solutions"
        desc="Boost Custom CRM Development Services for growing businesses. Our scalable CRMs integrate with your workflows, boosting sales and customer satisfaction."
        canonical="https://sprigstack.com/crm-development-services"
        ogtitle="Custom CRM Development Services | CRM Software Solutions"
        ogdesc="Custom CRM Development Services for growing businesses. Our scalable CRMs integrate with your workflows, boosting sales and customer satisfaction."
        ogtype="website"
        ogurl="https://sprigstack.com/crm-development-services"
        ogimg="https://sprigstack.com/images/crmHerosection.png"
      />
      <ContentAndImage
        h1Tag={true}
        ImagePosition="left"
        DescriptionHeader="Custom CRM Development Services"
        CustomHeaderClass="C_font1"
        CustomBodyClass="C_font2"
        Image="./images/crmHerosection.png"
        alt="Custom CRM Development Services"
        title="Custom CRM Development Services"
        CustomImageClass="img-fluid"
        CardMargin=" px-lg-6 px-sm-6"
        CustomDescriptionBoxClass="p-lg-4 px-4"
        Description="Stop struggling with generic CRM software. Our custom CRM development services create the perfect solution for your business. Whether modifying an existing CRM or developing a new platform, our scalable systems align with your unique needs, enhancing customer service and satisfaction. We build custom CRMs that seamlessly integrate with your unique workflows and processes. As a CRM development company, our focus is on creating solutions that drive sales, streamline operations, and keep your customers happy."
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3 text-center"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 homePageGYB"
        bodyClassCareerRequirement="d-none"
        IsShowButton="HeroSection"
        IsShowButtonShow={true}
        HeroSection={true}
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor="#F7F7F7"
        Header="Our Key CRM Development <br/> Services include"
        ContainerBottomPadding="pb-md-3 pt-5"
        Content={serviceConent}
        HeaderPadding="ourKeyHeading"
        flag={true}
        pt={true}
      />

      <HeaderWithDynamicContent
        BgColor=""
        Header="Major Benefits of our custom CRM Development"
        HeaderPadding="majorBenefitHeading"
        ContentCustomClass=""
        Content={webAppBenefitContent}
        flag={true}
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="Our Latest CRM project"
        HeaderPadding="majorBenefitHeading"
        flag={true}
      />
      {/* <div className="mt-md-3 mt-3 zoom-container">
        <div className="row crmaddress2 d-flex justify-content-center align-items-center p-md-3 pb-5">
          <div className="col-md-6 text-center">
            <img
              src="/images/AddressTwoMockup.webp"
              alt="address2"
              className="img-fluid max60 mt-4"
            />
          </div>
          <div className="col-md-6 text-center text-md-start">
            <h3 className="crmheading">AddressTwo - Small Business CRM</h3>
            <br />
            <label className="crmTechnology">
              Technologies Used:{" "}
              <span style={{ fontWeight: "300" }}>
                Asp.net core, Angular.js, MySql, Bootstrap
              </span>
            </label>
            <br />

            <div
              className="px-0 desktop mt-5"
              id="container"
              style={{ textAlign: "-webkit-left" }}
            >
              <button
                className="crmBtnEffect learn-more"
                onClick={() => {
                  window.open(
                    "/portfolio/custom-crm-development-for-address-two",
                    "_blank"
                  );
                }}
              >
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                </span>
                <span className="button-text">Know More</span>
              </button>
            </div>
            <div className="mobile" style={{ textAlign: "-webkit-center" }}>
              <div className="col-lg-8 col-md-8 m-auto mb-lg-4 col-8">
                <button
                  className="crmBtnMobileEffect mt-5"
                  onClick={() => {
                    window.open(
                      "/portfolio/custom-crm-development-for-address-two",
                      "_blank"
                    );
                  }}
                >
                  <img
                    className="img-fluid me-2"
                    src="/images/personalizedWhiteArrow.svg"
                    alt=""
                  />{" "}
                  Know More{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <CrmErpLatestProjects
        ProjectBackground="/images/address2crm.png"
        contentOrder="imageFirst"
        ProjectImage="AddressTwoMockup.webp"
        imgwidth="max60"
        Project="AddressTwo"
        TextColor="white"
        ProjectName="AddressTwo - Small Business CRM"
        ProjectTech="Asp.net core, Angular.js, MySql, Bootstrap"
        Btneffect="crmBtnEffect"
        BtnMobileEffect="crmBtnMobileEffect"
        ProjectUrl="/portfolio/custom-crm-development-for-address-two"
      />
      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="CRM Features"
        HeaderPadding="majorBenefitHeading"
        pt={true}
      />
      <div className="row px-6 mt-2">
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-1-hover.svg"
          imgUrl="/images/crm-feature-1.svg"
          text="Business Analytics"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-2-hover.svg"
          imgUrl="/images/crm-feature-2.svg"
          text="Virtual Assistants"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-3-hover.svg"
          imgUrl="/images/crm-feature-3.svg"
          text="Customer Data Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-4-hover.svg"
          imgUrl="/images/crm-feature-4.svg"
          text="Email Integration"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-5-hover.svg"
          imgUrl="/images/crm-feature-5.svg"
          text="Marketing"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-6-hover.svg"
          imgUrl="/images/crm-feature-6.svg"
          text="Sales"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-7-hover.svg"
          imgUrl="/images/crm-feature-7.svg"
          text="Sales forcasting"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-8-hover.svg"
          imgUrl="/images/crm-feature-8.svg"
          text="Data Analysis"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-9-hover.svg"
          imgUrl="/images/crm-feature-9.svg"
          text="Customer Self Service"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-10-hover.svg"
          imgUrl="/images/crm-feature-10.svg"
          text="Customer Feedback Management"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-11-hover.svg"
          imgUrl="/images/crm-feature-11.svg"
          text="Customer Service"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-12-hover.svg"
          imgUrl="/images/crm-feature-12.svg"
          text="Lead Capture"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-13-hover.svg"
          imgUrl="/images/crm-feature-13.svg"
          text="Chatbots"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-14-hover.svg"
          imgUrl="/images/crm-feature-14.svg"
          text="Automated Campaign"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-15-hover.svg"
          imgUrl="/images/crm-feature-15.svg"
          text="Reporting"
        />
        <CrmFeatures
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          crmDiv="common-component text-center p-md-4 p-2"
          hoverImageUrl="/images/crm-feature-16-hover.svg"
          imgUrl="/images/crm-feature-16.svg"
          text="Customer Retention"
        />
      </div>

      {/* <div className="px-6 mt-md-5 mt-4">
        <div className="row crmQuote mx-0 p-md-4">
          <div className="col-md-8 col-12 d-flex align-items-center px-md-5 px-5 py-3 py-md-0">
            <div className="px-md-5 d-md-blockF d-flex justify-content-center align-items-center flex-column px-0">
              <div className="ApiQuoteHeader">
                Stop Wasting Time. Streamline Your Customer Journey with a
                Custom CRM.
              </div>
              <br />
              <button
                className="btn btn CaseStudydBtn px-4 mb-4 mb-md-0"
                onClick={() => {
                  setShow(true);
                }}
              >
                Let's discuss
                <img
                  className="img-fluid ms-2"
                  src="/images/personalizedWhiteArrow.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
          <div className="col-md-4 col-12 text-center">
            <img
              className="img-fluid py-1"
              src="/images/crm-quote.svg"
              alt=""
            />
          </div>
        </div>
      </div> */}
      <CrmErpCta
        CtaDiv="px-6 mt-md-5 mt-4"
        quoteName="crmQuote"
        TextCols="col-md-7 col-12 d-flex align-items-center px-5 py-3 py-md-5"
        ImgCols="col-md-5 col-12 p-1 p-md-0"
        ImgPadding="py-1"
        quoteText=" Stop Wasting Time. Streamline Your Customer Journey with a Custom CRM."
        CtaBtn="CaseStudydBtn"
        arrowImg="personalizedWhiteArrow.svg"
        quoteImg="/images/crm-quote.svg"
      />

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="Industries Served with our CRM Development Services"
        HeaderPadding="majorBenefitHeading"
        pt={true}
      />
      <div className="row mt-2 px-6">
        <IndustryServingsHoverComp
          imgSrc="/images/Retail_Blue.svg"
          hoverImg="/images/Retail_Orange.svg"
          cardTitle="Retails & e-commerce"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Communication-blue.svg"
          hoverImg="/images/Communication_orange.svg"
          cardTitle="Communication"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/hospitality-crm-blue.svg"
          hoverImg="/images/hospitality-crm-orange.svg"
          cardTitle="Hospitality"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
          imgBorder=""
        />
        <IndustryServingsHoverComp
          imgSrc="/images/manufacturinfg-crm-blue.svg"
          hoverImg="/images/manufacturinfg-crm-orange.svg"
          cardTitle="Manufacturing"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
          imgBorder=""
        />
        <IndustryServingsHoverComp
          imgSrc="/images/healthcare-crm-blue.svg"
          hoverImg="/images/healthcare-crm-orange.svg"
          cardTitle="Healthcare"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/construction-crm-blue.svg"
          hoverImg="/images/construction-crm-orange.svg"
          cardTitle="Construction"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/ItSolution__Blue.svg"
          hoverImg="/images/ItSolution_Orange.svg"
          cardTitle="IT Solution"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Travel_Blue.svg"
          hoverImg="/images/Travel_Orange.svg"
          cardTitle="Travel"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Education_Blue.svg"
          hoverImg="/images/Education_Orange.svg"
          cardTitle="Education"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Banking&FinanceBlue.svg"
          hoverImg="/images/Banking&FinanceOrange.svg"
          cardTitle="Banking & Finance"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
        <IndustryServingsHoverComp
          imgSrc="/images/RealEstate_Blue.svg"
          hoverImg="/images/RealEstate_Orange.svg"
          cardTitle="Real Estate"
          ContainerSizeClass="pb-md-3  col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3 h-100 justify-contentSpaceBtw"
          imgBorder=""
        />
        <IndustryServingsHoverComp
          imgSrc="/images/Lifestyle&FashionBlue.svg"
          hoverImg="/images/Lifestyle&FashionOrange.svg"
          cardTitle="Lifestyle & Fashion"
          ContainerSizeClass="pb-md-3 col-lg-3 col-md-3 col-6 px-md-4 p-2"
          ContainerSizeName="IS_Container p-3"
        />
      </div>

      <HeaderWithDynamicContent
        HrLine={props.isHrLine}
        BgColor=""
        Header="What our clients think about us!"
        HeaderPadding="majorBenefitHeading"
        pt={true}
      />
      <div className="px-6 text-center mt-4">
        <img
          src="/images/address2-client.svg"
          alt="client"
          style={{ borderRadius: "50%" }}
        />
        <h4 className="fontPoppins mt-3">Jason Barresi</h4>
        <h6 className="fontPoppins">Director at Address Two</h6>
        {/* <img src="/images/video_icon.png" alt="" className={`clickable`}></img> */}
        <h6 className="custom-blockquote fontPoppins text-justify mt-3">
          We've been working with SprigStack for several years now and couldn't
          be happier with the partnership. As a result, we use them as our only
          third-party agency for this and new projects across our subsidiaries.
        </h6>
      </div>

      <HeaderWithDynamicContent
        BgColor="#F7F7F7"
        Header="Why choose us for CRM development Services?"
        HeaderPadding="whyChooseUsHeading"
        ContentCustomClass="whyChooseUsBox"
        Content={whyChooseUsContent}
      />

      <FAQDynamicComp />
    </>
  );
}
