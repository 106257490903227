import React, { useLayoutEffect, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NewNavbar } from "../NewChanges/NewNavbar";
import Footer from "./Footer";
import $ from "jquery";
import Popups from "../Modals/Popups";
import PersonalizedActionModal from "../Modals/PersonalizedActionModal";
import Cookies from "js-cookie";
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
    $("#navbarNavDropdown").removeClass("show");
  }, [location.pathname]);
  return children;
};

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(parseInt(Cookies.get("ModalCount")) || 0);
  // useEffect(() => {
  //   if (count === 0 || count === 1) {
  //     setTimeout(
  //       () => {
  //         setShow(true);
  //         setCount((prevCount) => prevCount + 1);
  //         Cookies.set("ModalCount", count + 1, { expires: 1 });
  //       },
  //       count === 0 ? 45000 : 180000
  //     );
  //   }
  // }, [count]);

  useEffect(() => {
    if (count === 0 || count === 1) {
      const timeoutDuration = count === 0 ? 45000 : 180000;
      const timer = setTimeout(() => {
        setShow(true);
        setCount((prevCount) => {
          const newCount = prevCount + 1;
          Cookies.set("ModalCount", newCount, { expires: 1 });
          return newCount;
        });
      }, timeoutDuration);
  
      return () => clearTimeout(timer);
    }
  }, [count]);

  return (
    <>
      <Wrapper>
        <div className="container-fluid px-0">
          <NewNavbar />
          <div className="container-fluid px-0">
            <main className="LayoutMainDiv">{children}</main>
          </div>
          <Footer />
          <div>
            {show && (
              <Popups
                show={show}
                setShow={setShow}
                title="FREE CONSULTATION"
                size="lg"
              >
                <PersonalizedActionModal modalSource="(Auto Pop up)" />
              </Popups>
            )}

          </div>
        </div>
      </Wrapper>
    </>
  );
};
export default Layout;
