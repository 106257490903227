import React from "react";
import { ContentAndImage } from "./Components/ContentAndImage";
import { BreadCrumbCard } from "./Components/BreadCrumbCard";
import { NamePlateCard } from "./Components/NamePlateCard";
import { DevelopmentCommonFunc } from "./Components/DevelopmentCommon";
import HelmetMetaTag from "./Components/HelmetMetaTag";
import FAQDynamicComp from "./DynamicComponent/FAQDynamicComp";
export default function DevOps() {
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/devopsKey1.svg"
      ImageClass="ImgHeight3"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="CI/CD Pipelines"
      CustomBodyClass="serviceBodyFonts letterSpacing2px ourKeyCardtext"
      Body="It is very to have a defined set of actions to be taken for the updation of a version of the software. So for this, CI/CD Pipelines make the processing time-saving."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/devopsKey2.svg"
      ImageClass="ImgHeight3"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="DevSecOps Services"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Proficient development, security and reliable operations are our main set of activities. Our team is taking care of the quality service to grow your business exponentially. "
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/devopsKey3.svg"
      ImageClass="ImgHeight3"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Automation"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="In this era, automation is considered the best way to accelerate the business process. We configure automation based on the project requirements and its scope."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/devopsKey4.svg"
      ImageClass="ImgHeight3"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Microservices"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Dividing applications into smaller, independent services makes it easier to scale and allows for faster iterations through continuous integration and delivery (CI/CD) pipelines. This promotes agile development and deployment."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/devopsKey5.svg"
      ImageClass="ImgHeight3"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="Release Management"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="By managing software releases, you can minimize downtime and maintain version control, while planning, scheduling, and deploying software releases more efficiently.It also ensures seamless transitions between development, testing, and production environments."
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/devopsKey6.svg"
      ImageClass="ImgHeight3"
      CustomHeaderClass="TpPlaceFont clr0E2B3D"
      Header="QAOps"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="By automating quality assurance procedures into the software development lifecycle, QAOps facilitates faster feedback loops, continuous improvement, and better quality deliverables."
    />
  );

  var webAppBenefitContent = [];
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg1.svg"
      CustomHeaderClass="AU_Font5"
      Header="It boosts your <br/> credibility"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg2.svg"
      CustomHeaderClass="AU_Font5"
      Header="It improves User <br/> Engagement Rates"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg3.svg"
      CustomHeaderClass="AU_Font5"
      Header="Allows You To<br/> Reach More People"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg4.svg"
      CustomHeaderClass="AU_Font5"
      Header="Gives You A <br/>Competitive Advantage"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg5.svg"
      CustomHeaderClass="AU_Font5"
      Header="Supports Organic<br/> Content"
    />
  );
  webAppBenefitContent.push(
    <NamePlateCard
      ColSize="col-md-4 py-4"
      Image="./images/BenefitsImg6.svg"
      CustomHeaderClass="AU_Font5"
      Header="Cost Effective<br/> Marketing"
    />
  );
  var processWeFollowConent = [];
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/devOpsVector1.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="1. Requirement Gathering"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Very first step for building an impactful website or application is putting a well defined requirements together. That is the first and key step we follow."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/devOpsVector2.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="2. Defining Technology"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Websites can be built in numerous different technologies. We choose the best suitable according to the needs and capabilities of the website we are building."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/devOpsVector3.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="3. UI/UX Design"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Look & feel is very important for websites to attract more and more users. Overall experience and user friendlyness is equally important. This is key step in website development."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-3"
      Image="./images/devOpsVector4.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="4. Prototype "
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Based on theme and look that we finalized in the previous step, we provide working prototype to clients to let them understand how will be the end result."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/devOpsVector5.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="5. Development & QA"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="Development phase is started as soon as requirements are clear and all or some designs are already finalized. This is the heart of web appliction and it has to be done by experts."
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/devOpsVector6.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="6. Deployment"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="When development & QA process is complete and website is ready to be moved to production server, that phase is called Deployment. We take care of it and make sure transition is smooth. "
      IsCard="processWeFollowContainer"
    />
  );
  processWeFollowConent.push(
    <BreadCrumbCard
      ColSize="col-md-4"
      Image="./images/devOpsVector7.svg"
      CustomHeaderClass="processWeFollowHeaderFont clr0E2B3D"
      Header="7. Support & Maintenance"
      CustomClass="textandItemCenter"
      CustomBodyClass="processWeFollowFonts"
      Body="After successful deployment on production server, we make sure everything works as expected and we maintain them as long as clients want us to. We have 100% maintenence retention ratio for our work. "
      IsCard="processWeFollowContainer"
    />
  );
  var whChooseUseLeftBox = [];
  whChooseUseLeftBox.push(
    <>
      <div className=" px-md-5">
        <div className="row">
          <div className="col-12 fontSizeEighteen">
            Our services contain quality and consistency for the operation and
            development system. We focus on continuous deployment, integration
            and process improvement. Our services provide a robust system that
            provides real-time results.
          </div>
        </div>
        <div className="row mt-4">
          <ul className="devOpsUl">
            <li>Transparency in the Process</li>
            <li>Excellent Essence</li>
            <li>Integrated Approach</li>
            <li>High-quality Applications</li>
            <li>Diverse Automation</li>
          </ul>
        </div>
      </div>
    </>
  );
  var whyChooseUsContent = (
    <>
      <div className="mt-4"></div>
      <ContentAndImage
        ImagePosition="left"
        DescriptionHeader=""
        Image="./images/whchNewDevops.svg"
        alt=" DevOps"
        title="DevOps Infographic"
        CardMargin="d-flex align-items-center mt-lg-4 pt-lg-0 pt-4 whyChooseContentCss"
        CustomDescriptionBoxClass="p-lg-2"
        Description={whChooseUseLeftBox}
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12"
        CustomHeaderClass="WhyChooseContentbox"
        bodyClassCareerRequirement="d-none"
      />
    </>
  );
  var HeroSection = {
    ImagePosition: "left",
    DescriptionHeader: "Devops - Development & Operations Services",
    CustomHeaderClass: "C_font1",
    CustomBodyClass: "C_font2 herotextJustify",
    Image: "./images/devopsMainImg.svg",
    alt: " Devops",
    title: "Devops Services",
    CustomImageClass: "heroImgCss",
    CardMargin: "px-lg-6 px-sm-6",
    CustomDescriptionBoxClass: "p-lg-4 px-4",
    Description:
      "We are experts in offering DevOps consulting services to streamline your operations and improve workflow. Our team is committed to creating technologies that improve user experience and reduce mistakes. We can react to your individual needs and offer specialised solutions thanks to our expertise in DevOps operations for various business categories.",
    customDescriptionItemFirst:
      "col-lg-6 col-md-6 col-12 px-md-5 pt-md-5 pb-0 p-3",
    customDescriptionItemSecond: "col-lg-6 col-md-6 col-12 homePageGYB",
    bodyClassCareerRequirement: "d-none",
  };
  var OurKey = {
    BgColor: "#F7F7F7",
    Header: "DevOps Key Offerings",
    ContainerBottomPadding: "pb-md-3 pt-5 mt-5",
    HeaderPadding: "ourKeyHeadingDevops",
    flag: true,
    Content: [serviceConent],
  };
  var PersonalizedCompFirst = {
    cardTitle: "Streamline your development <br/> with our DevOps services!",
    ContainerColor: "FFFPersonalize",
    flag: "1",
  };
  var UiUxProcess = {
    BgColor: "#F7F7F7",
    Header: `Web Development Process That We Follow`,
    ContainerBottomPadding: "pb-2",
    ContentCustomClass: "justifyCenter",
    Content: [processWeFollowConent],
  };
  var PersonalizedCompSecond = {
    cardTitle:
      "Ready to elevate your project with top-tier DevOps services? <br /> Let's connect!",
    ContainerColor: "cA49F9D",
    flag: "2",
  };
  //Ready to elevate your project with top-tier DevOps services? Let's connect!
  var WhyChooseUs = {
    BgColor: "#F7F7F7",
    Header: `Why Choose SprigStack for DevOps?`,
    HeaderPadding: "whyChooseUsHeadingApp",
    ContentCustomClass: "whyChooseUsBox",
    Content: [whyChooseUsContent],
  };
  var DevOpsServices = [];
  var ProcessAutomation = (
    <>
      <div className="bgF7F7F7 mt-md-4 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="right"
          DescriptionHeader="1. Framework Design"
          DescriptionBottomLineAndButton="true"
          Image="./images/DevOpsServicesImg6.svg"
          alt=" Framework Design"
          title="Services"
          CustomImageClass="Framework Design img-fluid"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="It is very necessary to define a framework for a project. The process of development is depending upon the framework selected so, we always consider planning and selecting the best framework design. "
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(ProcessAutomation);
  var SupportMonitoring = (
    <>
      <div className="bgF7F7F7 mt-3 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="left"
          DescriptionHeader="2. Data Security & Monitoring"
          DescriptionBottomLineAndButton="true"
          Image="./images/DevOpsServicesImg5.svg"
          alt=" Data Security & Monitoring"
          title="Data Security & Monitoring"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="It is very important to ensure the data security of any software or web application. Our team considers the data stored at a place where it is safe and monitored from time to time."
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(SupportMonitoring);

  var UpdatePipelineQuality = (
    <>
      <div className="bgF7F7F7 mt-3 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="right"
          DescriptionHeader="3. Automated Pipelines"
          DescriptionBottomLineAndButton="true"
          Image="./images/DevOpsServicesImg4.svg"
          alt=" Automated Pipelines"
          title="Automated Pipelines"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="Automated Pipelines, often known as the CI/CD process ensure the codes are always in sync with the conditions defined. This will ensure the efficiency of a project with versioning of the software. "
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(UpdatePipelineQuality);
  var DevOpsConsultingServices = (
    <>
      <div className="bgF7F7F7 mt-3 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="left"
          DescriptionHeader="4. Cloud Setup & Management"
          DescriptionBottomLineAndButton="true"
          Image="./images/DevOpsServicesImg3.svg"
          alt=" Cloud Setup & Management"
          title="Cloud Setup & Management"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="It is necessary to have proper storage to save data. Nowadays, it is common to use the cloud to store data, as it is the most accessible way to store data. We indulge in managing servers and backups to a highly scalable extent."
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB cloudSetupMob devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(DevOpsConsultingServices);

  var InitialFrameworkCreation = (
    <>
      <div className="bgF7F7F7 mt-3 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="right"
          DescriptionHeader="5. Existing Cloud Setup Review "
          DescriptionBottomLineAndButton="true"
          Image="./images/DevOpsServicesImg2.svg"
          alt=" Existing Cloud Setup Review"
          title="Existing Cloud Setup Review"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="We always believe in everything needs to be streamlined for better understanding. So, for that, we always conduct a detailed analysis of the existing system to ensure the right tools are used for further improvement."
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(InitialFrameworkCreation);
  var CapacityManagement = (
    <>
      <div className="bgF7F7F7 mt-3 py-lg-0 py-md-4 px-md-5 DevopsServicebgClr">
        <ContentAndImage
          ImagePosition="left"
          DescriptionHeader="6. Cost Management"
          DescriptionBottomLineAndButton="true"
          Image="./images/DevOpsServicesImg1.svg"
          alt=" Cost Management"
          title="Cost Management"
          CustomImageClass="webDpManImage"
          CardMargin="px-5 py-md-4"
          CustomDescriptionBoxClass="px-lg-4"
          Description="It is very important to choose wisely at the time of data storage. After doing all the analysis, our team of expert DevOps guide you in choosing the right plans for the specified service."
          customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
          customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB devopsRemoveMArginMob"
          RedirectUrl=""
          CustomHeaderClass="NewServicePara mb-2 bold-h3 SetMobileHeader"
          bodyClassCareerRequirement="d-none"
          hrImgClass="d-none"
        />
      </div>
    </>
  );
  DevOpsServices.push(CapacityManagement);
  return (
    <>
      <HelmetMetaTag
        titleName="Expert Development & Operations Services | SprigStack"
        desc="Streamline your process with our DevOps services. Our experts help you automate, optimize, and monitor your applications for seamless operation. Contact us now!"
        canonical="https://sprigstack.com/devops"
        ogtitle="Expert Development & Operations Services | SprigStack"
        ogdesc="Streamline your process with our DevOps services. Our experts help you automate, optimize, and monitor your applications for seamless operation. Contact us now!"
        ogtype="website"
        ogurl="https://sprigstack.com/devops"
      />
      <DevelopmentCommonFunc
        HeroSection={HeroSection}
        Space={false}
        isDevOpsSection={true}
        isHrLine={false}
        DevOpsHeaderTitle="DevOps Services We Offer"
        DevOpsWeOfr="devopsServiceheadingCss"
        SubHeader=""
        DevOpsServices={DevOpsServices}
        OurKeySection={OurKey}
        PersonalizedCompOne={PersonalizedCompFirst}
        PersonalizedCompTwo={PersonalizedCompSecond}
        IsNewSection="no"
        isProjectManagementSection={true}
        TechnologySection={
          <>
            <div className="col-12 text-center pt-lg-5 pt-5">
              <h2 className="headerFontSizeH6 fontColorBlue devopsTechMob">
                DevOps tools that we work with
              </h2>
              <h3
                className="headerFontSizeH3 fontColorBlue pt-1 devopsTechMobsubHeading"
                style={{ lineHeight: "1.6em" }}
              >
                We provide consulting, setup and customization services
                <br />
                for following DevOps providers.
              </h3>
              <img
                src="/images/hrImg.png"
                alt=""
                className="img-fluid mt-1"
              ></img>
            </div>
            <div className="row">
              <div className="container">
                <div className="deskDispFlex px-5 pt-4 pb-md-0 pb-4 justify-content-center gridClass ">
                  <div className="px-4 text-center py-2">
                    <div className="techClass">
                      <img
                        src="./images/Technology/CloudService1.png"
                        className="img-fluid"
                        alt="AWS"
                        title="AWS"
                      />
                    </div>
                    <h4 className="techNameClass">AWS</h4>
                  </div>
                  <div className="px-4 text-center py-2">
                    <div className="techClass">
                      <img
                        src="./images/Technology/CloudService2.png"
                        className="img-fluid"
                        alt="Azure"
                        title="Azure"
                      />
                    </div>
                    <h4 className="techNameClass">Azure</h4>
                  </div>
                  <div className="px-4 text-center py-2">
                    <div className="techClass">
                      <img
                        src="./images/Technology/CloudService3.png"
                        className="img-fluid"
                        alt="Google Cloud"
                        title="Google Cloud"
                      />
                    </div>
                    <h4 className="techNameClass">Google Cloud</h4>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        WhyChooseUsSection={WhyChooseUs}
        FAQSection={<FAQDynamicComp />}
      />
    </>
  );
}
