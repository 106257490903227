import React from "react";
import { useNavigate } from "react-router-dom";
import "../../Css/HoverBtnCss.css";
export default function ButtonHoverEffect(props) {
  let navigate = useNavigate();
  return (
    <>
      <div
        className="px-0 desktop"
        id="container"
        style={{ textAlign: "-webkit-left" }}
      >
        <button
          className="hoverBtnEffect learn-more"
          onClick={() => {
            if (
              props.RedirectUrl != undefined &&
              props.RedirectUrl != null &&
              props.RedirectUrl != ""
            ) {
              if (props.IsRedirectToSamePage == "yes") {
                navigate(props.RedirectUrl);
              } else {
                window.open(`${props.RedirectUrl}`, "_blank");
              }
            }
          }}
        >
          <span className="circle" aria-hidden="true">
            <span className="icon arrow"></span>
          </span>
          <span className="button-text">
            {props.btn_text == true ? "Go to the Web site" : "Learn More"}{" "}
          </span>
        </button>
      </div>
      <div className="mobile" style={{ textAlign: "-webkit-center" }}>
        <div className="col-lg-8 col-md-8 m-auto mb-lg-4 col-8">
          <button
            className={`hoverBtnMobileEffect ${props.hoverButtonClass} `}
            onClick={() => {
              if (
                props.RedirectUrl != undefined &&
                props.RedirectUrl != null &&
                props.RedirectUrl != ""
              ) {
                if (props.IsRedirectToSamePage == "yes") {
                  navigate(props.RedirectUrl);
                } else {
                  window.open(`${props.RedirectUrl}`, "_blank");
                }
              }
            }}
          >
            <img
              className="img-fluid me-2"
              src="./images/rightArrow.png"
              alt=""
            />{" "}
            Learn More{" "}
          </button>
        </div>
      </div>
    </>
  );
}
