import React from "react";

export default function HeroSectionCard(props) {
  return (
    <React.Fragment>
      <div className={`${props.CustomCol}`}>
        <div className="bor-card1 hover-hero-card">
          <div className="col-12 text-md-start text-left text-md-center py-1  pt-0">
            <h3
              className={`clr0E2B3D headerFontSizeH6 mb-0 setlineheight  ${props.bgclass}`}
            >
              {props.header}
            </h3>
            <img src="/images/hrImg.png" alt="" className="img-fluid " />
          </div>
          <div className="col-12  ">
            <div className="hero-card-txt ">{props.Desc}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
