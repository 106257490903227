import React, { useState } from 'react'
import PersonalizedActionModal from '../Modals/PersonalizedActionModal';
import Popups from '../Modals/Popups';
export default function CrmErpCta(props) {
    const [show, setShow] = useState(false);
  return (
    <>
     <div className={`${props.CtaDiv}`}>
        <div className={`row ${props.quoteName} mx-0`}>
          <div className={`${props.TextCols}`}>
            <div className="px-md-5 text-center text-md-start">
              <div className="ApiQuoteHeader">
               {props.quoteText}
              </div>
              <br />
              <button
                className={`btn btn ${props.CtaBtn} px-4 mb-4 mb-md-0`}
                onClick={() => {
                  setShow(true);
                }}
              >
                Let's discuss
                <img
                  className="img-fluid ms-2"
                  src={`/images/${props.arrowImg}`}
                  alt=""
                />
              </button>
            </div>
          </div>
          <div className={`${props.ImgCols}`}>
            <img
              className={`img-fluid ${props.ImgPadding}`}
              src={props.quoteImg}
              alt=""
            />
          </div>
        </div>
      </div> 
      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">
        <PersonalizedActionModal />
      </Popups>
    </>
  )
}
